import styles from "./MarsRocks.module.scss";
import { ReactElement, useEffect, useState } from "react";
import { ROCK_TO_IMAGE, ROCK_NAMES, ROCK_TO_DEED } from "@scripts/RockHelper";
import circleCheck from "@assets/images/utils/circle_check.png";
import { IRock } from "@interfaces/Rock/Rock";

interface props {
    rocks: IRock[];
    onAddRock: (id: number) => void;
    onRemoveRock: (id: number) => void;
}

const MarsRocks = ({ rocks, onAddRock, onRemoveRock }: props): ReactElement => {
    const [selectedRocks, setSelectedRocks] = useState({});

    useEffect(() => {
        const selectedRocks = {};

        setSelectedRocks(selectedRocks);
    }, [rocks]);

    const toggleRock = (rockId: number, key: string) => {
        const updateSelectedRocks = JSON.parse(JSON.stringify(selectedRocks));
        updateSelectedRocks[key] = !updateSelectedRocks[key];
        setSelectedRocks(updateSelectedRocks);
        updateSelectedRocks[key] ? onAddRock(rockId) : onRemoveRock(rockId);
    };

    const showDeedImage = (e: React.MouseEvent<HTMLImageElement>, rockId: number) => {
        e.currentTarget.src = ROCK_TO_DEED[rockId];
    };

    const showRockImage = (e: React.MouseEvent<HTMLImageElement>, rockId: number) => {
        e.currentTarget.src = ROCK_TO_IMAGE[rockId];
    };

    return (
        <div className={styles.marsRocks}>
            {rocks.map(({ id, amount }) => {
                const elements: ReactElement[] = [];
                for (let i = 0; i < amount; i++) {
                    const key = `${id}-${i}`;
                    const currentRock = selectedRocks[key];

                    elements.push(
                        <div key={key}>
                            <img
                                src={ROCK_TO_IMAGE[id]}
                                alt={ROCK_NAMES[id]}
                                onMouseOver={(e) => showDeedImage(e, id)}
                                onMouseLeave={(e) => showRockImage(e, id)}
                            />
                            <div className={styles.rockBottom}>
                                <button
                                    className={currentRock ? "" : styles.unchecked}
                                    onClick={() => toggleRock(id, key)}
                                >
                                    {currentRock && <img src={circleCheck} alt="check" />}
                                </button>
                                <p>{ROCK_NAMES[id]}</p>
                            </div>
                        </div>
                    );
                }

                return elements;
            })}
        </div>
    );
};

export default MarsRocks;
