import { EComponentType } from "@customTypes/RocketComponent/ComponentType";
import { RefObject } from "react";
import { HARDCODED_ROCKETS } from "./RocketPartFilter.data";
import styles from "./RocketPartFilter.module.scss";

interface IRocketPartFilter {
    onClick: (brand: string, type: string) => void;
    refComponent: RefObject<HTMLDivElement>;
}

const RocketPartFilter = ({ onClick, refComponent }: IRocketPartFilter): JSX.Element => {
    const handleClick = (brand: string, type: string) => {
        onClick(brand, type);
        refComponent?.current && refComponent.current.scrollIntoView();
    };

    return (
        <div className={styles.container}>
            <span>Click on any component to discover the collection.</span>
            {HARDCODED_ROCKETS.map(({ image, brand }) => (
                <div key={brand} className={styles.container__rockets}>
                    <img src={image} alt="Rocket" />
                    <button className={styles.nose} onClick={() => handleClick(brand, EComponentType.NOSE)} />
                    <button className={styles.body} onClick={() => handleClick(brand, EComponentType.BODY)} />
                    <button className={styles.tail} onClick={() => handleClick(brand, EComponentType.TAIL)} />
                </div>
            ))}
        </div>
    );
};
export default RocketPartFilter;
