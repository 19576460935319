import { EMintType } from "@customTypes/Mint/MintType";
import { EScratcherType } from "@customTypes/Scratcher/ScratcherType";
import About from "@pages/About";
import Collections from "@pages/Collections";
import CollectionsDetail from "@pages/CollectionsDetail";
import ComponentDetail from "@pages/ComponentDetail";
import Faq from "@pages/Faq";
import Game from "@pages/Game";
import Home from "@pages/Home";
import HowItWorks from "@pages/HowItWorks";
import Footer from "@pages/Layout/components/Footer";
import Navbar from "@pages/Layout/components/Navbar";
import MyHangar from "@pages/MyHangar";
import NotFound from "@pages/NotFound";
import PhysicalScratcher from "@pages/PhysicalScratcher";
import ScratcherSuccessPage from "@pages/PhysicalScratcher/components/ScratcherSuccessPage";
import PlanetMintFinalFrontier from "@pages/PlanetMintFinalFrontier";
import PlanetMintHome from "@pages/PlanetMintHome";
import PlanetMintSoldOut from "@pages/PlanetMintSoldOut";
import PlanetMintSuccess from "@pages/PlanetMintSuccess";
import PlanetMintWithIdCard from "@pages/PlanetMintWithIdCard";
import PlanetMintWithRocks from "@pages/PlanetMintWithRocks";
import Privacy from "@pages/Privacy";
import PublicMint from "@pages/PublicMint";
import RocketDetail from "@pages/RocketDetail";
import StuffBetaGamaMint from "@pages/StuffBetaGamaMint";
import Terms from "@pages/Terms";
import Timeline from "@pages/Timeline";
import TimelineDetail from "@pages/TimelineDetail";
import Zine from "@pages/Zine";
import {
    ABOUT,
    APARTMENT_DETAIL,
    COLLECTIONS,
    COLLECTIONS_DETAIL,
    COMPONENT_DETAIL,
    DIGITAL_SCRATCHER_SUCCESS,
    FAQ,
    GAME,
    HOME,
    HOW_IT_WORKS,
    MY_HANGAR,
    PHYSICAL_SCRATCHER,
    PHYSICAL_SCRATCHER_SUCCESS,
    PLANET_DETAIL,
    PLANET_MINT_FINAL_FRONTIER,
    PLANET_MINT_HOME,
    PLANET_MINT_IDCARD,
    PLANET_MINT_ROCKS,
    PLANET_MINT_SOLD_OUT,
    PLANET_MINT_SUCCESS,
    PRIVACY,
    PUBLIC_MINT,
    ROCKET_DETAIL,
    ROCKET_DETAIL_NO_SLUG,
    ROCK_DETAIL,
    SCRATCHER_MINT,
    STARMAP,
    STARMAP_GALAXY,
    STARMAP_SYSTEM,
    STARMAP_ZONE,
    STUFF_BETA_GAMMA_MINT,
    STUFF_DETAIL,
    TERMS,
    TIMELINE,
    TIMELINE_DETAIL,
    ZINE,
} from "@routes/routes";
import { Route, Switch } from "react-router-dom";
import ExploreGalaxy from "../Starmap/ExploreGalaxy";
import Galaxy from "../Starmap/Galaxy";
import SystemDetail from "../Starmap/SystemDetail";
import ZoneDetail from "../Starmap/ZoneDetail";

const Layout = (): JSX.Element => (
    <>
        <header>
            <Navbar />
        </header>
        <main>
            <Switch>
                <Route exact path={STARMAP} component={ExploreGalaxy} />
                <Route exact path={STARMAP_GALAXY} component={Galaxy} />
                <Route exact path={STARMAP_ZONE} component={ZoneDetail} />
                <Route exact path={STARMAP_SYSTEM} component={SystemDetail} />
                <Route exact path={COLLECTIONS} component={Collections} />
                <Route exact path={COLLECTIONS_DETAIL} component={CollectionsDetail} />
                <Route exact path={PLANET_DETAIL} component={CollectionsDetail} />
                <Route exact path={APARTMENT_DETAIL} component={CollectionsDetail} />
                <Route exact path={ROCK_DETAIL} component={CollectionsDetail} />
                <Route exact path={ROCKET_DETAIL_NO_SLUG} component={RocketDetail} />
                <Route exact path={ROCKET_DETAIL} component={RocketDetail} />
                <Route exact path={COMPONENT_DETAIL} component={ComponentDetail} />
                <Route exact path={STUFF_DETAIL} component={CollectionsDetail} />
                <Route exact path={MY_HANGAR} component={MyHangar} />
                <Route exact path={HOW_IT_WORKS} component={HowItWorks} />
                <Route exact path={HOME} component={Home} />
                <Route exact path={PRIVACY} component={Privacy} />
                <Route exact path={TERMS} component={Terms} />
                <Route exact path={ABOUT} component={About} />
                <Route exact path={FAQ} component={Faq} />
                <Route exact path={ZINE} component={Zine} />
                <Route exact path={TIMELINE} component={Timeline} />
                <Route exact path={TIMELINE_DETAIL} component={TimelineDetail} />
                <Route exact path={PLANET_MINT_ROCKS} component={PlanetMintWithRocks} />
                <Route exact path={PLANET_MINT_FINAL_FRONTIER} component={PlanetMintFinalFrontier} />
                <Route exact path={PLANET_MINT_HOME} component={PlanetMintHome} />
                <Route exact path={PLANET_MINT_SUCCESS} component={PlanetMintSuccess} />
                <Route exact path={PLANET_MINT_IDCARD} component={PlanetMintWithIdCard} />
                <Route exact path={PUBLIC_MINT} component={PublicMint} />
                <Route exact path={PLANET_MINT_SOLD_OUT} component={PlanetMintSoldOut} />
                <Route exact path={GAME} component={Game} />
                <Route exact path={PHYSICAL_SCRATCHER} component={PhysicalScratcher} />
                <Route exact path={PHYSICAL_SCRATCHER_SUCCESS} component={ScratcherSuccessPage} />
                <Route exact path={STUFF_BETA_GAMMA_MINT} component={StuffBetaGamaMint} />
                <Route
                    exact
                    path={DIGITAL_SCRATCHER_SUCCESS}
                    render={(props) => <ScratcherSuccessPage scratcherType={EScratcherType.DIGITAL} {...props} />}
                />
                <Route
                    exact
                    path={SCRATCHER_MINT}
                    render={(props) => <PublicMint mintContent={EMintType.SCRATCHER} {...props} />}
                />
                <Route component={NotFound} />
            </Switch>
        </main>
        <footer>
            <Footer />
        </footer>
    </>
);

export default Layout;
