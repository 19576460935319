import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import { IDataCollection } from "@interfaces/Collections/DataCollection";
import { IMetadata } from "@interfaces/Metadata/Metadata";
import { IMetadataDetail } from "@interfaces/Metadata/MetadataDetail";
import { IMetadataMinted } from "@interfaces/Metadata/MetadataMinted";
import { getCategoryByContractSlug, getContractAddressBySlug, getSerialBySlug } from "./CollectionCategoryHelper";
import { getLinkCollection } from "./CollectionHelper";
import { getOpenSeaLink } from "./OpenSeaHelper";

export const apiMetadataResponseToCollection = ({ config, metadata }: IMetadataMinted): IDataCollection | undefined => {
    const dataCategory = getCategoryByContractSlug(config.contractSlug);

    if (!dataCategory) {
        return;
    }

    const link = getLinkCollection(
        dataCategory.path,
        config.tokenId.toString(),
        metadata.name,
        dataCategory.contractAddress
    );

    const customClass =
        dataCategory?.name === CollectionCategory.tickets && parseInt(config.tokenId) >= 6
            ? "tickets__used"
            : undefined;

    return {
        id: parseInt(config.tokenId),
        name: metadata.name,
        image: metadata.image,
        link,
        type: dataCategory.name,
        customClass,
    };
};

export const apiMetadataResponseToDetail = ({
    name,
    image,
    external_url,
    animation_url,
    description,
    attributes,
    mona_image,
    mona_link,
    model_link,
}: IMetadata): IMetadataDetail => {
    const tokenId = external_url.split("/")[4];
    const category_slug = external_url.split("/")[3];
    const contractAddress = getContractAddressBySlug(category_slug);
    const opensea_url = getOpenSeaLink(contractAddress, tokenId);
    const serialNumber = getSerialBySlug(category_slug, tokenId);

    return {
        tokenId,
        name,
        image,
        external_url,
        animation_url,
        description,
        opensea_url,
        category_slug,
        attributes,
        mona_image,
        mona_link,
        serialNumber,
        model_link,
    };
};

export const getFilterAttrsByCategoryCollection = (category: string, valueToSearch: string): string[] | undefined => {
    const apartmentsFilterAttrs = [`type:${valueToSearch}`, `style:${valueToSearch}`];
    const planetsFilterAttrs = [
        `world type:${valueToSearch}`,
        `galactic zone:${valueToSearch}`,
        `system name:${valueToSearch}`,
    ];

    return {
        [CollectionCategory.planets]: planetsFilterAttrs,
        [CollectionCategory.apartments]: apartmentsFilterAttrs,
    }[category];
};

export const getFilterProps = (valueToSearch: string): string[] | undefined => {
    return [`name:${valueToSearch}`];
};

const getSerialNumberPatternBySlug = (category: string): RegExp | undefined => {
    return {
        [CollectionCategory.planets]: /^2023\.478\.([0-9]+)$/i,
        [CollectionCategory.apartments]: /^2024\.479\.([0-9]+)$/i,
    }[category];
};

export const getTokenIdBySerialNumber = (category: string, valueToSearch: string): string[] | undefined => {
    const pattern = getSerialNumberPatternBySlug(category);
    if (!pattern) {
        return undefined;
    }

    const result = pattern.exec(valueToSearch);
    if (!result) {
        return undefined;
    }

    return [Number(result[1]).toString()];
};

export const getMetadataMintedFilters = (contractSlug: string, valueToSearch?: string): { [key: string]: unknown } => {
    const category = getCategoryByContractSlug(contractSlug);
    if (!category || !valueToSearch) {
        return {};
    }

    const tokenId = getTokenIdBySerialNumber(category.name, valueToSearch);
    if (tokenId) {
        return { tokenId };
    }

    return {
        filtersProps: getFilterProps(valueToSearch),
        filtersAttrs: getFilterAttrsByCategoryCollection(category.name, valueToSearch),
    };
};
