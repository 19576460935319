import { ReactElement, ReactNode } from "react";
import Container from "../Container";
import Logo from "../Logo";
import NavbarInsidePage from "../NavbarInsidePage";
import styles from "./PageContainer.module.scss";
import BackToTop from "@components/BackToTop";
import { matchPath, useLocation } from "react-router-dom";
import { TIMELINE } from "@routes/routes";

interface IPageContainer {
    title: string;
    categories: string[];
    children: ReactNode;
}

const PageContainer = ({ title, categories, children }: IPageContainer): ReactElement => {
    const { pathname } = useLocation();

    const isTimelinePage = matchPath(pathname, {
        path: TIMELINE,
        exact: true,
        strict: false,
    });

    return (
        <Container>
            <div className={styles.pageContainer__container}>
                <Logo />
                <h1>{title}</h1>
                <NavbarInsidePage navbarCategories={categories} />
                {isTimelinePage && <hr />}
                {children}
            </div>
            <BackToTop />
        </Container>
    );
};

export default PageContainer;
