import { YOUTUBE_CHANNEL_URL } from "@constants/externalUrls";
import { ITimelineCard } from "@interfaces/Timeline/TimelineCard";
import { ReactElement } from "react";
import ButtonTimeline from "../ButtonTimeline";
import TimelineCard from "../TimelineCard";
import styles from "./MovieCard.module.scss";

const MovieCard = ({ item }: ITimelineCard): ReactElement => (
    <TimelineCard key={item.id} title={item.title} category={item.category} date={item.date}>
        <div className={styles.movies__container}>
            <iframe src={item.link} allow="autoplay; encrypted-media" allowFullScreen></iframe>
        </div>
        <ButtonTimeline to={YOUTUBE_CHANNEL_URL} target="_blank">
            REQUIRED VIEWING
        </ButtonTimeline>
    </TimelineCard>
);

export default MovieCard;
