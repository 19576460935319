import { AbiItem } from "web3-utils";

const abi: AbiItem[] = [
    {
        inputs: [
            { internalType: "uint256[]", name: "_rockTypes", type: "uint256[]" },
            { internalType: "uint256[]", name: "_rockAmounts", type: "uint256[]" },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "price",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "priceWithRocks",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint16", name: "_amount", type: "uint16" }],
        name: "publicMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            { internalType: "uint256", name: "_badgeId", type: "uint256" },
            { internalType: "uint8[]", name: "_planetTypes", type: "uint8[]" },
            { internalType: "uint8[]", name: "_roomTypes", type: "uint8[]" },
            { internalType: "bytes", name: "signature", type: "bytes" },
        ],
        name: "signatureMint",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "usedBadges",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
];

export default abi;
