import { METADATA } from "@constants/apiEndpoints";
import { METADATA_API, PLANETS_CONTRACT_ADDRESS } from "@constants/env";
import useApiCall from "@hooks/shared/useApiCall";
import { IMetadataMinted } from "@interfaces/Metadata/MetadataMinted";
import { getContractSlugByContractAddress } from "@scripts/CollectionCategoryHelper";

const useGetPlanetsMetadata = (STARS_IDS: string[] | undefined): IMetadataMinted[] | undefined =>
    useApiCall<IMetadataMinted[]>({
        url: `${METADATA_API}/${METADATA}${getContractSlugByContractAddress(PLANETS_CONTRACT_ADDRESS)}`,
        params: {
            tokenId: STARS_IDS,
        },
    }).data;

export default useGetPlanetsMetadata;
