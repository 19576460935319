import galaxy from "@assets/images/starmap/legend/galaxy.png";
import space_station from "@assets/images/starmap/legend/space_station.png";
import rogue from "@assets/images/starmap/legend/rogue.png";
import sun_system from "@assets/images/starmap/legend/sun_system.png";
import zone from "@assets/images/starmap/legend/zone.png";
import STARS from "./starmap.stars.data";

export const LEGEND_LIST_WIDE = [
    { image: galaxy, name: "GALAXY" },
    { image: zone, name: "GALACTIC ZONE" },
    { image: sun_system, name: "SOLAR SYSTEM" },
    { image: space_station, name: "SPACESTATION" },
    { image: rogue, name: "ROGUE" },
];

export const LEGEND_LIST_DETAILED = [
    { image: STARS["Water"], name: "WATER PLANET" },
    { image: STARS["Forest"], name: "FOREST PLANET" },
    { image: STARS["Desert"], name: "DESERT PLANET" },
    { image: STARS["Artificial"], name: "ARTIFICIAL PLANET" },
    { image: STARS["Post Civ"], name: "POST-CIV PLANET" },
    { image: STARS["Volcanic"], name: "VOLCANIC PLANET" },
    { image: STARS["Jovian Moon"], name: "JOVIAN MOON" },
    { image: STARS["Rocky Moon"], name: "ROCKY MOON" },
    { image: STARS["Comet"], name: "COMET" },
];
