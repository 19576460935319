import Tooltip from "@components/Starmap/Tooltip";
import usePlanetSelect from "@hooks/Starmap/usePlanetSelect";
import IPlanet from "@interfaces/Starmap/Planet";
import { createLinkName } from "@scripts/StringsHelper";
import STARS from "../../../../../starmap.stars.data";
import styles from "./SecondOrder.module.scss";

interface ISecondOrderProps {
    stars: IPlanet[] | undefined;
}

const SecondOrder = ({ stars }: ISecondOrderProps): JSX.Element => {
    const { handleChangeLinkColor, handleLeaveLinkColor } = usePlanetSelect({ stars });

    return (
        <div className={styles.secondOrder__container}>
            <div className={styles.heading}>
                <h2>2ND ORDER</h2>
                <hr /> <p>(Orbits Planet)</p>
            </div>
            <div className={styles.grid}>
                {stars?.map(({ id, name, world_type }) => (
                    <>
                        <Tooltip id={`star-tooltip-${id}`} />
                        <a
                            id={`star-item-${id}`}
                            onMouseOver={() => handleChangeLinkColor(id)}
                            onMouseLeave={() => handleLeaveLinkColor()}
                            data-tooltip-id={`star-tooltip-${id}`}
                            data-tooltip-html={`<div><h3>${name}</h3></div>`}
                            key={id}
                            className={styles.grid__item}
                            href={createLinkName(name)}
                        >
                            <img src={STARS[world_type]} alt={name} />
                        </a>
                    </>
                ))}
            </div>
        </div>
    );
};

export default SecondOrder;
