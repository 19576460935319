import Button from "@components/Button";
import { ComponentPropsWithoutRef, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import styles from "./BackButton.module.scss";

const BackButton = ({ children }: ComponentPropsWithoutRef<"button">): ReactElement => {
    const history = useHistory();

    return (
        <div className={styles.backButton}>
            <Button onClick={history.goBack}> {children}</Button>
        </div>
    );
};

export default BackButton;
