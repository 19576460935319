import { IMAGE_STORAGE_RF_URL } from "@constants/env";
import {
    AREAS_ZONE_1,
    AREAS_ZONE_2,
    AREAS_ZONE_3,
    AREAS_ZONE_4,
    AREAS_ZONE_5,
    AREAS_ZONE_6,
    AREAS_ZONE_7,
    AREAS_ZONE_8,
} from "../../constants/starmapHitboxes";

const STARMAP_ZONES = [
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-1-separated.png`,
        areas: AREAS_ZONE_1,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone1/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation1.png`,
        size: 0.65,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-2-separated.png`,
        areas: AREAS_ZONE_2,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone2/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation2.png`,
        size: 0.65,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-3-separated.png`,
        areas: AREAS_ZONE_3,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone3/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation3.png`,
        size: 0.7,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-4-separated.png`,
        areas: AREAS_ZONE_4,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone4/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation4.png`,
        size: 1,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-5-separated.png`,
        areas: AREAS_ZONE_5,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone5/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation5.png`,
        size: 0.65,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-6-separated.png`,
        areas: AREAS_ZONE_6,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone6/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation6.png`,
        size: 0.8,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-7-separated.png`,
        areas: AREAS_ZONE_7,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone7/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation7.png`,
        size: 0.6,
    },
    {
        default: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8.png`,
        preview: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8-min.png`,
        front: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8-front.png`,
        back: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8-back.png`,
        hover: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8-hover.png`,
        selected: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8-selected.png`,
        separated: `${IMAGE_STORAGE_RF_URL}/starmap/zones/zone-8-separated.png`,
        areas: AREAS_ZONE_8,
        planets: `${IMAGE_STORAGE_RF_URL}/starmap/planets/zone8/`,
        spacestation: `${IMAGE_STORAGE_RF_URL}/starmap/spacestations/spacestation8.png`,
        size: 0.8,
    },
];

export default STARMAP_ZONES;
