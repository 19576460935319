import scratcher from "@assets/images/stuff/scratcher.jpg";
import Link, { LinkVariant } from "@components/Link";
import { SessionContext } from "@context/SessionContext";
import getContractSignature from "@hooks/Scratcher/getContractSignature";
import { PHYSICAL_SCRATCHER } from "@routes/routes";
import { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./StuffOptionSelection.module.scss";

const StuffOptionSelection = (): ReactElement => {
    const { walletAddress } = useContext(SessionContext);
    const [hasBetaGammaMint, setHasBetaGammaMint] = useState(false);

    useEffect(() => {
        getContractSignature({ walletAddress })
            .then(() => {
                setHasBetaGammaMint(true);
            })
            .catch(() => {
                setHasBetaGammaMint(false);
            });
    }, [walletAddress]);

    return (
        <div className={styles.stuffOptionSelection}>
            <h1>"Stuff"</h1>
            <img src={scratcher} alt="scratchers" />

            {/* <div>
                <Link to={SCRATCHER_MINT} variant={LinkVariant.Button}>
                    Claim Digital Scratcher
                </Link>
            </div> */}
            <div>
                <Link to={PHYSICAL_SCRATCHER} variant={LinkVariant.Button}>
                    Claim Physical Scratcher
                </Link>
            </div>
            {/* <div>
                <Link to={PURCHASE_PHYSICAL_SCRATCHER_URL} variant={LinkVariant.Button}>
                    Purchase Physical Scratcher
                </Link>
            </div> */}
            {/* <div>
                <Link to={STUFF_BETA_GAMMA_MINT} variant={LinkVariant.Button} disabled={!hasBetaGammaMint}>
                    Beta/Gamma Rock Claim
                </Link>
            </div> */}
        </div>
    );
};

export default StuffOptionSelection;
