//when the backoffice is ready, all this circus will be replaced.

export enum timelineCategoryType {
    all = "all",
    launches = "Rocket Launches",
    press = "Press",
    milestones = "Milestones",
    irlEvents = "irl Events",
    movies = "Movies",
}

export enum timelineCategorySingularType {
    launches = "Rocket Launch",
    press = "Press",
    milestones = "Milestone",
    irlEvents = "irl Event",
    movies = "Movie",
}

export const getSingularCategoryType = (category: timelineCategoryType): string => {
    let categoryKey: string;
    let categoryValue: string = "";

    Object.entries(timelineCategoryType).forEach(([key, value]) => {
        if (value === category) categoryKey = key;
    });

    Object.entries(timelineCategorySingularType).forEach(([key, value]) => {
        if (key === categoryKey) categoryValue = value;
    });

    return categoryValue;
};
