import { ILegend } from "@interfaces/Starmap/Legend";
import styles from "./Legend.module.scss";

interface ILegendProps {
    legend: ILegend[];
}

const Legend = ({ legend }: ILegendProps): JSX.Element => (
    <div className={styles.legend__container}>
        <h2>LEGEND</h2>
        <ul className={styles.legend__body}>
            {legend.map(({ image, name }) => (
                <li key={name} className={styles.item}>
                    <img src={image} className={styles.item__image} alt={`pic of ${name}`} />
                    <p className={styles.item__name}>{name}</p>
                </li>
            ))}
        </ul>
    </div>
);

export default Legend;
