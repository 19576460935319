import { COMPONENTS } from "@constants/apiEndpoints";
import useApiCall from "@hooks/shared/useApiCall";
import { IComponent } from "@interfaces/RocketComponent/Component";
import { apiResponseToComponent } from "@scripts/ComponentHelper";
import { Props } from "use-axios-client/bin/useBaseAxios";

const useGetRocketComponent = (id: string): Props<IComponent> =>
    useApiCall<IComponent>({
        url: `${COMPONENTS}${id}`,
        transformResponse: [
            (data) => {
                return apiResponseToComponent(JSON.parse(data));
            },
        ],
    });

export default useGetRocketComponent;
