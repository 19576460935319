import starmap2d from "@assets/images/home/2dStarmap.png";
import starmap3d from "@assets/images/home/3dStarmap.png";
import videoThumbnailPlanets from "@assets/images/home/videoThumbnailPlanets.jpg";
import Contact from "@components/Contact";
import Link, { LinkVariant } from "@components/Link";
import Player from "@components/Player";
import { HOME_VIDEO } from "@constants/externalUrls";
import { ContactContext, IContactContextProps } from "@context/ContactContext";
import { GAME, PLANET_MINT_FINAL_FRONTIER, STARMAP } from "@routes/routes";
import { IS_PLANETS_PHASE_OPEN } from "@scripts/DateHelper";
import { useContext } from "react";
import styles from "./Home.module.scss";

const Home = (): JSX.Element => {
    const { show, showModal } = useContext(ContactContext) as IContactContextProps;

    return (
        <div className={styles.home__container}>
            {show && <Contact showModal={showModal} />}
            <div className={styles.home__content}>
                <div className={styles.home__content__container}>
                    <h1>The final Frontier</h1>

                    {IS_PLANETS_PHASE_OPEN && (
                        <div className={styles.learn__more__button}>
                            <Link variant={LinkVariant.Button} to={PLANET_MINT_FINAL_FRONTIER}>
                                MINT NOW
                            </Link>
                        </div>
                    )}
                </div>
                <div className={styles.home__player__container}>
                    <Player
                        width="100%"
                        volume={1}
                        controls={false}
                        muted={false}
                        loop={false}
                        playing={true}
                        media={HOME_VIDEO}
                        height="700px"
                        light={videoThumbnailPlanets}
                    />
                </div>

                <div className={`${styles.home__content__container} ${styles.home__starmap__container}`}>
                    <h2>Stellar Cartography</h2>
                    <p>
                        The Final Frontier Galaxy is made up of 1,000 Worlds, 209 Star Systems, and 8 Space Stations
                        across 8 Galactic Zones. There is a vast cosmos for us to explore, with multiple ways to find
                        your place within it.
                    </p>
                    <div className={styles.subSections}>
                        <div>
                            <img src={starmap2d} />
                            <div className={styles.text}>
                                <h3>2D Starmap</h3>
                                <p>
                                    A traditional cartographic map of the Final Frontier Galaxy. Go from a Galactic View
                                    all the way down to a specific System and Planets.
                                </p>
                                <div className={styles.linkContainer}>
                                    <Link variant={LinkVariant.Button} to={STARMAP}>
                                        Explore
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={starmap3d} />
                            <div className={styles.text}>
                                <h3>Flight Sim</h3>
                                <p>The galaxy is vast, explore it in its entirety within a rocket of your own.</p>
                                <div className={styles.linkContainer}>
                                    <Link variant={LinkVariant.Button} to={GAME}>
                                        Explore
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
