import usePlanetSelect from "@hooks/Starmap/usePlanetSelect";
import IPlanet from "@interfaces/Starmap/Planet";
import { createLinkName } from "@scripts/StringsHelper";
import styles from "./PlanetList.module.scss";

interface IPlanetListProps {
    stars: IPlanet[] | undefined;
}

const PlanetList = ({ stars }: IPlanetListProps): JSX.Element => {
    const { handleShowTooltip, handleHideTooltip } = usePlanetSelect({ stars });

    return (
        <div className={styles.planetList__container}>
            <h2>PLANETS</h2>
            <ul id="planets-list">
                {stars?.map(({ name, id }) => (
                    <li key={id}>
                        <a
                            id={`planet-item-${id}`}
                            href={createLinkName(name)}
                            onMouseOver={() => handleShowTooltip(id)}
                            onMouseLeave={() => handleHideTooltip()}
                        >
                            {name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PlanetList;
