import BackButton from "@components/BackButton";
import BoxAttributes from "@components/BoxAttributes";
import BoxRarities from "@components/BoxRarities";
import Container from "@components/Container";
import Link from "@components/Link";
import Loader from "@components/Loader";
import Logo from "@components/Logo";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import useGetMetadata from "@hooks/Metadata/useGetMetadata";
import { COLLECTIONS, HOME, NOT_FOUND } from "@routes/routes";
import { getContractSlugBySlug } from "@scripts/CollectionCategoryHelper";
import { IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { Redirect, generatePath, useLocation, useParams } from "react-router-dom";
import styles from "./CollectionsDetail.module.scss";
import BoxDetails from "./components/BoxDetails";

interface LocationState {
    isStuff?: boolean;
}

const CollectionDetail = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { pathname, state } = useLocation<LocationState | undefined>();

    const slugCategory = pathname.split("/")[1];
    const contractSlug = getContractSlugBySlug(slugCategory);
    const { data: collection, loading } = useGetMetadata(contractSlug, id);
    const showRarities = slugCategory === CollectionCategory.stuff.toLowerCase();
    const showAttributes = collection?.attributes && collection.attributes.length > 0 && !showRarities;
    const comesFromStuffMinting = state?.isStuff;

    /** Check access only if the stuff stage when the slug is stuff */
    if (!IS_STUFF_MINT_OPEN && showRarities) {
        return <Redirect to={HOME} />;
    }

    if (loading || !collection) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!loading && !collection) {
        return <Redirect to={NOT_FOUND} />;
    }

    return (
        <Container>
            <Logo />
            {!comesFromStuffMinting ? (
                <BackButton>Back to Collections</BackButton>
            ) : (
                <Link to={generatePath(COLLECTIONS, { category: "Planets" })}>Back to Collections</Link>
            )}
            <div className={styles.collectionDetail}>
                <BoxDetails item={collection} />
                {showAttributes && <BoxAttributes attributes={collection.attributes} />}
                {showRarities && <BoxRarities />}
            </div>
        </Container>
    );
};

export default CollectionDetail;
