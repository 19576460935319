import ButtonCentered from "@components/ButtonCentered";
import Container from "@components/Container";
import Loader from "@components/Loader";
import Logo from "@components/Logo";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import useGetAccountTokens from "@hooks/Hangar/useGetAccountTokens";
import { ITokenResponse } from "@interfaces/Hangar/TokenResponse";
import { COLLECTIONS } from "@routes/routes";
import { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import styles from "./MyHangar.module.scss";
import Header from "./components/Header";
import Patches from "./components/Patches";
import Planets from "./components/Planets";
import Components from "./components/RocketComponents";
import Rockets from "./components/Rockets";
import Rocks from "./components/Rocks";
import SpaceStations from "./components/SpaceStations";
import Stuff from "./components/Stuff";
import Tickets from "./components/Tickets";

const MyHangar = (): JSX.Element => {
    const { getTokens, isLoading } = useGetAccountTokens();
    const { walletAddress } = useParams<{ walletAddress: string }>();
    const [tokens, setTokens] = useState<ITokenResponse | undefined>();

    useEffect(() => {
        if (!walletAddress) {
            return;
        }

        getTokens(walletAddress).then((tokens) => {
            setTokens(tokens);
        });
    }, [walletAddress]);

    if (isLoading || !tokens) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    return (
        <Container>
            <div className={styles.container}>
                <Logo />
                <Header walletAddress={walletAddress} tokens={tokens} />
                <Stuff items={tokens.stuff} />
                <Planets items={tokens.planets} />
                <SpaceStations items={tokens.spaceStations} />
                <Rocks items={tokens.rocks} />
                <Rockets items={tokens.rockets} />
                <Components items={tokens.components} />
                <Tickets items={tokens.tickets} />
                <Patches items={tokens.patches} />
                <hr />
                <ButtonCentered
                    className={styles.button}
                    to={generatePath(COLLECTIONS, { category: CollectionCategory.planets.toString() })}
                >
                    VIEW ALL COLLECTIONS
                </ButtonCentered>
            </div>
        </Container>
    );
};

export default MyHangar;
