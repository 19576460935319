import { ReactElement } from "react";
import styles from "./BoxRockets.module.scss";

interface props {
    rockets: number[];
}
const BoxRockets = ({ rockets }: props): ReactElement => (
    <div className={styles.boxRockets}>
        {rockets.map((id) => (
            <div className={styles.boxRockets__rocket} key={id}>
                <img src={`https://storage.googleapis.com/tsrf/production/rockets/${id}.png?1677187027`} alt="rocket" />
                <span>S/N 2021.192.{id.toString().padStart(3, "0")}</span>
            </div>
        ))}
    </div>
);

export default BoxRockets;
