import styles from "./AssetList.module.scss";
import { ReactNode } from "react";
import ButtonCentered from "@components/ButtonCentered";

interface IAssetList {
    title: string;
    children: ReactNode;
    link: string;
}

const AssetList = ({ title, children, link }: IAssetList): JSX.Element => (
    <div className={styles.assetList}>
        <h2>{title}</h2>
        <div className={styles.body}>{children}</div>
        <hr />
        <ButtonCentered to={link} target="_blank">
            EXPLORE ENTIRE COLLECTION
        </ButtonCentered>
    </div>
);

export default AssetList;
