import { INITIAL_DEFAULT_LIMIT, INITIAL_DEFAULT_LIMIT_MIN } from "@constants/pagination";
import { IDataCollection } from "@interfaces/Collections/DataCollection";
import getMetadata from "@services/Metadata/getMetadataMinted";
import { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { getContractSlugByCategory } from "@scripts/CollectionCategoryHelper";
import DATA_PATCHES from "@pages/Collections/dataCollections/Patches.data";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";

interface returnTypes {
    itemsToShow: IDataCollection[];
    handleGetCollections: () => Promise<void>;
    handleSearchCollections: (event: FormEvent) => void;
    setCategory: Dispatch<SetStateAction<string | undefined>>;
    hasMore: boolean;
    isLoading: boolean;
    resetFilters: () => void;
}

const useGetCollections = (): returnTypes => {
    const [itemsToShow, SetItemsToShow] = useState<IDataCollection[]>([]);
    const [searchByName, setSearchByName] = useState<string>("");
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [category, setCategory] = useState<string>();
    const isFromMetadata = category !== CollectionCategory.patches;

    useEffect(() => {
        if (category) {
            handleGetCollections();
        }
    }, [searchByName, category]);

    const handleGetCollections = async () => {
        const itemsList = await (isFromMetadata ? getCollectionFromMetadata() : getCollectionsFromLocaldata());
        const limit = isFromMetadata ? INITIAL_DEFAULT_LIMIT : INITIAL_DEFAULT_LIMIT_MIN;

        setHasMore(itemsList.length !== 0 && itemsList.length === limit);
        SetItemsToShow([...itemsToShow, ...itemsList]);
        setPage((page) => page + 1);
    };

    const getCollectionFromMetadata = async (): Promise<IDataCollection[]> => {
        setIsLoading(true);
        let collectionList: IDataCollection[] = [];
        const contractSlug = getContractSlugByCategory(category);

        await getMetadata(contractSlug, page, searchByName)
            .then((data) => {
                collectionList = data.data;
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));

        return collectionList;
    };

    const getCollectionsFromLocaldata = async (): Promise<IDataCollection[]> => {
        return DATA_PATCHES;
    };

    const handleSearchCollections = (event: FormEvent) => {
        event.preventDefault();

        resetFilters();
        setSearchByName(event.target[0].value);
    };

    const resetFilters = () => {
        setPage(0);
        setSearchByName("");
        SetItemsToShow([]);
    };

    return {
        itemsToShow,
        handleGetCollections,
        handleSearchCollections,
        setCategory,
        resetFilters,
        hasMore,
        isLoading,
    };
};

export default useGetCollections;
