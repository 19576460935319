import { useState, useEffect, FormEvent } from "react";
import { INITIAL_DEFAULT_LIMIT, INITIAL_DEFAULT_OFFSET, INITIAL_DEFAULT_PAGE } from "@constants/pagination";
import { IRocket } from "@interfaces/Rocket/Rocket";
import getRocketsFromApi from "@services/Rocket/getRockets";

interface returnTypes {
    isLoading: boolean;
    rockets: IRocket[] | undefined;
    handleGetRockets: () => Promise<void>;
    handleSearchRockets: (event: FormEvent) => void;
    hasMore: boolean;
}

const useGetRockets = (): returnTypes => {
    const [isLoading, setIsLoading] = useState(true);
    const [rockets, setRockets] = useState<IRocket[]>([]);
    const [offset, setOffset] = useState(INITIAL_DEFAULT_OFFSET);
    const [hasMore, setHasMore] = useState(true);
    const [searchByName, setSearchByName] = useState<string>("");
    const [page, setPage] = useState(INITIAL_DEFAULT_PAGE);

    useEffect(() => {
        handleGetRockets();
    }, [searchByName]);

    const getRockets = async (): Promise<IRocket[]> => {
        setIsLoading(true);
        let rocketsList: IRocket[] = [];

        await getRocketsFromApi(page, offset, searchByName)
            .then(({ data }) => {
                rocketsList = data;
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));

        return rocketsList;
    };

    const handleSearchRockets = (event: FormEvent) => {
        event.preventDefault();
        setPage(INITIAL_DEFAULT_PAGE);
        setOffset(INITIAL_DEFAULT_OFFSET);
        setRockets([]);
        setSearchByName(event.target[0].value);
    };

    const handleGetRockets = async () => {
        const rocketsList = await getRockets();

        setOffset(INITIAL_DEFAULT_LIMIT);
        setHasMore(rocketsList.length !== 0 && rocketsList.length >= INITIAL_DEFAULT_LIMIT);
        setRockets(rockets.concat(rocketsList));
        setPage((page) => page + 1);
    };

    return {
        isLoading,
        rockets,
        handleGetRockets,
        handleSearchRockets,
        hasMore,
    };
};

export default useGetRockets;
