// Environment
export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME ?? "ethereum";

// IPFS
export const IPFS_ROCKET_UI = process.env.REACT_APP_IPFS_ROCKET_UI;
export const IPFS_ROCKET_COMPONENTS_UI = process.env.REACT_APP_IPFS_ROCKET_COMPONENTS_UI;

// Components - ContractAddress
export const COMPONENT_CONTRACT_ADDRESS = process.env.REACT_APP_ROCKET_COMPONENT_CONTRACT_ADDRESS ?? "";

// Rockets - ContractAddress
export const ROCKET_CONTRACT_ADDRESS = process.env.REACT_APP_ROCKET_CONTRACT_ADDRESS ?? "";

// Patches - ContractAddress
export const COMMEMORATIVE_TOKEN_CONTRACT_ADDRESS = process.env.REACT_APP_COMMEMORATIVE_TOKEN_CONTRACT_ADDRESS ?? "";
export const FIRST_10_CONTRACT_ADDRESS = process.env.REACT_APP_FIRST_10_CONTRACT_ADDRESS ?? "";
export const EIGHT_EIGHT_CONTRACT_ADDRESS = process.env.REACT_APP_EIGHT_EIGHT_CONTRACT_ADDRESS ?? "";
export const STL_CONTRACT_ADDRESS = process.env.REACT_APP_STL_CONTRACT_ADDRESS ?? "";

// Tickets - ContractAddress
export const FLIGHT_TICKETS_ADDRESS = process.env.REACT_APP_FLIGHT_TICKETS_ADDRESS ?? "";

// Rocks - ContractAddress
export const ROCKS_CONTRACT_ADDRESS = process.env.REACT_APP_ROCKS_CONTRACT_ADDRESS ?? "";

// Planets - ContractAddress
export const PLANETS_MINT_CONTRACT_ADDRESS = process.env.REACT_APP_PLANETS_MINT_CONTRACT_ADDRESS ?? "";
export const PLANETS_CONTRACT_ADDRESS = process.env.REACT_APP_PLANETS_CONTRACT_ADDRESS ?? "";

// Rooms - ContractAddress
export const ROOMS_CONTRACT_ADDRESS = process.env.REACT_APP_ROOMS_CONTRACT_ADDRESS ?? "";

// Stuff - ContractAddress
export const STUFF_CONTRACT_ADDRESS = process.env.REACT_APP_STUFF_CONTRACT_ADDRESS ?? "";
export const STUFF_MINTER_CONTRACT_ADDRESS = process.env.REACT_APP_STUFF_MINTER_CONTRACT_ADDRESS ?? "";
export const STUFF_DIGITAL_MINTER_CONTRACT_ADDRESS = process.env.REACT_APP_STUFF_DIGITAL_MINTER_CONTRACT_ADDRESS ?? "";

// External APIs
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PLANET_MINT_API = process.env.REACT_APP_PLANET_MINT_API;
export const METADATA_API = process.env.REACT_APP_METADATA_API;

// External URLs
export const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;
export const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_URL ?? "";
export const ROCKET_IMAGE_URL = process.env.REACT_APP_ROCKET_IMAGE_URL ?? "";
export const IMAGE_STORAGE_RF_URL = process.env.REACT_APP_IMAGE_STORAGE_RF_URL ?? "";
export const PURCHASE_PHYSICAL_SCRATCHER_URL = process.env.REACT_APP_PURCHASE_PHYSICAL_SCRATCHER_URL ?? "";

// MINT
export const MINT_WITH_ROCKS_START_DATE = parseInt(process.env.REACT_APP_MINT_WITH_ROCKS_START_DATE ?? "");
export const MINT_WITH_ROCKS_END_DATE = parseInt(process.env.REACT_APP_MINT_WITH_ROCKS_END_DATE ?? "");
export const PUBLIC_MINT_START_DATE = parseInt(process.env.REACT_APP_PUBLIC_MINT_START_DATE ?? "");
export const STUFF_MINT_START_DATE = parseInt(process.env.REACT_APP_STUFF_MINT_START_DATE ?? "");
export const STUFF_MINT_END_DATE = parseInt(process.env.REACT_APP_STUFF_MINT_END_DATE ?? "");
export const MINT_PUBLIC_DATE = parseInt(process.env.REACT_APP_PUBLIC_MINT_START_DATE ?? "");

// WEB3
export const INFURA_ID = process.env.REACT_APP_INFURA_ID ?? "";
export const WALLET_CONNECT_ID = process.env.REACT_APP_WALLET_CONNECT_ID ?? "";
export const WALLET_CONNECT_CHAIN = parseInt(process.env.REACT_APP_WALLET_CONNECT_CHAIN ?? "");

// SLUGS
export const PLANETS_SLUG = process.env.REACT_APP_PLANETS_SLUG ?? "";
export const SPACE_STATIONS_SLUG = process.env.REACT_APP_SPACE_STATIONS_SLUG ?? "";

//RECAPTCHA
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY ?? "";

// WEB3 API
export const WEB3_API_URL = process.env.REACT_APP_WEB3_API_URL ?? "";
export const WEB3_API_KEY = process.env.REACT_APP_WEB3_API_KEY ?? "";
export const DIGITAL_MINTER_CONTRACT_SLUG = process.env.REACT_APP_DIGITAL_MINTER_CONTRACT_SLUG ?? "";

// STUFF RAFFLE
export const STUFF_RAFFLE_START_DATE = parseInt(process.env.REACT_APP_STUFF_RAFFLE_START_DATE ?? "");
export const STUFF_RAFFLE_END_DATE = parseInt(process.env.REACT_APP_STUFF_RAFFLE_END_DATE ?? "");
