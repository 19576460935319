import { IStuffRarity } from "@interfaces/Stuff/StuffRarity";
import styles from "./Rarity.module.scss";
import { rarityTotalPercentage } from "@scripts/RaritiesHelper";

const Rarity = ({ item, rarities }: IStuffRarity): JSX.Element => (
    <div className={styles.container}>
        <div className={styles.rarityName}>
            <h5>{item}</h5>
            <h5>
                ({rarityTotalPercentage(rarities)}
                %)
            </h5>
        </div>
        {rarities.map(({ name, value }) => (
            <div className={styles.rarities} key={name}>
                <span>{name}</span> <span>({value})</span>
            </div>
        ))}
    </div>
);

export default Rarity;
