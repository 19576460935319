import {
    COMPONENT_CONTRACT_ADDRESS,
    FLIGHT_TICKETS_ADDRESS,
    PLANETS_CONTRACT_ADDRESS,
    ROCKET_CONTRACT_ADDRESS,
    ROCKS_CONTRACT_ADDRESS,
    ROOMS_CONTRACT_ADDRESS,
    STUFF_CONTRACT_ADDRESS,
} from "@constants/env";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import { ICollectionCategory } from "@interfaces/Collections/CollectionCategory";
import {
    APARTMENT_DETAIL,
    COMPONENT_DETAIL,
    PLANET_DETAIL,
    ROCKET_DETAIL,
    ROCK_DETAIL,
    STUFF_DETAIL,
} from "@routes/routes";

export const DATA_CATEGORIES: ICollectionCategory[] = [
    {
        name: CollectionCategory.planets,
        slug: "planet",
        contractSlug: "tom-sachs-rocket-factory-planets-contract",
        contractAddress: PLANETS_CONTRACT_ADDRESS,
        path: PLANET_DETAIL,
        serialFormat: "2023.478.",
    },
    {
        name: CollectionCategory.apartments,
        slug: "apartment",
        contractSlug: "tom-sachs-rocket-factory-apartments-contract",
        contractAddress: ROOMS_CONTRACT_ADDRESS,
        path: APARTMENT_DETAIL,
        serialFormat: "2024.479.",
    },
    {
        name: CollectionCategory.marsRocks,
        slug: "rock",
        contractSlug: "tom-sachs-rocket-factory-rocks-contract",
        contractAddress: ROCKS_CONTRACT_ADDRESS,
        path: ROCK_DETAIL,
    },
    {
        name: CollectionCategory.rockets,
        slug: "rocket",
        contractSlug: "",
        contractAddress: ROCKET_CONTRACT_ADDRESS,
        path: ROCKET_DETAIL,
    },
    {
        name: CollectionCategory.components,
        slug: "component",
        contractSlug: "",
        contractAddress: COMPONENT_CONTRACT_ADDRESS,
        path: COMPONENT_DETAIL,
    },
    {
        name: CollectionCategory.tickets,
        slug: "ticket",
        contractSlug: "tom-sachs-rocket-factory-tickets-contract",
        contractAddress: FLIGHT_TICKETS_ADDRESS,
        path: "",
    },
    {
        name: CollectionCategory.stuff,
        slug: "stuff",
        contractSlug: "tom-sachs-rocket-factory-stuffs-contract",
        contractAddress: STUFF_CONTRACT_ADDRESS,
        path: STUFF_DETAIL,
        serialFormat: "2023.480.",
    },
];

export const searchBoxNotAllowlist = [
    CollectionCategory.tickets,
    CollectionCategory.patches,
    CollectionCategory.components,
    CollectionCategory.rockets,
    CollectionCategory.stuff,
];

export const searchBoxLine = [CollectionCategory.tickets, CollectionCategory.patches, CollectionCategory.stuff];
