import economy from "@assets/images/flightConfirmation/economy.png";
import business from "@assets/images/flightConfirmation/business.png";
import firstA from "@assets/images/flightConfirmation/first_03.png";
import firstB from "@assets/images/flightConfirmation/first_02.png";
import firstC from "@assets/images/flightConfirmation/first_01.png";
import firstD from "@assets/images/flightConfirmation/first_00.png";
import usedEconomy from "@assets/images/flightConfirmation/used_economy.png";
import usedBusiness from "@assets/images/flightConfirmation/used_business.png";
import usedFirstA from "@assets/images/flightConfirmation/used_first_03.png";
import usedFirstB from "@assets/images/flightConfirmation/used_first_02.png";
import usedFirstC from "@assets/images/flightConfirmation/used_first_01.png";
import usedFirstD from "@assets/images/flightConfirmation/used_first_00.png";
import { ECabinType } from "../../types/Ticket/CabinType";
import { ITicket } from "@interfaces/Ticket/Ticket";

export const FIRST_CLASS_A = 0;
export const FIRST_CLASS_B = 1;
export const FIRST_CLASS_C = 2;
export const FIRST_CLASS_D = 3;
export const BUSINESS_CLASS = 4;
export const ECONOMY_CLASS = 5;
export const USED_FIRST_CLASS_A = 6;
export const USED_FIRST_CLASS_B = 7;
export const USED_FIRST_CLASS_C = 8;
export const USED_FIRST_CLASS_D = 9;
export const USED_BUSINESS_CLASS = 10;
export const USED_ECONOMY_CLASS = 11;

export const idToCabinType = (id: number): ECabinType => {
    if ([FIRST_CLASS_A, FIRST_CLASS_B, FIRST_CLASS_C, FIRST_CLASS_D].includes(id)) {
        return ECabinType.FirstClass;
    }

    if (id === BUSINESS_CLASS) {
        return ECabinType.BusinessClass;
    }

    return ECabinType.EconomyClass;
};

export const getImageForTicket = (ticket: ITicket): string => getImageForTicketId(ticket.id);

export const getImageForTicketId = (id: number): string => {
    let image = economy;
    if (FIRST_CLASS_A === id) {
        image = firstA;
    } else if (FIRST_CLASS_B === id) {
        image = firstB;
    } else if (FIRST_CLASS_C === id) {
        image = firstC;
    } else if (FIRST_CLASS_D === id) {
        image = firstD;
    } else if (BUSINESS_CLASS === id) {
        image = business;
    } else if (ECONOMY_CLASS === id) {
        image = economy;
    } else if (USED_FIRST_CLASS_A === id) {
        image = usedFirstA;
    } else if (USED_FIRST_CLASS_B === id) {
        image = usedFirstB;
    } else if (USED_FIRST_CLASS_C === id) {
        image = usedFirstC;
    } else if (USED_FIRST_CLASS_D === id) {
        image = usedFirstD;
    } else if (USED_BUSINESS_CLASS === id) {
        image = usedBusiness;
    } else if (USED_ECONOMY_CLASS === id) {
        image = usedEconomy;
    }

    return image;
};
