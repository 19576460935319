import { ReactElement } from "react";
import ReactPlayer from "react-player/lazy";

interface IPlayer {
    title?: string;
    media?: string;
    controls: boolean;
    volume: number;
    muted: boolean;
    loop: boolean;
    playing: boolean;
    light: string;
    height: string;
    width: string;
}

const Player = ({ media, light, height, controls, volume, muted, loop, playing, width }: IPlayer): ReactElement => (
    <ReactPlayer
        controls={controls}
        volume={volume}
        muted={muted}
        loop={loop}
        playing={playing}
        url={media}
        width={width}
        height={height}
        light={light}
    />
);

export default Player;
