import Container from "@components/Container";
import Logo from "@components/Logo";
import PhaseI from "./components/PhaseI";
import PhaseII from "./components/PhaseII";
import PhaseIII from "./components/PhaseIII";
import BottomSection from "./components/BottomSection";
import styles from "./HowItWorks.module.scss";

const HowItWorks = (): JSX.Element => (
    <Container className={styles.howItWorks}>
        <Logo />
        <h1>
            ROCKET FACTORY PERSONNEL
            <br className={styles.desktop} />
            TRAINING DOCUMENT RESOURCES
        </h1>
        <PhaseI />
        <hr />
        <PhaseII />
        <hr />
        <PhaseIII />
        <hr />
        <BottomSection />
    </Container>
);

export default HowItWorks;
