import Link from "@components/Link";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";
import { ITokenItem } from "@interfaces/Hangar/TokenItem";
import { STUFF_DETAIL } from "@routes/routes";
import { getLinkCollection } from "@scripts/CollectionHelper";
import { IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { ReactElement } from "react";
import TemplateBox from "../TemplateBox";

interface IStuffProps {
    items: ITokenItem[];
}

const Stuff = ({ items }: IStuffProps): ReactElement => {
    /** Check access only if the stuff stage when the slug is stuff */
    if (!IS_STUFF_MINT_OPEN) {
        return <></>;
    }
    /** Check access only if the stuff stage when the slug is stuff */

    return items.length > 0 ? (
        <TemplateBox title={EHangarTitleType.STUFF}>
            {items.map((stuff) =>
                [...Array(stuff.amount)].map((_, i) => (
                    <Link
                        to={getLinkCollection(STUFF_DETAIL, stuff.id.toString(), stuff.name)}
                        key={`${stuff.id}-${i}`}
                    >
                        <img
                            src={stuff.image.replace("ipfs://", "https://raregotchi.mypinata.cloud/ipfs/")}
                            alt={`stuff ${stuff.id}-${stuff.name}`}
                        />
                    </Link>
                ))
            )}
        </TemplateBox>
    ) : (
        <></>
    );
};

export default Stuff;
