import { ReactElement, useContext } from "react";
import styles from "./ConnectWallet.module.scss";
import galaxy from "@assets/images/planetMint/galaxy.png";
import Button from "@components/Button";
import { SessionContext } from "@context/SessionContext";

const ConnectWallet = (): ReactElement => {
    const { login } = useContext(SessionContext);

    return (
        <div className={styles.connectWallet}>
            <div className={styles.galaxy}>
                <img src={galaxy} alt="" />
            </div>
            <h1>Enter the Final Frontier</h1>
            <Button onClick={login}>Connect Wallet</Button>
        </div>
    );
};

export default ConnectWallet;
