import bars from "@assets/images/header/bars.png";
import logo from "@assets/images/header/header-logo.png";
import close from "@assets/images/utils/close.png";
import { STORE_URL } from "@constants/externalUrls";
import { SessionContext } from "@context/SessionContext";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import { timelineCategoryType } from "@customTypes/Timeline/TimelineCategoryType";
import {
    APARTMENT_DETAIL,
    COLLECTIONS,
    COLLECTIONS_DETAIL,
    COMPONENT_DETAIL,
    HOME,
    HOW_IT_WORKS,
    MY_HANGAR,
    PLANET_DETAIL,
    ROCKET_DETAIL,
    ROCK_DETAIL,
    STARMAP,
    TIMELINE,
    TIMELINE_DETAIL,
} from "@routes/routes";
import { useContext, useState } from "react";
import { Link, generatePath, matchPath, useHistory, useLocation } from "react-router-dom";
import styles from "./Navbar.module.scss";

const alwaysTrue = false;

const Navbar = (): JSX.Element => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [toggleOptions, setToggleOptions] = useState<boolean>(false);
    const { login, walletAddress } = useContext(SessionContext);

    const goToAccountDetails = () => {
        history.push(generatePath(MY_HANGAR, { walletAddress }));
    };

    const handleActive = (path: string) => (isRouteMatching(path) ? styles.active : styles.disabled);

    const isRouteMatching = (route: string) =>
        !!matchPath(pathname, {
            path: route,
            exact: true,
            strict: false,
        });

    return (
        <div className={styles.navbarContainer}>
            <div className={styles.container}>
                {true && (
                    <div className={styles.bars} onClick={() => setToggleOptions(!toggleOptions)}>
                        <img src={toggleOptions ? close : bars} alt="open menu" />
                    </div>
                )}

                <Link to={HOME}>
                    <img src={logo} alt="Tom Sachs signature" />
                </Link>

                <div className={styles.content}>
                    {true && (
                        <>
                            <Link className={handleActive(HOME)} to={HOME}>
                                HOME
                            </Link>

                            <Link className={handleActive(STARMAP)} to={STARMAP}>
                                FINAL FRONTIER
                            </Link>

                            <Link className={handleActive(HOW_IT_WORKS)} to={HOW_IT_WORKS}>
                                HOW IT WORKS
                            </Link>

                            <Link
                                className={
                                    isRouteMatching(COMPONENT_DETAIL) ||
                                    isRouteMatching(ROCK_DETAIL) ||
                                    isRouteMatching(ROCKET_DETAIL) ||
                                    isRouteMatching(PLANET_DETAIL) ||
                                    isRouteMatching(APARTMENT_DETAIL) ||
                                    isRouteMatching(COLLECTIONS) ||
                                    isRouteMatching(COLLECTIONS_DETAIL)
                                        ? styles.active
                                        : styles.disabled
                                }
                                to={generatePath(COLLECTIONS, {
                                    category: CollectionCategory.planets.toString(),
                                })}
                            >
                                COLLECTIONS
                            </Link>

                            <Link
                                className={
                                    isRouteMatching(TIMELINE) || isRouteMatching(TIMELINE_DETAIL)
                                        ? styles.active
                                        : styles.disabled
                                }
                                to={generatePath(TIMELINE, { category: timelineCategoryType.all.toString() })}
                            >
                                TIMELINE
                            </Link>
                            <a href={STORE_URL} target="_blank" className={styles.disabled}>
                                STORE
                            </a>
                        </>
                    )}
                    <button
                        onClick={walletAddress ? goToAccountDetails : login}
                        className={`${styles.primary} ${alwaysTrue ? styles.pushToLeft : ""}`}
                    >
                        {walletAddress ? "MY HANGAR" : "CONNECT"}
                    </button>
                </div>
                <button
                    onClick={walletAddress ? goToAccountDetails : login}
                    className={`${styles.connectButtonMobile} ${alwaysTrue ? styles.pushToLeft : ""}`}
                >
                    {walletAddress ? "MY HANGAR" : "CONNECT"}
                </button>
            </div>

            {toggleOptions && (
                <div className={styles.optionsMobile}>
                    <Link to={HOME} onClick={() => setToggleOptions(false)}>
                        HOME
                    </Link>

                    <Link to={STARMAP} onClick={() => setToggleOptions(false)}>
                        FINAL FRONTIER
                    </Link>

                    <Link to={HOW_IT_WORKS} onClick={() => setToggleOptions(false)}>
                        HOW IT WORKS
                    </Link>

                    <Link
                        to={generatePath(COLLECTIONS, {
                            category: CollectionCategory.planets.toString(),
                        })}
                        onClick={() => setToggleOptions(false)}
                    >
                        COLLECTIONS
                    </Link>

                    <Link
                        to={generatePath(TIMELINE, { category: timelineCategoryType.all.toString() })}
                        onClick={() => setToggleOptions(false)}
                    >
                        TIMELINE
                    </Link>
                    <a href={STORE_URL} target="_blank" onClick={() => setToggleOptions(false)}>
                        STORE
                    </a>
                </div>
            )}
        </div>
    );
};

export default Navbar;
