import { ReactElement } from "react";
import { generatePath } from "react-router-dom";
import { TIMELINE_DETAIL } from "@routes/routes";
import { ITimelineCard } from "@interfaces/Timeline/TimelineCard";
import ButtonTimeline from "../ButtonTimeline";
import TimelineCard from "../TimelineCard";
import styles from "./LaunchCard.module.scss";

const LaunchCard = ({ item }: ITimelineCard): ReactElement => (
    <TimelineCard key={item.id} title={item.title} category={item.category} date={item.date}>
        <div className={styles.launches__image__container}>
            <img src={item.image} alt="Rocket Launch" />
        </div>
        <ButtonTimeline
            to={generatePath(TIMELINE_DETAIL, {
                id: item.id,
                slug: encodeURIComponent(item.title),
            })}
            target="_blank"
        >
            GET MORE DETAILS
        </ButtonTimeline>
    </TimelineCard>
);

export default LaunchCard;
