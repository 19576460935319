import { STUFF_RAFFLE_REGISTERED, STUFF_RAFFLE_UNREGISTERED } from "@constants/apiEndpoints";
import { IRaffleAvailability } from "@interfaces/Hangar/RaffleAvailability";
import { apiInstance } from "@services/Shared/instanceAxios";
import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";

const getSuscriptionAvailability = (walletAddress: string): Promise<IRaffleAvailability> =>
    Promise.all(
        [STUFF_RAFFLE_REGISTERED, STUFF_RAFFLE_UNREGISTERED].map((path) =>
            apiInstance({
                url: generatePath(path, { walletAddress }),
                validateStatus: (status) => [204, 404].includes(status),
            })
        )
    ).then(([registeredResponse, unregisteredResponse]: AxiosResponse<unknown>[]) => ({
        canSubscribe: unregisteredResponse.status !== 404 || registeredResponse.status !== 404,
        isSubscribed: registeredResponse.status !== 404,
    }));

export default getSuscriptionAvailability;
