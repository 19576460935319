import { generatePath, Link } from "react-router-dom";
import { COLLECTIONS } from "@routes/routes";
import UnorganizedList from "../AssetList/components/UnorganizedList";
import { GET_APARTMENTS_BY_ZONE } from "@pages/Starmap/SystemDetail/components/Station/Station.apartments.data";
import AssetList from "../AssetList";
import formatStarmapApartmentItemList from "@formatting/formatStarmapApartmentItemList";

interface IApartmentsList {
    zoneId: number;
}

const ApartmentsList = ({ zoneId }: IApartmentsList): JSX.Element => {
    const data = formatStarmapApartmentItemList(GET_APARTMENTS_BY_ZONE(zoneId));

    return (
        <AssetList
            title="APARTMENTS SPACESTATION CERTIFICATE"
            link={generatePath(COLLECTIONS, {
                category: "Apartments",
            })}
        >
            <UnorganizedList listItem={data} />
        </AssetList>
    );
};

export default ApartmentsList;
