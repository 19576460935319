import { PUBLIC_ADDRESS_HEADER, SIGNATURE_HEADER, STUFF_RAFFLE_REGISTRATION } from "@constants/apiEndpoints";
import { apiInstance } from "@services/Shared/instanceAxios";
import { AxiosPromise } from "axios";

const postSubscription = (email: string, walletAddress: string, signature: string | null): AxiosPromise<void> =>
    apiInstance({
        method: "POST",
        url: STUFF_RAFFLE_REGISTRATION,
        data: {
            email,
        },
        headers: {
            [PUBLIC_ADDRESS_HEADER]: walletAddress,
            [SIGNATURE_HEADER]: signature,
        },
    });

export default postSubscription;
