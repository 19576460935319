import InfiniteScroll from "react-infinite-scroll-component";
import { FormEvent, useRef } from "react";
import RocketPartFilter from "./components/RocketPartFilter";
import Loader from "@components/Loader";
import styles from "./ComponentCollection.module.scss";
import RocketFilter from "./components/RocketFilter";
import useGetComponents from "@hooks/RocketComponent/useGetRocketComponents";
import ComponentList from "./components/ComponentList";
import NoItemCollection from "../NoItemCollection";
import BoxFilter from "@components/BoxFilter";

const ComponentCollection = (): JSX.Element => {
    const {
        isLoading,
        hasMore,
        components,
        status,
        handleGetComponents,
        handleRocketPartClicked,
        handleSearchComponents,
        filterByStatus,
        resetFilters,
    } = useGetComponents();
    const refContainerComponents = useRef<HTMLDivElement>(null);

    const handleSearchSubmit = (event: FormEvent) => {
        handleSearchComponents(event);
        refContainerComponents?.current && refContainerComponents.current.scrollIntoView();
    };

    return (
        <>
            <BoxFilter onSubmit={handleSearchSubmit} />
            <div className={styles.container}>
                <RocketPartFilter onClick={handleRocketPartClicked} refComponent={refContainerComponents} />
                <hr />
                <RocketFilter filterByStatus={filterByStatus} resetAllFilters={resetFilters} rocketStatus={status} />
                <div ref={refContainerComponents} className={styles.container__components}>
                    {components && (
                        <InfiniteScroll
                            dataLength={components.length}
                            next={handleGetComponents}
                            hasMore={hasMore}
                            loader={<Loader />}
                        >
                            <div>
                                <ComponentList components={components} />
                            </div>
                        </InfiniteScroll>
                    )}

                    {!isLoading && components?.length === 0 && <NoItemCollection message="No components found" />}
                </div>
            </div>
        </>
    );
};

export default ComponentCollection;
