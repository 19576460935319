import React, { useContext } from "react";
import Contact from "../Contact";
import { ContactContext, IContactContextProps } from "@context/ContactContext";
import styles from "./Container.module.scss";

interface IContainerProps {
    className?: string;
    children: React.ReactNode;
}

const Container = ({ children, className }: IContainerProps): JSX.Element => {
    const { show, showModal } = useContext(ContactContext) as IContactContextProps;
    return (
        <div className={styles.container}>
            {show && <Contact showModal={showModal} />}
            <div className={`${styles.children} ${className ? className : ""}`}>{children}</div>
        </div>
    );
};

export default Container;
