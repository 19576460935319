import { ReactNode } from "react";
import Link, { LinkVariant } from "../Link/Link";
import styles from "./ButtonCentered.module.scss";

interface IButtonCentered {
    to: string;
    children: ReactNode;
    [x: string]: unknown;
}

const ButtonCentered = ({ to, children, ...attributes }: IButtonCentered): JSX.Element => {
    return (
        <div className={styles.button__centered}>
            <Link variant={LinkVariant.Button} to={to} {...attributes}>
                {children}
            </Link>
        </div>
    );
};

export default ButtonCentered;
