import { IComponent } from "@interfaces/RocketComponent/Component";
import { ComponentApiResponse } from "@interfaces/RocketComponent/ComponentResponse";

const typeMap = {
    nose: "Nose Cone",
    body: "Body",
    tail: "Tail Assembly",
};

export const apiTypeToDisplayType = (type: string): string => {
    if (!(type in typeMap)) {
        throw Error(`${type} is and invalid component type.`);
    }

    return typeMap[type];
};

export const apiResponseToComponent = ({
    brand,
    edition,
    image,
    owner,
    serial,
    slug,
    status,
    sticker,
    tokenId,
    total,
    type,
    transactions,
}: ComponentApiResponse): IComponent => {
    return {
        brand,
        edition,
        image,
        owner,
        serial,
        slug,
        status,
        sticker,
        tokenId,
        total,
        type,
        transactions,
    };
};
