import styles from "./LegendMobile.module.scss";

type TLegend = {
    name: string;
    image: string;
};

interface ILegendMobile {
    legend: TLegend[];
}

const LegendMobile = ({ legend }: ILegendMobile): JSX.Element => (
    <div className={styles.legendMobile__container}>
        <hr />

        <ul className={styles.legend}>
            {legend.map(({ image, name }) => (
                <li key={name} className={styles.legend__item__container}>
                    <div>
                        <img src={image} alt={`pic of ${name}`} />
                        <p>{name}</p>
                    </div>
                </li>
            ))}
        </ul>

        <hr />
    </div>
);
export default LegendMobile;
