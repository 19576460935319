import { useEffect, useState } from "react";
import styles from "./BackToTop.module.scss";
import arrow from "../../assets/images/utils/arrow_top.png";

const BackToTop = (): React.ReactElement => {
    const [verticalPosition, setVerticalPosition] = useState(0);

    useEffect(() => {
        window.onscroll = function () {
            setVerticalPosition(window.pageYOffset);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className={styles.backToTop}>
            <a onClick={scrollToTop} className={verticalPosition > 600 ? styles.show : ""}>
                <img src={arrow} alt="back to top" />
            </a>
        </div>
    );
};

export default BackToTop;
