import galaxy from "@assets/images/planetMint/galaxySeparated.png";
import Link, { LinkVariant } from "@components/Link";
import { PLANET_MINT_API } from "@constants/env";
import usePlanetsMintRead from "@hooks/contracts/PlanetMinter/usePlanetsMintRead";
import useGetRocks from "@hooks/contracts/Rocks/useGetRocks";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { PLANET_MINT_IDCARD, PLANET_MINT_ROCKS, PUBLIC_MINT } from "@routes/routes";
import { IS_MINT_WITH_ROCKS_OPEN, IS_PUBLIC_MINT_OPEN } from "@scripts/DateHelper";
import axios from "axios";
import { ReactElement, useEffect, useState } from "react";
import styles from "./MintOptionSelection.module.scss";

const MintOptionSelection = (): ReactElement => {
    const { isInitialized: isWalletInitialized, walletAddress } = useWeb3Context();
    const { isInitialized: isGetRocksInitialized, getRocks } = useGetRocks();
    const { isBadgeUsed: checkIsBadgeUsed, isInitialized: isUsePlanetsMintReadInitialized } = usePlanetsMintRead();

    const [hasRocks, setHasRocks] = useState(false);
    const [hasBadge, setHasBadge] = useState(false);

    useEffect(() => {
        if (!isGetRocksInitialized || !isWalletInitialized || !walletAddress) {
            return;
        }

        getRocks(walletAddress).then((rocks) => {
            for (const rock of rocks) {
                if (rock.amount > 0) {
                    setHasRocks(true);

                    return;
                }
            }

            setHasRocks(false);
        });
    }, [isGetRocksInitialized, isWalletInitialized, walletAddress]);

    useEffect(() => {
        if (!isUsePlanetsMintReadInitialized || !isWalletInitialized || !walletAddress) {
            return;
        }

        axios
            .get(`/tokens/${walletAddress}`, { baseURL: PLANET_MINT_API })
            .then(async ({ data }) => {
                const isBadgeUsed = await checkIsBadgeUsed(data.tokenId);
                setHasBadge(!isBadgeUsed);
            })
            .catch(() => {
                setHasBadge(false);
            });
    }, [isUsePlanetsMintReadInitialized, isWalletInitialized, walletAddress]);

    return (
        <div className={styles.mintOptionSelection}>
            <h1>Final frontier</h1>
            <div className={styles.galaxy}>
                <img src={galaxy} alt="" />
            </div>
            {IS_MINT_WITH_ROCKS_OPEN && (
                <>
                    <div>
                        <Link to={PLANET_MINT_ROCKS} variant={LinkVariant.Button} disabled={!hasRocks}>
                            Mint with Mars Rock
                        </Link>
                    </div>

                    <div>
                        <Link to={PLANET_MINT_IDCARD} variant={LinkVariant.Button} disabled={!hasBadge}>
                            Mint with Identification Card
                        </Link>
                    </div>
                </>
            )}
            {IS_PUBLIC_MINT_OPEN && (
                <>
                    <div>
                        <Link to={PUBLIC_MINT} variant={LinkVariant.Button}>
                            Mint world or apartment
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};

export default MintOptionSelection;
