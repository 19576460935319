import { ROCKET_IMAGE_URL } from "@constants/env";
import background from "@assets/images/rockets_background/background.png";

interface RocketImageProps {
    id: string;
    image: string;
}

const RocketImage = ({ id, image }: RocketImageProps): JSX.Element => {
    if (id) {
        return <img src={`${ROCKET_IMAGE_URL.replace(":image:", id)}?${Date.now()}`} alt="rocket" />;
    }

    return (
        <img
            style={{
                backgroundImage: `url("${background}")`,
                backgroundSize: "cover",
            }}
            src={image}
            alt="rocket"
        />
    );
};

export default RocketImage;
