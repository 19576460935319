import useBackButton from "@hooks/Starmap/useBackButton";
import styles from "./LeftColumn.module.scss";
import Legend from "./components/Legend";
import StepPreview from "./components/StepPreview";

interface ILeftColumn {
    image?: string;
}

const LeftColumn = ({ image }: ILeftColumn): JSX.Element => {
    const { name, stepPreviewImage, legend, link } = useBackButton(image);

    return (
        <div className={styles.leftColumn__container}>
            <StepPreview name={name} image={stepPreviewImage} link={link} />
            {legend && <Legend legend={legend} />}
        </div>
    );
};

export default LeftColumn;
