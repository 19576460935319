import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-solid-svg-icons";
import styles from "./ExpandButton.module.scss";

interface IExpandButton {
    modalIsOpen: boolean;
    handleSetIsModalOpen: (boolean: boolean) => void;
}

const ExpandButton = ({ modalIsOpen, handleSetIsModalOpen }: IExpandButton): ReactElement => {
    return (
        <button
            className={`${styles.expandButton} ${modalIsOpen ? styles.hideButton : ""}`}
            onClick={() => handleSetIsModalOpen(true)}
        >
            <FontAwesomeIcon icon={faExpand} />
        </button>
    );
};

export default ExpandButton;
