import check from "@assets/images/utils/green_check.png";
import Button from "@components/Button";
import Link from "@components/Link";
import { TERMS } from "@routes/routes";
import { ReactElement, ReactNode, useState } from "react";
import Checkbox from "react-custom-checkbox";
import utils from "web3-utils";
import styles from "./ConfirmAndMint.module.scss";

interface Props {
    subtotal?: string;
    mintEnabled?: boolean;
    onSubmit: () => void;
    children: ReactNode;
}

const ConfirmAndMint = ({ subtotal, children, onSubmit, mintEnabled = true }: Props): ReactElement => {
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    return (
        <div className={styles.confirmAndMint}>
            <div className={styles.titleCheckbox}>
                <Checkbox
                    className={styles.checkbox}
                    icon={<img src={check} className={styles.checkboxIcon} alt="" />}
                    borderColor="#ffffff"
                    borderRadius={0}
                    size={20}
                    checked={isTermsChecked}
                    onChange={() => setIsTermsChecked((prev) => !prev)}
                    style={{ backgroundColor: "transparent" }}
                />
                <p className={styles.confirmTaC}>
                    <Link target="_blank" to={TERMS}>
                        CONFIRM TERMS <br className={styles.mobileOnly} />
                        AND CONDITIONS
                    </Link>
                </p>
            </div>
            <p className={styles.text}>{children}</p>
            <div className={styles.bottom}>
                {subtotal && (
                    <p>
                        SUBTOTAL: <span className={styles.ethSymbol}>Ξ</span>{" "}
                        {new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 5 }).format(
                            parseFloat(utils.fromWei(subtotal, "ether"))
                        )}
                    </p>
                )}
                <Button onClick={onSubmit} disabled={!isTermsChecked || !mintEnabled}>
                    Mint Now
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAndMint;
