import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import { EMintType } from "@customTypes/Mint/MintType";
import { DIGITAL_SCRATCHER_SUCCESS, PLANET_MINT_SUCCESS } from "@routes/routes";

export const PublicMintContentMap = {
    [EMintType.DEFAULT]: {
        title: "FINAL FRONTIER CERTIFICATE MINT",
        instructionsText:
            "Please select how many Final Frontier Certificates you wish to Mint. There is a 10 Certificate limit per transaction. The potential exists to Mint anything from a World to a Space Station Apartment. Best of luck Citizen!",
        confirmText:
            "I agree to pay the mint price of 0.08 ETH per Final Frontier Certificate, along with the gas fees charged by the Ethereum network. I understand that a randomly selected Final Frontier Certificate NFT will be minted (as an ERC-721 token), with randomly determined rarity.",
        mintingType: undefined,
        success: PLANET_MINT_SUCCESS,
    },
    [EMintType.SCRATCHER]: {
        title: "SCRATCHER MINT",
        instructionsText:
            'Everyone\'s a winner! Please select how many Scratchers you wish to mint. There is a 10 Scratcher limit per transaction. Each Scratcher will mint one of the 36 items from the "Stuff" collection.',
        confirmText: "Please check the box to confirm you have read and agree to our terms and conditions.",
        mintingType: CollectionCategory.stuff,
        success: DIGITAL_SCRATCHER_SUCCESS,
    },
};
