export const rarityData = [
    {
        item: "Altec Lamp",
        rarities: [
            {
                name: "Pink",
                value: "0.55%",
            },
            {
                name: "Green",
                value: "2.75%",
            },
            {
                name: "Black",
                value: "7.70%",
            },
        ],
    },
    {
        item: "Bed",
        rarities: [
            {
                name: "Stained",
                value: "0.55%",
            },
            {
                name: "Hello Kitty",
                value: "2.75%",
            },
            {
                name: "NASA",
                value: "7.70%",
            },
        ],
    },
    {
        item: "BMW",
        rarities: [
            {
                name: "Orange",
                value: "0.35%",
            },
            {
                name: "White",
                value: "1.75%",
            },
            {
                name: "Beige",
                value: "4.90%",
            },
        ],
    },
    {
        item: "Dome",
        rarities: [
            {
                name: "Ruined",
                value: "0.45%",
            },
            {
                name: "Overgrown",
                value: "2.25%",
            },
            {
                name: "Perfect",
                value: "6.30%",
            },
        ],
    },
    {
        item: "Bag",
        rarities: [
            {
                name: "White Crocodile",
                value: "0.25%",
            },
            {
                name: "Natural",
                value: "1.25%",
            },
            {
                name: "Black",
                value: "3.50%",
            },
        ],
    },
    {
        item: "McDonalds",
        rarities: [
            {
                name: "Ruined",
                value: "0.35%",
            },
            {
                name: "Overgrown",
                value: "1.75%",
            },
            {
                name: "Perfect",
                value: "4.90%",
            },
        ],
    },
    {
        item: "Pyramid",
        rarities: [
            {
                name: "Ruined",
                value: "2.10%",
            },
            {
                name: "Overgrown",
                value: "0.75%",
            },
            {
                name: "Perfect",
                value: "0.15%",
            },
        ],
    },
    {
        item: "Shop Chair",
        rarities: [
            {
                name: "Pink",
                value: "0.60%",
            },
            {
                name: "Gray",
                value: "3.00%",
            },
            {
                name: "Natural",
                value: "8.40%",
            },
        ],
    },
    {
        item: "Toilet",
        rarities: [
            {
                name: "Filthy",
                value: "0.75%",
            },
            {
                name: "Stainless",
                value: "3.75%",
            },
            {
                name: "White",
                value: "10.50%",
            },
        ],
    },
    {
        item: "Tulip Table",
        rarities: [
            {
                name: "Pink",
                value: "0.55%",
            },
            {
                name: "Gray",
                value: "2.75%",
            },
            {
                name: "White",
                value: "7.70%",
            },
        ],
    },
    {
        item: "VAB",
        rarities: [
            {
                name: "Ruined",
                value: "0.20%",
            },
            {
                name: "Overgrown",
                value: "1.00%",
            },
            {
                name: "Perfect",
                value: "2.80%",
            },
        ],
    },
    {
        item: "Villa Savoye",
        rarities: [
            {
                name: "Ruined",
                value: "0.25%",
            },
            {
                name: "Overgrown",
                value: "1.25%",
            },
            {
                name: "Perfect",
                value: "3.50%",
            },
        ],
    },
];
