import { UNAVAILABLE } from "@constants/statuses";
import styles from "./RocketFilter.module.scss";

interface IRocketFilter {
    resetAllFilters: () => void;
    filterByStatus: (status: string) => void;
    rocketStatus?: string;
}

const RocketFilter = ({ filterByStatus, resetAllFilters, rocketStatus }: IRocketFilter): JSX.Element => (
    <div className={styles.rocketFilter}>
        <button className={`${styles.filterButton} ${!rocketStatus ? styles.selected : ""}`} onClick={resetAllFilters}>
            ALL
        </button>
        <button
            className={`${styles.filterButton} ${rocketStatus === UNAVAILABLE ? styles.selected : ""}`}
            onClick={() => filterByStatus(UNAVAILABLE)}
        >
            MINTED
        </button>
    </div>
);
export default RocketFilter;
