import ISystem, { ISystemFormatted } from "@interfaces/Starmap/System";
import STARMAP_ZONES from "@pages/Starmap/starmap.zones.data";

const formatStarmapZoneDetail = (data: ISystem[] | undefined, zoneId: string, zoneName: string): ISystemFormatted => {
    const DATA_FROM_API = data?.sort((a, b) => a.name.localeCompare(b.name));
    const ZONE_ID = parseInt(zoneId) - 1;

    const ROGUE_SYSTEMS = DATA_FROM_API?.filter(({ type }) => type === "Rogue") ?? [];
    const SOLAR_SYSTEMS = DATA_FROM_API?.filter(({ type }) => type !== "Rogue") ?? [];

    const SPACE_STATION = {
        id: 1,
        name: `${zoneName}`,
        type: "station",
        category: "station",
        system_code: "1",
    };

    const ALL_SYSTEMS = [SPACE_STATION, ...ROGUE_SYSTEMS, ...SOLAR_SYSTEMS];

    ALL_SYSTEMS.forEach(function (item) {
        item.id = parseInt(item.id.toString());
    });

    const PLANETS = ALL_SYSTEMS?.map(({ id }) => ({
        img: `${STARMAP_ZONES[ZONE_ID].planets}/${id}.png`,
        id,
    }));

    return {
        zone: {
            zone_size: STARMAP_ZONES[ZONE_ID].size,
            space_position: STARMAP_ZONES[ZONE_ID].selected,
            default_image: {
                front: STARMAP_ZONES[ZONE_ID].front,
                back: STARMAP_ZONES[ZONE_ID].back,
                default: STARMAP_ZONES[ZONE_ID].separated,
            },
            stars: {
                planets: PLANETS,
                areas: STARMAP_ZONES[ZONE_ID].areas,
            },
        },
        systems: {
            station: SPACE_STATION,
            rogue: ROGUE_SYSTEMS,
            solar_systems: SOLAR_SYSTEMS,
            all_systems: ALL_SYSTEMS,
        },
    };
};

export default formatStarmapZoneDetail;
