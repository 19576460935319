import { useContext } from "react";
import missionControl from "@assets/images/about/mission-control.jpg";
import Container from "@components/Container";
import Logo from "@components/Logo";
import styles from "./About.module.scss";
import { ContactContext, IContactContextProps } from "@context/ContactContext";

const About = (): JSX.Element => {
    const { showModal } = useContext(ContactContext) as IContactContextProps;
    return (
        <Container>
            <div className={styles.container}>
                <Logo />
                <div>
                    <h1>ABOUT US</h1>
                    <img src={missionControl} alt="Mission control" width="694" height="464" />
                    <span>Tom Sachs, Space Program: Mars, 2012 at the Park Avenue Armory.</span>
                    <h2>TOM SACHS:</h2>
                    <p>
                        Tom Sachs (b. 1966) is an American contemporary artist, and self-proclaimed “handyman” working
                        in New York City. Sachs’ genre defying mixed media sculptures, often recreations of modern icons
                        using everyday materials, show all of the work that goes into producing an object — a reversal
                        of modernization’s trend towards products with cleaner, simpler, and more perfect edges. Sachs’s
                        sculptures are conspicuously handmade; lovingly cobbled together from plywood, resin, steel, and
                        ceramic. The scars and imperfections in the sculptures tell the story of how it came into being
                        and remove it from the realm of miraculous conception. His studio team functions like a teaching
                        hospital, or cult, that worships plywood and an ethos of transparency.
                    </p>
                    <p>
                        He has done major collaborations with brands like Nike, which have realized a number of
                        innovative products and U.S. utility patents. Major art exhibitions include the Space Program, a
                        demonstration of all that is necessary for survival, scientific exploration, resource
                        harvesting, and colonization in extraterrestrial environments. His works are held in museum
                        collections around the world, including the Solomon R. Guggenheim Museum, the Whitney Museum of
                        American Art, the Museum of Modern Art — New York, the Centre Georges Pompidou — Paris,
                        Metropolitan Museum of Art — New York, San Francisco Museum of Modern Art, the Astrup Fearnley
                        Museet for Moderne Kunst — Oslo, Los Angeles County Museum of Art.
                    </p>
                </div>
                <div className={styles.requireView}>
                    <h2>REQUIRED VIEWING:</h2>
                    <p>
                        To learn more visit:{" "}
                        <a rel="noopener noreferrer" href="https://www.tomsachs.org">
                            www.tomsachs.org
                        </a>
                        ,{" "}
                        <a rel="noopener noreferrer" href="http://www.tenbullets.com">
                            www.tenbullets.com
                        </a>
                        ,{" "}
                        <a rel="noopener noreferrer" href="https://www.nikecraft.com">
                            www.nikecraft.com
                        </a>
                    </p>
                    <div>
                        <div className={styles.videoContainer}>
                            <iframe
                                width="326"
                                height="576"
                                src="https://www.youtube.com/embed/49p1JVLHUos"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className={styles.videoContainer}>
                            <iframe
                                width="326"
                                height="576"
                                src="https://www.youtube.com/embed/eBM_9W_e_D4"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className={styles.videoContainer}>
                            <iframe
                                width="326"
                                height="576"
                                src="https://www.youtube.com/embed/tfBZXIn_Zrw"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className={styles.requireView}>
                        <h2>OUR DEVELOPMENT TEAM:</h2>
                        <p>
                            <a href="https://nftstudios.io/" target="_blank" rel="noopener noreferrer">
                                NFT Studios
                            </a>{" "}
                            is a holding of companies sharing one clear mission: Build the Metaverse one block at a
                            time, developing products, tools, and technologies to enable the NFT space to grow and
                            thrive.
                        </p>
                        <p>
                            NFT Studios has a broad portfolio of companies including CryptoMotors, PixelChain and Voxel
                            Architects. Altogether, these companies aim to provide the digital space with innovative,
                            high-quality tools, services, dapps, web platforms, video games, and assets.
                        </p>
                        <p>
                            By leveraging the Ethereum blockchain technology, NFT Studios empowers startups,
                            communities, and users to kickstart their own business, build a strong presence in the
                            Metaverse, create their own products, explore their creativity, and engage in new and
                            fascinating digital experiences.
                        </p>
                        <p>
                            NFT Studios:{" "}
                            <a href="https://twitter.com/NFTStudios_io" target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>{" "}
                            —{" "}
                            <a href="https://nftstudios.io/" target="_blank" rel="noopener noreferrer">
                                Website
                            </a>
                        </p>
                    </div>

                    <a onClick={showModal} className={styles.primary} href="mailto:info@tomsachsrocketfactory.com">
                        CONTACT US
                    </a>
                </div>
            </div>
        </Container>
    );
};
export default About;
