import artificial from "@assets/images/starmap/stars/artificial.png";
import brown from "@assets/images/starmap/stars/brown_dwarf.png";
import comet from "@assets/images/starmap/stars/comet.png";
import desert from "@assets/images/starmap/stars/desert.png";
import forest from "@assets/images/starmap/stars/forest.png";
import hotblue from "@assets/images/starmap/stars/hot_blue.png";
import jovian from "@assets/images/starmap/stars/jovian.png";
import moon from "@assets/images/starmap/stars/moon.png";
import neutron from "@assets/images/starmap/stars/neutron.png";
import post_civ from "@assets/images/starmap/stars/post_civ.png";
import red_dwarf from "@assets/images/starmap/stars/red_dwarf.png";
import red_giant from "@assets/images/starmap/stars/red_giant.png";
import sun_white from "@assets/images/starmap/stars/sun_white_dwarf.png";
import super_giant from "@assets/images/starmap/stars/super_giant.png";
import volcanic from "@assets/images/starmap/stars/volcanic.png";
import water from "@assets/images/starmap/stars/water.png";
import yellow from "@assets/images/starmap/stars/yellow_dwarf.png";

const STARS = {
    Artificial: artificial,
    "Brown Dwarf": brown,
    Comet: comet,
    Desert: desert,
    Forest: forest,
    "Hot Blue": hotblue,
    "Jovian Moon": jovian,
    "Rocky Moon": moon,
    Neutron: neutron,
    "Post Civ": post_civ,
    "Red Dwarf": red_dwarf,
    "Red Giant": red_giant,
    "White Dwarf": sun_white,
    "Super Giant": super_giant,
    Volcanic: volcanic,
    Water: water,
    "Yellow Dwarf": yellow,
};

export default STARS;
