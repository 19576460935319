import { COMPONENTS } from "@constants/apiEndpoints";
import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";
import { IComponent } from "@interfaces/RocketComponent/Component";
import { apiResponseToComponent } from "@scripts/ComponentHelper";
import { ComponentApiResponse } from "@interfaces/RocketComponent/ComponentResponse";
import { INITIAL_DEFAULT_LIMIT } from "@constants/pagination";

const getRocketComponents = (
    page: number,
    offset: number,
    status?: string,
    brand?: string,
    type?: string,
    searchByName?: string
): AxiosPromise<IComponent[]> =>
    apiInstance({
        url: COMPONENTS,
        params: {
            limit: INITIAL_DEFAULT_LIMIT,
            offset: page * offset,
            sort: "tokenId",
            order: "asc",
            status,
            brand,
            type,
            search: searchByName,
        },
        transformResponse: [
            (data) => {
                const result = JSON.parse(data);

                const componentsList: IComponent[] = result.map((apiComponent: ComponentApiResponse) =>
                    apiResponseToComponent(apiComponent)
                );

                return componentsList;
            },
        ],
    });

export default getRocketComponents;
