import IPlanet from "@interfaces/Starmap/Planet";
import { ISuns } from "@interfaces/Starmap/Suns";

interface IUsePlanetSelectProps {
    stars?: IPlanet[] | undefined;
    suns?: ISuns[] | undefined;
}

interface IUsePlanetSelectReturn {
    handleShowTooltip: (planetId: number) => void;
    handleChangeLinkColor: (planetId: number, isUninhabitable?: boolean) => void;
    handleLeaveLinkColor: () => void;
    handleHideTooltip: () => void;
}

const usePlanetSelect = ({ stars, suns }: IUsePlanetSelectProps): IUsePlanetSelectReturn => {
    const handleLeaveLinkColor = () => {
        stars?.map(({ id }) => {
            const itemSelected = document.getElementById(`planet-item-${id}`) as HTMLLinkElement;
            itemSelected.style.color = "";
        });

        suns?.map(({ id }) => {
            const itemSelected = document.getElementById(`planet-item-${id}`) as HTMLLinkElement;
            itemSelected.style.color = "";
            itemSelected.style.opacity = "0.5";
        });
    };

    const handleChangeLinkColor = (planetId: number, isUninhabitable: boolean = false) => {
        const itemSelected = document.getElementById(`planet-item-${planetId}`) as HTMLLinkElement;

        if (itemSelected.style.color === "white") {
            itemSelected.style.color = "";

            if (isUninhabitable) {
                itemSelected.style.opacity = "0.5";
            }
            return;
        }

        itemSelected.style.color = "white";

        if (isUninhabitable) {
            itemSelected.style.opacity = "1";
        }

        scrollToPlanetItem(planetId);
    };

    const scrollToPlanetItem = (planetId: number) => {
        const linkList = document.getElementById("planets-list") as HTMLUListElement;
        const itemSelected = document.getElementById(`planet-item-${planetId}`) as HTMLLIElement;

        if (linkList && itemSelected) {
            const firstElemPosition = linkList.children[0] as HTMLLIElement;
            linkList.scrollTop = itemSelected?.offsetTop - firstElemPosition?.offsetTop;
        }
    };

    const handleShowTooltip = (planetId: number) => {
        const itemSelected = document.getElementById(`star-item-${planetId}`) as HTMLLinkElement;
        itemSelected.focus();
    };

    const handleHideTooltip = () => {
        const itemSelected = document.getElementById("back-button-step") as HTMLLinkElement;
        itemSelected.focus();
    };

    return {
        handleShowTooltip,
        handleHideTooltip,
        handleChangeLinkColor,
        handleLeaveLinkColor,
    };
};

export default usePlanetSelect;
