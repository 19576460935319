import Layout from "@pages/Layout";
import { Web3ContextProvider } from "@nftstudios/web3-provider";
import SessionContextProvider from "@context/SessionContext";
import ContactContextProvider from "@context/ContactContext";
import { useState } from "react";
import Loader from "@components/Loader";
import { INFURA_ID, WALLET_CONNECT_CHAIN, WALLET_CONNECT_ID } from "@constants/env";

const App = (): JSX.Element => {
    const [isReady, setIsReady] = useState(false);

    document.fonts.load("16px Amarillo-USAF");

    document.fonts.load("16px courier-regular");

    document.fonts.load("16px courier-bold");

    document.fonts.ready.then(() => setIsReady(true));

    return isReady ? (
        <Web3ContextProvider infuraId={INFURA_ID} projectId={WALLET_CONNECT_ID} chainIds={[WALLET_CONNECT_CHAIN]}>
            <SessionContextProvider>
                <ContactContextProvider>
                    <Layout />
                </ContactContextProvider>
            </SessionContextProvider>
        </Web3ContextProvider>
    ) : (
        <Loader />
    );
};

export default App;
