import { DISCORD_URL, INSTAGRAM_URL, TWITTER_URL } from "@constants/socials";
import { timelineSocialType } from "@customTypes/Timeline/TimelineSocialType";
import { ISocial } from "@interfaces/Timeline/Social";

export const getLinkSocial = (socials: ISocial[] | undefined, type: timelineSocialType): string =>
    socials?.find((social: ISocial) => social.type === type)?.link ?? getLinkSocialDefault(type);

const getLinkSocialDefault = (type: timelineSocialType): string => {
    switch (type) {
        case timelineSocialType.discord:
            return DISCORD_URL;
        case timelineSocialType.instagram:
            return INSTAGRAM_URL;
        case timelineSocialType.twitter:
            return TWITTER_URL;
        default:
            return "";
    }
};
