import { PLANETS_MINT_CONTRACT_ADDRESS } from "@constants/env";
import { CONFIRMATION, ERROR, TRANSACTION_HASH } from "@constants/web3Events";
import abi from "@contracts/PlanetsMintAbi";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { isExchangeableForPlanet } from "@scripts/RockHelper";
import { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";
import useCalculateMintPrice from "./useCalculateMintPrice";

export type RocksToBeBurned = { [key: number]: number };

interface ReturnTypes {
    txId: string;
    isMintingInProgress: boolean;
    isMintingSuccessful: boolean;
    mint: (rocks: RocksToBeBurned) => Promise<void>;
}

const usePlanetsMint = (): ReturnTypes => {
    const { web3, walletAddress } = useWeb3Context();
    const { calculateWithRocks } = useCalculateMintPrice();
    const [txId, setTxId] = useState("");
    const [isMintingInProgress, setIsMintingInProgress] = useState(false);
    const [isMintingSuccessful, setIsMintingSuccessful] = useState(false);
    const [mintContract, setMintContract] = useState<Contract | undefined>(undefined);

    useEffect(() => {
        if (!web3) {
            return;
        }

        const mintContract = new web3.eth.Contract(abi, PLANETS_MINT_CONTRACT_ADDRESS);
        setMintContract(mintContract);
    }, [web3]);

    const mint = async (rocks: RocksToBeBurned): Promise<void> => {
        if (!mintContract) {
            return;
        }

        const rockIds: number[] = [];
        const rockQuantities: number[] = [];
        for (const [rockId, quantity] of Object.entries(rocks)) {
            if (quantity === 0) {
                continue;
            }

            rockIds.push(parseInt(rockId));
            rockQuantities.push(quantity);
        }

        let amountPlanets = 0;
        let amountSpaceStations = 0;
        for (const [rockId, quantity] of Object.entries(rocks)) {
            isExchangeableForPlanet(parseInt(rockId)) ? (amountPlanets += quantity) : (amountSpaceStations += quantity);
        }

        const value = await calculateWithRocks(amountPlanets + amountSpaceStations);

        await mintContract.methods
            .mint(rockIds, rockQuantities)
            .send({ from: walletAddress, value })
            .once(CONFIRMATION, () => {
                setIsMintingInProgress(false);
                setIsMintingSuccessful(true);
            })
            .once(TRANSACTION_HASH, (txId: string) => {
                setTxId(txId);
                setIsMintingSuccessful(false);
                setIsMintingInProgress(true);
            })
            .once(ERROR, (err: Error) => {
                console.log(err);
                setIsMintingInProgress(false);
                setIsMintingSuccessful(false);
            });
    };

    return { txId, isMintingInProgress, isMintingSuccessful, mint };
};

export default usePlanetsMint;
