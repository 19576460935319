import { IMetadataMinted } from "@interfaces/Metadata/MetadataMinted";
import { IItemList } from "@interfaces/Starmap/ItemList";

const formatStarmapPlanetItemList = (data: IMetadataMinted[] | undefined): IItemList[] | undefined =>
    data?.map((item, index) => ({
        id: index,
        title: item.metadata.name,
        imageUrl: item.metadata.image,
        linkUrl: "",
    }));

export default formatStarmapPlanetItemList;
