import Container from "@components/Container";
import { SessionContext } from "@context/SessionContext";
import { HOME } from "@routes/routes";
import { IS_PLANETS_PHASE_OPEN, IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { ReactElement, useContext } from "react";
import { Redirect } from "react-router-dom";
import styles from "./PlanetMintFinalFrontier.module.scss";
import ConnectWallet from "./components/ConnectWallet";
import MintOptionSelection from "./components/MintOptionSelection";
import StuffOptionSelection from "./components/StuffOptionSelection";

const PlanetMintFinalFrontier = (): ReactElement => {
    const { walletAddress } = useContext(SessionContext);

    if (!IS_PLANETS_PHASE_OPEN) {
        return <Redirect to={HOME} />;
    }

    console.log(IS_STUFF_MINT_OPEN);

    return (
        <div className={styles.planetMintFinalFrontier}>
            <Container className={`${styles.container} ${IS_STUFF_MINT_OPEN ? "" : styles.centeredItems}`}>
                {!walletAddress ? (
                    <ConnectWallet />
                ) : (
                    <>
                        <MintOptionSelection />
                        {IS_STUFF_MINT_OPEN && <StuffOptionSelection />}
                    </>
                )}
            </Container>
        </div>
    );
};

export default PlanetMintFinalFrontier;
