import { ReactElement } from "react";
import { Redirect, useParams } from "react-router-dom";
import Container from "@components/Container";
import Logo from "@components/Logo";
import { ITimeline } from "@interfaces/Timeline/Timeline";
import formatDate from "@formatting/formatDate";
import DATA_TIMELINE from "../Timeline/Timeline.data";
import styles from "./TimelineDetail.module.scss";
import useGetLaunchByDate from "@hooks/Launch/useGetLaunchByDate";
import { NOT_FOUND } from "@routes/routes";
import Loader from "@components/Loader";
import BoxRockets from "./BoxRockets";
import BoxDescription from "./BoxDescription";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import BackButton from "@components/BackButton";

const TimelineDetail = (): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const item = DATA_TIMELINE.find((item: ITimeline) => item.id.toString() === id);

    if (!item) {
        return <Redirect to={NOT_FOUND} />;
    }

    const { data: launchDetail, loading } = useGetLaunchByDate(item.date);

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    return (
        <Container>
            <Logo />
            <BackButton>Back to Timeline</BackButton>
            <div className={styles.timelineDetail}>
                <Title heading={HeadingType.h1}>{item?.title.toUpperCase()}</Title>
                <Title heading={HeadingType.h2}>{formatDate(item?.date, "MMM dd. yyyy")}</Title>

                <div className={styles.timelineDetail__image}>
                    <img src={item?.image} alt="launch" />
                </div>

                {launchDetail && <BoxDescription launchDetail={launchDetail} />}

                {launchDetail?.rockets && <BoxRockets rockets={launchDetail.rockets} />}
            </div>
        </Container>
    );
};

export default TimelineDetail;
