import { ReactElement } from "react";
import styles from "./InvalidSerialNumber.module.scss";

const InvalidSerialNumber = (): ReactElement => {
    return (
        <div className={styles.invalidSerialNumber}>
            <p className={styles.title}>INVALID S/N</p>
            <p className={styles.text}>
                One or more of your S/N are invalid or have been already used. Try reentering the S/N on your scratcher.
            </p>
            {/* <ButtonCentered className={styles.purchaseButton} to={PLANET_MINT_HOME}>
                Purchase Physical Scratcher
            </ButtonCentered> */}
        </div>
    );
};

export default InvalidSerialNumber;
