import { IRocket } from "@interfaces/Rocket/Rocket";
import Rocket from "../Rocket";
import NotMintedRocket from "../RocketNotMinted";

interface IRocketListProps {
    rockets: IRocket[];
    showPlaceHolders: boolean;
}

const TOTAL_ROCKETS = 1000;
const EXTRA_ROCKETS = 10;

const RocketList = ({ rockets, showPlaceHolders }: IRocketListProps): JSX.Element => {
    let amountNonBuild = EXTRA_ROCKETS;
    if (TOTAL_ROCKETS - rockets.length < EXTRA_ROCKETS) {
        amountNonBuild = TOTAL_ROCKETS - rockets.length;
    }

    return (
        <>
            {rockets.map(({ id, name, date, image, slug }) => (
                <Rocket id={id} name={name} date={date} image={image} slug={slug} key={id} />
            ))}
            {showPlaceHolders &&
                [...Array(amountNonBuild)].map((_, i) => (
                    <NotMintedRocket isPayloadEligible={(i + rockets.length) % 5 === 0} key={i} />
                ))}
        </>
    );
};

export default RocketList;
