import Container from "@components/Container";
import styles from "./Terms.module.scss";
import Logo from "@components/Logo";

const Terms = (): JSX.Element => (
    <Container>
        <Logo />
        <div className={styles.container}>
            <h1>TERMS AND CONDITIONS</h1>
            <h4>Overview</h4>
            <p>
                This website (the <span>“Site”</span>) is owned and operated by Home Hobby Solutions, LLC, a Delaware
                limited liability company (referred to herein as “we/us” or <span>“The Rocket Factory”</span>).
            </p>
            <p>
                The Site is associated with and serves as the primary digital home for the on-going fine-art project by
                the artist Tom Sachs (the <span>“Artist”</span>) known as Tom Sachs: Rocket Factory (including its
                metaverse implementation known as The Final Frontier). The products and services made available through
                the Site (collectively, the <span>“Services”</span>) are primarily intended to function as part of this
                overall project, either as individual artworks or as associated supporting or contextual materials.
            </p>
            <p>
                The terms, conditions, and notices set forth herein (the <span>“Terms of Service”</span>) will govern
                your access to and use of the Site and the Services. Such access and all of the Services are provided
                subject to the Terms of Service.
            </p>
            <p>
                By using the Services and the Site, you are agreeing to all of the Terms of Service, as they may be
                updated by us from time to time. It is your responsibility to check this page regularly in order to stay
                informed of any changes we may make to the Terms of Service.
            </p>
            <p>
                Access to the Site is permitted on a temporary basis. We reserve the right to withdraw or amend the
                Services and such access at any time without notice. We will not be liable if for any reason the Site or
                the Services becomes unavailable at any time or for any period. We will have the right to restrict
                access to some or all parts of the Site at any time.
            </p>
            <p>
                The Site may contain links to other websites that are not operated by The Rocket Factory, We assume no
                liability with respect to any such website or any of its content or functionality.
            </p>
            <h4>Limitations on Use</h4>
            <p>
                As a condition of use, you promise not to use the Services or the Site for any purpose that is unlawful
                or prohibited by these Terms of Service, or any other purpose not reasonably intended by The Rocket
                Factory. In particular (without limitation), you agree not to use the Services to: transmit or
                distribute any virus, trojan, worm, logic bomb or any other material which is malicious, technologically
                harmful; breach any duty of confidentiality; post any material that is offensive or obscene; hack into
                any aspect of the Service; corrupt any data; cause annoyance to other users; infringe upon any other
                person's proprietary rights; send any unsolicited advertising or promotional material (i.e., "spam"); or
                attempt to affect the performance or functionality of any computer facilities associated with or
                accessible through the Site. Breaching the foregoing provision may constitute a criminal offense. The
                Rocket Factory will report any criminal breach to the relevant law enforcement authorities, and reserves
                the right to terminate your account or restrict your access to the Site.
            </p>
            <p>
                Neither The Rocket Factory nor Tom Sachs, nor any of their respective affiliates, successors, or agents,
                will be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other
                technologically harmful material that may infect your computer equipment, computer programs, data or
                other proprietary material as the result of your use of the Site and/or the Services, or your
                downloading of any material posted on or transmitted by the Site or on any website linked to it.
            </p>
            <h4>Content & Intellectual Property Rights</h4>
            <p>
                Unless otherwise noted, all images, illustrations, designs, icons, photographs, video clips, texts,
                music, sounds, software, and materials that appear as part of the Site or that are used to create,
                operate, maintain, or update the Site or to provide the Services, and the copyrights, trademarks, trade
                dress, publicity/personality rights, and/or other intellectual properties therein (collectively, the
                <span>“Content”</span>) are owned or used under license by The Rocket Factory and/or its affiliates. The
                Content is protected by laws governing copyright, trademark, patent, trade secret, privacy rights and
                other proprietary rights under US law and worldwide, as applicable.
            </p>
            <p>
                The Content is intended for your personal, noncommercial use. Unauthorized reproduction or distribution
                or any form of commercialization of the Content is not permitted. You may not publish, transmit,
                manipulate, distribute or otherwise reproduce, or create derivative works from, any of the Content or
                the Site, in whole or in part, or use any such content in connection with any business or commercial
                enterprise, without the express permission of The Rocket Factory (or the applicable rights owner, if
                different). In the event of any permitted copying, redistribution or publication of the Content, no
                changes in or deletion of author attribution, trademark or copyright notices, or any similar notice will
                made.
            </p>
            <p>
                In addition to the other Terms of Service, the Content constituting Sachs NFTs (as defined below) is
                governed by the the NFT Ownership License set forth below.
            </p>
            <h4>NFT Ownership License</h4>
            <p>
                The terms of this section apply to all non-fungible tokens (“NFTs”) created by Tom Sachs and/or The
                Rocket Factory that are sold, distributed, or otherwise made available by The Rocket Factory, whether
                via the Site, as part of the Services, or otherwise (<span>“Sachs NFTs”</span>).
            </p>
            <p>
                <em>Terms of Acquisition.</em> Each Sachs NFT that is made available by The Rocket Factory for purchase
                or acquisition is subject to any specific terms communicated by The Rocket Factory in connection with
                the initial distribution or primary-market sale of such Sachs NFT, together with the NFT Ownership
                License (as defined below) and the other Terms of Services, as applicable. The costs associated with the
                purchase or acquisition may vary and may be charged in cryptocurrency (e.g., ETH) or fiat currency
                (e.g., USD), and typically will require you to pay the “gas” fees charged automatically by the Ethereum
                network (or the applicable blockchain). NFT transactions typically require you to obtain and maintain a
                digital cryptographic "wallet" that allows you to engage in transactions on blockchains. The Rocket
                Factory is not a wallet provider, exchange, broker, dealer, financial institution, payments processor,
                money services business, or creditor. All purchases, sales, and payments of Sachs NFTs are final. We do
                not offer cancellations, returns, or refunds.
            </p>
            <p>
                <em>Ownership of Sachs NFTs.</em> Reference herein to your ownership of the Sachs NFT means your
                exclusive ownership of the authenticated NFT that constitutes the digital original of the Sachs NFT, as
                such ownership is recorded on the applicable blockchain. Only a person or entity with the legal right to
                access and control the cryptocurrency address or account to which the Sachs NFT is assigned on the
                blockchain will qualify hereunder as an owner of the Sachs NFT.
            </p>
            <p>
                <em>Your Rights as Owner.</em> For so long as you remain the owner of each Sachs NFT you will be
                entitled to exercise the following rights (the <span>“NFT Ownership License”</span>):
            </p>
            <p>
                <ul>
                    <li>
                        To store the Sachs NFT in any account (i.e., cryptocurrency address) and to freely transfer the
                        Sachs NFT between accounts.
                    </li>

                    <li>To privately view and display the Sachs NFT for personal purposes on any device.</li>

                    <li>
                        To sell the Sachs NFT to any third party, to exchange it in a swap with any third party, to list
                        and offer it for sale or swap on any marketplace and/or through any platform or outlet that
                        supports such sale or swap, to donate or gift the Sachs NFT to any third party, and to transfer
                        ownership of the Sachs NFT to the applicable purchaser or other intended recipient.
                    </li>

                    <li>
                        To reproduce the images and other content produced or presented by the Sachs NFT (collectively,
                        the <span>“Imagery”</span>) for your reasonable, private, noncommercial purposes, such as
                        displaying the Imagery on your personal website and/or in your personal social media, or
                        including the Imagery as an informational illustration in a book, magazine article or other
                        publication dealing with your NFT collection or art collection.
                    </li>

                    <li>
                        To use the Imagery as your personal profile image or avatar, or as a similar personal graphic
                        that serves to personally identify you in your personal social media and in comparable personal
                        noncommercial contexts.
                    </li>

                    <li>
                        To include and exhibit the Sachs NFT in any public or private art exhibition (or any comparable
                        context), whether organized by you or by any third party such as a museum or gallery; provided
                        that unless the Sachs NFT has been recognized by Tom Sachs as a work of fine art created by him,
                        it will not be identified or exhibited as such.
                    </li>
                </ul>
            </p>
            <p>
                The NFT Ownership License also includes the limited, nonexclusive right to make use of the Artist’s name
                and the Reserved Rights (as defined below) to the extent reasonably required to enable you to exercise
                the aforementioned usage rights.
            </p>
            <p>
                <em>The Final Frontier.</em> If the Sachs NFT in questions is suitable and/or intended for use in the
                online metaverse created by The Rocket Factory and hosted by Mona known as <em>The Final Frontier</em>,
                the NFT Ownership License will also include the right to make use of the Sachs NFT for its intended
                purpose in connection with <em>The Final Frontier</em>, in compliance with Mona's terms of use.
            </p>
            <p>
                <em>Faithful Display & Reproduction.</em> Sachs NFTs may not be materially altered or changed, and must
                be faithfully displayed and reproduced in the form originally minted. The NFT Ownership License only
                applies to each Sachs NFT in this original form, and does not apply to, and may not be exercised in
                connection with, any version of a Sachs NFT that has been materially altered or changed.
            </p>
            <p>
                <em>Excluded Uses.</em> You may not reproduce, display, use, or exploit any Sachs NFT in any manner
                other than as expressly permitted by the NFT Ownership License, as set forth above. In particular,
                without limitation, the NFT Ownership License does not include any right to reproduce, display, use, or
                exploit any Sachs NFT or its Imagery for any of the following purposes or usages without our express
                written consent:
            </p>
            <p>
                <ul>
                    <li>To create any derivative work based on a Sachs NFT.</li>

                    <li>
                        To reproduce any Sachs NFT for merchandising purposes (e.g., to produce goods offered for sale
                        or given away as premiums or for promotional purposes).
                    </li>

                    <li>
                        To make use of any Sachs NFT as a logo, trademark, service mark, or in any similar manner (other
                        than personal use as your personally identifying profile image, avatar, or graphic, as expressly
                        permitted above).
                    </li>

                    <li>
                        Use of any Sachs NFT to promote or advertise any brand, product, product line, or service (with
                        the understanding that the Imagery of certain Sachs NFTs incorporate well-known third-party
                        brand names, logos, and/or trademarks for artistic purposes).
                    </li>

                    <li>
                        Any other use of the Sachs NFT for your commercial benefit or the commercial benefit of any
                        third party (other than resale of the Sachs NFT, as expressly permitted above).
                    </li>

                    <li>Use for any political purpose or to promote any political or other cause.</li>

                    <li>
                        Use of the Reserved Rights (as defined below) for any purpose other than as reasonably required
                        in order to exercise the NFT Ownership License, such as, without limitation, any use of the
                        Artist’s name or the title of any Sachs NFT for endorsement, advertising, trademark, or other
                        commercial purposes.
                    </li>
                </ul>
            </p>
            <p>
                <em>Use of Artist’s Name.</em> The NFT Ownership License also does not include any right to use the
                Artist’s name, signature, likeness, persona, or other personally identifying indicia (or the name Tom
                Sachs: Rocket Factory and its variations, such as TSRF) (collectively, the
                <span>“Artist Identification”</span>) for any purpose, except that the Artist’s name may be used solely
                for informational purposes as reasonably required to identify the Artist as the creator of the Sachs
                NFTs. Any use of the Artist’s name, or any other Artist Identification, for endorsement, advertising, or
                merchandising purposes, or for any other commercial or non-informational purpose, will require our
                express prior written consent in each instance.
            </p>
            <p>
                <em>Reserved Rights.</em> The NFT Ownership License does not apply to or include any of the following
                items of intellectual property associated with each Sachs NFT (the <span>“Reserved Rights”</span>), and
                all of the copyrights, trademark rights, patent rights, and other rights of reproduction, usage, and/or
                exploitation therein are retained and reserved by us, as our exclusive property: (i) the copyright in
                the Imagery as displayed or presented by each Sachs NFT and the copyright in the original digital
                artwork image and data created by the Artist and/or The Rocket Factory; (ii) the programming,
                algorithms, and code used to generate the Imagery, and the on-chain smart contract, software code,
                scripts, and/or data constituting and/or implementing the applicable NFT; and (iii) the Artist
                Identification. The Reserved Rights are, and at all times will remain, our (or the Artist’s) exclusive
                property, and all rights in the intellectual property to which the Reserved Rights apply that are not
                expressly granted herein are reserved to us, including, without limitation, all copyrights, patent
                rights, trademark rights, publicity/personality rights, and other intellectual-property rights. You
                hereby irrevocably assign to us any and all rights or ownership you may have, or claim to have, in any
                such intellectual property. We will be free to exercise the Reserved Rights for any purpose and for any
                other project, without restriction, and to register any or all of the intellectual property to which the
                Reserved Rights apply in our name or in the name of our designee.
            </p>
            <p>
                <em>Transfer of Sachs NFTs.</em> The NFT Ownership License is granted to you with respect to each Sachs
                NFT only for so long as you remain the legal owner of the Sachs NFT. If and when you sell, swap, donate,
                gift, give away, “burn,” or otherwise cease to own the Sachs NFT for any reason, your rights to exercise
                the NFT Ownership License will immediately and automatically terminate. When the Sachs NFT is legally
                transferred to a new owner, as recorded on the applicable blockchain, the new owner will thereafter be
                entitled to exercise the NFT Ownership License, and references to “you” herein with respect to such
                Sachs NFT will thereafter be deemed to refer to the new owner.
            </p>
            <p>
                <em>Resale Royalty.</em> With respect to the resale of each Sachs NFT, we will typically be entitled to
                receive a portion of the amount paid by the purchaser thereof, as specified for the applicable Sachs NFT
                at the time it is first released (the “Resale Royalty”). For example, if the Sachs NFT is subject to a
                Resale Royalty of 10% and is subsequently resold for 1.0 ETH, the Resale Royalty due will be 0.10 ETH.
                The Resale Royalty is intended to be deducted and paid whenever the Sachs NFT is resold after the
                initial sale. However, if for any reason the full amount due as the Resale Royalty is not deducted and
                paid (for example, if the Resale Royalty is not collected and remitted by the applicable marketplace
                and/or if some or all of the applicable purchase price is paid outside the blockchain), in addition to
                any other available remedies we will be entitled (i) to recover the full unpaid amount of the Resale
                Royalty along with any attorneys’ fees and other costs reasonably incurred to enable such recovery; (ii)
                to terminate and suspend the NFT Ownership License until full payment is received; and (iii) to obtain
                injunctive or other equitable relief in any applicable jurisdiction. It is understood that we may decide
                not to enforce our Resale Royalty rights in circumstances we deem to be appropriate. However, no such
                decision shall be deemed to waive or terminate our Resale Royalty rights or affect our right to claim
                the Resale Royalty in the future.
            </p>
            <p>
                <em>Illegal Acquisition.</em> If a Sachs NFT is acquired by unauthorized or illegal means, such as an
                unauthorized or unintended transfer to a new cryptocurrency address as the result of hacking, fraud,
                phishing, conversion, accident, or other unauthorized action, the following terms will apply until such
                time as the Sachs NFT is returned to its rightful owner: (i) the NFT Ownership License will immediately
                terminate and be deemed suspended; and (ii) we will be entitled to take any and all steps necessary to
                prevent the Sachs NFT from being sold or traded, including, without limitation, causing the Sachs NFT to
                be removed or delisted from any marketplace or platform where it is offered for sale. Notwithstanding
                the foregoing, nothing herein will obligate us to take any action with respect to any unauthorized
                acquisition or disposition of a Sachs NFT, and we will not have any liability in this regard.{" "}
            </p>
            <p>
                <em>Limited Guarantee.</em> We guarantee that each Sachs NFT will constitute an authentic original
                digital work by the Artist. In all other respects, each Sachs NFT is provided strictly “as is.” We make
                no other representation, provide no other warranty, and assume no other liability of any kind whatsoever
                in connection with any Sachs NFT, including, without limitation, any representations, warranties, or
                conditions, express or implied, as to merchantability, fitness for a particular purpose, functionality,
                technical quality or performance, freedom from malware or errors, or value, all of which
                representations, warranties, and conditions are expressly disclaimed. No statement made by The Rocket
                Factory, the Artist, or any of their affiliates, whether oral or in writing, will be deemed to
                constitute any such representation, warranty, or condition. EXCEPT AS EXPRESSLY PROVIDED ABOVE, EACH
                SACHS NFT IS PROVIDED BY US ENTIRELY ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT REPRESENTATIONS,
                WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED.
            </p>
            <p>
                <em>Your Knowledge & Experience.</em> You represent and warrant that you are knowledgeable, experienced,
                and sophisticated in using blockchain and cryptocurrency technology and that you understand and accept
                the risks associated with technological and cryptographic systems such as blockchains, NFTs,
                cryptocurrencies, smart contracts, consensus algorithms, decentralized or peer-to-peer networks and
                systems, and similar systems and technologies, which risks may include malfunctions, bugs, timing
                errors, transfer errors, hacking and theft, changes to the protocol rules of the blockchain (e.g.,
                forks), hardware, software and/or Internet connectivity failures, unauthorized third-party data access,
                and other technological risks, any of which can adversely affect any Sachs NFT owned by you and/or
                expose you to a risk of loss, forfeiture of your NFTs or digital currency, or lost opportunities to buy
                or sell digital assets.
            </p>
            <p>
                <em>Acknowledgement of Inherent Risks.</em> You acknowledge and accept that by owning, using, accessing,
                and/or transacting with any Sachs NFT, and receiving, accessing, and using the Services and the Site,
                you are agreeing to assume the risks arising from the following factors:
            </p>
            <p>
                <ul>
                    <li>
                        The prices of blockchain assets, including NFTs, are extremely volatile and unpredictable as the
                        result of technological, social, market, subjective, and other factors and forces that are not
                        within our control.
                    </li>

                    <li>Digital assets such as the Sachs NFTs may have little or no inherent or intrinsic value.</li>

                    <li>
                        Fluctuations in the pricing or markets of digital assets such as Sachs NFTs could materially and
                        adversely affect each NFT’s value, which may be subject to significant price volatility.
                    </li>

                    <li>
                        Providing information and conducting business over the Internet and via related technological
                        means with respect to cryptocurrencies and digital assets such as NFTs entails substantial
                        inherent security risks that are or may be unavoidable.
                    </li>

                    <li>
                        Due to the aforementioned risk factors, and other factors that cannot be predicted or
                        controlled, there is no assurance whatsoever that any Sachs NFT will retain its value at the
                        original purchase price or that it will attain any future value thereafter.
                    </li>
                </ul>
            </p>
            <p>
                <em>Liability for NFT-Related Claims.</em> Our maximum total liability to you for any claim arising or
                asserted hereunder or otherwise in connection with any Sachs NFT will be limited to the amount paid to
                us by the original purchaser for the initial, primary-market purchase of the Sachs NFT.
            </p>
            <h4>Limitation of Liability</h4>
            <p>
                Under no circumstances will we be liable for any loss or damage you may suffer or cause as the result of
                receiving, accessing, and/or using the Services and/or the Site, or as the result of your ownership,
                use, access, and/or transaction with any Sachs NFT, and you assume all risks in this regard, including,
                but not limited to risks associated with the following factors:
            </p>
            <p>
                <ul>
                    <li>
                        Unauthorized third-party activities and actions, such as hacking, exploits, introduction of
                        viruses or other malicious code, phishing, Sybil attacks, 51% attacks, brute forcing, mining
                        attacks, cybersecurity attacks, or other means of attack that affect any Sachs NFT in any way.
                    </li>

                    <li>Weaknesses in security, blockchain malfunctions, or other technical errors.</li>

                    <li>Telecommunications or Internet failures.</li>

                    <li>Protocol changes or hard forks in the blockchain on which any Sachs NFT is recorded.</li>

                    <li>Errors by you (such as forgotten passwords, lost private keys, or mistyped addresses).</li>

                    <li>Errors by us (such as incorrectly constructed transactions or incorrectly programmed NFTs).</li>

                    <li>
                        Unfavorable regulatory determinations or actions, or newly implemented laws or regulations, in
                        any jurisdiction.
                    </li>

                    <li>
                        Taxation of NFTs or cryptocurrencies, the uncertainty of the tax treatment of NFT or
                        cryptocurrency transactions, and any changes in applicable tax laws, or other laws, in any
                        jurisdiction.
                    </li>

                    <li>Your inability to access, transfer, sell, or use any Sachs NFT for any reason.</li>

                    <li>
                        Disclosure or breaches of personal information arising in connection with your use or ownership
                        of any Sachs NFT.
                    </li>

                    <li>
                        Total or partial loss of value of any Sachs NFT due to the inherent price volatility of digital
                        blockchain-based and cryptocurrency assets and markets.
                    </li>
                </ul>
            </p>
            <p>
                UNDER NO CIRCUMSTANCES WILL WE BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                EXEMPLARY DAMAGES OF ANY KIND, EVEN IF WE HAVE BEEN ADVISED OR OTHERWISE WERE AWARE OF THE POSSIBILITY
                OF SUCH DAMAGES.
            </p>
            <p>
                The foregoing limitations on liability apply to all claims, whether based in contract, tort, or any
                other legal or equitable theory.
            </p>
            <p>
                Notwithstanding the foregoing, nothing herein will be deemed to exclude or limit in any way our
                liability if it would be unlawful to do so, such as any liability that cannot legally be excluded or
                limited under applicable law. It is acknowledged that the laws of some jurisdictions do not allow some
                or all of the disclaimers, limitations or exclusions set forth herein. If these laws apply in your case,
                some or all of the foregoing disclaimers, limitations or exclusions may not apply to you, and you may
                have additional rights.
            </p>
            <h4>Additional Terms & Conditions</h4>
            <p>
                Indemnification. You agree to indemnify, defend and hold harmless The Rocket Factory and the Artist, and
                their respective affiliates and, as applicable, their respective directors, officers, employees,
                consultants, agents, affiliates, successors, legal representatives, licensees, and assigns (jointly and
                severally, the <span>“Indemnified Parties”</span>) from and against any and all claims, causes of
                action, costs, proceedings, demands, obligations, losses, liabilities, penalties, damages, awards,
                judgments, interest, fees, and expenses (including reasonable attorneys’ fees and legal, court,
                settlement, and other related costs) of any kind or nature, in law or equity, whether in tort, contract
                or otherwise, arising out of or relating to your use of the Site, the Services, or any Sachs NFT, and/or
                any actual or alleged breach by you of the Terms of Service.
            </p>
            <p>
                <em>Release.</em> You hereby release, acquit, and forever discharge each of the Indemnified Parties from
                any damages, suits, or controversies or causes of action resulting from your use of the Services and/or
                the Site in violation of the Terms of Service, and/or your acquisition, transfer, sale, disposition, or
                use of any Sachs NFT in violation of the terms of the NFT Ownership License or the other Terms of
                Service. In this regard, if and as applicable, you hereby waive the provision of California Civil Code
                Section 1542, which says: “A general release does not extend to claims that the creditor or releasing
                party does not know or suspect to exist in his or her favor at the time of executing the release and
                that, if known by him or her, would have materially affected his or her settlement with the debtor or
                released party.” If any comparable legal provision applies in any other jurisdiction, you hereby also
                waive such provision to the maximum extent permitted by law.
            </p>
            <p>
                <em>Severability.</em> If any term, clause, or provision of the Terms of Service is held to be invalid
                or unenforceable, it will be deemed severed from the remaining terms hereof and will not be deemed to
                affect the validity or enforceability of such terms.
            </p>
            <p>
                <em>Conflicting Terms.</em> In the event of any conflict between the Terms of Service and any terms
                imposed by or in connection with any platform, marketplace, or similar service or application on which
                any Sachs NFT is offered, listed, included, sold, traded, swapped, gifted, stored, or transferred in the
                secondary market, the Terms of Service will control.
            </p>
            <p>
                <em>Waiver.</em> Our failure in any instance to exercise or enforce any right or provision of the Terms
                and Conditions will not constitute a waiver of such right or provision.
            </p>
            <p>
                <em>Data Protection.</em> We take the protection of your personal information very seriously. We treat
                your personal information as confidential and will handle it in accordance with any applicable data
                protection legislation as well as with the terms of this statement. To the extent that personal
                information is provided (e.g. name, address, email address, etc.), we take steps to protect and limit
                any use of it to the purposes for which it is provided. Please note that there can sometimes be security
                issues when information is transmitted over the Internet (e.g., via email). It is therefore not possible
                to guarantee that all data will be protected from access by third parties. Every reasonably achievable
                effort is made to prevent unauthorized access by third parties to any user date we collect and store.
                However, the transmission of data via the Internet is never risk-free. Neither The Rocket Factory nor
                the Artist, or any of their respective affiliates, will be liable for any damages incurred as a
                consequence of such security risks. See additional information in our Privacy Policy.
            </p>
            <p>
                <em>Applicable Law.</em> The Terms of Service are governed by the laws of the State of New York and the
                United States applicable to contracts to be wholly performed therein, without reference to
                conflicts-of-laws provisions.
            </p>
            <p>
                <em>Dispute Resolution.</em> Other than claims for preliminary injunctive relief or other pre-judgment
                remedies, any dispute, controversy or claim arising out of, relating to, or in connection with any of
                the Terms of Service, or the alleged breach, termination, or validity thereof (a “Dispute”) shall be
                submitted to JAMS, or its successor, for mediation in New York County, New York State, USA. If the
                Dispute is not settled by mediation within 60 days from the date when mediation is initiated, then the
                Dispute shall be submitted to JAMS, or its successor, for final and binding arbitration in accordance
                with its Comprehensive Arbitration Rules and Procedures or, if the Dispute involves a non-U.S. party,
                the JAMS International Arbitration Rules. The seat of the arbitration shall be New York and the
                arbitration shall be conducted by a single arbitrator, who shall be appointed within 30 days after the
                initiation of the arbitration. The arbitration will be confidential, except to the extent necessary to
                enforce a judgment or where disclosure is required by law. The arbitration award will be final and
                binding on all parties involved. Judgment upon the award may be entered by any court having jurisdiction
                thereof or having jurisdiction over the relevant party or its assets. Each party will bear its own costs
                in connection with such arbitration.
            </p>
            <p>
                <em>Waiver of Jury Trial.</em> YOU AND WE WAIVE ANY AND ALL CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE
                IN COURT AND TO HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and we have instead agreed that all
                claims and disputes arising hereunder will be resolved by arbitration, as provided above.
            </p>
            <p>
                <em>Waiver of Class Action.</em> ALL CLAIMS AND DISPUTES FALLING WITHIN THE SCOPE OF ARBITRATION
                HEREUNDER MUST BE ARBITRATED ON AN INDIVIDUAL BASIS, AND NOT ON A CLASS-ACTION, COLLECTIVE-CLASS, OR
                NON-INDIVIDUALIZED BASIS. YOUR CLAIMS CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER USER
                OF THE SITE OR THE SERVICES, OR ANY OTHER OWNER OF A SACHS NFT OR ANY OTHER WORK BY THE ARTIST. If
                applicable law precludes enforcement of this limitation as to a given claim for relief, the claim must
                be severed from the arbitration and brought in the applicable court located in the State of New York.
                All remaining claims must be arbitrated.
            </p>
            <p>
                <em>Entire Agreement.</em> The Terms of Service constitute the entire agreement between the parties with
                respect to the matters contained herein, and supersede any and all preceding agreements, understandings,
                statements, discussions and arrangements in this regard.
            </p>
            <p>
                <em>Contact.</em> If you have any questions about the Terms of Service, you may contact us by email at
                the following address:
                <a href="mailto:info@tomsachsrocketfactory.com">info@tomsachsrocketfactory.com</a>
            </p>
        </div>
    </Container>
);

export default Terms;
