import galaxy from "@assets/images/starmap/galaxy.png";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import { STARMAP_GALAXY } from "@routes/routes";
import { Link } from "react-router-dom";
import styles from "./ExploreGalaxy.module.scss";

const ExploreGalaxy = (): JSX.Element => {
    return (
        <GalaxyBackground>
            <h1 className={styles.galaxy__title}>THE FINAL FRONTIER</h1>
            <Link to={STARMAP_GALAXY} className={styles.galaxy__explore_desktop}>
                EXPLORE OUR GALAXY
            </Link>
            <img className={styles.galaxy__image} src={galaxy} alt="galaxy" />
            <Link to={STARMAP_GALAXY} className={styles.galaxy__explore_mobile}>
                EXPLORE OUR GALAXY
            </Link>
        </GalaxyBackground>
    );
};

export default ExploreGalaxy;
