import styles from "./Button.module.scss";

export enum ButtonVariant {
    Primary,
    Secondary,
    Link,
}

const stylesMap = {
    [ButtonVariant.Primary]: styles.primary,
    [ButtonVariant.Secondary]: styles.secondary,
    [ButtonVariant.Link]: styles.link,
};

interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
    variant?: ButtonVariant;
}

const Button = ({ children, className, variant = ButtonVariant.Primary, ...props }: ButtonProps): JSX.Element => (
    <button className={`${className} ${stylesMap[variant]}`} {...props}>
        {children}
    </button>
);

export default Button;
