import { createLinkName } from "@scripts/StringsHelper";
import styles from "./AssetItemList.module.scss";

interface IAssetItemList {
    img: string;
    name: string;
}

const AssetItemList = ({ img, name }: IAssetItemList): JSX.Element => (
    <li id={createLinkName(name, "")} className={styles.nft}>
        <img className={styles.nft__image} src={img} alt={`item ${name}`} />
        <p className={styles.nft__name}>{name}</p>
    </li>
);

export default AssetItemList;
