import Link, { LinkVariant } from "@components/Link";
import { SessionContext } from "@context/SessionContext";
import { ITokenResponse } from "@interfaces/Hangar/TokenResponse";
import { IRock } from "@interfaces/Rock/Rock";
import { ITicket } from "@interfaces/Ticket/Ticket";
import { IS_STUFF_MINT_OPEN, IS_STUFF_RAFFLE_OPEN } from "@scripts/DateHelper";
import { ECONOMY_CLASS } from "@scripts/TicketHelper";
import getSuscriptionAvailability from "@services/Hangar/getSuscriptionAvailability";
import { useContext, useEffect, useState } from "react";
import styles from "../../MyHangar.module.scss";
import Subscribe from "../Subscribe";
import headerStyles from "./Header.module.scss";

interface IHeaderParams {
    walletAddress: string;
    tokens: ITokenResponse;
}

const Header = ({ walletAddress, tokens }: IHeaderParams): JSX.Element => {
    const { walletAddress: signedWalletAddress } = useContext(SessionContext);
    const [showModal, setShowModal] = useState(false);
    const [canSubscribe, setCanSubscribe] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        getAvailability();
    }, [walletAddress, signedWalletAddress]);

    const getTicketsQuantity = () =>
        tokens.tickets.reduce((acc, ticket: ITicket) => (ticket.id <= ECONOMY_CLASS ? ticket.amount + acc : acc), 0);

    const getUsedTicketsQuantity = () =>
        tokens.tickets.reduce((acc, ticket: ITicket) => (ticket.id > ECONOMY_CLASS ? ticket.amount + acc : acc), 0);

    const getRocksQuantity = () =>
        tokens.rocks.reduce((acc, rock: IRock) => {
            return rock.amount + acc;
        }, 0);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    const getAvailability = () => {
        if (!IS_STUFF_RAFFLE_OPEN || walletAddress.toLowerCase() !== signedWalletAddress.toLowerCase()) {
            return;
        }

        getSuscriptionAvailability(walletAddress).then((availability) => {
            setCanSubscribe(availability.canSubscribe);
            setIsSubscribed(availability.isSubscribed);
        });
    };

    const setShowModalAndRefreshAvailability = () => {
        toggleModal();
        getAvailability();
    };

    return (
        <div className={styles.section}>
            {showModal && <Subscribe showModal={setShowModalAndRefreshAvailability} />}
            <div className={headerStyles.header__title}>
                <h1>MY HANGAR</h1>
                <span>{walletAddress}</span>
            </div>

            <div className={headerStyles.header__buttons}>
                <Link variant={LinkVariant.Button} to={`https://etherscan.io/address/${walletAddress}`}>
                    VIEW ETHERSCAN
                </Link>
                <Link variant={LinkVariant.Button} to={`https://opensea.io/${walletAddress}`}>
                    VIEW OPENSEA ACCOUNT
                </Link>
                {canSubscribe && (
                    <a
                        onClick={toggleModal}
                        className={`${headerStyles.button} ${isSubscribed ? headerStyles.disabled : ""}`}
                    >
                        {isSubscribed ? "REGISTERED" : "REGISTER"}
                    </a>
                )}
            </div>

            <div className={headerStyles.header__data}>
                <div className={headerStyles.header__data__item}>
                    {IS_STUFF_MINT_OPEN && (
                        <div>
                            <span>Total Stuff Owned</span>
                            <p>{tokens.stuff.length}</p>
                        </div>
                    )}
                    <div>
                        <span>Total Planets Owned</span>
                        <p>{tokens.planets.length}</p>
                    </div>
                    <div>
                        <span>Total Mars Rocks Owned</span>
                        <p>{getRocksQuantity()}</p>
                    </div>
                    <div>
                        <span>Total Components Owned</span>
                        <p>{tokens.components.length}</p>
                    </div>
                    <div>
                        <span>Total Used Tickets Owned</span>
                        <p>{getUsedTicketsQuantity()}</p>
                    </div>
                </div>
                <div className={headerStyles.header__data__item}>
                    <div>
                        <span>Total Apartments Owned</span>
                        <p>{tokens.spaceStations.length}</p>
                    </div>
                    <div>
                        <span>Total Rockets Owned</span>
                        <p>{tokens.rockets.length}</p>
                    </div>
                    <div>
                        <span>Total Tickets Owned</span>
                        <p>{getTicketsQuantity()}</p>
                    </div>
                    <div>
                        <span>Total Patches Owned</span>
                        <p>{tokens.patches.length}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
