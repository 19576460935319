export const HOME_VIDEO = "https://www.youtube.com/embed/f3x-wN6yaCQ";
export const COMPONENTS_COLLECTIONS_OPENSEA_URL = "https://opensea.io/collection/tom-sachs-rocket-factory";
export const MARS_ROCK_COLLECTIONS_OPENSEA_URL = "https://opensea.io/collection/tom-sachs-mars-rocks";
export const ROCKETS_COLLECTIONS_OPENSEA_URL = "https://opensea.io/collection/tom-sachs-rockets";
export const TICKETS_COLLECTIONS_OPENSEA_URL = "https://opensea.io/collection/tom-sachs-mothership-tickets";
export const PLANETS_COLLECTIONS_OPENSEA_URL = "https://opensea.io/collection/tom-sachs-planets";
export const PATCHES_COLLECTIONS_OPENSEA_URL = "https://opensea.io/collection/tom-sachs-rocket-factory-patches-";

export const DETAIL_OPENSEA_URL = "https://opensea.io/assets/ethereum/:address/:id";

export const PURCHASE_REPORT_ZINE_URL =
    "https://store.tomsachs.com/collections/frontpage/products/rocket-factory-phase-ii-experience-report-zine-nft";
export const DOWNLOAD_PDF_ZINE_URL =
    "https://storage.googleapis.com/tsrf/documents/TSRF%20Phase%20II%20Experience%20Report.pdf";
export const STORE_URL = "https://store.tomsachs.com/";
export const YOUTUBE_CHANNEL_URL = "https://www.youtube.com/channel/UC7AoujVn6ImYPcbucSvR2bw";
