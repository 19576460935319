import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import styles from "./GoBackMobile.module.scss";
import useBackButton from "@hooks/Starmap/useBackButton";
import { Link } from "react-router-dom";

const GoBackMobile = (): JSX.Element => {
    const { name, link } = useBackButton();

    return (
        <Link aria-label="back to previous step" to={link} className={styles.goback__mobile}>
            <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
            {name}
        </Link>
    );
};

export default GoBackMobile;
