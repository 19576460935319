import { generatePath, useParams } from "react-router-dom";
import galaxy from "@assets/images/starmap/galaxy.png";
import { STARMAP, STARMAP_GALAXY, STARMAP_ZONE } from "@routes/routes";
import { LEGEND_LIST_DETAILED, LEGEND_LIST_WIDE } from "@pages/Starmap/starmap.legends.data";
import STARMAP_ZONES from "@pages/Starmap/starmap.zones.data";
import { ILegend } from "@interfaces/Starmap/Legend";

interface IUseBackButton {
    name: string;
    link: string;
    stepPreviewImage?: string;
    legend?: ILegend[];
}

const useBackButton = (image?: string): IUseBackButton => {
    const { zoneId, zoneName, systemId } = useParams<{ zoneId: string; zoneName: string; systemId: string }>();

    const isStep1 = !zoneId && !systemId;
    const isStep2 = zoneId && !systemId;
    const isStep3 = zoneId && systemId;
    const isSpaceStation = zoneId && systemId && systemId === "1";

    const name = isStep3 ? `BACK TO ${zoneName}` : isStep2 ? "BACK TO ZONE OVERVIEW" : "BACK TO GALAXY";

    const stepPreviewImage = image
        ? image
        : isStep1
        ? galaxy
        : isStep3
        ? STARMAP_ZONES[parseInt(zoneId) - 1]?.preview
        : undefined;

    const legend = isSpaceStation ? undefined : isStep3 ? LEGEND_LIST_DETAILED : LEGEND_LIST_WIDE;

    const link = isStep3
        ? generatePath(STARMAP_ZONE, {
              zoneId: zoneId,
              zoneName: zoneName,
          })
        : isStep1
        ? STARMAP
        : generatePath(STARMAP_GALAXY);

    return {
        name,
        link,
        stepPreviewImage,
        legend,
    };
};

export default useBackButton;
