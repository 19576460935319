import { useEffect, useState } from "react";
import getTokensMetadata from "@services/Metadata/getTokensMetadata";
import { PLANETS_SLUG } from "@constants/env";
import { ITokenItem } from "@interfaces/Hangar/TokenItem";
import TemplateBox from "../TemplateBox";
import { getLinkCollection } from "@scripts/CollectionHelper";
import Link from "@components/Link";
import { PLANET_DETAIL } from "@routes/routes";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";

interface IPlanetsParams {
    items: ITokenItem[];
}

const Planets = ({ items }: IPlanetsParams): JSX.Element => {
    const [planetsMetadata, setPlanetsMetadata] = useState<{ tokenId: string; name: string; image: string }[]>([]);

    useEffect(() => {
        if (items.length === 0) {
            return;
        }

        const tokenIds = items.map(({ id }) => id.toString());

        getTokensMetadata(PLANETS_SLUG, tokenIds).then(({ data: planets }) => {
            const planetsMetadata: { tokenId: string; name: string; image: string }[] = [];
            for (const {
                metadata: { name, image },
                config: { tokenId },
            } of planets) {
                planetsMetadata.push({ tokenId, name, image });
            }

            setPlanetsMetadata(planetsMetadata);
        });
    }, []);

    return items.length > 0 ? (
        <TemplateBox title={EHangarTitleType.PLANETS}>
            {planetsMetadata.map(({ tokenId, image, name }) => (
                <Link to={getLinkCollection(PLANET_DETAIL, tokenId.toString(), name)} key={tokenId}>
                    <img src={image} alt={name} />
                    <span>{name}</span>
                </Link>
            ))}
        </TemplateBox>
    ) : (
        <></>
    );
};

export default Planets;
