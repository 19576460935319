import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { IDataCollection } from "@interfaces/Collections/DataCollection";
import styles from "./ItemCollection.module.scss";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";

interface IItemCollection {
    detail: IDataCollection;
    column: string;
}

const stylesCollectionsMap = {
    ["tickets__used"]: styles.item__ticket__used,
    ["patches__item"]: styles.item__patch,
};

const stylesContainerMap = {
    ["two"]: styles.collectionContainer__two,
    ["three"]: styles.collectionContainer__three,
    ["four"]: styles.collectionContainer__four,
    ["five"]: styles.collectionContainer__five,
};

const ItemCollection = ({ detail, column }: IItemCollection): ReactElement => {
    const isExternalLink = detail.type === CollectionCategory.patches || detail.type === CollectionCategory.tickets;
    const customClass = detail.customClass && stylesCollectionsMap[detail.customClass];

    return (
        <div key={detail.id} className={`${stylesContainerMap[column]}`}>
            <Link
                key={detail.id}
                target={isExternalLink ? "_blank" : ""}
                to={isExternalLink ? { pathname: detail.link } : detail.link ?? "#"}
                className={customClass}
            >
                <img src={detail.image} alt={detail.name} />
            </Link>
            <p>{detail.name}</p>
        </div>
    );
};

export default ItemCollection;
