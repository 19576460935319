import { FormEventHandler, ReactElement } from "react";
import styles from "./BoxFilter.module.scss";
import search from "@assets/images/utils/search.png";

interface IBoxFilter {
    onSubmit: FormEventHandler<HTMLFormElement>;
}

const BoxFilter = ({ onSubmit }: IBoxFilter): ReactElement => (
    <>
        <div className={styles.BoxFilter__container}>
            <form onSubmit={onSubmit}>
                <div className={styles.boxFilter__search}>
                    <img src={search} alt="search" />
                    <input type="text" aria-label="search" aria-describedby="search-input" placeholder="Search NFT" />
                    <button type="submit" />
                </div>
            </form>
        </div>
        <hr />
    </>
);

export default BoxFilter;
