import { Dispatch, FormEvent, SetStateAction, useContext, useState } from "react";
import { SessionContext } from "@context/SessionContext";
import postSubscription from "@services/Hangar/postSubscription";
import getMessageToSign from "@services/Session/getMessageToSign";

interface ReturnTypes {
    isLoading: boolean;
    isSubscribed: boolean;
    setEmail: Dispatch<SetStateAction<string>>;
    handleSubscribe: (e: FormEvent) => Promise<void>;
}

const useSubscribe = (): ReturnTypes => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);
    const { walletAddress, signMessage } = useContext(SessionContext);

    const handleSubscribe = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        const messageToSign = await getMessageToSign();
        const signature = await signMessage(messageToSign.data).catch(() => setIsLoading(false));
        if (signature) {
            postSubscription(email, walletAddress, signature)
                .then(() => setIsSubscribed(true))
                .finally(() => setIsLoading(false));
        }
    };

    return {
        isLoading,
        isSubscribed,
        setEmail,
        handleSubscribe,
    };
};

export default useSubscribe;
