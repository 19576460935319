import { ReactElement } from "react";
import Modal from "react-modal";
import styles from "./Modal3D.module.scss";
import ModelBox from "@components/ModelBox";
import { ModelBoxSize } from "@components/ModelBox/ModelBox";

export interface IModal3D {
    isOpen: boolean;
    modelLink: string;
    handleSetIsModalOpen: (boolean: boolean) => void;
}

const Modal3D = ({ isOpen, modelLink, handleSetIsModalOpen }: IModal3D): ReactElement => {
    const handleClose = () => {
        handleSetIsModalOpen(false);
    };

    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            preventScroll
            onRequestClose={() => {
                handleClose();
            }}
        >
            <ModelBox src={modelLink} size={ModelBoxSize.Expand} handleSetIsModalOpen={handleSetIsModalOpen} />
        </Modal>
    );
};

export default Modal3D;
