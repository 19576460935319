import { useParams } from "react-router-dom";
import GoBackMobile from "@components/Starmap/GoBackMobile";
import LeftColumn from "@components/Starmap/LeftColumn";
import Title from "@components/Starmap/Title";
import styles from "./Station.module.scss";
import { GET_APARTMENTS } from "@pages/Starmap/starmap.apartments.data";
import { apartmentType } from "@customTypes/Starmap/ApartmentType";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import ApartmentsList from "@components/Starmap/ApartmentsList";
import { createLinkName } from "@scripts/StringsHelper";
import STARMAP_ZONES from "@pages/Starmap/starmap.zones.data";

const Station = (): JSX.Element => {
    const { zoneId, zoneName } = useParams<{ zoneId: string; zoneName: string; systemId: string }>();
    const oneBedroom = GET_APARTMENTS(apartmentType.oneBedroom);
    const studio = GET_APARTMENTS(apartmentType.studio);

    return (
        <GalaxyBackground>
            <GoBackMobile />

            <Title title={`SPACESTATION: ${zoneName}`} />

            <div className={styles.body}>
                <LeftColumn />

                <div className={styles.apartmentsList}>
                    <div className={styles.room}>
                        <h2>
                            ONE BEDROOM APARTMENTS <hr />
                        </h2>
                        <div className={styles.apartments}>
                            {oneBedroom.map(({ image, name }) => (
                                <div key={name} className={styles.apartment}>
                                    <p>{name}</p>
                                    <a href={createLinkName(name)}>
                                        <img src={image} alt={name} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.room}>
                        <h2>
                            STUDIO APARTMENTS <hr />
                        </h2>
                        <div className={styles.apartments}>
                            {studio.map(({ image, name }) => (
                                <div key={name} className={styles.apartment}>
                                    <p>{name}</p>
                                    <a href={createLinkName(name)}>
                                        <img src={image} alt={name} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={styles.rightInfo}>
                    <h2>SPACESTATION</h2>
                    <img src={STARMAP_ZONES[parseInt(zoneId) - 1].spacestation} alt="space station preview" />
                    <p>{zoneName}</p>
                </div>
            </div>

            <ApartmentsList zoneId={parseInt(zoneId)} />
        </GalaxyBackground>
    );
};

export default Station;
