import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import Container from "@components/Container";
import styles from "./ComponentDetail.module.scss";
import { apiTypeToDisplayType } from "@scripts/ComponentHelper";
import ComponentImage from "@components/ComponentImage";
import { NOT_FOUND } from "@routes/routes";
import Loader from "@components/Loader";
import Logo from "@components/Logo";
import BackButton from "@components/BackButton";
import useGetRocketComponent from "@hooks/RocketComponent/useGetRocketComponent";
import BoxAttributes from "@components/BoxAttributes";
import { IMetadataAttribute } from "@interfaces/Metadata/MetadataAttribute";

const ComponentDetail = (): JSX.Element => {
    const id = useParams<{ id: string }>().id;
    const { data: component, loading } = useGetRocketComponent(id);

    if (loading || !component) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!component && !loading) {
        return <Redirect to={NOT_FOUND} />;
    }

    const attributes: IMetadataAttribute[] = [
        { trait_type: "Assembly Status", value: component.status === "burned" ? "Assembled" : "Unassembled" },
        { trait_type: "Brand", value: component.brand },
        { trait_type: "Sticker(s)", value: component.sticker ? "1" : "0" },
    ];

    return (
        <Container>
            <Logo />
            <BackButton>Back to Collections</BackButton>
            <div className={styles.container}>
                <div className={styles.component}>
                    <h1>COMPONENT</h1>
                    <div className={styles.componentContainer}>
                        <div className={styles.componentImageContainer}>
                            <ComponentImage image={component.image} brand={component.brand} type={component.type} />
                        </div>
                        <h2>
                            {component.brand} {apiTypeToDisplayType(component.type)}
                        </h2>
                        <span>S/N: {component.serial}</span>
                    </div>

                    {attributes.length > 0 && <BoxAttributes attributes={attributes} />}
                </div>
            </div>
        </Container>
    );
};

export default ComponentDetail;
