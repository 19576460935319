import Button from "@components/Button";
import { SessionContext } from "@context/SessionContext";
import useGetScratcherSignature from "@hooks/Scratcher/useGetScratcherSignature";
import { IScratcherSignature } from "@interfaces/Scratcher/ScratcherSignature";
import { FormEvent, ReactElement, useContext, useState } from "react";
import styles from "./SerialNumberInput.module.scss";
import SerialNumberLoader from "./components/SerialNumberLoader";

interface Props {
    onSuccess: (signature: IScratcherSignature) => void;
    onFailure: () => void;
    onReset: () => void;
}

const MIN_SERIAL_NUMBERS = 1;
const MAX_SERIAL_NUMBERS = 10;

const SerialNumberInput = ({ onSuccess, onFailure, onReset }: Props): ReactElement => {
    const { walletAddress, login } = useContext(SessionContext);
    const { getSignature } = useGetScratcherSignature();
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(MIN_SERIAL_NUMBERS);

    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!walletAddress) {
            return;
        }

        const formData = new FormData(event.target as HTMLFormElement);
        const serialNumbers = formData.getAll("serialNumber[]");

        setIsLoading(true);
        try {
            const signature = await getSignature(walletAddress, serialNumbers as string[]);
            onSuccess(signature);
        } catch (e) {
            onFailure();
        }

        setIsLoading(false);
    };

    const handleIncreaseQuantity = () => {
        setQuantity((prev) => prev + 1);
        onReset();
    };

    const handleDecreaseQuantity = () => {
        setQuantity((prev) => prev - 1);
        onReset();
    };

    return (
        <div className={styles.serialNumberInput}>
            <form onSubmit={handleFormSubmit} className={styles.serialNumberForm}>
                {Array.from({ length: quantity }, (_, index) => (
                    <input
                        key={index}
                        name="serialNumber[]"
                        type="text"
                        aria-label="send serial number"
                        aria-describedby="serial-number-input"
                        placeholder="2023.461.XXXXXXXXXXXX"
                        pattern="2023\.461\.\d{12}"
                        title="Serial Number must be in the format 2023.461.XXXXXXXXXXXX where X is a digit from 0 to 9."
                        required
                    />
                ))}

                <div className={styles.buttonBox}>
                    <button onClick={handleDecreaseQuantity} disabled={quantity <= MIN_SERIAL_NUMBERS} type="button">
                        -
                    </button>
                    <span>{quantity}</span>
                    <button onClick={handleIncreaseQuantity} disabled={quantity >= MAX_SERIAL_NUMBERS} type="button">
                        +
                    </button>
                </div>
                {walletAddress ? (
                    <Button className={`${styles.submitButton}`}>Submit</Button>
                ) : (
                    <Button onClick={login}>Connect Wallet</Button>
                )}
                {isLoading && <SerialNumberLoader />}
            </form>
        </div>
    );
};

export default SerialNumberInput;
