import { apiTypeToDisplayType } from "@scripts/ComponentHelper";
import styles from "./ComponentImage.module.scss";
import smallBackgroundNose from "@assets/images/components/nose-background.png";
import smallBackgroundBody from "@assets/images/components/body-background.png";
import smallBackgroundTail from "@assets/images/components/tail-background.png";
import bigBackgroundNose from "@assets/images/components/big-nose-background.png";
import bigBackgroundBody from "@assets/images/components/big-body-background.png";
import bigBackgroundTail from "@assets/images/components/big-tail-background.png";

interface ComponentImageProps {
    image: string;
    brand: string;
    type: string;
    small?: boolean;
}

const smallBackgrounds = {
    nose: smallBackgroundNose,
    body: smallBackgroundBody,
    tail: smallBackgroundTail,
};

const bigBackgrounds = {
    nose: bigBackgroundNose,
    body: bigBackgroundBody,
    tail: bigBackgroundTail,
};

const ComponentImage = ({ image, brand, type, small }: ComponentImageProps): JSX.Element => {
    let backgroundImage = `url("${bigBackgrounds[type]}")`;
    if (small) {
        backgroundImage = `url("${smallBackgrounds[type]}")`;
    }

    return (
        <img
            style={{
                backgroundImage,
            }}
            src={image}
            alt={`${brand} ${apiTypeToDisplayType(type)}`}
            className={styles.image}
        />
    );
};

export default ComponentImage;
