import Link, { LinkVariant } from "@components/Link";
import ModelBox from "@components/ModelBox";
import { ModelBoxSize } from "@components/ModelBox/ModelBox";
import { IMetadataDetail } from "@interfaces/Metadata/MetadataDetail";
import { ReactElement, useState } from "react";
import styles from "./BoxDetails.module.scss";
import Modal3D from "./components/Modal3D";

interface IBoxDetailsProps {
    item: IMetadataDetail;
}

const BoxDetails = ({ item }: IBoxDetailsProps): ReactElement => {
    const { category_slug, model_link, serialNumber, opensea_url, mona_image, mona_link, image, name } = item;
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleSetIsModalOpen = (boolean: boolean) => {
        setModalIsOpen(boolean);
    };

    return (
        <div className={styles.boxDetails}>
            <h1>{category_slug?.toUpperCase()}</h1>
            <div className={`${styles.mainSection} ${model_link ? styles.isStuffMain : ""}`}>
                <div className={styles.boxDetails__description}>
                    <img src={image} alt="collection" className={model_link ? styles.isStuffImage : ""} />
                    <h2>{name}</h2>
                    {serialNumber && <span>S/N: {serialNumber}</span>}
                    {opensea_url && (
                        <div className={styles.boxDetails__button}>
                            <Link to={opensea_url} variant={LinkVariant.Button}>
                                View on Opensea
                            </Link>
                        </div>
                    )}
                </div>

                {mona_link && mona_image && (
                    <div className={styles.boxDetails__mona}>
                        <img src={mona_image} alt="mona space" />
                        <div className={styles.buttonSection}>
                            <div>
                                <h3>Virtual Galaxy Space</h3>
                                <p>Access to spaces will open 24h following the public mint</p>
                            </div>
                            <div className={styles.buttonContainer}>
                                <Link to={mona_link} variant={LinkVariant.Button}>
                                    ENTER SPACE
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                {model_link && (
                    <>
                        <div className={styles.boxDetails__model}>
                            <ModelBox
                                src={model_link}
                                size={ModelBoxSize.Default}
                                handleSetIsModalOpen={handleSetIsModalOpen}
                                modalIsOpen={modalIsOpen}
                            />
                            <div className={styles.textSection}>
                                <div>
                                    <h3>3D Model</h3>
                                    <p>Click icon to explore 3D model</p>
                                </div>
                            </div>
                        </div>

                        <Modal3D
                            isOpen={modalIsOpen}
                            modelLink={model_link}
                            handleSetIsModalOpen={handleSetIsModalOpen}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default BoxDetails;
