import slugify from "slugify";
import { RocketApiResponse } from "@interfaces/Rocket/RocketResponse";
import { IRocket } from "@interfaces/Rocket/Rocket";

export const apiResponseToRocket = ({
    owner,
    tokenId,
    name,
    serial,
    image,
    imagePhysical,
    videoPhysical,
    status,
    assemblyDate,
    type,
    launched,
    recoveryStatus,
    launchCity,
    launchPaymentStatus,
    launchRequestDate,
    transactions,
    stickers,
    launchDate,
    brands: { nose: noseBrand, body: bodyBrand, tail: tailBrand },
}: RocketApiResponse): IRocket => {
    return {
        owner,
        id: tokenId.toString(),
        serial,
        name,
        slug: slugify(name, { strict: true, lower: true }),
        image,
        imagePhysical,
        videoPhysical,
        status,
        date: assemblyDate,
        launchCity,
        launchPaymentStatus,
        launchRequestDate,
        type,
        launched,
        launchDate,
        recoveryStatus,
        noseBrand,
        bodyBrand,
        tailBrand,
        stickers,
        transactions,
    };
};
