import { ReactNode } from "react";
import { Link as LinkReactRouter } from "react-router-dom";
import styles from "./Link.module.scss";

export enum LinkVariant {
    Default,
    Button,
}

interface LinkProps {
    children: ReactNode;
    className?: string;
    variant?: LinkVariant;
    disabled?: boolean;
    to: string;
    [x: string]: unknown;
}

const stylesMap = {
    [LinkVariant.Default]: styles.default,
    [LinkVariant.Button]: styles.button,
};

const Link = ({
    to,
    className,
    children,
    disabled = false,
    variant = LinkVariant.Default,
    ...attributes
}: LinkProps): JSX.Element => {
    if (to.startsWith("http")) {
        return (
            <a
                href={to}
                target="_blank"
                rel="noopener noreferrer"
                className={`${className} ${stylesMap[variant]} ${disabled ? styles.disabled : ""}`}
            >
                {children}
            </a>
        );
    }

    return (
        <LinkReactRouter
            to={{ pathname: to }}
            className={`${className} ${stylesMap[variant]} ${disabled ? styles.disabled : ""}`}
            {...attributes}
        >
            {children}
        </LinkReactRouter>
    );
};

export default Link;
