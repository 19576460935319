import { useState, createContext, ReactNode } from "react";

export interface IContactContextProps {
    show: boolean;
    showModal: () => void;
}

interface IChildrenProps {
    children: ReactNode;
}

export const ContactContext = createContext<IContactContextProps | null>(null);

const ContactContextProvider = ({ children }: IChildrenProps): JSX.Element => {
    const [show, setShow] = useState<boolean>(false);

    const showModal = () => {
        setShow(!show);
    };
    return (
        <ContactContext.Provider
            value={{
                show,
                showModal,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};

export default ContactContextProvider;
