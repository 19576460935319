export const HOME = "/";
export const FAQ = "/faq";
export const ABOUT = "/about";
export const CONTACT = "/contact";
export const PRIVACY = "/privacy-policy";
export const ASSEMBLE = "/assemble";
export const HOW_IT_WORKS = "/how-it-works";
export const TERMS = "/terms-and-conditions";
export const ROCKET_DETAIL_NO_SLUG = "/rocket/:id";
export const MY_HANGAR = "/hangar/:walletAddress";
export const ROCKET_COMPONENTS = "/rocket-components";
export const NOT_FOUND = "/404-not-found";
export const ZINE = "/zine";
export const COLLECTIONS = "/collections/:category";
export const COLLECTIONS_DETAIL = "/collections/:type/:id/:slug";

export const ROCKET_DETAIL = "/rocket/:id/:slug";
export const PLANET_DETAIL = "/planet/:id/:slug";
export const APARTMENT_DETAIL = "/apartment/:id/:slug";
export const ROCK_DETAIL = "/rock/:id/:slug";
export const COMPONENT_DETAIL = "/component/:id/:slug";
export const STUFF_DETAIL = "/stuff/:id/:slug";

export const TIMELINE = "/timeline/:category";
export const TIMELINE_DETAIL = "/timeline/:id/:slug";
export const PLANET_MINT_HOME = "/mint-home";
export const PLANET_MINT_FINAL_FRONTIER = "/final-frontier";
export const PLANET_MINT_ROCKS = "/mint-with-rocks";
export const PLANET_MINT_SUCCESS = "/mint-success/:txId";
export const PLANET_MINT_IDCARD = "/mint-with-identification-card";
export const PLANET_MINT_SOLD_OUT = "/public-mint-sold-out";
export const STARMAP = "/starmap";
export const STARMAP_GALAXY = `${STARMAP}/galaxy`;
export const STARMAP_ZONE = `${STARMAP_GALAXY}/:zoneId/:zoneName`;
export const STARMAP_SYSTEM = `${STARMAP_ZONE}/:systemId/:systemName`;

export const GAME = "/game";

export const PHYSICAL_SCRATCHER = "/scratcher-claim";
export const PHYSICAL_SCRATCHER_SUCCESS = `${PHYSICAL_SCRATCHER}/success/:txId`;

export const PUBLIC_MINT = "/public-mint";
export const SCRATCHER_MINT = "/scratcher-mint";
export const DIGITAL_SCRATCHER_SUCCESS = `${SCRATCHER_MINT}/success/:txId`;
export const STUFF_BETA_GAMMA_MINT = "/beta-gamma-claim";
