import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { NOT_FOUND } from "@routes/routes";
import { IPFS_ROCKET_UI, ROCKET_CONTRACT_ADDRESS } from "@constants/env";
import { SessionContext } from "@context/SessionContext";
import { areWalletsEqual } from "@scripts/StringsHelper";
import Container from "@components/Container";
import Logo from "@components/Logo";
import styles from "./RocketDetail.module.scss";
import RocketImage from "@components/RocketImage";
import Loader from "@components/Loader";
import mergeImages from "merge-images";
import twitterBannerBackground from "@assets/images/rockets_background/twitter_banner_background.png";
import Link, { LinkVariant } from "@components/Link";
import BackButton from "@components/BackButton";
import { IRocket } from "@interfaces/Rocket/Rocket";
import getRocket from "@services/Rocket/getRocket";
import useGetRocketsStats from "@hooks/Rocket/useGetRocketsStats";
import BoxAttributes from "@components/BoxAttributes";
import { IMetadataAttribute } from "@interfaces/Metadata/MetadataAttribute";

const STAT_TEXT = "assembled rockets have this";

const RocketDetail = (): JSX.Element => {
    const { walletAddress } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams<{ id: string }>();
    const [rocket, setRocket] = useState<null | IRocket>(null);
    const [imageBase64, setImageBase64] = useState("");
    const {
        setRocketStats,
        totalBodyBrand,
        totalLaunched,
        totalNoseBrand,
        totalPerType,
        totalRockets,
        totalTailBrand,
    } = useGetRocketsStats();

    useEffect(() => {
        setIsLoading(true);
        getRocket(id)
            .then(({ data: retrievedRocket }) => {
                setRocket(retrievedRocket);
                setRocketStats(retrievedRocket);
                generateDownloadTwitterBanner(retrievedRocket.image, retrievedRocket.launched);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const isOwner = (): boolean => {
        if (!rocket || !walletAddress) {
            return false;
        }

        return areWalletsEqual(rocket.owner, walletAddress);
    };

    const generateDownloadTwitterBanner = (image: string, launched: boolean) => {
        const x = Math.abs(844 / 2 - 600 / 2) * -1 + (launched ? 200 : 15);
        const y = Math.abs(1080 / 2 - 1807 / 2) + 80;
        const rotation = launched ? 75 : 90;

        const images = [{ src: image, x, y }];

        if (launched) {
            images.push({ src: `${IPFS_ROCKET_UI}flame.png`, x, y });
        }

        mergeImages(images, { width: 800, height: 1807, crossOrigin: "anonymous" }).then((b64) => {
            const offScreenCanvas = document.createElement("canvas");
            const offScreenCanvasCtx = offScreenCanvas.getContext("2d");
            if (!offScreenCanvasCtx) {
                return;
            }

            const img = new Image();
            img.src = b64;
            img.addEventListener("load", () => {
                offScreenCanvas.height = img.width;
                offScreenCanvas.width = img.height;

                offScreenCanvasCtx.rotate((rotation * Math.PI) / 180);
                offScreenCanvasCtx.translate(0, -offScreenCanvas.width);

                offScreenCanvasCtx.drawImage(img, 0, 0);
                const rotated = offScreenCanvas.toDataURL("image/png");

                mergeImages([{ src: twitterBannerBackground }, { src: rotated }], {
                    width: 1807,
                    height: 600,
                    crossOrigin: "anonymous",
                }).then((b64) => {
                    setImageBase64("data:application/octet-stream;base64," + b64.replace("data:image/png;base64,", ""));
                });
            });
        });
    };

    if (isLoading || !rocket) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!isLoading && !rocket) {
        return <Redirect to={NOT_FOUND} />;
    }

    const {
        image,
        name,
        serial,
        videoPhysical,
        imagePhysical,
        type,
        launched,
        noseBrand,
        bodyBrand,
        tailBrand,
        stickers,
    } = rocket;

    const attributes: IMetadataAttribute[] = [
        {
            trait_type: "Rocket Type",
            value: type === "franken" ? "Frankenrocket" : "Perfect Rocket",
            data: `${totalPerType} ${STAT_TEXT}`,
        },
        {
            trait_type: "Flight History",
            value: launched ? "Launched" : "Not Launched",
            data: `${totalLaunched} ${STAT_TEXT}`,
        },
        { trait_type: "Nose Cone Brand", value: noseBrand, data: `${totalNoseBrand} ${STAT_TEXT}` },
        { trait_type: "Body Brand", value: bodyBrand, data: `${totalBodyBrand} ${STAT_TEXT}` },
        { trait_type: "Tail Assembly Brand", value: tailBrand, data: `${totalTailBrand} ${STAT_TEXT}` },
        { trait_type: "Sticker(s)", value: stickers.length.toString() },
    ];

    return (
        <Container>
            <Logo />
            <BackButton>Back to Collections</BackButton>
            <div className={styles.container}>
                <div className={styles.rocketView}>
                    <h1>ROCKET</h1>
                    <div className={styles.rocketContainer}>
                        <RocketImage id={id} image={image} />
                        <h2>{name}</h2>
                        <span>S/N: {serial}</span>
                        <span>
                            {parseInt(id) + 1} of {totalRockets} assembled rockets
                        </span>

                        <div className={styles.rocketDetail__button}>
                            <Link
                                to={`https://opensea.io/assets/ethereum/${ROCKET_CONTRACT_ADDRESS}/${id}`}
                                variant={LinkVariant.Button}
                            >
                                View on Opensea
                            </Link>
                        </div>

                        {walletAddress && isOwner() && (
                            <div className={styles.downloadTwitterBanner}>
                                <a download={`banner-rocket-${id}.png`} href={imageBase64}>
                                    Download Twitter Banner
                                </a>
                            </div>
                        )}
                    </div>
                    <div className={styles.mediaRocket}>
                        <div>
                            {videoPhysical && (
                                <video controls>
                                    <source src={videoPhysical} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                            {imagePhysical && <img src={imagePhysical} alt={`${name} Rocket`} />}
                        </div>
                    </div>
                </div>
                {attributes.length > 0 && <BoxAttributes attributes={attributes} />}
            </div>
        </Container>
    );
};

export default RocketDetail;
