import { PLANETS_MINT_CONTRACT_ADDRESS } from "@constants/env";
import { useWeb3Context } from "@nftstudios/web3-provider";
import abi from "@contracts/PlanetsMintAbi";
import { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";

interface ReturnTypes {
    isInitialized: boolean;
    getMaxPlanetsMintsPerWallet: () => Promise<number>;
    getMaxRoomsMintsPerWallet: () => Promise<number>;
    getPublicMintedPlanetsPerWallet: (walletAddress: string) => Promise<number>;
    getPublicMintedRoomsPerWallet: (walletAddress: string) => Promise<number>;
    isBadgeUsed: (badgeId: number) => Promise<boolean>;
}

const usePlanetsMintRead = (): ReturnTypes => {
    const { web3 } = useWeb3Context();
    const [isInitialized, setIsInitialized] = useState(false);
    const [mintContract, setMintContract] = useState<Contract | undefined>(undefined);

    useEffect(() => {
        if (!web3) {
            return;
        }

        const mintContract = new web3.eth.Contract(abi, PLANETS_MINT_CONTRACT_ADDRESS);
        setMintContract(mintContract);
        setIsInitialized(true);
    }, [web3]);

    const getMaxPlanetsMintsPerWallet = async (): Promise<number> => {
        if (!mintContract) {
            return 0;
        }

        return parseInt(await mintContract.methods.maxPlanetsMintsPerWallet().call());
    };

    const getMaxRoomsMintsPerWallet = async (): Promise<number> => {
        if (!mintContract) {
            return 0;
        }

        return parseInt(await mintContract.methods.maxRoomsMintsPerWallet().call());
    };

    const getPublicMintedPlanetsPerWallet = async (walletAddress: string): Promise<number> => {
        if (!mintContract) {
            return 0;
        }

        return parseInt(await mintContract.methods.planetMintsPerWallet(walletAddress).call());
    };

    const getPublicMintedRoomsPerWallet = async (walletAddress: string): Promise<number> => {
        if (!mintContract) {
            return 0;
        }

        return parseInt(await mintContract.methods.roomMintsPerWallet(walletAddress).call());
    };

    const isBadgeUsed = async (badgeId: number): Promise<boolean> => {
        if (!mintContract) {
            return false;
        }

        return await mintContract.methods.usedBadges(badgeId).call();
    };

    return {
        isInitialized,
        getMaxPlanetsMintsPerWallet,
        getMaxRoomsMintsPerWallet,
        getPublicMintedPlanetsPerWallet,
        getPublicMintedRoomsPerWallet,
        isBadgeUsed,
    };
};

export default usePlanetsMintRead;
