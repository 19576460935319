import ButtonCentered from "@components/ButtonCentered";
import Link from "@components/Link";
import { FLIGHT_TICKETS_ADDRESS } from "@constants/env";
import { TICKETS_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";
import { ITicket } from "@interfaces/Ticket/Ticket";
import { getOpenSeaLink } from "@scripts/OpenSeaHelper";
import { ECONOMY_CLASS, getImageForTicket } from "@scripts/TicketHelper";
import TemplateBox from "../TemplateBox";

interface ITicketsParams {
    items: ITicket[];
}

const Tickets = ({ items }: ITicketsParams): JSX.Element => {
    const nonUsedTickets = items.filter((ticket) => ticket.id <= ECONOMY_CLASS);
    const usedTickets = items.filter((ticket) => ticket.id > ECONOMY_CLASS);

    return items.length > 0 ? (
        <>
            <TemplateBox title={EHangarTitleType.TICKETS}>
                {nonUsedTickets.map((ticket) =>
                    [...Array(ticket.amount)].map((_, i) => (
                        <Link
                            to={getOpenSeaLink(FLIGHT_TICKETS_ADDRESS, ticket.id.toString())}
                            key={`${ticket.id}-${i}`}
                        >
                            <img src={getImageForTicket(ticket)} alt={ticket.type} />
                        </Link>
                    ))
                )}

                {nonUsedTickets.length === 0 && (
                    <ButtonCentered to={TICKETS_COLLECTIONS_OPENSEA_URL} rel="noopener noreferrer" target="_blank">
                        BUY TICKETS
                    </ButtonCentered>
                )}
            </TemplateBox>

            {usedTickets.length > 0 && (
                <TemplateBox title={EHangarTitleType.USED_TICKETS}>
                    {usedTickets.map((ticket) =>
                        [...Array(ticket.amount)].map((_, i) => (
                            <Link
                                to={getOpenSeaLink(FLIGHT_TICKETS_ADDRESS, ticket.id.toString())}
                                key={`${ticket.id}-${i}`}
                            >
                                <img src={getImageForTicket(ticket)} alt={ticket.type} />
                            </Link>
                        ))
                    )}
                </TemplateBox>
            )}
        </>
    ) : (
        <></>
    );
};

export default Tickets;
