import { SCRATCHER_SIGNATURE } from "@constants/apiEndpoints";
import { IScratcherSignature } from "@interfaces/Scratcher/ScratcherSignature";
import { apiInstance } from "@services/Shared/instanceAxios";
import { AxiosPromise } from "axios";

const checkSerialNumber = (
    walletAddress: string,
    version: string,
    serialNumbers: string[],
    recaptchaToken: string
): AxiosPromise<IScratcherSignature> =>
    apiInstance({
        url: SCRATCHER_SIGNATURE,
        params: {
            walletAddress,
            version,
            serialNumbers,
            recaptchaToken,
        },
    });

export default checkSerialNumber;
