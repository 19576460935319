import styles from "./Rocket.module.scss";
import { generatePath, Link } from "react-router-dom";
import { ROCKET_DETAIL } from "@routes/routes";
import RocketImage from "@components/RocketImage";
import { format, fromUnixTime } from "date-fns";
import { EFormatsType } from "@customTypes/Rocket/FormatsType";
import slugify from "slugify";

interface IRocketProps {
    image: string;
    date: number;
    id: string;
    slug: string;
    name: string;
}

const Rocket = ({ image, name, date, id, slug }: IRocketProps): JSX.Element => (
    <Link
        to={generatePath(ROCKET_DETAIL, { id, slug: slugify(slug, { lower: true }) })}
        className={styles.rocket__container}
    >
        <RocketImage
            id={id}
            image={image.replace(
                "https://tomsachsrocketfactory.mypinata.cloud/ipfs/QmaTcnN4AFr7P8BBh2Gbg2LBxAC3AZxsZLRiPGidHz2ciT/",
                "https://storage.googleapis.com/whitepaper/tsrf/rockets/"
            )}
        />
        <div className={styles.rocket__description}>
            <h5>{name}</h5>
            <span>{format(fromUnixTime(date), EFormatsType.LONG_LOCALIZED_PP)}</span>
        </div>
    </Link>
);

export default Rocket;
