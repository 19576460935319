import { AbiItem } from "web3-utils";

const abi: AbiItem[] = [
    {
        inputs: [
            { internalType: "address", name: "_signerAddress", type: "address" },
            { internalType: "address", name: "_erc1155Address", type: "address" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
            { indexed: true, internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [],
        name: "erc1155Contract",
        outputs: [{ internalType: "contract IERC1155Mintable", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            { internalType: "bytes32[]", name: "_serialNumbers", type: "bytes32[]" },
            { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
            { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
            { internalType: "uint256", name: "_fromTimestamp", type: "uint256" },
            { internalType: "uint256", name: "_toTimestamp", type: "uint256" },
            { internalType: "bytes", name: "_signature", type: "bytes" },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
        inputs: [{ internalType: "address", name: "_signerAddress", type: "address" }],
        name: "setSignerAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "signerAddress",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        name: "usedScratches",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
    },
];

export default abi;
