import { ReactElement } from "react";
import styles from "./MintDetail.module.scss";
import Link, { LinkVariant } from "@components/Link";
import { IDeed } from "@interfaces/Deeds/Deed";

interface Props {
    tokens: IDeed[];
}

const MintDetail = ({ tokens }: Props): ReactElement => {
    return (
        <div className={styles.mintDetail}>
            {tokens.map(({ title, imageUrl, openSeaUrl }) => (
                <div key={title}>
                    <div className={styles.imageContainer}>
                        <img src={imageUrl} alt={title} />
                    </div>
                    <div className={styles.bottom}>
                        <div>
                            <p>{title}</p>
                        </div>
                        <div>
                            <Link variant={LinkVariant.Button} to={openSeaUrl}>
                                View on OpenSea
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MintDetail;
