import { ITimelineCard } from "@interfaces/Timeline/TimelineCard";
import { ReactElement } from "react";
import ButtonTimeline from "../ButtonTimeline";
import TimelineCard from "../TimelineCard";
import styles from "./MilestoneCard.module.scss";

const MilestoneCard = ({ item }: ITimelineCard): ReactElement => (
    <div key={item.id} className={styles.milestones__container}>
        <TimelineCard key={item.id} title={item.title} category={item.category} date={item.date}>
            <img src={item.image} alt="Rocket Launch" />
            {item.link && (
                <div className={styles.milestones__buttonsbar}>
                    <ButtonTimeline to={item.link} target="_blank">
                        LEARN MORE
                    </ButtonTimeline>
                </div>
            )}
        </TimelineCard>
    </div>
);

export default MilestoneCard;
