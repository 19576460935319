export enum EFormatsType {
    DAY_OF_WEEK = "EEEE",
    SECONDS_TIMESTAMP = "t",
    DAY_OF_MONTH = "d",
    LONG_LOCALIZED_P = "P",
    LONG_LOCALIZED_PP = "PP",
    LONG_LOCALIZED_PPP = "ppp",
    MONTH_YEAR = "MMMM yyyy",
    YEAR = "yyyy",
}
