import ButtonCentered from "@components/ButtonCentered";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import { MARS_ROCK_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import styles from "./PhaseIIHero.module.scss";

const PhaseIIHero = (): JSX.Element => {
    return (
        <div className={styles.phaseIIHero}>
            <div className={styles.textContainer}>
                <Title heading={HeadingType.h2}>PHASE II - THE MISSION</Title>
                <iframe
                    src="https://www.youtube.com/embed/YlFGIOJuGAw"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
                <p>
                    Our Community of Rocket and Patch holders departed on a mission to Mars. Where we mined Rocks in the
                    Metaverse and Returned them to Earth. Mars Rock NFTs fuel our future missions.
                </p>
                <div className={styles.buttonContainer}>
                    <ButtonCentered to={MARS_ROCK_COLLECTIONS_OPENSEA_URL}>GET A MARS ROCK</ButtonCentered>
                </div>
            </div>
        </div>
    );
};

export default PhaseIIHero;
