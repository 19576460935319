import { ChangeEvent, FormEvent, useState } from "react";
import styles from "./Contact.module.scss";
import close from "@assets/images/utils/close.png";
import axios from "axios";
import { API_BASE_URL } from "@constants/env";
import { CONTACT } from "@constants/apiEndpoints";
import Loader from "../Loader";

interface IContactProps {
    showModal: () => void;
}

const Contact = ({ showModal }: IContactProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");

    const handleSend = (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        axios
            .post(`${API_BASE_URL}${CONTACT}`, {
                fullname: fullName,
                email,
                subject,
                body,
            })
            .then(() => setIsSend(true))
            .finally(() => setIsLoading(false));
    };

    if (isLoading) {
        return (
            <div className={styles.modalContainer}>
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                {isSend ? (
                    <>
                        <h1>YOUR EMAIL HAS BEEN SENT</h1>
                        <p>You'll be hearing from us soon.</p>
                        <button onClick={showModal} className={styles.primary}>
                            CLOSE
                        </button>
                    </>
                ) : (
                    <>
                        <div className={styles.header}>
                            <h1>CONTACT US</h1>
                            <img onClick={showModal} src={close} alt="close" />
                        </div>

                        <form className={styles.form} onSubmit={handleSend}>
                            <div className={styles.userInfo}>
                                <div className={styles.formItem}>
                                    <span>Full Name</span>
                                    <input
                                        id="name"
                                        type="text"
                                        aria-label="name"
                                        aria-describedby="name-input"
                                        required
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setFullName(event.target.value);
                                        }}
                                    />
                                </div>
                                <div className={styles.formItem}>
                                    <span>Email</span>
                                    <input
                                        id="email"
                                        type="email"
                                        aria-label="email"
                                        aria-describedby="email-input"
                                        required
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setEmail(event.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={styles.formItem}>
                                <span>Subject</span>
                                <input
                                    id="subject"
                                    type="text"
                                    aria-label="subject"
                                    aria-describedby="subject-input"
                                    required
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setSubject(event.target.value);
                                    }}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <span>Text</span>
                                <textarea
                                    id="Text"
                                    required
                                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                        setBody(event.target.value);
                                    }}
                                />
                            </div>
                            <button type="submit" className={styles.primary}>
                                SEND
                            </button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};
export default Contact;
