import ConfirmAndMint from "@components/ConfirmAndMint";
import { ReactElement } from "react";
import styles from "./ValidSerialNumber.module.scss";

export interface IValidSerialNumber {
    onMint: () => void;
}

const ValidSerialNumber = ({ onMint }: IValidSerialNumber): ReactElement => (
    <div className={styles.validSerialNumber}>
        <p className={styles.title}>VALID S/N</p>
        <p className={styles.text}>Congratulations on winning a TSRF "Stuff" NFT.</p>
        <ConfirmAndMint onSubmit={onMint}>
            Please check the box to confirm you have read and agree to our terms and conditions.
        </ConfirmAndMint>
    </div>
);

export default ValidSerialNumber;
