import ConfirmAndMint from "@components/ConfirmAndMint";
import Container from "@components/Container";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import { SessionContext } from "@context/SessionContext";
import { EMintType } from "@customTypes/Mint/MintType";
import useCalculateMintPrice from "@hooks/contracts/PlanetMinter/useCalculateMintPrice";
import useDigitalScratchersMint from "@hooks/contracts/PlanetMinter/useDigitalScratchersMint";
import usePlanetsPublicMint from "@hooks/contracts/PlanetMinter/usePlanetsPublicMint";
import MintingInProgress from "@pages/MintingInProgress";
import { HOME, PLANET_MINT_FINAL_FRONTIER } from "@routes/routes";
import { IS_PUBLIC_MINT_OPEN, IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, generatePath } from "react-router-dom";
import { PublicMintContentMap } from "./PublicMint.data";
import styles from "./PublicMint.module.scss";
import MintSelection from "./components/MintSelection";

interface IPublicMintProps {
    mintContent?: EMintType;
}

const PublicMint = ({ mintContent = EMintType.DEFAULT }: IPublicMintProps): ReactElement => {
    const { walletAddress, isInitialized } = useContext(SessionContext);
    const {
        mint: mintPlanet,
        txId: txIdPlanet,
        isMintingInProgress: isMintingInProgressPlanet,
        isMintingSuccessful: isMintingSuccessfulPlanet,
    } = usePlanetsPublicMint();
    const { mint, txId, isMintingInProgress, isMintingSuccessful } = useDigitalScratchersMint();

    const {
        calculate,
        calcualteMintPriceForScratcher,
        isInitialized: isCalculateInitialized,
    } = useCalculateMintPrice();
    const [amountToMint, setAmountToMint] = useState(1);
    const [subtotal, setSubtotal] = useState("0");

    /** Check access only if the stuff stage when the slug is stuff */
    if (!IS_STUFF_MINT_OPEN && mintContent === EMintType.SCRATCHER) {
        return <Redirect to={HOME} />;
    }
    /** Check access only if the stuff stage when the slug is stuff */

    if (!IS_PUBLIC_MINT_OPEN && mintContent === EMintType.DEFAULT) {
        return <Redirect to={HOME} />;
    }

    useEffect(() => {
        if (!isCalculateInitialized) {
            return;
        }

        if (mintContent === EMintType.DEFAULT) {
            calculate(amountToMint).then(setSubtotal);
        } else {
            calcualteMintPriceForScratcher(amountToMint).then(setSubtotal);
        }
    }, [amountToMint, isCalculateInitialized]);

    const handleAmountSelected = (amount: number) => setAmountToMint(amount === amountToMint ? 0 : amount);

    const handleMint = () => {
        if (mintContent === EMintType.DEFAULT) {
            mintPlanet(amountToMint);
        } else {
            mint(amountToMint);
        }
    };

    if (isInitialized && !walletAddress) {
        return <Redirect to={PLANET_MINT_FINAL_FRONTIER} />;
    }

    if (isMintingInProgress) {
        return <MintingInProgress txId={txId} mintingType={PublicMintContentMap[mintContent].mintingType} />;
    }

    if (isMintingInProgressPlanet) {
        return <MintingInProgress txId={txIdPlanet} mintingType={PublicMintContentMap[mintContent].mintingType} />;
    }

    if (isMintingSuccessful) {
        return <Redirect to={generatePath(PublicMintContentMap[mintContent].success, { txId })} push />;
    }

    if (isMintingSuccessfulPlanet) {
        return <Redirect to={generatePath(PublicMintContentMap[mintContent].success, { txId: txIdPlanet })} push />;
    }

    return (
        <GalaxyBackground noPadding>
            <Container className={styles.publicMint}>
                <h1>{PublicMintContentMap[mintContent].title}</h1>
                <p>{PublicMintContentMap[mintContent].instructionsText}</p>
                <MintSelection
                    amount={amountToMint}
                    onAmountSelected={handleAmountSelected}
                    mintContent={mintContent}
                />
                <ConfirmAndMint subtotal={subtotal} onSubmit={handleMint}>
                    {PublicMintContentMap[mintContent].confirmText}
                </ConfirmAndMint>
            </Container>
        </GalaxyBackground>
    );
};

export default PublicMint;
