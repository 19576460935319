import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import { ICollectionCategory } from "@interfaces/Collections/CollectionCategory";
import { DATA_CATEGORIES } from "@pages/Collections/dataCollections/Category.data";

export const getContractAddressBySlug = (slug: string): string =>
    DATA_CATEGORIES.find((p) => p.slug === slug)?.contractAddress ?? "";

export const getContractSlugByCategory = (category?: string): string =>
    DATA_CATEGORIES.find((p) => p.name === category)?.contractSlug ?? "";

export const getContractSlugByContractAddress = (contractAddress: string): string =>
    DATA_CATEGORIES.find((p) => p.contractAddress.toLowerCase() === contractAddress.toLowerCase())?.contractSlug ?? "";

export const getContractSlugBySlug = (slug: string): string =>
    DATA_CATEGORIES.find((p) => p.slug === slug)?.contractSlug ?? "";

export const getCategoryByContractSlug = (contractSlug: string): ICollectionCategory | undefined =>
    DATA_CATEGORIES.find((p) => p.contractSlug === contractSlug);

export const getCategoryByName = (name: CollectionCategory): ICollectionCategory | undefined =>
    DATA_CATEGORIES.find((p) => p.name === name);

export const getColumnByCategory = (category: string): string => {
    switch (category) {
        case CollectionCategory.planets:
            return "four";
        case CollectionCategory.apartments:
        case CollectionCategory.marsRocks:
        case CollectionCategory.patches:
        case CollectionCategory.stuff:
            return "three";
        default:
            return "two";
    }
};

export const getSerialBySlug = (slug: string, tokenId: string): string | undefined => {
    const format = DATA_CATEGORIES.find((p) => p.slug === slug)?.serialFormat ?? "";

    if (format) {
        return format + tokenId.padStart(3, "0");
    }
    return;
};
