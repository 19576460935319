import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import styles from "./AccordionSection.module.scss";

interface IAccordionSectionProps {
    title: string;
    paragraph: string;
    uuid: string;
}

const AccordionSection = ({ title, paragraph, uuid }: IAccordionSectionProps): JSX.Element => (
    <AccordionItem className={styles.accordionItem} uuid={uuid}>
        <AccordionItemHeading className={styles.accordionItemHeading}>
            <AccordionItemButton className={styles.accordionButton}>{title}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.accordionItemPanel}>
            <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        </AccordionItemPanel>
    </AccordionItem>
);

export default AccordionSection;
