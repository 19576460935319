import { ReactNode } from "react";
import styles from "./Title.module.scss";

export enum HeadingType {
    h1 = "h1",
    h2 = "h2",
    h3 = "h3",
    h4 = "h4",
}

interface IHeading {
    heading: HeadingType;
    headingColor?: "default" | "gray";
    children: ReactNode;
}

const stylesHeadingsMap = {
    ["default"]: "",
    ["gray"]: styles.heading__gray,
};

const Title = ({ heading: Heading, children, headingColor = "default" }: IHeading): JSX.Element => {
    return (
        <div className={styles.title}>
            <Heading className={`${stylesHeadingsMap[headingColor]}`}>{children}</Heading>
        </div>
    );
};

export default Title;
