export enum EHangarTitleType {
    PATCHES = "PATCHES",
    PLANETS = "PLANETS",
    COMPONENTS = "COMPONENTS",
    ROCKETS = "ROCKETS",
    ROCKS = "ROCKS",
    APARTMENTS = "APARTMENTS",
    STUFF = "STUFF",
    TICKETS = "TICKETS",
    USED_TICKETS = "USED TICKETS",
}
