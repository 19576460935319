import { STUFF_MINTER_CONTRACT_ADDRESS } from "@constants/env";
import { CONFIRMATION, ERROR, TRANSACTION_HASH } from "@constants/web3Events";
import abi from "@contracts/ScratchersMintAbi";
import { IScratcherSignature } from "@interfaces/Scratcher/ScratcherSignature";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { useState } from "react";

interface ReturnTypes {
    txId: string;
    isMintingInProgress: boolean;
    isMintingSuccessful: boolean;
    mint: (signature: IScratcherSignature) => Promise<void>;
}

const useStuffScratcherMint = (): ReturnTypes => {
    const { web3, walletAddress } = useWeb3Context();
    const [txId, setTxId] = useState("");
    const [isMintingInProgress, setIsMintingInProgress] = useState(false);
    const [isMintingSuccessful, setIsMintingSuccessful] = useState(false);

    const mint = async ({
        serialNumbers,
        tokenIds,
        amounts,
        mintFrom,
        mintTo,
        signature,
    }: IScratcherSignature): Promise<void> => {
        if (!web3) {
            return;
        }

        const mintContract = new web3.eth.Contract(abi, STUFF_MINTER_CONTRACT_ADDRESS);

        await mintContract.methods
            .mint(serialNumbers, tokenIds, amounts, mintFrom, mintTo, signature)
            .send({ from: walletAddress })
            .once(CONFIRMATION, () => {
                setIsMintingInProgress(false);
                setIsMintingSuccessful(true);
            })
            .once(TRANSACTION_HASH, (txId: string) => {
                setTxId(txId);
                setIsMintingSuccessful(false);
                setIsMintingInProgress(true);
            })
            .once(ERROR, (err: Error) => {
                console.log(err);
                setIsMintingInProgress(false);
                setIsMintingSuccessful(false);
            });
    };

    return { txId, isMintingInProgress, isMintingSuccessful, mint };
};

export default useStuffScratcherMint;
