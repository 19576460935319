import { DIGITAL_MINTER_CONTRACT_SLUG } from "@constants/env";
import { apiInstance } from "@hooks/shared/web3ApiInstance";
import { ISignatureContract } from "@interfaces/Mint/SignatureContract";
import { AxiosPromise } from "axios";

interface IGetContractSignatureProps {
    walletAddress: string;
}

const getContractSignature = ({ walletAddress }: IGetContractSignatureProps): AxiosPromise<ISignatureContract> =>
    apiInstance({
        url: `web3/contract/${DIGITAL_MINTER_CONTRACT_SLUG}/signatures/${walletAddress}`,
    });

export default getContractSignature;
