import { generatePath, Link } from "react-router-dom";
import { COLLECTIONS } from "@routes/routes";
import UnorganizedList from "../AssetList/components/UnorganizedList";
import AssetList from "../AssetList";
import IPlanet from "@interfaces/Starmap/Planet";
import useGetPlanetsMetadata from "@hooks/Starmap/useGetPlanetsMetadata";
import formatStarmapPlanetItemList from "@formatting/formatStarmapPlanetItemList";

interface IPlanetItemList {
    planets: IPlanet[] | undefined;
}

const PlanetItemList = ({ planets }: IPlanetItemList): JSX.Element => {
    const STARS_IDS = planets?.map(({ id }) => `${id}`);
    const data = formatStarmapPlanetItemList(useGetPlanetsMetadata(STARS_IDS));

    return (
        <AssetList
            title="SYSTEM NAME X PLANETS"
            link={generatePath(COLLECTIONS, {
                category: "Planets",
            })}
        >
            {data && <UnorganizedList listItem={data} />}
        </AssetList>
    );
};

export default PlanetItemList;
