import {
    MINT_PUBLIC_DATE,
    MINT_WITH_ROCKS_END_DATE,
    MINT_WITH_ROCKS_START_DATE,
    STUFF_MINT_END_DATE,
    STUFF_MINT_START_DATE,
    STUFF_RAFFLE_END_DATE,
    STUFF_RAFFLE_START_DATE,
} from "@constants/env";

const dateNow = (Date.now() / 1000) | 0;

export const IS_MINT_WITH_ROCKS_OPEN = dateNow > MINT_WITH_ROCKS_START_DATE && dateNow < MINT_WITH_ROCKS_END_DATE;
export const IS_PUBLIC_MINT_OPEN = dateNow > MINT_PUBLIC_DATE;
export const IS_STUFF_MINT_OPEN = dateNow > STUFF_MINT_START_DATE && dateNow < STUFF_MINT_END_DATE;
export const IS_PLANETS_PHASE_OPEN = IS_MINT_WITH_ROCKS_OPEN || IS_PUBLIC_MINT_OPEN;
export const IS_STUFF_RAFFLE_OPEN = dateNow > STUFF_RAFFLE_START_DATE && dateNow < STUFF_RAFFLE_END_DATE;

console.log(IS_STUFF_RAFFLE_OPEN);
