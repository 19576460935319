import { DETAIL_OPENSEA_URL } from "@constants/externalUrls";
import { generatePath } from "react-router-dom";
import slugify from "slugify";

export const getLinkCollection = (path: string, id: string, name: string, address?: string): string => {
    const [newPath, parameters] = path
        ? [
              path,
              {
                  id,
                  slug: slugify(name, { strict: true, lower: true }),
              },
          ]
        : [
              DETAIL_OPENSEA_URL,
              {
                  address,
                  id,
              },
          ];

    return generatePath(newPath, parameters);
};
