import { ILaunchDate } from "@interfaces/Launch/LaunchDate";
import { ReactElement } from "react";
import styles from "./BoxDescription.module.scss";

interface props {
    launchDetail: ILaunchDate;
}

const BoxDescription = ({ launchDetail }: props): ReactElement => (
    <div className={styles.boxDescription}>
        <p>
            <span>Launch Location:</span> {launchDetail.location}
        </p>
        <p>
            <span>Launched Rockets:</span> {launchDetail.totalRockets}
        </p>
    </div>
);

export default BoxDescription;
