import { PLANETS_MINT_CONTRACT_ADDRESS } from "@constants/env";
import { CONFIRMATION, ERROR, TRANSACTION_HASH } from "@constants/web3Events";
import { useWeb3Context } from "@nftstudios/web3-provider";
import abi from "@contracts/PlanetsMintAbi";
import { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";
import useCalculateMintPrice from "./useCalculateMintPrice";

export interface MintParameters {
    tokenId: number;
    planetIds: number[];
    spaceStationIds: number[];
    signature: string;
}

interface ReturnTypes {
    txId: string;
    isMintingInProgress: boolean;
    isMintingSuccessful: boolean;
    mint: (mintParameters: MintParameters) => Promise<void>;
}

const usePlanetsSignatureMint = (): ReturnTypes => {
    const { web3, walletAddress } = useWeb3Context();
    const { calculate } = useCalculateMintPrice();
    const [txId, setTxId] = useState("");
    const [isMintingInProgress, setIsMintingInProgress] = useState(false);
    const [isMintingSuccessful, setIsMintingSuccessful] = useState(false);
    const [mintContract, setMintContract] = useState<Contract | undefined>(undefined);

    useEffect(() => {
        if (!web3) {
            return;
        }

        const mintContract = new web3.eth.Contract(abi, PLANETS_MINT_CONTRACT_ADDRESS);
        setMintContract(mintContract);
    }, [web3]);

    const mint = async ({ tokenId, planetIds, spaceStationIds, signature }: MintParameters): Promise<void> => {
        if (!mintContract) {
            return;
        }

        await mintContract.methods
            .signatureMint(tokenId, planetIds, spaceStationIds, signature)
            .send({ from: walletAddress, value: await calculate(planetIds.length + spaceStationIds.length) })
            .once(CONFIRMATION, () => {
                setIsMintingInProgress(false);
                setIsMintingSuccessful(true);
            })
            .once(TRANSACTION_HASH, (txId: string) => {
                setTxId(txId);
                setIsMintingSuccessful(false);
                setIsMintingInProgress(true);
            })
            .once(ERROR, (err: Error) => {
                console.log(err);
                setIsMintingInProgress(false);
                setIsMintingSuccessful(false);
            });
    };

    return { txId, isMintingInProgress, isMintingSuccessful, mint };
};

export default usePlanetsSignatureMint;
