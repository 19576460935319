import scratcherStack from "@assets/images/stuff/scratcher_stack.png";
import ConfirmAndMint from "@components/ConfirmAndMint";
import Container from "@components/Container";
import PlanetMintNotAuthorized from "@components/PlanetMintNotAuthorized";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import { SessionContext } from "@context/SessionContext";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import getContractSignature from "@hooks/Scratcher/getContractSignature";
import useStuffDigitalMintRead from "@hooks/contracts/Stuff/useStuffDigitalMintRead";
import useStuffSignatureDigitalMint from "@hooks/contracts/Stuff/useStuffSignatureDigitalMint";
import MintingInProgress from "@pages/MintingInProgress";
import { DIGITAL_SCRATCHER_SUCCESS, HOME } from "@routes/routes";
import { IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, generatePath } from "react-router-dom";
import styles from "./StuffBetaGamaMint.module.scss";

const StuffBetaGamaMint = (): ReactElement => {
    const { walletAddress } = useContext(SessionContext);
    const { mint, isMintingInProgress, isMintingSuccessful, txId } = useStuffSignatureDigitalMint();
    const { getMintedPerWallet } = useStuffDigitalMintRead();
    const [hasMinted, setHasMinted] = useState(false);

    useEffect(() => {
        if (!walletAddress) {
            return;
        }

        getMintedPerWallet(walletAddress).then((mints) => setHasMinted(mints > 0));
    }, [walletAddress]);

    const handleMint = async () => {
        const { data } = await getContractSignature({ walletAddress });
        await mint(data);
    };

    if (!IS_STUFF_MINT_OPEN) {
        return <Redirect to={HOME} />;
    }

    if (isMintingSuccessful && txId) {
        return <Redirect to={generatePath(DIGITAL_SCRATCHER_SUCCESS, { txId })} />;
    }

    if (isMintingInProgress) {
        return <MintingInProgress txId={txId} mintingType={CollectionCategory.stuff} />;
    }

    if (hasMinted) {
        return (
            <PlanetMintNotAuthorized>
                <p>
                    This wallet has already been used to claim your free "Stuff" Mints. Please proceed to our Mint page
                    if you wish to mint additional "Stuff" items.
                </p>
            </PlanetMintNotAuthorized>
        );
    }

    return (
        <GalaxyBackground noPadding>
            <Container className={styles.stuffBetaGamaMint}>
                <h1>CONGRATULATIONS</h1>
                <p>
                    As a Consecrated Beta or Gamma Mars Rock Holder, you can claim three Scratchers free of charge for
                    each of these Mars Rocks you hold.
                </p>
                <div className={styles.scratcherStack}>
                    <img src={scratcherStack} alt="" />
                </div>
                <ConfirmAndMint subtotal={"0"} onSubmit={handleMint}>
                    Please check the box to confirm you have read and agree to our terms and conditions.
                </ConfirmAndMint>
            </Container>
        </GalaxyBackground>
    );
};

export default StuffBetaGamaMint;
