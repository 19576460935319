import { ROCKETS } from "@constants/apiEndpoints";
import { IRocket } from "@interfaces/Rocket/Rocket";
import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";
import { apiResponseToRocket } from "@scripts/RocketHelper";

const getRocket = (id: string): AxiosPromise<IRocket> =>
    apiInstance({
        url: `${ROCKETS}${id}`,
        transformResponse: [
            (data) => {
                return apiResponseToRocket(JSON.parse(data));
            },
        ],
    });

export default getRocket;
