import { Suspense, lazy, useState } from "react";
import { useParams } from "react-router-dom";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import GoBackMobile from "@components/Starmap/GoBackMobile";
import RightColumn from "@components/Starmap/RightColumn";
import LeftColumn from "@components/Starmap/LeftColumn";
import Combobox from "@components/Starmap/Combobox";
import Tooltip from "@components/Starmap/Tooltip";
import Title from "@components/Starmap/Title";
import IPlanet from "@interfaces/Starmap/Planet";
import styles from "./Rogue.module.scss";
import { LEGEND_LIST_DETAILED } from "../../../starmap.legends.data";
import STARS from "../../../starmap.stars.data";
import RogueItemList from "@components/Starmap/RogueItemList";
import { createLinkName } from "@scripts/StringsHelper";
import usePlanetSelect from "@hooks/Starmap/usePlanetSelect";
const LegendMobile = lazy(() => import("@components/Starmap/LegendMobile"));

interface IRogueProps {
    stars: IPlanet[] | undefined;
}

const Rogue = ({ stars }: IRogueProps): JSX.Element => {
    const { handleChangeLinkColor, handleLeaveLinkColor, handleShowTooltip, handleHideTooltip } = usePlanetSelect({
        stars,
    });
    const [planetSelected, setPlanetSelected] = useState<null | number>(null);
    const WORLD_TYPES = stars?.map(({ world_type }) => world_type);
    const CATEGORIES = WORLD_TYPES?.filter((item, index) => WORLD_TYPES.indexOf(item) === index);
    const FORMATTED_STARS = CATEGORIES?.map((category) => ({
        category,
        stars: stars?.filter(({ world_type }) => world_type === category),
    }));

    const scrollToPlanetMetadata = () => {
        const element = document.getElementById(`${planetSelected}`);
        element && element.scrollIntoView({ block: "center", inline: "center" });
    };

    return (
        <GalaxyBackground>
            <GoBackMobile />
            <Title title="SYSTEM: ROGUE" description="Click to discover Planetary Certificate Token" />
            <Combobox title="Select planet" handleComboboxOption={(option) => setPlanetSelected(option)}>
                {stars?.map(({ id, name }) => (
                    <option key={id} value={name}>
                        Planet {name}
                    </option>
                ))}
            </Combobox>

            <div className={styles.body}>
                <LeftColumn />

                <div className={styles.body__stars}>
                    <h2>
                        3RD ORDER - ROGUE PLANETS <hr />
                    </h2>

                    {FORMATTED_STARS?.map(({ category, stars }) => (
                        <div key={category} className={styles.body__stars__category}>
                            <p>{category}</p>
                            <div className={styles.grid}>
                                {stars?.map(({ name, id, world_type }) => (
                                    <>
                                        <Tooltip id={`star-tooltip-${id}`} />
                                        <a
                                            className={styles.body__stars__item}
                                            href={createLinkName(name)}
                                            id={`star-item-${id}`}
                                            data-tooltip-id={`star-tooltip-${id}`}
                                            data-tooltip-html={`<div><h3>${name}</h3></div>`}
                                            onMouseOver={() => handleChangeLinkColor(id)}
                                            onMouseLeave={() => handleLeaveLinkColor()}
                                        >
                                            <img src={STARS[world_type]} />
                                            <p>{name}</p>
                                        </a>
                                    </>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <RightColumn title="SOLAR SYSTEMS">
                    <ul>
                        {FORMATTED_STARS?.map(({ category, stars }) => (
                            <li key={category}>
                                <h3>{category}</h3>
                                <ul id="planets-list">
                                    {stars?.map(({ name, id }) => (
                                        <li key={id}>
                                            <a
                                                id={`planet-item-${id}`}
                                                href={createLinkName(name)}
                                                onMouseOver={() => handleShowTooltip(id)}
                                                onMouseLeave={() => handleHideTooltip()}
                                            >
                                                {name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </RightColumn>
            </div>

            {planetSelected && (
                <button onClick={scrollToPlanetMetadata} className={styles.scrollto__planet}>
                    EXPLORE PLANET
                </button>
            )}

            <Suspense fallback={<p>loading...</p>}>
                <LegendMobile legend={LEGEND_LIST_DETAILED} />
            </Suspense>

            <RogueItemList planets={stars} />
        </GalaxyBackground>
    );
};

export default Rogue;
