import { API_BASE_URL } from "@constants/env";
import { useWeb3Context } from "@nftstudios/web3-provider";
import axios from "axios";
import { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "./Game.module.scss";

interface Rocket {
    name: string;
    nose: string;
    body: string;
    tail: string;
    noseSticker: string;
    bodySticker: string;
    tailSticker: string;
}

const Game = (): React.ReactElement => {
    const { isInitialized, walletAddress } = useWeb3Context();

    const buildUrl = "https://storage.googleapis.com/voxelarchitects/3dstarmap";
    const { unityProvider, isLoaded, loadingProgression, sendMessage } = useUnityContext({
        loaderUrl: `${buildUrl}/Build.loader.js?${Date.now()}`,
        dataUrl: `${buildUrl}/Build.data?${Date.now()}`,
        frameworkUrl: `${buildUrl}/Build.framework.js?${Date.now()}`,
        codeUrl: `${buildUrl}/Build.wasm?${Date.now()}`,
    });

    const [percentage, setPercentage] = useState(0);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (!walletAddress || !isInitialized) {
            return;
        }

        axios
            .get(`/hangar/${walletAddress}`, {
                baseURL: API_BASE_URL,
            })
            .then(({ data }) => {
                const rockets: Rocket[] = [];
                for (const rocket of data.rockets) {
                    rockets.push({
                        name: rocket.name,
                        nose: rocket.brands.nose,
                        body: rocket.brands.body,
                        tail: rocket.brands.tail,
                        noseSticker: rocket.stickersMap?.nose ?? "",
                        bodySticker: rocket.stickersMap?.body ?? "",
                        tailSticker: rocket.stickersMap?.tail ?? "",
                    });
                }

                setUserData({
                    address: walletAddress,
                    rockets,
                });
            });
    }, [isInitialized, walletAddress]);

    useEffect(() => {
        setPercentage(Math.round(loadingProgression * 100));
    }, [loadingProgression]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        sendMessage("_NahuReceiver", "OnReceiveData", JSON.stringify(userData));
    }, [isLoaded]);

    return (
        <div className={styles.game}>
            {!isLoaded && (
                <div className={styles.loading}>
                    <div className={styles.logo} />

                    <div className={styles.progressBar}>
                        <div className={styles.progressBarInner} style={{ width: `${percentage}%` }}></div>
                    </div>
                </div>
            )}
            <Unity
                unityProvider={unityProvider}
                className={`${styles.unityProvider} ${percentage === 100 && styles.visible}`}
            />
        </div>
    );
};

export default Game;
