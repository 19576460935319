import { COMPONENT_DETAIL } from "@routes/routes";
import ComponentImage from "@components/ComponentImage";
import { IComponent } from "@interfaces/RocketComponent/Component";
import ButtonCentered from "@components/ButtonCentered";
import { COMPONENTS_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import Link from "@components/Link";
import TemplateBox from "../TemplateBox";
import { getLinkCollection } from "@scripts/CollectionHelper";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";

interface IComponentsParams {
    items: IComponent[];
}

const RocketComponents = ({ items }: IComponentsParams): JSX.Element => {
    return (
        <TemplateBox title={EHangarTitleType.COMPONENTS}>
            {items.map(({ tokenId, image, brand, type, slug }) => (
                <Link to={getLinkCollection(COMPONENT_DETAIL, tokenId.toString(), slug)}>
                    <ComponentImage image={image} brand={brand} type={type} small />
                </Link>
            ))}

            {items.length === 0 && (
                <ButtonCentered to={COMPONENTS_COLLECTIONS_OPENSEA_URL} rel="noopener noreferrer" target="_blank">
                    BUY COMPONENTS
                </ButtonCentered>
            )}
        </TemplateBox>
    );
};

export default RocketComponents;
