import { generatePath, Link } from "react-router-dom";
import { COLLECTIONS } from "@routes/routes";
import AssetList from "../AssetList";
import IPlanet from "@interfaces/Starmap/Planet";
import useGetPlanetsMetadata from "@hooks/Starmap/useGetPlanetsMetadata";
import CategorizedList from "../AssetList/components/CategorizedList";

interface IRogueItemList {
    planets: IPlanet[] | undefined;
}

const RogueItemList = ({ planets }: IRogueItemList): JSX.Element => {
    const STARS_IDS = planets?.map(({ id }) => `${id}`);
    const WORLD_TYPES = planets?.map(({ world_type }) => world_type);
    const categories = WORLD_TYPES?.filter((item, index) => WORLD_TYPES.indexOf(item) === index);
    const metadata = useGetPlanetsMetadata(STARS_IDS);

    const metadataByCategories = categories?.map((category) =>
        metadata
            ?.filter(({ metadata }) => metadata.attributes[0].value === category)
            .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name))
    );

    return (
        <AssetList
            title="SYSTEM NAME X WORLDS"
            link={generatePath(COLLECTIONS, {
                category: "Planets",
            })}
        >
            {metadataByCategories && <CategorizedList metadataCategorized={metadataByCategories} />}
        </AssetList>
    );
};

export default RogueItemList;
