import Link, { LinkVariant } from "@components/Link";
import { COLLECTIONS, TIMELINE } from "@routes/routes";
import { ReactElement } from "react";
import { generatePath, matchPath, useLocation, useParams } from "react-router-dom";
import styles from "./NavbarInsidePage.module.scss";

interface INavbarInsidePage {
    navbarCategories: string[];
}

const NavbarInsidePage = ({ navbarCategories }: INavbarInsidePage): ReactElement => {
    const location = useLocation();
    const { category } = useParams<{ category: string }>();
    const categorySearch = decodeURIComponent(category);

    const isTimeline = !!matchPath(location.pathname, {
        path: TIMELINE,
        strict: true,
    });

    const stylesMap = isTimeline ? styles.navbar__timeline : styles.navbar__collection;

    return (
        <div className={styles.navbar__container}>
            <div className={`${styles.navbar__menu} ${stylesMap}`}>
                {navbarCategories.map((category, id) => (
                    <Link
                        variant={LinkVariant.Button}
                        key={id}
                        className={categorySearch === category ? styles.navbar__selected : ""}
                        to={generatePath(isTimeline ? TIMELINE : COLLECTIONS, { category })}
                    >
                        {category}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default NavbarInsidePage;
