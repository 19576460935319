import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import styles from "./StepPreview.module.scss";
import { Link } from "react-router-dom";

interface IStepPreviewProps {
    name: string;
    link: string;
    image: string | undefined;
}

const StepPreview = ({ image, link, name }: IStepPreviewProps): JSX.Element => {
    return (
        <div className={styles.stepPreview__container}>
            <Link id="back-button-step" aria-label="back to previous step" to={link} className={styles.backBtn}>
                <FontAwesomeIcon icon={faChevronLeft} className={styles.backBtn__icon} />
                <span>{name}</span>
            </Link>
            {image && (
                <div className={styles.galaxy}>
                    <img className={styles.galaxy__image} src={image} alt="preview of previous step" />
                </div>
            )}
        </div>
    );
};
export default StepPreview;
