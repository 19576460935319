import { ROCKETS } from "@constants/apiEndpoints";
import { IRocket } from "@interfaces/Rocket/Rocket";
import { RocketApiResponse } from "@interfaces/Rocket/RocketResponse";
import { apiResponseToRocket } from "@scripts/RocketHelper";
import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";
import { INITIAL_DEFAULT_LIMIT } from "@constants/pagination";

const getRockets = (
    page: number,
    offset: number,
    searchByName?: string,
    limit: number = INITIAL_DEFAULT_LIMIT
): AxiosPromise<IRocket[]> =>
    apiInstance({
        url: ROCKETS,
        params: {
            limit,
            offset: page * offset,
            sort: "tokenId",
            order: "asc",
            searchCollection: searchByName,
        },
        transformResponse: [
            (data) => {
                const result = JSON.parse(data);

                const rocketsList: IRocket[] = result.map((apiRocket: RocketApiResponse) =>
                    apiResponseToRocket(apiRocket)
                );

                return rocketsList;
            },
        ],
    });

export default getRockets;
