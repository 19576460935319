import { IZoneFormatted } from "@interfaces/Starmap/Zone";
import { useState } from "react";

interface IZoneSelected {
    name: string;
    id: number;
}

interface IComboboxOption {
    id: number;
    name: string;
    image_hover: string;
    image_default: string;
}

interface IUseHoverReturn {
    zoneSelected: IZoneSelected | null;
    comboboxOptions: IComboboxOption[] | undefined;
    handleHover: (zoneId: number, image: string) => void;
    handleChangeComboboxOption: (option: number) => void;
    handleComboboxOption: (option: number) => void;
    handleChangeLinkColor: (zoneId: number) => void;
}

interface IProps {
    zones: IZoneFormatted[] | undefined;
}

const useZoneSelect = ({ zones }: IProps): IUseHoverReturn => {
    const [zoneSelected, setZoneSelected] = useState<null | IZoneSelected>(null);

    const comboboxOptions = zones?.map(({ id, name, image_hover, image_default }) => ({
        id,
        name,
        image_hover,
        image_default,
    }));

    const handleHover = (zoneId: number, image: string) => {
        const img = document.getElementById(`zoneImage${zoneId}`) as HTMLImageElement;
        img.src = image;
    };

    const handleChangeLinkColor = (zoneId: number) => {
        const systemLink = document.getElementById(`zoneLink${zoneId}`) as HTMLLinkElement;

        if (systemLink.style.color === "white") {
            systemLink.style.color = "";
            return;
        }

        systemLink.style.color = "white";
    };

    const handleChangeComboboxOption = (option: number) => {
        const combo = document.getElementById("zonesComboBox") as HTMLSelectElement;
        combo.value = option.toString();
        handleComboboxOption(option);
    };

    const handleComboboxOption = (option: number) => {
        comboboxOptions?.map(({ id, image_default }) => {
            handleHover(id, image_default);
        });

        const SELECTED_OPTION = comboboxOptions?.find(({ id }) => option === id);
        SELECTED_OPTION && handleHover(SELECTED_OPTION.id, SELECTED_OPTION.image_hover);
        SELECTED_OPTION && setZoneSelected({ name: SELECTED_OPTION.name, id: SELECTED_OPTION.id });
    };

    return {
        zoneSelected,
        comboboxOptions,
        handleHover,
        handleComboboxOption,
        handleChangeComboboxOption,
        handleChangeLinkColor,
    };
};

export default useZoneSelect;
