export const CONTACT = "/contact";
export const NEWSLETTER_SUBSCRIPTION = "/newsletter-subscription";
export const PUBLIC_ADDRESS_HEADER = "auth-public-address";
export const SIGNATURE_HEADER = "auth-signed-message";

export const HANGAR = "hangar/";
export const COMPONENTS = "components/";
export const ROCKETS = "rockets/";
export const LAUNCH_DATES = "launch-dates/";
export const METADATA = "metadata/";
export const STARMAP_ZONES = "/starmap/zones";
export const GET_ZONE_DETAIL = `${STARMAP_ZONES}/:zoneId/systems`;
export const GET_PLANETS = `${GET_ZONE_DETAIL}/:systemId/planets`;
export const INITIAL_DEFAULT_LIMIT = 25;
export const INITIAL_DEFAULT_OFFSET = 0;

export const SCRATCHER_SIGNATURE = "/physical-scratch/signature";

export const STUFF_RAFFLE = "/stuff-raffle/holder";
export const STUFF_RAFFLE_REGISTERED = `${STUFF_RAFFLE}/:walletAddress/registered`;
export const STUFF_RAFFLE_UNREGISTERED = `${STUFF_RAFFLE}/:walletAddress/unregistered`;
export const STUFF_RAFFLE_REGISTRATION = `${STUFF_RAFFLE}/registration`;

export const MESSAGE_TO_SIGN = "message-to-sign";
