import { ROCKS_CONTRACT_ADDRESS } from "@constants/env";
import { getImageForRock } from "@scripts/RockHelper";
import { IRock } from "@interfaces/Rock/Rock";
import { getOpenSeaLink } from "@scripts/OpenSeaHelper";
import TemplateBox from "../TemplateBox";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";

interface IRocksParams {
    items: IRock[];
}

const Rocks = ({ items }: IRocksParams): JSX.Element =>
    items.length > 0 ? (
        <TemplateBox title={EHangarTitleType.ROCKS}>
            {items.map((rock) =>
                [...Array(rock.amount)].map((_, i) => (
                    <a
                        href={getOpenSeaLink(ROCKS_CONTRACT_ADDRESS, rock.id.toString())}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={`${rock.id}-${i}`}
                    >
                        <img src={getImageForRock(rock)} alt={`rock type ${rock.id}`} />
                    </a>
                ))
            )}
        </TemplateBox>
    ) : (
        <></>
    );

export default Rocks;
