import close from "@assets/images/utils/close.png";
import Loader from "@components/Loader";
import useSubscribe from "@hooks/Hangar/useSubscribe";
import { ChangeEvent } from "react";
import styles from "./Subscribe.module.scss";

interface ISubscribeProps {
    showModal: () => void;
}

const Subscribe = ({ showModal }: ISubscribeProps): JSX.Element => {
    const { isLoading, isSubscribed, setEmail, handleSubscribe } = useSubscribe();

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                {isLoading ? (
                    <Loader />
                ) : isSubscribed ? (
                    <>
                        <h1>SUCCESSFULLY REGISTERED</h1>
                        <p>You are now participating in our raffle.</p>
                        <button onClick={showModal} className={styles.primary}>
                            CLOSE
                        </button>
                    </>
                ) : (
                    <>
                        <div className={styles.header}>
                            <h1>REGISTER</h1>
                            <img onClick={showModal} src={close} alt="close" />
                        </div>
                        <form className={styles.form} onSubmit={handleSubscribe}>
                            <p>
                                Please provide a contact Email in order to Register for the Rocket Factory Drawing
                                Giveaway.
                            </p>
                            <div className={styles.formItem}>
                                <span>Email</span>
                                <input
                                    id="email"
                                    type="email"
                                    aria-label="email"
                                    aria-describedby="email-input"
                                    required
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </div>
                            <button type="submit" className={styles.primary}>
                                SUBSCRIBE
                            </button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};
export default Subscribe;
