import { generatePath, useParams } from "react-router-dom";
import { GET_ZONE_DETAIL } from "@constants/apiEndpoints";
import useApiCall from "@hooks/shared/useApiCall";
import formatStarmapZoneDetail from "@formatting/formatStarmapZoneDetail";
import { ISystemFormatted } from "@interfaces/Starmap/System";

interface IReturnProps {
    zoneDetail: ISystemFormatted | undefined;
}

const useGetStarmapZoneDetail = (zoneId: string): IReturnProps => {
    const { zoneName } = useParams<{ zoneName: string }>();

    const zoneDetail = useApiCall<ISystemFormatted>({
        url: generatePath(GET_ZONE_DETAIL, { zoneId }),
        transformResponse: [(data) => formatStarmapZoneDetail(JSON.parse(data), zoneId, zoneName)],
    }).data;

    return { zoneDetail };
};

export default useGetStarmapZoneDetail;
