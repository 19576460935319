import Combobox from "@components/Starmap/Combobox";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import GoBackMobile from "@components/Starmap/GoBackMobile";
import LeftColumn from "@components/Starmap/LeftColumn";
import LegendMobile from "@components/Starmap/LegendMobile";
import RightColumn from "@components/Starmap/RightColumn";
import Title from "@components/Starmap/Title";
import Tooltip from "@components/Starmap/Tooltip";
import useGetStarmapZoneDetail from "@hooks/Starmap/useGetStarmapZoneDetail";
import useSystemSelect from "@hooks/Starmap/useSystemSelect";
import useResponsive from "@hooks/shared/useResponsive";
import { STARMAP_SYSTEM } from "@routes/routes";
import { Link, generatePath, useParams } from "react-router-dom";
import { LEGEND_LIST_WIDE } from "../starmap.legends.data";
import styles from "./ZoneDetail.module.scss";

const ZoneDetail = (): JSX.Element => {
    const { zoneId, zoneName } = useParams<{ zoneId: string; zoneName: string }>();
    const { zoneDetail } = useGetStarmapZoneDetail(zoneId);
    const { isLaptopM } = useResponsive();

    const ALL_SYSTEMS = zoneDetail?.systems.all_systems;
    const SOLAR_SYSTEMS = zoneDetail?.systems.solar_systems;
    const ROGUE_SYSTEMS = zoneDetail?.systems.rogue;
    const SPACESTATION = zoneDetail?.systems.station;
    const ZONE = zoneDetail?.zone;

    const {
        ELEMENTS_IDS,
        systemSelected,
        getComboboxOptions,
        scrollToSystemLink,
        getRealSystemId,
        getLinkId,
        getAreaId,
        handleComboboxOption,
        handleChangeComboboxOption,
        handleShowTooltip,
        handleHideTooltip,
        handleChangeLinkColor,
    } = useSystemSelect({
        ALL_SYSTEMS,
    });

    return (
        <GalaxyBackground>
            <GoBackMobile />

            <Title
                title={zoneName}
                description="Click to explore Solar Systems, <br /> Spacestation and Rogue Planets"
            />

            <Combobox title="Select solar system" handleComboboxOption={handleComboboxOption}>
                <optgroup label="SYSTEMS">
                    {SOLAR_SYSTEMS &&
                        getComboboxOptions(SOLAR_SYSTEMS).map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                </optgroup>

                <optgroup label="SPACESTATION">
                    <option key={SPACESTATION?.id} value={SPACESTATION?.id}>
                        {SPACESTATION?.name}
                    </option>
                </optgroup>

                <optgroup label="ROGUE PLANETS">
                    {ROGUE_SYSTEMS &&
                        getComboboxOptions(ROGUE_SYSTEMS).map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                </optgroup>
            </Combobox>

            <div className={styles.body}>
                <LeftColumn image={ZONE?.space_position} />

                <div className={styles.zone__container} id={ELEMENTS_IDS.zone_container}>
                    <div className={styles.zone}>
                        <div className={styles.zone__tooltips}>
                            {ZONE?.stars.areas.map(({ name }) => (
                                <Tooltip key={getRealSystemId(name)} id={getAreaId(getRealSystemId(name))} />
                            ))}
                        </div>

                        <div className={styles.zone__rescaled} style={{ transform: `scale(${ZONE?.zone_size})` }}>
                            <img
                                className={styles.zone__rescaled__image}
                                src={ZONE?.default_image.default}
                                alt="Zone preview"
                            />

                            {ZONE?.stars.areas.map(({ position, name, type }) => (
                                <Link
                                    key={getRealSystemId(name)}
                                    id={getAreaId(getRealSystemId(name))}
                                    style={position}
                                    className={styles.zone__rescaled__hitbox}
                                    data-tooltip-id={getAreaId(getRealSystemId(name))}
                                    data-tooltip-html={`<div><h3>${name}</h3><p>${type ? type : ""}</p></div>`}
                                    onMouseOver={() => {
                                        handleChangeLinkColor(getRealSystemId(name));
                                        scrollToSystemLink(getRealSystemId(name));
                                    }}
                                    onMouseLeave={() => {
                                        handleChangeLinkColor(getRealSystemId(name));
                                    }}
                                    onClick={() => isLaptopM && handleChangeComboboxOption(getRealSystemId(name))}
                                    to={
                                        !isLaptopM
                                            ? generatePath(STARMAP_SYSTEM, {
                                                  zoneId: zoneId,
                                                  zoneName: zoneName,
                                                  systemId: type ? 0 : getRealSystemId(name),
                                                  systemName: name,
                                              })
                                            : "#"
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <RightColumn title={`${zoneName} ZONE`}>
                    <ul id={ELEMENTS_IDS.link_list}>
                        <li>
                            <h3>SYSTEMS</h3>
                            <ul>
                                {SOLAR_SYSTEMS?.map(({ id, name, system_code }) => (
                                    <li key={id}>
                                        <Link
                                            id={getLinkId(id)}
                                            to={generatePath(STARMAP_SYSTEM, {
                                                zoneId: zoneId,
                                                zoneName: zoneName,
                                                systemId: system_code,
                                                systemName: name,
                                            })}
                                            onMouseOver={() => handleShowTooltip(id)}
                                            onMouseLeave={() => handleHideTooltip()}
                                        >
                                            {name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                            <h3>SPACESTATION</h3>
                            <ul>
                                <li>
                                    <Link
                                        id={SPACESTATION && getLinkId(SPACESTATION?.id)}
                                        to={
                                            SPACESTATION
                                                ? generatePath(STARMAP_SYSTEM, {
                                                      zoneId: zoneId,
                                                      zoneName: zoneName,
                                                      systemId: SPACESTATION?.system_code,
                                                      systemName: SPACESTATION?.name,
                                                  })
                                                : ""
                                        }
                                        onMouseOver={() => SPACESTATION && handleShowTooltip(SPACESTATION?.id)}
                                        onMouseLeave={() => handleHideTooltip()}
                                    >
                                        {SPACESTATION?.name}
                                    </Link>
                                </li>
                            </ul>

                            <h3>ROGUE PLANETS</h3>
                            <ul>
                                {ROGUE_SYSTEMS?.map(({ id, name, system_code }) => (
                                    <li key={id}>
                                        <Link
                                            id={getLinkId(id)}
                                            to={generatePath(STARMAP_SYSTEM, {
                                                zoneId: zoneId,
                                                zoneName: zoneName,
                                                systemId: system_code,
                                                systemName: name,
                                            })}
                                            onMouseOver={() => handleShowTooltip(id)}
                                            onMouseLeave={() => handleHideTooltip()}
                                        >
                                            {name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </RightColumn>
            </div>

            <div className={styles.information__module_mobile}>
                {systemSelected && (
                    <Link
                        to={generatePath(STARMAP_SYSTEM, {
                            zoneId: zoneId,
                            zoneName: zoneName,
                            systemId: systemSelected.id,
                            systemName: systemSelected.name,
                        })}
                        className={styles.goto__system}
                    >
                        EXPLORE SYSTEM
                    </Link>
                )}

                <LegendMobile legend={LEGEND_LIST_WIDE} />
            </div>
        </GalaxyBackground>
    );
};

export default ZoneDetail;
