import useGetStarmapPlanets from "@hooks/Starmap/useGetStarmapPlanets";
import { useParams } from "react-router-dom";
import Rogue from "./components/Rogue";
import Station from "./components/Station";
import System from "./components/System";

const ZoneDetail = (): JSX.Element => {
    const { zoneId, systemId } = useParams<{ zoneId: string; systemId: string }>();

    if (systemId === "1") {
        return <Station />;
    }

    const systemDetail = useGetStarmapPlanets(zoneId, systemId);
    const systemType = systemDetail ? systemDetail[0]?.system_type : "";

    const zoneDetail = {
        rogue: <Rogue stars={systemDetail} />,
        single: <System stars={systemDetail} systemType={systemType} />,
        binary: <System stars={systemDetail} systemType={systemType} />,
        trinary: <System stars={systemDetail} systemType={systemType} />,
    };

    return <>{zoneDetail[systemType?.toLowerCase()]}</>;
};

export default ZoneDetail;
