import { HTMLAttributes, ReactElement } from "react";
import ExpandButton from "./components/ExpandButton";
import styles from "./ModelBox.module.scss";

interface IModelBoxProps extends HTMLAttributes<HTMLDivElement> {
    src: string;
    size: ModelBoxSize;
    handleSetIsModalOpen: (boolean: boolean) => void;
    modalIsOpen?: boolean;
}

export enum ModelBoxSize {
    Expand = "expand",
    Default = "default",
}

const sizeMap = {
    [ModelBoxSize.Expand]: styles.expand,
    [ModelBoxSize.Default]: styles.default,
};

const ModelBox = ({
    src,
    size = ModelBoxSize.Default,
    handleSetIsModalOpen,
    modalIsOpen = false,
    ...props
}: IModelBoxProps): ReactElement => {
    const hasFrame = size === ModelBoxSize.Default;

    return (
        <div className={`${styles.modelBox} ${sizeMap[size]}`} {...props}>
            {!hasFrame ? (
                <model-viewer
                    src={src}
                    ar-modes="webxr scene-viewer quick-look"
                    camera-controls
                    touch-action="pan-y"
                    loading="eager"
                    camera-orbit="0deg 90deg 100%"
                ></model-viewer>
            ) : (
                <div className={styles.frame}>
                    <ExpandButton modalIsOpen={modalIsOpen} handleSetIsModalOpen={handleSetIsModalOpen} />
                    <model-viewer
                        src={src}
                        ar-modes="webxr scene-viewer quick-look"
                        camera-controls
                        touch-action="pan-y"
                        loading="eager"
                        disable-zoom
                    ></model-viewer>
                </div>
            )}
        </div>
    );
};

export default ModelBox;
