import { ROCKS_CONTRACT_ADDRESS } from "@constants/env";
import RocksAbi from "@contracts/RocksAbi";
import { IRock } from "@interfaces/Rock/Rock";
import { useWeb3Context } from "@nftstudios/web3-provider";
import {
    ROCK_AMERICAN_HISTORY_X,
    ROCK_ANTIMATTER,
    ROCK_BAMBI,
    ROCK_BLOODY_FINGERS,
    ROCK_GOLDFINGER,
    ROCK_GREASY,
    ROCK_PARTY_GIRL,
    ROCK_PEARLS_BEFORE_WINE,
    ROCK_PETROLEUM,
    ROCK_PLYWOOD,
    ROCK_RESIN_SLURRY,
    ROCK_SHITCORN,
    ROCK_SNOW,
    ROCK_THE_SPICE,
    ROCK_URANIUM,
} from "@scripts/RockHelper";

const rocksOrder = {
    0: 8,
    1: 7,
    2: 6,
    3: 14,
    4: 13,
    5: 12,
    6: 11,
    7: 10,
    8: 9,
    9: 5,
    10: 4,
    11: 3,
    12: 2,
    13: 1,
    14: 0,
};

interface ReturnTypes {
    isInitialized: boolean;
    getRocks(walletAddress: string): Promise<IRock[]>;
}

const useGetRocks = (): ReturnTypes => {
    const { web3, isInitialized } = useWeb3Context();

    const getRocks = async (walletAddress: string): Promise<IRock[]> => {
        if (!isInitialized || !web3) {
            return [];
        }

        const rocksContract = new web3.eth.Contract(RocksAbi, ROCKS_CONTRACT_ADDRESS);
        const balances: string[] = await rocksContract.methods
            .balanceOfBatch(new Array(15).fill(walletAddress), [
                ROCK_RESIN_SLURRY,
                ROCK_PARTY_GIRL,
                ROCK_BAMBI,
                ROCK_PLYWOOD,
                ROCK_SHITCORN,
                ROCK_PETROLEUM,
                ROCK_BLOODY_FINGERS,
                ROCK_PEARLS_BEFORE_WINE,
                ROCK_GREASY,
                ROCK_AMERICAN_HISTORY_X,
                ROCK_URANIUM,
                ROCK_SNOW,
                ROCK_GOLDFINGER,
                ROCK_ANTIMATTER,
                ROCK_THE_SPICE,
            ])
            .call({
                from: walletAddress,
            });

        const rocks: IRock[] = [];

        for (let i = 0; i < balances.length; i++) {
            if (parseInt(balances[i]) === 0) {
                continue;
            }

            rocks.push({
                id: i,
                amount: parseInt(balances[i]),
                order: rocksOrder[i],
            });
        }

        rocks.sort((a, b) => a.order - b.order);

        return rocks;
    };

    return { getRocks, isInitialized };
};

export default useGetRocks;
