import styles from "./RequiredReadingAndViewing.module.scss";
import insigniaGuide from "@assets/images/howItWorks/insigniaGuide.png";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import ModalImage from "react-modal-image";
import rarityChart from "@assets/images/howItWorks/raritychart.png";
import Link from "@components/Link";
import { LinkVariant } from "@components/Link/Link";

const RequiredReadingAndViewing = (): JSX.Element => {
    return (
        <div className={styles.requiredReadingAndViewing__container}>
            <Title heading={HeadingType.h3}>Phase I required reading and viewing</Title>
            <div className={styles.columns__container}>
                <div>
                    <div>
                        <ModalImage small={rarityChart} large={rarityChart} alt="Rariry Chart" hideDownload={true} />
                    </div>
                    <div>
                        <h5>Rarity Chart</h5>
                    </div>
                </div>
                <div>
                    <ModalImage
                        small={insigniaGuide}
                        large={insigniaGuide}
                        alt="Insignia Guide"
                        hideDownload={true}
                        className={styles.modalImage}
                    />
                    <div>
                        <h5>Insignia Guide</h5>
                    </div>
                </div>
                <div>
                    <div className={styles.videoContainer}>
                        <iframe
                            src="https://www.youtube.com/embed/BfqQZI-lfR0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                        ></iframe>
                        <div>
                            <h5>The Shredder Movie</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Link variant={LinkVariant.Button} to="https://medium.com/@tsrocketfactory">
                    Read More On Medium
                </Link>
            </div>
        </div>
    );
};

export default RequiredReadingAndViewing;
