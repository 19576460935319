import { useContext } from "react";
import { Accordion } from "react-accessible-accordion";
import AccordionSection from "@components/AccordionSection";
import Container from "@components/Container";
import Logo from "@components/Logo";
import styles from "./Faq.module.scss";
import { ContactContext, IContactContextProps } from "@context/ContactContext";
import { ChangeEvent, useState } from "react";
import ALL_QUESTIONS from "./Questions";
import search from "@assets/images/utils/search.png";

const Faq = (): JSX.Element => {
    const { showModal } = useContext(ContactContext) as IContactContextProps;

    const [questions, setQuestions] = useState(ALL_QUESTIONS);

    const searchResults = (e: ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.toLocaleLowerCase();
        const questionsFiltered = ALL_QUESTIONS.filter(({ title }) => title.toLocaleLowerCase().includes(input));
        input === "" ? setQuestions(ALL_QUESTIONS) : setQuestions(questionsFiltered);
    };

    return (
        <Container>
            <div className={styles.container}>
                <Logo />
                <div>
                    <h1 className={styles.screenTitle}>FREQUENTLY ASKED QUESTIONS</h1>
                    <div className={styles.search}>
                        <img src={search} alt="search" />
                        <input type="text" placeholder="Search your question" onChange={searchResults} />
                        <div className={styles.submitSearch} />
                    </div>
                    <Accordion className={styles.accordion} preExpanded={["0"]}>
                        {questions.length > 0 ? (
                            questions.map(({ title, paragraph }, index) => (
                                <AccordionSection title={title} paragraph={paragraph} uuid={`${index}`} />
                            ))
                        ) : (
                            <h5>No question found</h5>
                        )}
                    </Accordion>
                    <div className={styles.contact}>
                        <button onClick={showModal} className={styles.primary}>
                            CONTACT US
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Faq;
