import { generatePath } from "react-router-dom";
import { GET_PLANETS } from "@constants/apiEndpoints";
import useApiCall from "@hooks/shared/useApiCall";
import IPlanet from "@interfaces/Starmap/Planet";

const useGetStarmapPlanets = (zoneId: string, systemId: string): IPlanet[] | undefined =>
    useApiCall<IPlanet[]>({
        url: generatePath(GET_PLANETS, { zoneId, systemId }),
    }).data;

export default useGetStarmapPlanets;
