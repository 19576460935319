import { ReactElement, ReactNode } from "react";
import ButtonCentered from "@components/ButtonCentered";
import styles from "./ButtonTimeline.module.scss";

interface IButtonTimeline {
    to: string;
    children: ReactNode;
    [x: string]: unknown;
}

const ButtonTimeline = ({ to, children, attributes }: IButtonTimeline): ReactElement => {
    return (
        <ButtonCentered to={to} x={attributes} className={styles.buttonTimeline}>
            {children}
        </ButtonCentered>
    );
};

export default ButtonTimeline;
