import { useContext } from "react";
import { Link } from "react-router-dom";
import ds from "@assets/images/footer/ds-icon.png";
import ig from "@assets/images/footer/ig-icon.png";
import tw from "@assets/images/footer/tw-icon.png";
import md from "@assets/images/footer/md-icon.png";
import os from "@assets/images/footer/os-icon.png";
import styles from "./Footer.module.scss";
import Subscribe from "./components/Subscribe";
import { FAQ, ABOUT, TERMS, PRIVACY } from "@routes/routes";
import { ContactContext, IContactContextProps } from "@context/ContactContext";

const Footer = (): JSX.Element => {
    const { showModal } = useContext(ContactContext) as IContactContextProps;
    return (
        <div className={styles.container}>
            <div className={styles.subscribeMobile}>
                <h3 className={styles.title}>Subscribe</h3>
                <div className={styles.SubscribeFooter}>
                    <Subscribe />
                </div>
            </div>

            <div className={styles.optionsMobile}>
                <div>
                    <Link to={ABOUT}>About</Link>
                    <Link to={TERMS}>Terms & conditions</Link>
                    <Link to={PRIVACY}>Privacy</Link>
                </div>

                <div>
                    <Link to={FAQ}>FAQs</Link>
                    <a onClick={showModal}>Contact us</a>
                </div>
            </div>

            <div className={styles.socialMobile}>
                <h3>Social Channels</h3>
                <div className={styles.social}>
                    <a href="https://discord.gg/tomsachs" target="_blank" rel="noopener noreferrer">
                        <img src={ds} alt="discord" />
                    </a>
                    <a href="https://www.instagram.com/tomsachs" target="_blank" rel="noopener noreferrer">
                        <img src={ig} alt="instagram" />
                    </a>
                    <a href="https://twitter.com/tsrocketfactory" target="_blank" rel="noopener noreferrer">
                        <img src={tw} alt="twitter" />
                    </a>
                    <a href="https://medium.com/@tsrocketfactory" target="_blank" rel="noopener noreferrer">
                        <img src={md} alt="medium" />
                    </a>
                    <a href="https://opensea.io/category/tom-sachs" target="_blank" rel="noopener noreferrer">
                        <img src={os} alt="opensea" />
                    </a>
                </div>
                <div className={styles.nftstudios}>
                    Powered by{" "}
                    <a href="https://nftstudios.io/" target="_blank" rel="noopener noreferrer">
                        NFT Studios
                    </a>
                </div>
            </div>

            <div className={styles.optionsDesktop}>
                <Link to={ABOUT}>About us</Link>
                <Link to={TERMS}>Terms & conditions</Link>

                <Link to={PRIVACY}>Privacy</Link>
            </div>

            <div className={styles.otherDesktop}>
                <Link to={FAQ}>FAQs</Link>
                <a onClick={showModal}>Contact us</a>
            </div>

            <div className={styles.socialDesktop}>
                <h3 className={styles.socialTitle}>Social Channels</h3>
                <div className={styles.social}>
                    <a href="https://discord.gg/tomsachs" target="_blank" rel="noopener noreferrer">
                        <img src={ds} alt="discord" />
                    </a>
                    <a href="https://www.instagram.com/tomsachs" target="_blank" rel="noopener noreferrer">
                        <img src={ig} alt="instagram" />
                    </a>
                    <a href="https://twitter.com/tsrocketfactory" target="_blank" rel="noopener noreferrer">
                        <img src={tw} alt="twitter" />
                    </a>
                    <a href="https://medium.com/@tsrocketfactory" target="_blank" rel="noopener noreferrer">
                        <img src={md} alt="medium" />
                    </a>
                    <a href="https://opensea.io/category/tom-sachs" target="_blank" rel="noopener noreferrer">
                        <img src={os} alt="opensea" />
                    </a>
                </div>
                <div className={styles.nftstudios}>
                    Powered by{" "}
                    <a href="https://nftstudios.io/" target="_blank" rel="noopener noreferrer">
                        NFT Studios
                    </a>
                </div>
            </div>

            <div className={styles.socialDesktop}>
                <h3 className={styles.title}>Subscribe</h3>
                <div className={styles.SubscribeFooter}>
                    <Subscribe />
                </div>
            </div>
        </div>
    );
};

export default Footer;
