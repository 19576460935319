import ComponentImage from "@components/ComponentImage";
import { IComponent } from "@interfaces/RocketComponent/Component";
import { COMPONENT_DETAIL } from "@routes/routes";
import { generatePath, Link } from "react-router-dom";
import slugify from "slugify";
import styles from "./ComponentList.module.scss";

interface IComponentListProps {
    components: IComponent[];
}

const ComponentList = ({ components }: IComponentListProps): JSX.Element => {
    return (
        <div className={styles.componentList}>
            {components.map(({ tokenId, image, brand, type, slug }, index) => (
                <div className={styles.componentList__item} key={index}>
                    <Link
                        to={generatePath(COMPONENT_DETAIL, {
                            id: tokenId,
                            slug: slugify(slug, { lower: true }),
                        })}
                    >
                        <ComponentImage image={image} brand={brand} type={type} small />
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default ComponentList;
