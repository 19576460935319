import { useState } from "react";
import ISystem from "@interfaces/Starmap/System";

interface IComboboxOption {
    id: number;
    name: string;
}

interface IElemsId {
    zone_container: string;
    link_list: string;
}

interface IUseSystemSelectReturn {
    ELEMENTS_IDS: IElemsId;
    systemSelected: IComboboxOption | null;
    getComboboxOptions: (systems: ISystem[]) => IComboboxOption[];
    scrollToSystemLink: (linkId: number) => void;
    getLinkId: (str: number) => string;
    getAreaId: (str: number) => string;
    handleComboboxOption: (option: number) => void;
    handleChangeComboboxOption: (option: number) => void;
    handleShowTooltip: (systemId: number) => void;
    handleHideTooltip: () => void;
    handleChangeLinkColor: (systemId: number) => void;
    getRealSystemId: (systemName: string) => number;
}

interface IProps {
    ALL_SYSTEMS: ISystem[] | undefined;
}

const useSystemSelect = ({ ALL_SYSTEMS }: IProps): IUseSystemSelectReturn => {
    const [systemSelected, setSystemSelected] = useState<null | IComboboxOption>(null);
    const ELEMENTS_IDS = {
        zone_container: "zone-container",
        link_list: "system-link-list",
    };

    const getComboboxOptions = (systems: ISystem[]) =>
        systems.map(({ id, name }) => ({
            id,
            name,
        }));

    const getAreaId = (str: number) => `area-${str}`;

    const getLinkId = (str: number) => `link-${str}`;

    const getRealSystemId = (systemName: string) => {
        const SELECTED_OPTION = ALL_SYSTEMS?.find(({ name }) => systemName === name.toUpperCase());

        return SELECTED_OPTION?.id ?? 0;
    };

    const handleComboboxOption = (option: number) => {
        const SELECTED_OPTION = ALL_SYSTEMS?.find(({ id }) => option === id);

        SELECTED_OPTION && handleShowTooltip(SELECTED_OPTION?.id);
        SELECTED_OPTION &&
            setSystemSelected({
                name: SELECTED_OPTION.name,
                id: SELECTED_OPTION?.system_code === "0" ? 0 : SELECTED_OPTION.id,
            });
    };

    const handleChangeComboboxOption = (option: number) => {
        const combo = document.getElementById("zonesComboBox") as HTMLSelectElement;
        combo.value = option.toString();
        handleComboboxOption(option);
    };

    const handleChangeLinkColor = (systemId: number) => {
        const systemLink = document.getElementById(getLinkId(systemId)) as HTMLLinkElement;

        if (systemLink.style.color === "white") {
            systemLink.style.color = "";
            return;
        }

        systemLink.style.color = "white";
    };

    const scrollToSystemLink = (linkId: number) => {
        const linkList = document.getElementById(ELEMENTS_IDS.link_list) as HTMLUListElement;
        const linkItem = document.getElementById(getLinkId(linkId)) as HTMLLIElement;

        if (linkList && linkItem) {
            const firstElemPosition = linkList.children[0] as HTMLLIElement;
            linkList.scrollTop = linkItem?.offsetTop - firstElemPosition?.offsetTop;
        }
    };

    const handleShowTooltip = (systemId: number) => {
        const area = document.getElementById(getAreaId(systemId)) as HTMLDivElement;
        area.focus();
    };

    const handleHideTooltip = () => {
        const area = document.getElementById(ELEMENTS_IDS.zone_container) as HTMLDivElement;
        area.focus();
    };

    return {
        ELEMENTS_IDS,
        systemSelected,
        getComboboxOptions,
        scrollToSystemLink,
        getLinkId,
        getAreaId,
        handleComboboxOption,
        handleChangeComboboxOption,
        handleShowTooltip,
        handleHideTooltip,
        handleChangeLinkColor,
        getRealSystemId,
    };
};

export default useSystemSelect;
