import ConfirmAndMint from "@components/ConfirmAndMint";
import Container from "@components/Container";
import Link, { LinkVariant } from "@components/Link";
import Loader from "@components/Loader";
import PlanetMintNotAuthorized from "@components/PlanetMintNotAuthorized";
import { MARS_ROCK_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import { SessionContext } from "@context/SessionContext";
import useCalculateMintPrice from "@hooks/contracts/PlanetMinter/useCalculateMintPrice";
import usePlanetsMintWithRocks, { RocksToBeBurned } from "@hooks/contracts/PlanetMinter/usePlanetsMintWithRocks";
import useGetRocks from "@hooks/contracts/Rocks/useGetRocks";
import { IRock } from "@interfaces/Rock/Rock";
import MintingInProgress from "@pages/MintingInProgress";
import { HOME, PLANET_MINT_SUCCESS } from "@routes/routes";
import { IS_MINT_WITH_ROCKS_OPEN } from "@scripts/DateHelper";
import { isExchangeableForPlanet } from "@scripts/RockHelper";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, generatePath } from "react-router-dom";
import styles from "./PlanetMintWithRocks.module.scss";
import MarsRocks from "./components/MarsRocks";

const PlanetMint = (): ReactElement => {
    const { walletAddress, isInitialized: isSessionInitialized } = useContext(SessionContext);
    const { isInitialized: isGetRocksInitialized, getRocks } = useGetRocks();
    const { mint, isMintingInProgress, txId, isMintingSuccessful } = usePlanetsMintWithRocks();
    const { isInitialized: isCalculateInitialized, calculateWithRocks } = useCalculateMintPrice();
    const [isFetchingRocks, setIsFetchingRocks] = useState(true);
    const [rocks, setRocks] = useState<IRock[]>([]);
    const [selectedRocks, setSelectedRocks] = useState<RocksToBeBurned>({});
    const [price, setPrice] = useState("0");

    useEffect(() => {
        if (!isGetRocksInitialized || !walletAddress) {
            return;
        }

        getRocks(walletAddress).then((rocks) => {
            setIsFetchingRocks(false);
            setRocks(rocks);

            const selectedRocks = {};
            for (const { id } of rocks) {
                selectedRocks[id] = 0;
            }

            setSelectedRocks(selectedRocks);
        });
    }, [isGetRocksInitialized, walletAddress]);

    useEffect(() => {
        if (!isCalculateInitialized) {
            return;
        }

        let amountPlanets = 0;
        let amountSpaceStations = 0;

        for (const [rockId, quantity] of Object.entries(selectedRocks)) {
            isExchangeableForPlanet(parseInt(rockId)) ? (amountPlanets += quantity) : (amountSpaceStations += quantity);
        }

        calculateWithRocks(amountPlanets + amountSpaceStations).then(setPrice);
    }, [selectedRocks, isCalculateInitialized]);

    const handleAddRock = (rockId: number) => {
        const updateSelectedRocks = JSON.parse(JSON.stringify(selectedRocks));
        updateSelectedRocks[rockId]++;
        setSelectedRocks(updateSelectedRocks);
    };

    const handleRemoveRock = (rockId: number) => {
        const updateSelectedRocks = JSON.parse(JSON.stringify(selectedRocks));
        updateSelectedRocks[rockId]--;
        setSelectedRocks(updateSelectedRocks);
    };

    const handleMint = () => {
        mint(selectedRocks);
    };

    const hasAtLeastOneSelectedRock = () => {
        for (const [_, value] of Object.entries(selectedRocks)) {
            if (value > 0) {
                return true;
            }
        }

        return false;
    };

    if (!IS_MINT_WITH_ROCKS_OPEN) {
        return <Redirect to={HOME} />;
    }

    if (isSessionInitialized && !walletAddress) {
        return <Redirect to={HOME} />;
    }

    if (isFetchingRocks) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (isMintingInProgress) {
        return <MintingInProgress txId={txId} />;
    }

    if (isMintingSuccessful) {
        return <Redirect to={generatePath(PLANET_MINT_SUCCESS, { txId })} push />;
    }

    if (rocks.length === 0) {
        return (
            <PlanetMintNotAuthorized>
                <>
                    <p>
                        In order to gain access to the Final Frontier
                        <br />
                        you need to hold Mars Rocks. Get them on OpenSea:
                    </p>
                    <Link to={MARS_ROCK_COLLECTIONS_OPENSEA_URL} variant={LinkVariant.Button}>
                        Buy Mars Rocks
                    </Link>
                </>
            </PlanetMintNotAuthorized>
        );
    }

    return (
        <div className={styles.planetMint}>
            <Container>
                <h1>SELECT MARS ROCKS TO TRANSUBSTANTIATION</h1>
                <p>
                    Please select which Mars Rocks you would like to Transubstantiate into Final Frontier Certificates.
                </p>
                <MarsRocks rocks={rocks} onAddRock={handleAddRock} onRemoveRock={handleRemoveRock} />
                <ConfirmAndMint subtotal={price} onSubmit={handleMint} mintEnabled={hasAtLeastOneSelectedRock()}>
                    I understand that minting a Final Frontier Certificate with a Mars Rock NFT will exchange my
                    original Mars Rock NFT for both a corresponding modified Consecrated Mars Rock NFT (as an ERC-1155
                    token) and a randomly selected Final Frontier Certificate NFT (as an ERC-721 token) that provides
                    exclusive access to the associated World or Space Station Apartment. I also understand that I will
                    be required to pay the associated gas fees charged by the Ethereum network.
                </ConfirmAndMint>
            </Container>
        </div>
    );
};

export default PlanetMint;
