import Button from "@components/Button";
import ButtonCentered from "@components/ButtonCentered";
import Link from "@components/Link";
import { SessionContext } from "@context/SessionContext";
import { EScratcherType } from "@customTypes/Scratcher/ScratcherType";
import { MY_HANGAR, PHYSICAL_SCRATCHER, SCRATCHER_MINT } from "@routes/routes";
import { ReactElement, useContext } from "react";
import { generatePath } from "react-router-dom";
import styles from "./ButtonsSection.module.scss";

interface IButtonSectionProps {
    scratcherType: EScratcherType;
}

const ButtonsSection = ({ scratcherType = EScratcherType.PHYSICAL }: IButtonSectionProps): ReactElement => {
    const tryAgainTo = scratcherType === EScratcherType.PHYSICAL ? PHYSICAL_SCRATCHER : SCRATCHER_MINT;
    const { walletAddress } = useContext(SessionContext);

    return (
        <div className={styles.buttonsSection}>
            {walletAddress && (
                <ButtonCentered to={generatePath(MY_HANGAR, { walletAddress })}>View Hangar</ButtonCentered>
            )}
            <Link to={tryAgainTo}>
                <Button className={styles.tryAgainButton}>Try Again</Button>
            </Link>
        </div>
    );
};

export default ButtonsSection;
