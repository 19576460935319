import Container from "@components/Container";
import Link, { LinkVariant } from "@components/Link";
import Loader from "@components/Loader";
import { SessionContext } from "@context/SessionContext";
import { DeedType } from "@customTypes/Deed/DeedType";
import useGetTokensMintedOnTx from "@hooks/contracts/PlanetMinter/useGetTokensMintedOnTx";
import { IDeed } from "@interfaces/Deeds/Deed";
import { HOME, MY_HANGAR, NOT_FOUND } from "@routes/routes";
import { getTokensByDeedType } from "@scripts/DeedHelper";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, generatePath, useParams } from "react-router-dom";
import styles from "./PlanetMintSuccess.module.scss";
import MintDetail from "./components/MintDetail";

const PlanetMintSuccess = (): ReactElement => {
    const { walletAddress } = useContext(SessionContext);
    const { isInitialized, getTokensMintedOnTx } = useGetTokensMintedOnTx();
    const { txId } = useParams<{ txId: string }>();
    const [mintedTokens, setMintedTokens] = useState<IDeed[]>([]);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        getTokensMintedOnTx(txId).then((tokens) => {
            if (tokens.length === 0) {
                setNotFound(true);
                return;
            }

            setMintedTokens(tokens);
        });
    }, [isInitialized]);

    if (notFound) {
        return <Redirect to={NOT_FOUND} />;
    }

    if (!isInitialized) {
        return <Loader />;
    }

    if (isInitialized && !walletAddress) {
        return <Redirect to={HOME} />;
    }

    const mintedPlanets = getTokensByDeedType(mintedTokens, DeedType.PLANET);
    const mintedRooms = getTokensByDeedType(mintedTokens, DeedType.ROOM);

    return (
        <div className={styles.planetMintSuccess}>
            <Container>
                <h1>SUCCESS</h1>
                <p>
                    Congratulations! Your have successfully minted and claimed your Final Frontier Certificates. The
                    Metaverse Space associated with your Certificate will be open at 10:00am ET on February 8th, 2024.
                </p>

                {mintedPlanets.length > 0 && (
                    <>
                        <h2>Planetary Certificate</h2>
                        <MintDetail tokens={mintedPlanets} />
                    </>
                )}
                {mintedRooms.length > 0 && (
                    <>
                        <h2>Spacestation Certificate</h2>
                        <MintDetail tokens={mintedRooms} />
                    </>
                )}
                <hr />
                <div className={styles.bottom}>
                    <Link variant={LinkVariant.Button} to={generatePath(MY_HANGAR, { walletAddress })}>
                        View in Hangar
                    </Link>
                </div>
            </Container>
        </div>
    );
};

export default PlanetMintSuccess;
