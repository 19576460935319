import { METADATA } from "@constants/apiEndpoints";
import { METADATA_API } from "@constants/env";
import { IMetadata } from "@interfaces/Metadata/Metadata";
import { apiInstance } from "@services/Shared/instanceAxios";
import { AxiosPromise } from "axios";

interface Response {
    config: {
        tokenId: string;
    };
    metadata: IMetadata;
}

const getTokensMetadata = (contractSlug: string, tokenIds: string[]): AxiosPromise<Response[]> =>
    apiInstance({
        baseURL: METADATA_API,
        url: `${METADATA}${contractSlug}`,
        params: {
            tokenId: tokenIds,
            limit: 100,
        },
    });

export default getTokensMetadata;
