export const AREAS_ZONE_1 = [
    {
        position: {
            width: "30px",
            height: "30px",
            top: "201px",
            left: "123px",
        },
        id: 0,
        name: "HAMLET",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "223px",
            left: "216px",
        },
        id: 1,
        name: "COLOSSUS",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "119px",
            left: "360px",
        },
        id: 2,
        name: "BEHEMOTH",
        type: "COMET",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "216px",
            left: "408px",
        },
        id: 3,
        name: "BARBARELLA",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "248px",
            left: "351px",
        },
        id: 4,
        name: "HUNCHBACK",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "264px",
            left: "277px",
        },
        id: 5,
        name: "LUCIFER",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "274px",
            left: "384px",
        },
        id: 6,
        name: "STRANGELOVE",
        type: "COMET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "308px",
            left: "325px",
        },
        id: 7,
        name: "OEDIPUS",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "288px",
            left: "187px",
        },
        id: 8,
        name: "MARILYN",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "314px",
            left: "126px",
        },
        id: 9,
        name: "ACHILLES",
    },
    {
        position: {
            width: "24px",
            height: "24px",
            top: "297px",
            left: "239px",
        },
        id: 10,
        name: "NIGHTCRAWLER",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "384px",
            left: "229px",
        },
        id: 11,
        name: "ANDROMEDA",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "362px",
            left: "365px",
        },
        id: 12,
        name: "ICARUS",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "336px",
            left: "480px",
        },
        id: 13,
        name: "HECTOR",
        type: "COMET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "329px",
            left: "410px",
        },
        id: 14,
        name: "MOSES",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "307px",
            left: "538px",
        },
        id: 15,
        name: "CHIMERA",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "385px",
            left: "557px",
        },
        id: 16,
        name: "HERACLES",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "453px",
            left: "554px",
        },
        id: 17,
        name: "BACCHUS",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "316px",
            left: "618px",
        },
        id: 18,
        name: "TARANTINO",
        type: "COMET",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "401px",
            left: "300px",
        },
        id: 19,
        name: "RORSCHACH",
    },
    {
        position: {
            width: "24px",
            height: "24px",
            top: "471px",
            left: "309px",
        },
        id: 20,
        name: "APOLLO",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "507px",
            left: "274px",
        },
        id: 21,
        name: "LEVIATHAN",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "505px",
            left: "221px",
        },
        id: 22,
        name: "ELECTRA",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "457px",
            left: "260px",
        },
        id: 23,
        name: "PHOENIX",
        type: "COMET",
    },
    {
        position: {
            width: "76px",
            height: "76px",
            top: "561px",
            left: "300px",
        },
        id: 24,
        name: "MAGNETO",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "476px",
            left: "366px",
        },
        id: 25,
        name: "ULYSSES",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "413px",
            left: "367px",
        },
        id: 26,
        name: "SISYPHUS",
    },
    {
        position: {
            width: "68px",
            height: "68px",
            top: "529px",
            left: "420px",
        },
        id: 27,
        name: "ZEUS",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "404px",
            left: "429px",
        },
        id: 28,
        name: "JUGHEAD",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "461px",
            left: "457px",
        },
        id: 29,
        name: "CARAVAGGIO",
    },
    {
        position: {
            width: "68px",
            height: "68px",
            top: "565px",
            left: "143px",
        },
        id: 30,
        name: "KILLGORE",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "569px",
            left: "221px",
        },
        id: 31,
        name: "BEELZEBUB",
        type: "COMET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "644px",
            left: "295px",
        },
        id: 32,
        name: "TINKERBELL",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "611px",
            left: "371px",
        },
        id: 33,
        name: "DJANGO",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "711px",
            left: "362px",
        },
        id: 34,
        name: "BUFFY",
    },
    {
        position: {
            width: "94px",
            height: "94px",
            top: "652px",
            left: "479px",
        },
        id: 35,
        name: "ARTEMIS",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "594px",
            left: "537px",
        },
        id: 36,
        name: "NYMPH",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "738px",
            left: "421px",
        },
        id: 37,
        name: "THING",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "544px",
            left: "502px",
        },
        id: 38,
        name: "METHUSELAH",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "763px",
            left: "492px",
        },
        id: 39,
        name: "SPOCK",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "622px",
            left: "209px",
        },
        id: 40,
        name: "BELUSHI",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "627px",
            left: "615px",
        },
        id: 41,
        name: "OLYMPIA",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "564px",
            left: "614px",
        },
        id: 42,
        name: "HEMINGWAY",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "518px",
            left: "560px",
        },
        id: 43,
        name: "MYTHOS",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "495px",
            left: "604px",
        },
        id: 44,
        name: "OMEN",
        type: "COMET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "504px",
            left: "480px",
        },
        id: 45,
        name: "HERMES",
        type: "COMET",
    },
    {
        position: {
            width: "62px",
            height: "62px",
            top: "742px",
            left: "585px",
        },
        id: 46,
        name: "ROSA PARKS",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "452px",
            left: "161px",
        },
        id: 47,
        name: "GOLEM",
    },
];

export const AREAS_ZONE_2 = [
    {
        position: {
            width: "26px",
            height: "26px",
            top: "122px",
            left: "295px",
        },
        id: 0,
        name: "ADAMANTIUM",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "146px",
            left: "409px",
        },
        id: 1,
        name: "BOSS",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "199px",
            left: "329px",
        },
        id: 2,
        name: "AMONIA",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "313px",
            left: "477px",
        },
        id: 3,
        name: "ANTIFREEZE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "469px",
            left: "606px",
        },
        id: 4,
        name: "APOCALYPSE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "608px",
            left: "545px",
        },
        id: 5,
        name: "BASTARD",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "688px",
            left: "319px",
        },
        id: 6,
        name: "BAUDELAIRE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "637px",
            left: "119px",
        },
        id: 7,
        name: "BITCH",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "464px",
            left: "200px",
        },
        id: 8,
        name: "BLOOD",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "484px",
            left: "143px",
        },
        id: 9,
        name: "BLUE",
        type: "COMET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "476px",
            left: "416px",
        },
        id: 10,
        name: "BLUMPKIN",
        type: "COMET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "220px",
            left: "280px",
        },
        id: 11,
        name: "BONE",
        type: "COMET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "577px",
            left: "670px",
        },
        id: 12,
        name: "BURNOUT",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "383px",
            left: "540px",
        },
        id: 13,
        name: "JUNKYARD",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "664px",
            left: "367px",
        },
        id: 14,
        name: "ZADDY",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "419px",
            left: "359px",
        },
        id: 15,
        name: "DEATH",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "616px",
            left: "347px",
        },
        id: 16,
        name: "VIRGO",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "655px",
            left: "436px",
        },
        id: 17,
        name: "LEO",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "584px",
            left: "481px",
        },
        id: 18,
        name: "CANCER",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "271px",
            left: "528px",
        },
        id: 19,
        name: "AQUARIUS",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "390px",
            left: "243px",
        },
        id: 20,
        name: "VICE",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "530px",
            left: "490px",
        },
        id: 21,
        name: "ASBESTOS",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "633px",
            left: "217px",
        },
        id: 22,
        name: "HUSTLER",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "558px",
            left: "416px",
        },
        id: 23,
        name: "LIBRA",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "641px",
            left: "285px",
        },
        id: 24,
        name: "BALLS",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "542px",
            left: "557px",
        },
        id: 25,
        name: "SAGITTARIUS",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "547px",
            left: "192px",
        },
        id: 26,
        name: "SCORPIO",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "339px",
            left: "275px",
        },
        id: 27,
        name: "GOTH",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "448px",
            left: "391px",
        },
        id: 28,
        name: "CHOAD",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "638px",
            left: "499px",
        },
        id: 29,
        name: "ARIES",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "478px",
            left: "253px",
        },
        id: 30,
        name: "TAURUS",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "508px",
            left: "333px",
        },
        id: 31,
        name: "NIGHTMARE",
    },
    {
        position: {
            width: "70px",
            height: "70px",
            top: "452px",
            left: "498px",
        },
        id: 32,
        name: "DIVINE",
    },
    {
        position: {
            width: "58px",
            height: "58px",
            top: "390px",
            left: "454px",
        },
        id: 33,
        name: "MAGNETRON",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "424px",
            left: "300px",
        },
        id: 34,
        name: "SHITASS",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "323px",
            left: "421px",
        },
        id: 35,
        name: "GEMINI",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "556px",
            left: "267px",
        },
        id: 36,
        name: "CAPRICORN",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "284px",
            left: "332px",
        },
        id: 37,
        name: "PISCES",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "355px",
            left: "347px",
        },
        id: 38,
        name: "DINGLEBERRY",
    },
];

export const AREAS_ZONE_3 = [
    {
        position: {
            width: "36px",
            height: "36px",
            top: "439px",
            left: "520px",
        },
        id: 0,
        name: "AMERICIUM",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "711px",
            left: "249px",
        },
        id: 1,
        name: "ARSENIC",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "298px",
            left: "676px",
        },
        id: 2,
        name: "ASTATINE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "744px",
            left: "419px",
        },
        id: 3,
        name: "BARIUM",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "307px",
            left: "196px",
        },
        id: 4,
        name: "BISMUTH",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "62px",
            height: "62px",
            top: "468px",
            left: "178px",
        },
        id: 5,
        name: "DUBNIUM",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "218px",
            left: "356px",
        },
        id: 6,
        name: "HOLMIUM",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "674px",
            left: "471px",
        },
        id: 7,
        name: "IODINE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "62px",
            height: "62px",
            top: "169px",
            left: "689px",
        },
        id: 8,
        name: "LAWRENCIUM",
        type: "COMET",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "240px",
            left: "451px",
        },
        id: 9,
        name: "LIVERMORIUM",
        type: "COMET",
    },
    {
        position: {
            width: "58px",
            height: "58px",
            top: "594px",
            left: "544px",
        },
        id: 10,
        name: "PHOSPHORUS",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "471px",
            left: "129px",
        },
        id: 11,
        name: "RADIUM",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "409px",
            left: "430px",
        },
        id: 12,
        name: "RHENIUM",
        type: "COMET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "167px",
            left: "443px",
        },
        id: 13,
        name: "RUTHERFORDIUM",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "575px",
            left: "171px",
        },
        id: 14,
        name: "SAMARIUM",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "202px",
            left: "270px",
        },
        id: 15,
        name: "SCANDIUM",
        type: "COMET",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "788px",
            left: "309px",
        },
        id: 16,
        name: "SEABORGIUM",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "233px",
            left: "627px",
        },
        id: 17,
        name: "ELEMENT",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "634px",
            left: "641px",
        },
        id: 18,
        name: "ZIRCONIUM",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "546px",
            left: "611px",
        },
        id: 19,
        name: "TUNGSTEN",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "390px",
            left: "495px",
        },
        id: 20,
        name: "ARGON",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "369px",
            left: "649px",
        },
        id: 21,
        name: "KRYPTON",
    },
    {
        position: {
            width: "66px",
            height: "66px",
            top: "580px",
            left: "464px",
        },
        id: 22,
        name: "MERCURY",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "317px",
            left: "283px",
        },
        id: 23,
        name: "NICKEL",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "275px",
            left: "404px",
        },
        id: 24,
        name: "CADMIUM",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "323px",
            left: "586px",
        },
        id: 25,
        name: "CHLORINE",
    },
    {
        position: {
            width: "64px",
            height: "64px",
            top: "255px",
            left: "512px",
        },
        id: 26,
        name: "NEON",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "393px",
            left: "580px",
        },
        id: 27,
        name: "SILVER",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "477px",
            left: "406px",
        },
        id: 28,
        name: "PALLADIUM",
    },
    {
        position: {
            width: "64px",
            height: "64px",
            top: "500px",
            left: "308px",
        },
        id: 29,
        name: "HYDROGEN",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "386px",
            left: "726px",
        },
        id: 30,
        name: "XENON",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "637px",
            left: "292px",
        },
        id: 31,
        name: "LITHIUM",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "356px",
            left: "438px",
        },
        id: 32,
        name: "NEODYMIUM",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "573px",
            left: "691px",
        },
        id: 33,
        name: "LEAD",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "653px",
            left: "418px",
        },
        id: 34,
        name: "TITANIUM",
    },
    {
        position: {
            width: "70px",
            height: "70px",
            top: "481px",
            left: "477px",
        },
        id: 35,
        name: "BORON",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "533px",
            left: "543px",
        },
        id: 36,
        name: "MAGNESIUM",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "457px",
            left: "573px",
        },
        id: 37,
        name: "URANIUM",
    },
    {
        position: {
            width: "74px",
            height: "74px",
            top: "421px",
            left: "345px",
        },
        id: 38,
        name: "PLATINUM",
    },
    {
        position: {
            width: "64px",
            height: "64px",
            top: "411px",
            left: "230px",
        },
        id: 39,
        name: "NITROGEN",
    },
    {
        position: {
            width: "70px",
            height: "70px",
            top: "478px",
            left: "656px",
        },
        id: 40,
        name: "ZINC",
    },
    {
        position: {
            width: "68px",
            height: "68px",
            top: "703px",
            left: "544px",
        },
        id: 41,
        name: "TIN",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "567px",
            left: "281px",
        },
        id: 42,
        name: "GOLD",
    },
    {
        position: {
            width: "62px",
            height: "62px",
            top: "556px",
            left: "389px",
        },
        id: 43,
        name: "PLUTONIUM",
    },
];

export const AREAS_ZONE_4 = [
    {
        position: {
            width: "36px",
            height: "36px",
            top: "253px",
            left: "636px",
        },
        id: 0,
        name: "BANANA",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "141px",
            left: "665px",
        },
        id: 1,
        name: "BISCUIT",
        type: "COMET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "387px",
            left: "263px",
        },
        id: 2,
        name: "CHILI",
        type: "COMET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "192px",
            left: "618px",
        },
        id: 3,
        name: "CREAMPIE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "267px",
            left: "344px",
        },
        id: 4,
        name: "DUMPLING",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "209px",
            left: "260px",
        },
        id: 5,
        name: "JELLYBEAN",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "168px",
            left: "409px",
        },
        id: 6,
        name: "LOLLIPOP",
        type: "COMET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "247px",
            left: "236px",
        },
        id: 7,
        name: "ORTOLAN",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "374px",
            left: "491px",
        },
        id: 8,
        name: "PINEAPPLE",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "293px",
            left: "649px",
        },
        id: 9,
        name: "PIZZA",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "155px",
            left: "536px",
        },
        id: 10,
        name: "RITZEO",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "343px",
            left: "161px",
        },
        id: 11,
        name: "SAUSAGE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "194px",
            left: "289px",
        },
        id: 12,
        name: "SPRINKLE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "188px",
            left: "380px",
        },
        id: 13,
        name: "TATER TOT",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "350px",
            left: "407px",
        },
        id: 14,
        name: "TRUFFLE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "272px",
            left: "108px",
        },
        id: 15,
        name: "TURDUCKEN",
        type: "COMET",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "291px",
            left: "205px",
        },
        id: 16,
        name: "FOOD",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "378px",
            left: "388px",
        },
        id: 17,
        name: "GNOCCHI",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "289px",
            left: "479px",
        },
        id: 18,
        name: "NOODLE",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "298px",
            left: "433px",
        },
        id: 19,
        name: "GRUEL",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "261px",
            left: "420px",
        },
        id: 20,
        name: "TACO",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "483px",
            left: "417px",
        },
        id: 21,
        name: "CHERRY PIE",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "415px",
            left: "247px",
        },
        id: 22,
        name: "CHIMICHANGA",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "284px",
            left: "392px",
        },
        id: 23,
        name: "BUTTERCUP",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "411px",
            left: "451px",
        },
        id: 24,
        name: "MILKSHAKE",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "430px",
            left: "550px",
        },
        id: 25,
        name: "PEANUT BUTTER",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "203px",
            left: "670px",
        },
        id: 26,
        name: "BACON",
    },
    {
        position: {
            width: "58px",
            height: "58px",
            top: "204px",
            left: "442px",
        },
        id: 27,
        name: "GORGONZOLA",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "419px",
            left: "401px",
        },
        id: 28,
        name: "BABAGANOUSH",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "334px",
            left: "487px",
        },
        id: 29,
        name: "BUBBLEGUM",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "179px",
            left: "188px",
        },
        id: 30,
        name: "BOLOGNA",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "456px",
            left: "470px",
        },
        id: 31,
        name: "SIDE SALAD",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "360px",
            left: "444px",
        },
        id: 32,
        name: "SCHNITZEL",
    },
    {
        position: {
            width: "68px",
            height: "68px",
            top: "403px",
            left: "325px",
        },
        id: 33,
        name: "PISTACHIO",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "257px",
            left: "535px",
        },
        id: 34,
        name: "CORNHOLIO",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "243px",
            left: "486px",
        },
        id: 35,
        name: "JERK",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "384px",
            left: "529px",
        },
        id: 36,
        name: "SPAM",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "352px",
            left: "303px",
        },
        id: 37,
        name: "NACHO",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "279px",
            left: "289px",
        },
        id: 38,
        name: "MEATBALL",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "323px",
            left: "375px",
        },
        id: 39,
        name: "HAMBURGER",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "366px",
            left: "580px",
        },
        id: 40,
        name: "CAKE",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "290px",
            left: "587px",
        },
        id: 41,
        name: "LASAGNA",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "341px",
            left: "616px",
        },
        id: 42,
        name: "JALAPEÑO",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "352px",
            left: "237px",
        },
        id: 43,
        name: "CHALUPA",
    },
];

export const AREAS_ZONE_5 = [
    {
        position: {
            width: "36px",
            height: "36px",
            top: "726px",
            left: "544px",
        },
        id: 0,
        name: "2C-B",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "485px",
            left: "617px",
        },
        id: 1,
        name: "ATLANTIS",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "943px",
            left: "412px",
        },
        id: 2,
        name: "BETTE NESMITH",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "623px",
            left: "671px",
        },
        id: 3,
        name: "GENE KRANZ",
        type: "COMET",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "219px",
            left: "396px",
        },
        id: 4,
        name: "HUNTER S. THOMPSON",
        type: "COMET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "736px",
            left: "240px",
        },
        id: 5,
        name: "KETAMINE",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "408px",
            left: "428px",
        },
        id: 7,
        name: "MARK GONZALES",
        type: "COMET",
    },
    {
        position: {
            width: "55px",
            height: "55px",
            top: "937px",
            left: "158px",
        },
        id: 7,
        name: "LINNAEUS",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "921px",
            left: "301px",
        },
        id: 8,
        name: "MASTERS & JOHNSON",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "862px",
            left: "501px",
        },
        id: 9,
        name: "PEYOTE",
        type: "COMET",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "271px",
            left: "585px",
        },
        id: 10,
        name: "PUGSLEY",
        type: "COMET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "364px",
            left: "670px",
        },
        id: 11,
        name: "SERGEY BRIN",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "690px",
            left: "396px",
        },
        id: 12,
        name: "WILBUR WRIGHT",
        type: "COMET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "844px",
            left: "396px",
        },
        id: 13,
        name: "DISCOVERY",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "369px",
            left: "146px",
        },
        id: 14,
        name: "PICARD",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "588px",
            left: "362px",
        },
        id: 15,
        name: "HYPATIA",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "384px",
            left: "210px",
        },
        id: 16,
        name: "TURING",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "425px",
            left: "163px",
        },
        id: 17,
        name: "DAEDALUS",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "568px",
            left: "185px",
        },
        id: 18,
        name: "TALOS",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "292px",
            left: "328px",
        },
        id: 19,
        name: "CETI-ALPHA",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "497px",
            left: "260px",
        },
        id: 20,
        name: "DARWIN",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "449px",
            left: "253px",
        },
        id: 21,
        name: "KIRK",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "738px",
            left: "456px",
        },
        id: 22,
        name: "GALILEO",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "404px",
            left: "102px",
        },
        id: 23,
        name: "VOYAGER",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "333px",
            left: "391px",
        },
        id: 24,
        name: "SHACKLETON",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "626px",
            left: "250px",
        },
        id: 25,
        name: "ARCHIMEDES",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "376px",
            left: "299px",
        },
        id: 26,
        name: "UHURA",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "753px",
            left: "325px",
        },
        id: 27,
        name: "KEPLER",
    },
    {
        position: {
            width: "62px",
            height: "62px",
            top: "764px",
            left: "197px",
        },
        id: 28,
        name: "NEWTON",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "566px",
            left: "250px",
        },
        id: 29,
        name: "COPERNICUS",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "532px",
            left: "95px",
        },
        id: 30,
        name: "PROMETHEUS",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "569px",
            left: "430px",
        },
        id: 31,
        name: "FOUCAULT",
    },
    {
        position: {
            width: "62px",
            height: "62px",
            top: "490px",
            left: "189px",
        },
        id: 32,
        name: "GUTENBERG",
    },
    {
        position: {
            width: "68px",
            height: "68px",
            top: "321px",
            left: "236px",
        },
        id: 33,
        name: "Q",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "653px",
            left: "144px",
        },
        id: 34,
        name: "FREUD",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "489px",
            left: "490px",
        },
        id: 35,
        name: "LOVELACE",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "495px",
            left: "360px",
        },
        id: 36,
        name: "EINSTEIN",
    },
];

export const AREAS_ZONE_6 = [
    {
        position: {
            width: "32px",
            height: "32px",
            top: "456px",
            left: "398px",
        },
        id: 0,
        name: "BACH",
        type: "COMET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "509px",
            left: "240px",
        },
        id: 1,
        name: "BURZUM",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "304px",
            left: "211px",
        },
        id: 2,
        name: "CHE",
        type: "COMET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "525px",
            left: "441px",
        },
        id: 3,
        name: "CHRIST",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "24px",
            height: "24px",
            top: "372px",
            left: "469px",
        },
        id: 4,
        name: "CORBUSIER",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "491px",
            left: "469px",
        },
        id: 5,
        name: "DONALD JUDD",
        type: "COMET",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "335px",
            left: "451px",
        },
        id: 6,
        name: "EL DORADO",
        type: "COMET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "343px",
            left: "543px",
        },
        id: 7,
        name: "FUJI",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "512px",
            left: "132px",
        },
        id: 8,
        name: "GFOS",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "144px",
            left: "357px",
        },
        id: 9,
        name: "GOETHE",
        type: "COMET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "455px",
            left: "314px",
        },
        id: 10,
        name: "GURDJIEFF",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "381px",
            left: "602px",
        },
        id: 11,
        name: "JONESTOWN",
        type: "COMET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "277px",
            left: "652px",
        },
        id: 12,
        name: "KIERKEGAARD",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "409px",
            left: "129px",
        },
        id: 13,
        name: "MCLUHAN",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "426px",
            left: "522px",
        },
        id: 14,
        name: "NASA",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "278px",
            left: "552px",
        },
        id: 15,
        name: "OBAMA",
        type: "COMET",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "383px",
            left: "674px",
        },
        id: 16,
        name: "RINGO",
        type: "COMET",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "335px",
            left: "83px",
        },
        id: 17,
        name: "YOKO ONO",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "381px",
            left: "179px",
        },
        id: 18,
        name: "FAITH",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "296px",
            left: "490px",
        },
        id: 19,
        name: "VONNEGUT",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "411px",
            left: "254px",
        },
        id: 20,
        name: "SAGAN",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "131px",
            left: "438px",
        },
        id: 21,
        name: "EDEN",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "423px",
            left: "378px",
        },
        id: 22,
        name: "MONEY",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "206px",
            left: "260px",
        },
        id: 23,
        name: "WAGNER",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "115px",
            left: "382px",
        },
        id: 24,
        name: "TUPAC",
    },
    {
        position: {
            width: "22px",
            height: "22px",
            top: "280px",
            left: "382px",
        },
        id: 25,
        name: "VALHALLA",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "323px",
            left: "360px",
        },
        id: 26,
        name: "ALI",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "293px",
            left: "165px",
        },
        id: 27,
        name: "CERN",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "249px",
            left: "294px",
        },
        id: 28,
        name: "MANDELA",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "261px",
            left: "435px",
        },
        id: 29,
        name: "NADER",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "127px",
            left: "295px",
        },
        id: 30,
        name: "SHANGRI-LA",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "181px",
            left: "389px",
        },
        id: 31,
        name: "HERZOG",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "258px",
            left: "242px",
        },
        id: 32,
        name: "TARKOVSKY",
    },
    {
        position: {
            width: "80px",
            height: "80px",
            top: "201px",
            left: "559px",
        },
        id: 33,
        name: "MADONNA",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "202px",
            left: "209px",
        },
        id: 34,
        name: "MANSON",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "377px",
            left: "416px",
        },
        id: 35,
        name: "KUBRICK",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "77px",
            left: "354px",
        },
        id: 36,
        name: "BIGGIE",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "219px",
            left: "392px",
        },
        id: 37,
        name: "LEBRON",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "271px",
            left: "339px",
        },
        id: 38,
        name: "VOODOO",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "394px",
            left: "320px",
        },
        id: 39,
        name: "BOWIE",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "190px",
            left: "474px",
        },
        id: 40,
        name: "KORESH",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "327px",
            left: "263px",
        },
        id: 41,
        name: "MALCOM X",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "182px",
            left: "323px",
        },
        id: 42,
        name: "Brâncuși",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "327px",
            left: "414px",
        },
        id: 43,
        name: "MANET",
    },
];

export const AREAS_ZONE_7 = [
    {
        position: {
            width: "30px",
            height: "30px",
            top: "723px",
            left: "414px",
        },
        id: 0,
        name: "AMBITION",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "723px",
            left: "677px",
        },
        id: 1,
        name: "AGONY",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "576px",
            left: "290px",
        },
        id: 2,
        name: "ANGER",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "521px",
            left: "494px",
        },
        id: 3,
        name: "ANGST",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "284px",
            left: "305px",
        },
        id: 4,
        name: "ANNOYANCE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "158px",
            left: "147px",
        },
        id: 5,
        name: "ANXIETY",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "180px",
            left: "252px",
        },
        id: 6,
        name: "APATHY",
        type: "COMET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "279px",
            left: "541px",
        },
        id: 7,
        name: "AWE",
        type: "COMET",
    },
    {
        position: {
            width: "60px",
            height: "60px",
            top: "351px",
            left: "225px",
        },
        id: 8,
        name: "AWESOME",
        type: "COMET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "553px",
            left: "669px",
        },
        id: 9,
        name: "BASHFUL",
        type: "COMET",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "704px",
            left: "455px",
        },
        id: 10,
        name: "BOREDOM",
        type: "COMET",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "300px",
            left: "176px",
        },
        id: 11,
        name: "CARNALITY",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "385px",
            left: "346px",
        },
        id: 12,
        name: "FEELINGS",
    },
    {
        position: {
            width: "24px",
            height: "24px",
            top: "542px",
            left: "620px",
        },
        id: 13,
        name: "SERENITY",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "188px",
            left: "362px",
        },
        id: 14,
        name: "TERROR",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "251px",
            left: "599px",
        },
        id: 15,
        name: "CHARISMA",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "241px",
            left: "667px",
        },
        id: 16,
        name: "DREAD",
    },
    {
        position: {
            width: "66px",
            height: "66px",
            top: "409px",
            left: "266px",
        },
        id: 17,
        name: "LOVE",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "458px",
            left: "527px",
        },
        id: 18,
        name: "PSYCHO",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "520px",
            left: "396px",
        },
        id: 19,
        name: "BEDLAM",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "400px",
            left: "545px",
        },
        id: 20,
        name: "HAVOC",
    },
    {
        position: {
            width: "94px",
            height: "94px",
            top: "681px",
            left: "564px",
        },
        id: 21,
        name: "GUILT",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "407px",
            left: "730px",
        },
        id: 22,
        name: "LUST",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "470px",
            left: "337px",
        },
        id: 23,
        name: "INADEQUACY",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "491px",
            left: "562px",
        },
        id: 24,
        name: "FEAR",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "396px",
            left: "608px",
        },
        id: 25,
        name: "PANIC",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "224px",
            left: "480px",
        },
        id: 26,
        name: "HORNY",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "306px",
            left: "688px",
        },
        id: 27,
        name: "ECSTASY",
    },
    {
        position: {
            width: "74px",
            height: "74px",
            top: "431px",
            left: "439px",
        },
        id: 28,
        name: "HYSTERIA",
    },
    {
        position: {
            width: "58px",
            height: "58px",
            top: "339px",
            left: "516px",
        },
        id: 29,
        name: "PLEASURE",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "477px",
            left: "633px",
        },
        id: 30,
        name: "TRUST",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "331px",
            left: "598px",
        },
        id: 31,
        name: "SURPRISE!",
    },
    {
        position: {
            width: "58px",
            height: "58px",
            top: "830px",
            left: "531px",
        },
        id: 32,
        name: "RAINBOW",
    },
    {
        position: {
            width: "76px",
            height: "76px",
            top: "307px",
            left: "405px",
        },
        id: 33,
        name: "CHAOS",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "397px",
            left: "676px",
        },
        id: 34,
        name: "JUSTICE",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "513px",
            left: "729px",
        },
        id: 35,
        name: "EUPHORIA",
    },
    {
        position: {
            width: "54px",
            height: "54px",
            top: "604px",
            left: "463px",
        },
        id: 36,
        name: "GREED",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "558px",
            left: "561px",
        },
        id: 37,
        name: "JOY",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "610px",
            left: "675px",
        },
        id: 38,
        name: "MELANCHOLIA",
    },
];

export const AREAS_ZONE_8 = [
    {
        position: {
            width: "30px",
            height: "30px",
            top: "510px",
            left: "425px",
        },
        id: 0,
        name: "ANACONDA",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "449px",
            left: "215px",
        },
        id: 1,
        name: "ANENOME",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "266px",
            left: "608px",
        },
        id: 2,
        name: "ANGLERFISH",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "22px",
            height: "22px",
            top: "148px",
            left: "488px",
        },
        id: 3,
        name: "ASS",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "215px",
            left: "218px",
        },
        id: 4,
        name: "BARN OWL",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "262px",
            left: "94px",
        },
        id: 5,
        name: "BENGAL TIGER",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "177px",
            left: "263px",
        },
        id: 6,
        name: "BLACK WIDOW",
        type: "COMET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "288px",
            left: "420px",
        },
        id: 7,
        name: "BOOMSLANG",
        type: "COMET",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "146px",
            left: "586px",
        },
        id: 8,
        name: "BUTTERFLY",
        type: "COMET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "265px",
            left: "687px",
        },
        id: 9,
        name: "BUZZARD",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "360px",
            left: "597px",
        },
        id: 10,
        name: "CAPYBARA",
        type: "COMET",
    },
    {
        position: {
            width: "46px",
            height: "46px",
            top: "440px",
            left: "556px",
        },
        id: 11,
        name: "CASSOWARY",
        type: "COMET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "561px",
            left: "438px",
        },
        id: 12,
        name: "CERBERUS",
        type: "COMET",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "545px",
            left: "285px",
        },
        id: 13,
        name: "CHEETAH",
        type: "COMET",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "396px",
            left: "249px",
        },
        id: 14,
        name: "CHEWY",
        type: "COMET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "576px",
            left: "373px",
        },
        id: 15,
        name: "CHIMPANZEE",
        type: "ARTIFICIAL PLANET",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "555px",
            left: "516px",
        },
        id: 16,
        name: "COCK",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "392px",
            left: "603px",
        },
        id: 17,
        name: "CONDOR",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "183px",
            left: "637px",
        },
        id: 18,
        name: "CONEJO",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "92px",
            left: "573px",
        },
        id: 19,
        name: "COYOTE",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "26px",
            height: "26px",
            top: "181px",
            left: "322px",
        },
        id: 20,
        name: "CROCODILE",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "279px",
            left: "149px",
        },
        id: 21,
        name: "DEATHSTALKER SCORPION",
        type: "ROCKY MOON PLANET",
    },
    {
        position: {
            width: "36px",
            height: "36px",
            top: "341px",
            left: "318px",
        },
        id: 22,
        name: "FAUNA",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "497px",
            left: "351px",
        },
        id: 23,
        name: "GREMLIN",
    },
    {
        position: {
            width: "28px",
            height: "28px",
            top: "310px",
            left: "357px",
        },
        id: 24,
        name: "PIRANHA",
    },
    {
        position: {
            width: "48px",
            height: "48px",
            top: "185px",
            left: "532px",
        },
        id: 25,
        name: "COCKROACH",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "421px",
            left: "330px",
        },
        id: 26,
        name: "STEGOSAURUS",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "334px",
            left: "507px",
        },
        id: 27,
        name: "PANDA",
    },
    {
        position: {
            width: "56px",
            height: "56px",
            top: "237px",
            left: "268px",
        },
        id: 28,
        name: "TARANTULA",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "267px",
            left: "448px",
        },
        id: 29,
        name: "RAT",
    },
    {
        position: {
            width: "44px",
            height: "44px",
            top: "287px",
            left: "311px",
        },
        id: 30,
        name: "ORCA",
    },
    {
        position: {
            width: "38px",
            height: "38px",
            top: "274px",
            left: "388px",
        },
        id: 31,
        name: "THUNDERBIRD",
    },
    {
        position: {
            width: "30px",
            height: "30px",
            top: "238px",
            left: "333px",
        },
        id: 32,
        name: "RACOON",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "218px",
            left: "576px",
        },
        id: 33,
        name: "KRAKEN",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "381px",
            left: "521px",
        },
        id: 34,
        name: "BUNNY",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "310px",
            left: "458px",
        },
        id: 35,
        name: "HOBGOBLIN",
    },
    {
        position: {
            width: "34px",
            height: "34px",
            top: "426px",
            left: "477px",
        },
        id: 36,
        name: "MEERKAT",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "376px",
            left: "290px",
        },
        id: 37,
        name: "BEAVER",
    },
    {
        position: {
            width: "50px",
            height: "50px",
            top: "215px",
            left: "476px",
        },
        id: 38,
        name: "PLATYPUS",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "424px",
            left: "390px",
        },
        id: 39,
        name: "SPHINX",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "317px",
            left: "233px",
        },
        id: 40,
        name: "UNICORN",
    },
    {
        position: {
            width: "52px",
            height: "52px",
            top: "346px",
            left: "389px",
        },
        id: 41,
        name: "WEASEL",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "275px",
            left: "528px",
        },
        id: 42,
        name: "VALKYRIE",
    },
    {
        position: {
            width: "32px",
            height: "32px",
            top: "379px",
            left: "439px",
        },
        id: 43,
        name: "GOAT",
    },
    {
        position: {
            width: "68px",
            height: "68px",
            top: "166px",
            left: "412px",
        },
        id: 44,
        name: "TARDIGRADE",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "219px",
            left: "384px",
        },
        id: 45,
        name: "SLOTH",
    },
    {
        position: {
            width: "40px",
            height: "40px",
            top: "322px",
            left: "580px",
        },
        id: 46,
        name: "PEACOCK",
    },
    {
        position: {
            width: "42px",
            height: "42px",
            top: "466px",
            left: "510px",
        },
        id: 47,
        name: "PITBULL",
    },
];
