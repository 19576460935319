import { IDeed } from "@interfaces/Deeds/Deed";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { getContractSlugByContractAddress } from "@scripts/CollectionCategoryHelper";
import { getDeedTypeByContract } from "@scripts/DeedHelper";
import { getOpenSeaLink } from "@scripts/OpenSeaHelper";
import getTokenMetadata from "@services/Metadata/getTokenMetadata";

interface ReturnTypes {
    isInitialized: boolean;
    getTokensMintedOnTx: (txId: string) => Promise<IDeed[]>;
}

const input = [
    {
        type: "address",
        name: "from",
        indexed: true,
    },
    {
        type: "address",
        name: "to",
        indexed: true,
    },
    {
        type: "uint256",
        name: "tokenId",
        indexed: true,
    },
];

const useGetTokensMintedOnTx = (): ReturnTypes => {
    const { web3, isInitialized } = useWeb3Context();

    const getTokensMintedOnTx = async (txId: string): Promise<IDeed[]> => {
        if (!web3) {
            return [];
        }

        const data = await web3.eth.getTransactionReceipt(txId);

        if (!data) {
            return [];
        }

        const mintedTokens: IDeed[] = [];
        for (const log of data.logs) {
            // Filter only the transfer events.
            const transferEventHash = web3.utils.sha3("Transfer(address,address,uint256)");
            if (log.topics[0] !== transferEventHash) {
                continue;
            }

            const contractAddress = log.address.toLowerCase();
            const { tokenId } = web3.eth.abi.decodeLog(input, log.data, log.topics.slice(1));
            const contractSlug = getContractSlugByContractAddress(contractAddress);

            await getTokenMetadata(contractSlug, tokenId).then(({ data }) => {
                mintedTokens.push({
                    id: tokenId,
                    type: getDeedTypeByContract(contractAddress),
                    title: data.name,
                    imageUrl: data.image,
                    openSeaUrl: getOpenSeaLink(contractAddress, tokenId),
                });
            });
        }

        return mintedTokens;
    };

    return { isInitialized, getTokensMintedOnTx };
};

export default useGetTokensMintedOnTx;
