import PhaseIHero from "./components/PhaseIHero";
import RequiredReadingAndViewing from "./components/RequiredReadingAndViewing";

const PhaseI = (): JSX.Element => {
    return (
        <>
            <PhaseIHero />
            <RequiredReadingAndViewing />
        </>
    );
};

export default PhaseI;
