import Container from "@components/Container";
import Logo from "@components/Logo";
import { Link } from "react-router-dom";
import { HOME } from "@routes/routes";
import styles from "./NotFound.module.scss";

const NotFound = (): JSX.Element => (
    <Container>
        <div className={styles.container}>
            <Logo />
            <h1>Oops we got off course.</h1>
            <span>Mission Control advises clicking here.</span>
            <div className={styles.btnContainer}>
                <Link to={HOME} className={styles.primary}>
                    GO TO HOME
                </Link>
            </div>
        </div>
    </Container>
);

export default NotFound;
