import styles from "./Title.module.scss";
import Parser from "html-react-parser";

interface ITitle {
    title?: string;
    description?: string;
}

const Title = ({ title, description }: ITitle): JSX.Element => (
    <div className={styles.title}>
        <h1>{title}</h1>
        {description && <p>{Parser(description)}</p>}
    </div>
);

export default Title;
