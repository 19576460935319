import Web3 from "web3";
import getTokensFromApi from "@services/Hangar/getTokens";
import { useState } from "react";
import { ITokenResponse } from "@interfaces/Hangar/TokenResponse";

interface ReturnTypes {
    getTokens: (walletAddress: string) => Promise<ITokenResponse>;
    isLoading: boolean;
}

const useGetAccountTokens = (): ReturnTypes => {
    const [isLoading, setIsLoading] = useState(true);

    const getTokens = async (walletAddress: string): Promise<ITokenResponse> => {
        const checkSumAddress = Web3.utils.toChecksumAddress(walletAddress);
        const response = await getTokensFromApi(checkSumAddress).finally(() => setIsLoading(false));

        return response.data;
    };

    return { getTokens, isLoading };
};

export default useGetAccountTokens;
