import { ReactElement, useEffect } from "react";
import Container from "@components/Container";
import Link, { LinkVariant } from "@components/Link";
import styles from "./MintingInProgress.module.scss";
import { getEtherscanLinkForTxId } from "@scripts/EtherscanHelper";
import defaultMintingInProgressImage from "@assets/images/planetMint/mintInProgress.gif";
import stuffMintinInProgressImage from "@assets/images/stuff/scratcher-animation.gif";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";

interface IMintingInProgressProps {
    txId: string;
    mintingType?: CollectionCategory;
}

const MintingInProgress = ({ txId, mintingType }: IMintingInProgressProps): ReactElement => {
    const isStuff = mintingType === CollectionCategory.stuff;
    const mintingInProgressImage = !isStuff ? defaultMintingInProgressImage : stuffMintinInProgressImage;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GalaxyBackground>
            <Container className={`${styles.mintingInProgress} ${isStuff ? styles.stuffMintingInProgress : ""}`}>
                <img src={mintingInProgressImage} alt="Minting in progress" />
                <br />
                <h1 className={styles.transactionInProgress}>Transaction in progress</h1>
                <Link to={getEtherscanLinkForTxId(txId)} variant={LinkVariant.Button}>
                    View on Etherscan
                </Link>
            </Container>
        </GalaxyBackground>
    );
};

export default MintingInProgress;
