import { IMetadataMinted } from "@interfaces/Metadata/MetadataMinted";
import AssetItemList from "../AssetItemList";
import styles from "./CategorizedList.module.scss";

interface ICategorizedList {
    metadataCategorized: (IMetadataMinted[] | undefined)[] | undefined;
}

const CategorizedList = ({ metadataCategorized }: ICategorizedList): JSX.Element => (
    <>
        {metadataCategorized?.map(
            (content) =>
                content &&
                content[0] && (
                    <div key={content[0].config.tokenId} className={styles.metadata__category}>
                        <p className={styles.metadata__category_name}>{content[0].metadata.attributes[0].value}</p>
                        <ul className={styles.metadata__category_list}>
                            {content.map(({ metadata }) => (
                                <AssetItemList key={metadata.name} img={metadata.image} name={metadata.name} />
                            ))}
                        </ul>
                    </div>
                )
        )}
    </>
);
export default CategorizedList;
