import useResponsive from "@hooks/shared/useResponsive";
import { IZoneFormatted } from "@interfaces/Starmap/Zone";
import { STARMAP_ZONE } from "@routes/routes";
import { generatePath, Link } from "react-router-dom";
import styles from "./GalaxyZones.module.scss";

interface IGalaxyZonesProp {
    zones: IZoneFormatted[] | undefined;
    handleHover: (zoneId: number, image: string) => void;
    handleChangeComboboxOption: (option: number) => void;
    handleChangeLinkColor: (zoneId: number) => void;
}
const GalaxyZones = ({
    zones,
    handleHover,
    handleChangeLinkColor,
    handleChangeComboboxOption,
}: IGalaxyZonesProp): JSX.Element => {
    const { isLaptopM } = useResponsive();
    const stylesZones = {
        [1]: styles.zone1,
        [2]: styles.zone2,
        [3]: styles.zone3,
        [4]: styles.zone4,
        [5]: styles.zone5,
        [6]: styles.zone6,
        [7]: styles.zone7,
        [8]: styles.zone8,
    };

    return (
        <div className={styles.galaxyZones}>
            {zones?.map(({ id, image_default, image_hover, name }) => (
                <>
                    <img key={`zoneImage${id}`} id={`zoneImage${id}`} src={image_default} alt={name} />
                    <Link
                        id={`zoneArea${id}`}
                        key={`zoneArea${id}`}
                        onMouseOver={() => {
                            handleHover(id, image_hover);
                            handleChangeLinkColor(id);
                        }}
                        onMouseLeave={() => {
                            handleHover(id, image_default);
                            handleChangeLinkColor(id);
                        }}
                        className={stylesZones[id]}
                        onClick={() => isLaptopM && handleChangeComboboxOption(id)}
                        to={!isLaptopM ? generatePath(STARMAP_ZONE, { zoneId: id, zoneName: name }) : "#"}
                    />
                </>
            ))}
        </div>
    );
};

export default GalaxyZones;
