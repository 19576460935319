import { IStuff } from "@interfaces/Stuff/Stuff";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { getContractSlugByContractAddress } from "@scripts/CollectionCategoryHelper";
import { getOpenSeaLink } from "@scripts/OpenSeaHelper";
import getTokensMetadata from "@services/Metadata/getTokensMetadata";

interface ReturnTypes {
    isInitialized: boolean;
    getTokensMintedOnTx: (txId: string) => Promise<IStuff[]>;
}

const input = [
    { indexed: true, name: "operator", type: "address" },
    { indexed: true, name: "from", type: "address" },
    { indexed: true, name: "to", type: "address" },
    { indexed: false, name: "ids", type: "uint256[]" },
    { indexed: false, name: "values", type: "uint256[]" },
];

const useGetTokensMintedOnTx = (): ReturnTypes => {
    const { web3, isInitialized } = useWeb3Context();

    const getTokensMintedOnTx = async (txId: string): Promise<IStuff[]> => {
        if (!web3) {
            return [];
        }

        const data = await web3.eth.getTransactionReceipt(txId);

        if (!data) {
            return [];
        }

        let mintedTokens: IStuff[] = [];
        for (const log of data.logs) {
            // Filter only the transfer events.
            const transferEventHash = web3.utils.sha3("TransferBatch(address,address,address,uint256[],uint256[])");
            if (log.topics[0] !== transferEventHash) {
                continue;
            }

            const contractAddress = log.address.toLowerCase();
            const { ids, values } = web3.eth.abi.decodeLog(input, log.data, log.topics.slice(1));
            const contractSlug = getContractSlugByContractAddress(contractAddress);

            const { data: stuffs } = await getTokensMetadata(contractSlug, ids as unknown as string[]);

            for (const stuff of stuffs) {
                const i = ids.indexOf(stuff.config.tokenId);
                if (i === -1) {
                    continue;
                }

                mintedTokens = mintedTokens.concat(
                    new Array<IStuff>(Number(values[i])).fill({
                        id: stuff.config.tokenId,
                        title: stuff.metadata.name,
                        imageUrl: stuff.metadata.image,
                        openSeaUrl: getOpenSeaLink(contractAddress, stuff.config.tokenId),
                    })
                );
            }
        }

        return mintedTokens;
    };

    return { isInitialized, getTokensMintedOnTx };
};

export default useGetTokensMintedOnTx;
