import { ReactElement, useEffect, useState } from "react";
import styles from "./MintSelection.module.scss";
import mintStateroom from "@assets/images/planetMint/mintStateroom.png";
import mintPlanet from "@assets/images/planetMint/mintPlanet.png";
import scratcherOne from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_1.png";
import scratcherTwo from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_2.png";
import scratcherThree from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_3.png";
import scratcherFour from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_4.png";
import scratcherFive from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_5.png";
import scratcherSix from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_6.png";
import scratcherSeven from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_7.png";
import scratcherEight from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_8.png";
import scratcherNine from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_9.png";
import scratcherTen from "@assets/images/stuff/scratcherStack/TSRF_Scratcher_10.png";
import { EMintType } from "@customTypes/Mint/MintType";

interface IMintSelectionProps {
    amount: number;
    onAmountSelected: (amount: number) => void;
    mintContent?: EMintType;
}

const MINIMUM_MINT = 1;
const MAXIMUM_MINT = 10;

const scratcherStack = [
    scratcherOne,
    scratcherTwo,
    scratcherThree,
    scratcherFour,
    scratcherFive,
    scratcherSix,
    scratcherSeven,
    scratcherEight,
    scratcherNine,
    scratcherTen,
];

const MintSelection = ({
    amount,
    onAmountSelected,
    mintContent = EMintType.DEFAULT,
}: IMintSelectionProps): ReactElement => {
    const [currentImage, setCurrentImage] = useState(mintStateroom);
    const isScratcher = mintContent === EMintType.SCRATCHER;
    const scratcherStackIndex = amount - 1;
    const buttonLabel =
        amount > 1 && !isScratcher
            ? "Certificates"
            : amount > 1 && isScratcher
            ? "Scratchers"
            : amount === 1 && !isScratcher
            ? "Certificate"
            : "Scratcher";

    useEffect(() => {
        if (isScratcher) {
            return;
        }

        const changeImage = () => {
            setCurrentImage((prevValue) => (prevValue === mintPlanet ? mintStateroom : mintPlanet));
        };

        const interval = setInterval(changeImage, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleIncrease = () => {
        if (amount === MAXIMUM_MINT) {
            return;
        }

        onAmountSelected(amount + 1);
    };

    const handleDecrease = () => {
        if (amount === MINIMUM_MINT) {
            return;
        }

        onAmountSelected(amount - 1);
    };

    return (
        <div className={styles.mintSelection}>
            <div className={styles.deed}>
                {!isScratcher && (
                    <div className={styles.images}>
                        <img className={styles.topImage} src={currentImage} alt="" />
                        <div className={styles.stack}>
                            {Array.from({ length: amount - 1 }, (_, index) => index).map((value) => (
                                <img key={value} src={mintStateroom} className={styles[`c${value + 1}`]} alt="" />
                            ))}
                        </div>
                    </div>
                )}
                {isScratcher && (
                    <img className={styles.scratcherImage} alt="scratcher" src={scratcherStack[scratcherStackIndex]} />
                )}
                <div className={`${styles.buttonBox} ${isScratcher ? styles.isScratcher : ""}`}>
                    <button onClick={handleDecrease} className={amount > MINIMUM_MINT ? styles.active : ""}>
                        -
                    </button>
                    <span>{amount}</span>
                    <button onClick={handleIncrease} className={amount < MAXIMUM_MINT ? styles.active : ""}>
                        +
                    </button>
                </div>
                <div className={`${styles.buttonLabel} ${isScratcher ? styles.isScratcher : ""}`}>{buttonLabel}</div>
            </div>
        </div>
    );
};

export default MintSelection;
