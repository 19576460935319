import { ITimelineCard } from "@interfaces/Timeline/TimelineCard";
import TimelineCard from "../TimelineCard";
import Title from "@components/Title";
import styles from "./PressCard.module.scss";
import { HeadingType } from "@components/Title/Title";
import ButtonTimeline from "../ButtonTimeline";
import { ReactElement } from "react";

const PressCard = ({ item }: ITimelineCard): ReactElement => (
    <TimelineCard key={item.id} title={item.title} category={item.category} date={item.date}>
        <div className={styles.press__container}>
            <img src={item.image} alt="Rocket Launch" />
            <div className={styles.text__button__container}>
                <Title heading={HeadingType.h4}>{item.subtitle}</Title>
                {item.description && <p>{item.description}</p>}
                {item.link && (
                    <div className={styles.press__button}>
                        <ButtonTimeline to={item.link} target="_blank">
                            READ FULL ARTICLE
                        </ButtonTimeline>
                    </div>
                )}
            </div>
        </div>
    </TimelineCard>
);

export default PressCard;
