import patch_8_8 from "@assets/images/patches/patches_tom.png";
import patch_first_10 from "@assets/images/patches/patches_first.png";
import patch_mars from "@assets/images/patches/patches_mars.png";
import patch_stl from "@assets/images/patches/patches_stl.png";
import patch_crew from "@assets/images/patches/patches_crew.png";
import patch_mod from "@assets/images/patches/patches_mod.png";
import { PATCHES_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";

const DATA_PATCHES = [
    {
        id: 1,
        name: "Test Flight Crew Patch",
        image: patch_crew,
        link: PATCHES_COLLECTIONS_OPENSEA_URL,
        type: CollectionCategory.patches,
        customClass: "patches__item",
    },
    {
        id: 2,
        name: "8/8 Patch",
        image: patch_8_8,
        link: PATCHES_COLLECTIONS_OPENSEA_URL,
        type: CollectionCategory.patches,
        customClass: "patches__item",
    },
    {
        id: 3,
        name: "First Ten Patch",
        image: patch_first_10,
        link: PATCHES_COLLECTIONS_OPENSEA_URL,
        type: CollectionCategory.patches,
        customClass: "patches__item",
    },
    {
        id: 4,
        name: "Mod Patch",
        image: patch_mod,
        link: "https://opensea.io/collection/modpatch",
        type: CollectionCategory.patches,
        customClass: "patches__item",
    },
    {
        id: 5,
        name: "STL Patch",
        image: patch_stl,
        link: PATCHES_COLLECTIONS_OPENSEA_URL,
        type: CollectionCategory.patches,
        customClass: "patches__item",
    },
    {
        id: 6,
        name: "Mars Rock Mining Mission Patch",
        image: patch_mars,
        link: PATCHES_COLLECTIONS_OPENSEA_URL,
        type: CollectionCategory.patches,
        customClass: "patches__item",
    },
];

export default DATA_PATCHES;
