import { ReactElement } from "react";
import styles from "./BadgeUsed.module.scss";
import galaxy from "@assets/images/planetMint/galaxySeparated.png";
import Link, { LinkVariant } from "@components/Link";
import { PLANET_MINT_ROCKS } from "@routes/routes";
import Container from "@components/Container";
import { MARS_ROCK_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";

const BadgeUsed = (): ReactElement => {
    return (
        <div className={styles.badgeUsed}>
            <Container>
                <div className={styles.galaxy}>
                    <img src={galaxy} alt="" />
                </div>
                <p>
                    You minted the maxium amount of Worlds and Apartments for you ID. You can mint more Deeds by burning
                    Mars Rocks.
                </p>
                <div className={styles.buttonContainer}>
                    <Link to={PLANET_MINT_ROCKS} variant={LinkVariant.Button}>
                        Mint with Mars Rock
                    </Link>
                    <Link to={MARS_ROCK_COLLECTIONS_OPENSEA_URL} variant={LinkVariant.Button}>
                        Buy Mars Rocks
                    </Link>
                </div>
            </Container>
        </div>
    );
};

export default BadgeUsed;
