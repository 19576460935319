import { METADATA } from "@constants/apiEndpoints";
import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";
import { METADATA_API } from "@constants/env";
import { IDataCollection } from "@interfaces/Collections/DataCollection";
import { apiMetadataResponseToCollection, getMetadataMintedFilters } from "@scripts/MetadataHelper";
import { IMetadataMinted } from "@interfaces/Metadata/MetadataMinted";
import { INITIAL_DEFAULT_LIMIT } from "@constants/pagination";

const getMetadataMinted = (
    contractSlug: string,
    page: number,
    searchByName?: string
): AxiosPromise<IDataCollection[]> =>
    apiInstance({
        baseURL: METADATA_API,
        url: `${METADATA}${contractSlug}`,
        params: {
            limit: INITIAL_DEFAULT_LIMIT,
            offset: page * INITIAL_DEFAULT_LIMIT,
            sort: "config.tokenId",
            order: "asc",
            ...getMetadataMintedFilters(contractSlug, searchByName),
        },
        transformResponse: [
            (data) => {
                const result = JSON.parse(data);

                const planetsList: IDataCollection[] = result.map((apiResponse: IMetadataMinted) =>
                    apiMetadataResponseToCollection(apiResponse)
                );

                return planetsList;
            },
        ],
    });

export default getMetadataMinted;
