import { METADATA } from "@constants/apiEndpoints";
import { METADATA_API } from "@constants/env";
import useApiCall from "@hooks/shared/useApiCall";
import { IMetadataDetail } from "@interfaces/Metadata/MetadataDetail";
import { apiMetadataResponseToDetail } from "@scripts/MetadataHelper";
import { Props } from "use-axios-client/bin/useBaseAxios";

const useGetMetadata = (contractSlug: string, tokenId: string): Props<IMetadataDetail> =>
    useApiCall<IMetadataDetail>({
        baseURL: METADATA_API,
        url: `${METADATA}${contractSlug}/${tokenId}`,
        transformResponse: [
            (data) => {
                return apiMetadataResponseToDetail(JSON.parse(data));
            },
        ],
    });

export default useGetMetadata;
