import Container from "@components/Container";
import Loader from "@components/Loader";
import PlanetMintNotAuthorized from "@components/PlanetMintNotAuthorized";
import { PLANET_MINT_API } from "@constants/env";
import { SessionContext } from "@context/SessionContext";
import usePlanetsMintRead from "@hooks/contracts/PlanetMinter/usePlanetsMintRead";
import usePlanetsSignatureMint, { MintParameters } from "@hooks/contracts/PlanetMinter/usePlanetsSignatureMint";
import MintingInProgress from "@pages/MintingInProgress";
import { HOME, PLANET_MINT_FINAL_FRONTIER, PLANET_MINT_SUCCESS } from "@routes/routes";
import { IS_MINT_WITH_ROCKS_OPEN } from "@scripts/DateHelper";
import axios from "axios";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, generatePath } from "react-router-dom";
import styles from "./PlanetMintWithIdCard.module.scss";
import BadgeUsed from "./components/BadgeUsed";
import Mint from "./components/Mint";

const PlanetMintWithIdCard = (): ReactElement => {
    const { isBadgeUsed: checkIsBadgeUsed, isInitialized: isUsePlanetsMintReadInitialized } = usePlanetsMintRead();
    const { txId, isMintingInProgress, isMintingSuccessful, mint } = usePlanetsSignatureMint();
    const { isInitialized, walletAddress } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [mintParameters, setMintParameters] = useState<MintParameters | undefined>(undefined);
    const [isBadgeUsed, setIsBadgeUsed] = useState(false);

    useEffect(() => {
        if (!walletAddress || !isUsePlanetsMintReadInitialized) {
            return;
        }

        axios
            .get(`/tokens/${walletAddress}`, { baseURL: PLANET_MINT_API })
            .then(async ({ data }) => {
                const isBadgeUsed = await checkIsBadgeUsed(data.tokenId);
                setIsBadgeUsed(isBadgeUsed);
                setMintParameters(data);
                setIsAuthorized(true);
            })
            .catch(() => {
                setIsBadgeUsed(false);
                setMintParameters(undefined);
                setIsAuthorized(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [walletAddress, isUsePlanetsMintReadInitialized]);

    const handleMint = () => {
        if (!mintParameters) {
            return;
        }

        mint(mintParameters);
    };

    if (!IS_MINT_WITH_ROCKS_OPEN) {
        return <Redirect to={HOME} />;
    }

    if (isBadgeUsed) {
        return <BadgeUsed />;
    }

    if (isMintingSuccessful) {
        return <Redirect to={generatePath(PLANET_MINT_SUCCESS, { txId })} push />;
    }

    if (isInitialized && !walletAddress) {
        return <Redirect to={PLANET_MINT_FINAL_FRONTIER} />;
    }

    if (!isAuthorized && !isLoading) {
        return (
            <PlanetMintNotAuthorized>
                <p>
                    In order to gain access to the Final Frontier
                    <br />
                    you need to apply for an ID at our Indoctrination Office
                </p>
            </PlanetMintNotAuthorized>
        );
    }

    if (!isInitialized || isLoading || !mintParameters) {
        return <Loader />;
    }

    if (isMintingInProgress) {
        return <MintingInProgress txId={txId} />;
    }

    return (
        <div className={styles.planetMintParis}>
            <Container>
                <Mint
                    planetIds={mintParameters.planetIds}
                    spaceStationIds={mintParameters.spaceStationIds}
                    onMint={handleMint}
                />
            </Container>
        </div>
    );
};

export default PlanetMintWithIdCard;
