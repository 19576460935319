import Button from "@components/Button";
import { IStuff } from "@interfaces/Stuff/Stuff";
import { STUFF_DETAIL } from "@routes/routes";
import { ReactElement } from "react";
import { Link, generatePath } from "react-router-dom";
import slugify from "slugify";
import styles from "./StuffContainer.module.scss";

export interface IStuffContainer {
    stuffs: IStuff[];
}

const StuffContainer = ({ stuffs }: IStuffContainer): ReactElement => (
    <div className={styles.stuffContainer}>
        <h3>"STUFF"</h3>
        <div className={styles.stuffDetails}>
            {stuffs.map(({ id, imageUrl, title }, index) => (
                <div className={styles.stuffItem} key={index}>
                    <img src={imageUrl} alt="collection" className={styles.stuffImage} />
                    <div className={styles.stuffItem__bottom}>
                        <p>{title}</p>
                        <Link
                            to={{
                                pathname: generatePath(STUFF_DETAIL, {
                                    id,
                                    slug: slugify(title, { lower: true }),
                                }),
                                state: { isStuff: true },
                            }}
                        >
                            <Button className={styles.detailsButton}>More Details</Button>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default StuffContainer;
