import { NEWSLETTER_SUBSCRIPTION } from "@constants/apiEndpoints";
import { API_BASE_URL } from "@constants/env";
import axios from "axios";
import { ChangeEvent, FormEvent, useState } from "react";
import styles from "./Subscribe.module.scss";

const Subscribe = (): JSX.Element => {
    const NOT_SENT = 0;
    const SENT = 1;
    const ERROR = 2;
    const [email, setEmail] = useState("");
    const [isSent, setIsSent] = useState(NOT_SENT);

    const buttonStyle = {
        0: styles.notSent,
        1: styles.sent,
        2: styles.error,
    };

    const sendMailToBackend = (e: FormEvent) => {
        e.preventDefault();
        if (isSent !== 1) {
            axios
                .post(`${API_BASE_URL}${NEWSLETTER_SUBSCRIPTION}`, {
                    email: email,
                })
                .then(({ status }) => {
                    status === 201 && setIsSent(SENT);
                })
                .catch(() => {
                    setIsSent(ERROR);
                });
        }
    };

    return (
        <form onSubmit={sendMailToBackend} className={styles.subscribeContainer}>
            <input
                type="text"
                aria-label="subscribe"
                aria-describedby="subscribe-input"
                placeholder="Email address"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value), setIsSent(NOT_SENT);
                }}
            />
            <button className={buttonStyle[isSent]} type="submit" />
        </form>
    );
};

export default Subscribe;
