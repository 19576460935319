import styles from "./RocketNotMinted.module.scss";
import RocketImage from "@components/RocketImage";
import { IPFS_ROCKET_UI } from "@constants/env";

interface IRocketNotMintedProps {
    isPayloadEligible: boolean;
}

const RocketNotMinted = ({ isPayloadEligible }: IRocketNotMintedProps): JSX.Element => (
    <div className={isPayloadEligible ? styles.payloadContainer : styles.nonPayloadContainer}>
        <RocketImage id={""} image={`${IPFS_ROCKET_UI}outline.png`} />
    </div>
);

export default RocketNotMinted;
