import { PLANETS_MINT_CONTRACT_ADDRESS, STUFF_DIGITAL_MINTER_CONTRACT_ADDRESS } from "@constants/env";
import abiDigitalScratchers from "@contracts/DigitalScratchersMintAbi";
import abi from "@contracts/PlanetsMintAbi";
import { useWeb3Context } from "@nftstudios/web3-provider";
import { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";
import utils from "web3-utils";

interface ReturnTypes {
    isInitialized: boolean;
    calculate: (amount: number) => Promise<string>;
    calculateWithRocks: (amount: number) => Promise<string>;
    calcualteMintPriceForScratcher: (amount: number) => Promise<string>;
}

const useCalculateMintPrice = (): ReturnTypes => {
    const { web3 } = useWeb3Context();
    const [isInitialized, setIsInitialized] = useState(false);
    const [mintContract, setMintContract] = useState<Contract | undefined>(undefined);

    useEffect(() => {
        if (!web3) {
            return;
        }

        const mintContract = new web3.eth.Contract(abi, PLANETS_MINT_CONTRACT_ADDRESS);
        setMintContract(mintContract);
        setIsInitialized(true);
    }, [web3]);

    const calculate = async (amount: number): Promise<string> => {
        if (!mintContract) {
            return "0";
        }

        const unitPrice = await mintContract.methods.price().call();

        const totalPrice = utils.toBN(amount).mul(utils.toBN(unitPrice));

        return totalPrice.toString();
    };

    const calculateWithRocks = async (amount: number): Promise<string> => {
        if (!mintContract) {
            return "0";
        }

        const unitPrice = await mintContract.methods.priceWithRocks().call();

        const totalPrice = utils.toBN(amount).mul(utils.toBN(unitPrice));

        return totalPrice.toString();
    };

    const calcualteMintPriceForScratcher = async (amount: number): Promise<string> => {
        if (!web3) {
            return "0";
        }

        const contract = new web3.eth.Contract(abiDigitalScratchers, STUFF_DIGITAL_MINTER_CONTRACT_ADDRESS);

        const unitPrice = await contract.methods.price().call();

        const totalPrice = utils.toBN(amount).mul(utils.toBN(unitPrice));

        return totalPrice.toString();
    };

    return { isInitialized, calculate, calculateWithRocks, calcualteMintPriceForScratcher };
};

export default useCalculateMintPrice;
