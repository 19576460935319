import { apartmentType } from "@customTypes/Starmap/ApartmentType";
import { IZoneApartment } from "@interfaces/Starmap/Apartment";

const STATION_APARTMENTS: IZoneApartment[] = [
    {
        id: 1,
        zoneId: 1,
        zoneName: "Mythos",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_mythos.png",
    },
    {
        id: 2,
        zoneId: 1,
        zoneName: "Mythos",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_mythos.png",
    },
    {
        id: 3,
        zoneId: 1,
        zoneName: "Mythos",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_mythos.png",
    },
    {
        id: 4,
        zoneId: 1,
        zoneName: "Mythos",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_mythos.png",
    },
    {
        id: 5,
        zoneId: 1,
        zoneName: "Mythos",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_mythos.png",
    },
    {
        id: 6,
        zoneId: 1,
        zoneName: "Mythos",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_mythos.png",
    },
    //zone2
    {
        id: 7,
        zoneId: 2,
        zoneName: "Junkyard",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_junkyard.png",
    },
    {
        id: 8,
        zoneId: 2,
        zoneName: "Junkyard",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_junkyard.png",
    },
    {
        id: 9,
        zoneId: 2,
        zoneName: "Junkyard",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_junkyard.png",
    },
    {
        id: 10,
        zoneId: 2,
        zoneName: "Junkyard",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_junkyard.png",
    },
    {
        id: 11,
        zoneId: 2,
        zoneName: "Junkyard",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_junkyard.png",
    },
    {
        id: 12,
        zoneId: 2,
        zoneName: "Junkyard",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_junkyard.png",
    },
    //zone 3
    {
        id: 13,
        zoneId: 3,
        zoneName: "Element",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_elements.png",
    },
    {
        id: 14,
        zoneId: 3,
        zoneName: "Element",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_elements.png",
    },
    {
        id: 15,
        zoneId: 3,
        zoneName: "Element",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_elements.png",
    },
    {
        id: 16,
        zoneId: 3,
        zoneName: "Element",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_elements.png",
    },
    {
        id: 17,
        zoneId: 3,
        zoneName: "Element",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_elements.png",
    },
    {
        id: 18,
        zoneId: 3,
        zoneName: "Element",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_elements.png",
    },
    //zone 4
    {
        id: 19,
        zoneId: 4,
        zoneName: "Food",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_food.png",
    },
    {
        id: 20,
        zoneId: 4,
        zoneName: "Food",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_food.png",
    },
    {
        id: 21,
        zoneId: 4,
        zoneName: "Food",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_food.png",
    },
    {
        id: 22,
        zoneId: 4,
        zoneName: "Food",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_food.png",
    },
    {
        id: 23,
        zoneId: 4,
        zoneName: "Food",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_food.png",
    },
    {
        id: 24,
        zoneId: 4,
        zoneName: "Food",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_food.png",
    },
    //zone 5
    {
        id: 25,
        zoneId: 5,
        zoneName: "Discovery",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_discovery.png",
    },
    {
        id: 26,
        zoneId: 5,
        zoneName: "Discovery",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_discovery.png",
    },
    {
        id: 27,
        zoneId: 5,
        zoneName: "Discovery",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_discovery.png",
    },
    {
        id: 28,
        zoneId: 5,
        zoneName: "Discovery",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_discovery.png",
    },
    {
        id: 29,
        zoneId: 5,
        zoneName: "Discovery",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_discovery.png",
    },
    {
        id: 30,
        zoneId: 5,
        zoneName: "Discovery",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_discovery.png",
    },
    //zone 6
    {
        id: 31,
        zoneId: 6,
        zoneName: "Faith",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_faith.png",
    },
    {
        id: 32,
        zoneId: 6,
        zoneName: "Faith",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_faith.png",
    },
    {
        id: 33,
        zoneId: 6,
        zoneName: "Faith",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_faith.png",
    },
    {
        id: 34,
        zoneId: 6,
        zoneName: "Faith",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_faith.png",
    },
    {
        id: 35,
        zoneId: 6,
        zoneName: "Faith",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_faith.png",
    },
    {
        id: 36,
        zoneId: 6,
        zoneName: "Faith",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_faith.png",
    },
    //zone 7
    {
        id: 37,
        zoneId: 7,
        zoneName: "Feelings",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_feelings.png",
    },
    {
        id: 38,
        zoneId: 7,
        zoneName: "Feelings",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_feelings.png",
    },
    {
        id: 39,
        zoneId: 7,
        zoneName: "Feelings",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_feelings.png",
    },
    {
        id: 40,
        zoneId: 7,
        zoneName: "Feelings",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_feelings.png",
    },
    {
        id: 41,
        zoneId: 7,
        zoneName: "Feelings",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_feelings.png",
    },
    {
        id: 42,
        zoneId: 7,
        zoneName: "Feelings",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_feelings.png",
    },
    //zone 8
    {
        id: 43,
        zoneId: 8,
        zoneName: "Fauna",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_corb_fauna.png",
    },
    {
        id: 44,
        zoneId: 8,
        zoneName: "Fauna",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_plywood_fauna.png",
    },
    {
        id: 45,
        zoneId: 8,
        zoneName: "Fauna",
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_one_bedroom_steel_fauna.png",
    },
    {
        id: 46,
        zoneId: 8,
        zoneName: "Fauna",
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_corb_fauna.png",
    },
    {
        id: 47,
        zoneId: 8,
        zoneName: "Fauna",
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_plywood_fauna.png",
    },
    {
        id: 48,
        zoneId: 8,
        zoneName: "Fauna",
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: "https://storage.googleapis.com/nftstudios/tom-sachs/rocket-factory/rooms/space_station_studio_steel_fauna.png",
    },
];

export const GET_APARTMENTS_BY_ZONE = (zoneId: number): IZoneApartment[] =>
    STATION_APARTMENTS.filter((c) => c.zoneId === zoneId);
