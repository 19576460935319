import styles from "./Loader.module.scss";
import loadingGif from "@assets/images/utils/loading_gif.gif";

const Loader = (): JSX.Element => (
    <div className={styles.loader}>
        <img src={loadingGif} />
    </div>
);

export default Loader;
