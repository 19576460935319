import styles from "./RightColumn.module.scss";

interface IRightColumn {
    title: string;
    children: React.ReactNode;
}

const RightColumn = ({ title, children }: IRightColumn): JSX.Element => (
    <div className={styles.rightColumn__container}>
        <div className={styles.body}>
            <h2>{title}</h2>
            {children}
        </div>
    </div>
);

export default RightColumn;
