import { MESSAGE_TO_SIGN, ROCKETS } from "@constants/apiEndpoints";
import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";

const getMessageToSign = (): AxiosPromise<string> =>
    apiInstance({
        url: MESSAGE_TO_SIGN,
        transformResponse: [
            (data) => {
                return JSON.parse(data).messageToSign;
            },
        ],
    });

export default getMessageToSign;
