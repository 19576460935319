import { ReactNode } from "react";
import styles from "./Subtitle.module.scss";

interface ISubtitleParams {
    children: ReactNode;
}

const Subtitle = ({ children }: ISubtitleParams): JSX.Element => <div className={styles.subtitle}>{children}</div>;

export default Subtitle;
