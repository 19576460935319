import Container from "@components/Container";
import InfiniteScroll from "react-infinite-scroll-component";
import RocketList from "./components/RocketList";
import styles from "./RocketCollection.module.scss";
import Loader from "@components/Loader";
import useGetRockets from "@hooks/Rocket/useGetRockets";
import NoItemCollection from "../NoItemCollection";
import BoxFilter from "@components/BoxFilter";

const RocketCollection = (): JSX.Element => {
    const { isLoading, rockets, handleGetRockets, hasMore, handleSearchRockets } = useGetRockets();

    return (
        <>
            <BoxFilter onSubmit={handleSearchRockets} />
            <Container>
                <div className={styles.container}>
                    {rockets && (
                        <InfiniteScroll
                            dataLength={rockets.length}
                            next={handleGetRockets}
                            hasMore={hasMore}
                            loader={<Loader />}
                        >
                            <div>
                                <RocketList rockets={rockets} showPlaceHolders={!hasMore} />
                            </div>
                        </InfiniteScroll>
                    )}

                    {!isLoading && rockets?.length === 0 && <NoItemCollection message="No rockets found" />}
                </div>
            </Container>
        </>
    );
};

export default RocketCollection;
