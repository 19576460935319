import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import GoBackMobile from "@components/Starmap/GoBackMobile";
import LeftColumn from "@components/Starmap/LeftColumn";
import PlanetItemList from "@components/Starmap/PlanetItemList";
import Title from "@components/Starmap/Title";
import IPlanet from "@interfaces/Starmap/Planet";
import { Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { LEGEND_LIST_DETAILED } from "../../../starmap.legends.data";
import styles from "./System.module.scss";
import FirstOrder from "./components/FirstOrder";
import PlanetList from "./components/PlanetList";
import SecondOrder from "./components/SecondOrder";
import Suns from "./components/Suns";
import UninhabitablePlanetsList from "./components/UninhabitablePlanetsList";

const LegendMobile = lazy(() => import("@components/Starmap/LegendMobile"));

interface ISystemProps {
    systemType: string;
    stars: IPlanet[] | undefined;
}

const System = ({ systemType, stars }: ISystemProps): JSX.Element => {
    const { systemName } = useParams<{ systemName: string }>();

    const STAR = stars && stars[0];
    const SUNS = [
        { id: 0, name: STAR?.sun_1 ?? "" },
        { id: 1, name: STAR?.sun_2 ?? "" },
        { id: 2, name: STAR?.sun_3 ?? "" },
    ];

    const FIRST_ORDER = stars?.filter(({ planetary_order }) => planetary_order.includes("1"));
    const SECOND_ORDER = stars?.filter(({ planetary_order }) => planetary_order.includes("2"));

    return (
        <GalaxyBackground>
            <GoBackMobile />

            <Title title={`SYSTEM: ${systemName}`} description="Click to discover Planetary Certificate Token" />

            <div className={styles.body}>
                <LeftColumn />

                <div className={styles.stars__container}>
                    <Suns suns={SUNS} systemType={systemType} />
                    <FirstOrder stars={FIRST_ORDER} />
                    <SecondOrder stars={SECOND_ORDER} />
                </div>

                <div className={styles.rightInfo}>
                    <PlanetList stars={stars} />
                    <UninhabitablePlanetsList suns={SUNS} />
                </div>
            </div>

            <Suspense fallback={<p>loading...</p>}>
                <LegendMobile legend={LEGEND_LIST_DETAILED} />
            </Suspense>

            <PlanetItemList planets={stars} />
        </GalaxyBackground>
    );
};

export default System;
