import { ChangeEvent, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import styles from "./Combobox.module.scss";

interface ICombobox {
    children: ReactNode;
    title: string;
    handleComboboxOption: (option: number) => void;
}

const Combobox = ({ children, title, handleComboboxOption }: ICombobox): JSX.Element => (
    <div className={styles.combobox}>
        <div className={styles.combobox_icon}>
            <FontAwesomeIcon icon={faChevronDown} className={styles.arrow} />
        </div>
        <select
            id="zonesComboBox"
            className={styles.combobox_select}
            defaultValue="title"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleComboboxOption(parseInt(e.target.value))}
        >
            <option value="title" disabled>
                {title}
            </option>

            {children}
        </select>
    </div>
);

export default Combobox;
