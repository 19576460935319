export enum CollectionCategory {
    planets = "Planets",
    apartments = "Apartments",
    marsRocks = "Mars Rocks",
    rockets = "Rockets",
    components = "Components",
    tickets = "Tickets",
    patches = "Patches",
    stuff = "Stuff",
}
