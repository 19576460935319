import { ReactElement } from "react";
import notAuthorized from "@assets/images/planetMint/notAuthorized.png";
import planetaryProtectionLogo from "@assets/images/planetMint/planetaryProtectionLogo.png";
import styles from "./PlanetMintNotAuthorized.module.scss";

interface Props {
    children: ReactElement;
}

const PlanetMintNotAuthorized = ({ children }: Props): ReactElement => {
    return (
        <div className={styles.notAuthorized}>
            <div>
                <div className={styles.top}>
                    <img src={notAuthorized} alt="STOP. You are not authorized to enter this page." />
                </div>
                {children}
                <hr />
                <div className={styles.customsAndPlanetaryProtection}>
                    <img src={planetaryProtectionLogo} alt="Planetary Protection Logo" />
                    <p>
                        Rocket Factory
                        <br />
                        Customs and Planetary
                        <br />
                        Protection
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PlanetMintNotAuthorized;
