import mintPlanet from "@assets/images/planetMint/mintPlanet.png";
import mintStateroom from "@assets/images/planetMint/mintStateroom.png";
import ConfirmAndMint from "@components/ConfirmAndMint";
import { PLANET_NAMES, SPACE_STATION_NAMES } from "@constants/planets";
import useCalculateMintPrice from "@hooks/contracts/PlanetMinter/useCalculateMintPrice";
import { ReactElement, useEffect, useState } from "react";
import utils from "web3-utils";
import styles from "./Mint.module.scss";

interface props {
    planetIds: number[];
    spaceStationIds: number[];
    onMint: () => void;
}

const Mint = ({ planetIds, spaceStationIds, onMint }: props): ReactElement => {
    const { calculate, isInitialized } = useCalculateMintPrice();
    const [subtotal, setSubtotal] = useState("0");

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        calculate(planetIds.length + spaceStationIds.length).then(setSubtotal);
    }, [isInitialized, JSON.stringify(planetIds), JSON.stringify(spaceStationIds)]);

    return (
        <div className={styles.mint}>
            <h1>Welcome Citizen</h1>
            <p>
                You have been authenticated as a TSRF ID holder. Please Proceed to Mint your reserved Final Frontier
                Certificates. If you choose not to mint your reserved Certificates, you ID designation and status will
                be updated following the mint period.
            </p>
            <div className={styles.tokenContainer}>
                {planetIds.map((id) => (
                    <div key={`planet-${id}`}>
                        <h3>PLANETARY CERTIFICATE</h3>
                        <img src={mintPlanet} alt="" />
                        <p>{PLANET_NAMES[id]}</p>
                    </div>
                ))}

                {spaceStationIds.map((id) => (
                    <div key={`space-station-${id}`}>
                        <h3>SPACESTATION CERTIFICATE</h3>
                        <img src={mintStateroom} alt="" />
                        <p>{SPACE_STATION_NAMES[id]}</p>
                    </div>
                ))}
            </div>
            <ConfirmAndMint subtotal={subtotal} onSubmit={onMint}>
                I agree to pay the mint price of{" "}
                {new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 5 }).format(
                    parseFloat(utils.fromWei(subtotal, "ether"))
                )}{" "}
                ETH, plus any gas fees charged by the Ethereum network. A randomly picked Final Frontier Certificate NFT
                evidencing access to the associated World or Space Station Apartment NFT will be minted (as an ERC-721
                token)
            </ConfirmAndMint>
        </div>
    );
};

export default Mint;
