import ButtonCentered from "@components/ButtonCentered";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import { ROCKETS_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import styles from "./PhaseIHero.module.scss";

const PhaseIHero = (): JSX.Element => {
    return (
        <div className={styles.phaseIHero__container}>
            <div className={styles.textContainer}>
                <Title heading={HeadingType.h2}>PHASE I - THE FACTORY</Title>
                <p>
                    Tom Sachs: Rocket Factory is a trans-dimensional manufacturing plant. We began our collection with a
                    free to mint Test Flight Crew Patch.
                </p>
                <p>
                    On August 1st, 2021 Rocket Components were released. Components could be Com-burned into Rocket
                    NFTs. Rocket Holders could elect to have a physical counterpart of their Rocket assembled and
                    launched. The Final Physical Rocket Launch took place 1 year later in August, 2022.
                </p>
                <div className={styles.buttonContainer}>
                    <ButtonCentered to={ROCKETS_COLLECTIONS_OPENSEA_URL}>GET A ROCKET</ButtonCentered>
                </div>
            </div>
            <div className={styles.videoContainer}>
                <iframe
                    src="https://www.youtube.com/embed/fNzlU9tPM4o"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default PhaseIHero;
