import { ReactElement } from "react";
import Link, { LinkVariant } from "@components/Link";
import Title, { HeadingType } from "@components/Title/Title";
import styles from "./PlanetMintSoldOut.module.scss";
import background from "@assets/images/planetMint/background.jpg";
import { PLANETS_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";

const PlanetMintSoldOut = (): ReactElement => (
    <div className={styles.PlanetMintSoldOut}>
        <Title heading={HeadingType.h1}>SOLD OUT</Title>

        <img src={background} alt="sold out" />

        <Link to={PLANETS_COLLECTIONS_OPENSEA_URL} variant={LinkVariant.Button}>
            VIEW COLLECTION
        </Link>
    </div>
);

export default PlanetMintSoldOut;
