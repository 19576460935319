import styles from "./Attribute.module.scss";

interface IAttributeProps {
    attribute: string;
    subtitle: string;
    data?: string;
}

const Attribute = ({ attribute, subtitle, data }: IAttributeProps): JSX.Element => (
    <div className={styles.container}>
        <h5>{attribute}</h5>
        <span>{subtitle}</span>
        {data && <span>{data}</span>}
    </div>
);

export default Attribute;
