import Combobox from "@components/Starmap/Combobox";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import GoBackMobile from "@components/Starmap/GoBackMobile";
import LeftColumn from "@components/Starmap/LeftColumn";
import LegendMobile from "@components/Starmap/LegendMobile";
import RightColumn from "@components/Starmap/RightColumn";
import Title from "@components/Starmap/Title";
import useZoneSelect from "@hooks/Starmap/useZoneSelect";
import { generatePath, Link } from "react-router-dom";

import GalaxyZones from "@components/Starmap/GalaxyZones";
import useGetStarmapZones from "@hooks/Starmap/useGetStarmapZones";
import { STARMAP_ZONE } from "@routes/routes";
import { LEGEND_LIST_WIDE } from "../starmap.legends.data";
import styles from "./Galaxy.module.scss";

const Galaxy = (): JSX.Element => {
    const zones = useGetStarmapZones();

    const {
        zoneSelected,
        comboboxOptions,
        handleHover,
        handleComboboxOption,
        handleChangeComboboxOption,
        handleChangeLinkColor,
    } = useZoneSelect({
        zones,
    });

    return (
        <GalaxyBackground>
            <GoBackMobile />

            <Title title="ZONE OVERVIEW" description="Click to explore <br /> Galactic zones" />

            <Combobox title="Select galactic zone" handleComboboxOption={handleComboboxOption}>
                {comboboxOptions?.map(({ id, name }) => (
                    <option key={id} value={id}>
                        Zone {id}: {name}
                    </option>
                ))}
            </Combobox>

            <div className={styles.body}>
                <LeftColumn />

                <GalaxyZones
                    zones={zones}
                    handleHover={handleHover}
                    handleChangeLinkColor={handleChangeLinkColor}
                    handleChangeComboboxOption={handleChangeComboboxOption}
                />

                <RightColumn title="ZONE">
                    <ul>
                        {zones?.map(({ id, name, image_hover, image_default }) => (
                            <li key={`zoneLink${id}`}>
                                <Link
                                    id={`zoneLink${id}`}
                                    to={generatePath(STARMAP_ZONE, { zoneId: id, zoneName: name })}
                                    onMouseOver={() => handleHover(id, image_hover)}
                                    onMouseLeave={() => handleHover(id, image_default)}
                                >
                                    Zone {id}: {name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </RightColumn>
            </div>

            <div className={styles.information__module_mobile}>
                {zoneSelected && (
                    <Link
                        to={generatePath(STARMAP_ZONE, { zoneId: zoneSelected.id, zoneName: zoneSelected.name })}
                        className={styles.goto__zone}
                    >
                        EXPLORE ZONE
                    </Link>
                )}
                <LegendMobile legend={LEGEND_LIST_WIDE} />
            </div>
        </GalaxyBackground>
    );
};
export default Galaxy;
