import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";
import { METADATA_API } from "@constants/env";
import { METADATA } from "@constants/apiEndpoints";
import { IMetadata } from "@interfaces/Metadata/Metadata";

const getTokenMetadata = (contractSlug: string, tokenId: string): AxiosPromise<IMetadata> =>
    apiInstance({
        baseURL: METADATA_API,
        url: `${METADATA}${contractSlug}/${tokenId}`,
    });

export default getTokenMetadata;
