import { LAUNCH_DATES } from "@constants/apiEndpoints";
import useApiCall from "@hooks/shared/useApiCall";
import { ILaunchDate } from "@interfaces/Launch/LaunchDate";
import { Props } from "use-axios-client/bin/useBaseAxios";

const useGetLaunchByDate = (date: number): Props<ILaunchDate> =>
    useApiCall<ILaunchDate>({
        url: LAUNCH_DATES + date,
    });

export default useGetLaunchByDate;
