import { Link } from "react-router-dom";
import { ROCKET_DETAIL } from "@routes/routes";
import RocketImage from "@components/RocketImage";
import { IRocket } from "@interfaces/Rocket/Rocket";
import ButtonCentered from "@components/ButtonCentered";
import { ROCKETS_COLLECTIONS_OPENSEA_URL } from "@constants/externalUrls";
import TemplateBox from "../TemplateBox";
import { getLinkCollection } from "@scripts/CollectionHelper";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";

interface IRocketsParams {
    items: IRocket[];
}

const Rockets = ({ items }: IRocketsParams): JSX.Element => (
    <TemplateBox title={EHangarTitleType.ROCKETS}>
        {items.map(({ id, slug, image }) => (
            <Link to={getLinkCollection(ROCKET_DETAIL, id.toString(), slug)} key={id}>
                <RocketImage id={id} image={image} />
            </Link>
        ))}

        {items.length === 0 && (
            <ButtonCentered to={ROCKETS_COLLECTIONS_OPENSEA_URL} rel="noopener noreferrer" target="_blank">
                BUY ROCKETS
            </ButtonCentered>
        )}
    </TemplateBox>
);

export default Rockets;
