const ALL_QUESTIONS = [
    {
        title: "What is Tom Sachs: Rocket Factory?",
        paragraph: `<p>Tom Sachs: Rocket Factory is a trans-dimensional manufacturing plant. A way to reshape how art is produced and distributed. Everything generated in the Rocket Factory are artworks and use NFTs to build new NFTs.</p>`,
    },
    {
        title: "What are the qualities of a Rocket?",
        paragraph: `<p>A Rocket is composed of three Component NFTs: a Nose Cone, a Body, and a Tail Assembly. Our Rocket Factory has enough inventory to produce 1,000 Completed Rockets (1,000 Nose Cones, 1,000 Bodies, and 1,000 Tail Assemblies). All 3,000 Components are branded with 1 of 30 unique Brands (with differing rarity). Each Component NFT is branded with 1 of 30 unique brands (with differing rarity). 100 Component NFTs will be minted with an additional Sticker graphic. There are 10 Sticker types (with differing rarity). A Nose Cone, a Body, and a Tail Assembly may be combined to form a Completed Rocket NFT. The Component NFTs are burned, and a Completed Rocket NFT is minted. There are two types of Completed Rocket NFTs: Frankenrockets and Perfect Rockets. A Frankenrocket is a disparate clusterfuck of branding, composed of unmatching Component NFTs. A Perfect Rocket is singular in its branding, composed of three matching Component NFTs - three of a kind, total perfection. Every Completed Rocket NFT is given a unique name by Tom. Our smart contracts are custom tailored to accomplish the specific needs of our project and are powered by fairness and transparency. Aside from the images (which are hosted by IPFS), our entire project lives on-chain, and illuminates the structure of our Rocket Factory for all who are invested in it.</p>`,
    },
    {
        title: "Where can I buy?",
        paragraph: `<p>Component NFTs can be claimed, bought + sold, and assembled through the Rocket Factory website using our custom-built Marketplace. Rocket Component NFTs will cost 0.15ETH (plus Rocket Fuel aka Gas). The Rocket Factory is making sure the community can get involved. Multiple mechanisms will be implemented for the Component NFT drop, ensuring that it is fair and transparent. Updates about how to claim Component NFTs and the mechanics of our drop will be available in the lead up to launch.</p>`,
    },
    {
        title: "Can I buy and sell my Rocket Components on Opensea?",
        paragraph: `<p>Yes, you can but you will potentially be paying fees that you don’t have to pay on our Marketplace.</p>`,
    },
    {
        title: "What’s the minting process?",
        paragraph: `<p>The Component NFTs will be claimed randomly, but how you combine them to mint a Completed Rocket is entirely up to you. We are putting the power in your hands. You, the community, will decide, out of 113,500+ potential combinations, which 1,000 Rockets the world needs. Build the Rocket you desire, the Rocket that defines you.</p>`,
    },
    {
        title: "How much of the inventory was included in the Fair Launch?",
        paragraph: `<p>
        We are releasing 70% of the inventory to the community. In the traditional art world, essentially 0% of art is available for the general public to claim, purchase, or own. It is an industry of hurdles, reckoning with gatekeepers and barriers to entry.
        <br/>
        <br/>
        The Rocket Factory is focused on making “the best, for the most, for the least”.
        <br/>
        <br/>
        This is a fair launch project. We’ve worked hard to avoid gas wars, and to keep the cost of claiming as low as possible. The Rocket Factory has no bonding curve. Claiming a Component NFT costs the same for everybody. The Component NFT you receive is randomly assigned, and there will never be more than 3,000 Components.
        <br/>
        <br/>
        Rocket Component NFTs cost 0.15 ETH to claim (plus the price of rocket fuel, or what some of you may know as Gas).
        </p>`,
    },
    {
        title: "What was the holdback on this project?",
        paragraph: `<p>
        We believe in this space. A project on this scale by a widely established contemporary artist from the traditional art world has not been done before. We are proud to introduce a fair launch on this scale and set the precedent.
        <br/>
        <br/>
        Building a Rocket Factory has real costs... we bring this project to the world by distributing the remaining 30% to the people who built it.
        <br/>
        <br/>
        Fairness and transparency is the guiding principle for this project, and all allocation of Rocket Component NFTs will be reflected in the blockchain for all to see - for all eternity.
        <br/>
        <br/>
        The rarity of Rocket Component NFTs for both pools reflect the same rarity curve (pro-rata).
        <br/>
        <br/>
        This 30% goes to: <br/>
        - Dev. Team <br/>
        - NFT Mentors and Community Educators <br/>
        - Studio Team <br/>
        - Pre-Sales including Community Giveaways
        </p>`,
    },
    {
        title: "How does the Royalty Structure work for this project?",
        paragraph: `<p>
        Robert Rauschenberg tried, and failed, to pass a law for Artist Resale Royalty Rights - where artists could get a percentage of resales, or residuals.
        <br/>
        <br/>
        Droit de suite is French. It translates roughly to “fuck you, pay me.” Put more simply, it is a legal mandate in France guaranteeing artists a royalty when their work sells on the secondary market. French institutions and auction houses routinely skirt the law, conducting most business over the border. The artist doesn’t see a dime. For 35 years, California had a similar law. A judge found it unconstitutional in 2012. That was the end of that.
        <br/>
        <br/>
        This decentralized world respects artists. Historically artists have seen the value of their work go up exponentially in the secondary market without the ability to share in any of that growth. Here artists get 10% as the industry standard of every sale.
        <br/>
        <br/>
        Our royalty structure: 
        <br/>
        <br/>
        10% on the Rocket Factory Marketplace. Part of the reason we built our Marketplace is to save you the additional 2.5% Opensea charges. The choice is yours.
        </p>`,
    },
    {
        title: "Does this project live on the blockchain?",
        paragraph: `<p>Our smart contracts are custom tailored to accomplish the specific needs of our project and are powered by fairness and transparency. Aside from the images (which are hosted by IPFS), our entire project lives on-chain, and illuminates the structure of our Rocket Factory for all who are invested in it.</p>`,
    },
    {
        title: "How do Component NFT Token IDs work?",
        paragraph: `<p>Token IDs are not sequential with respect to the order in which they are claimed at our drop. Token IDs are pre-assigned based on our rarity curve, and each Component NFT is essentially pre-minted. When a claim takes place on the Rocket Factory Marketplace, the Component NFT is randomly assigned to the claimer's wallet. It is a blind draw. There is no conceivable way of knowing what Component NFT will be issued for a given claim. This was a decision we made to keep gas prices consistently low,  and to avoid gas wars on the day of launch. This makes the transaction as inexpensive, and fair, as possible. It gives everyone in line an equal shot.</p>`,
    },
    {
        title: "How do Completed Rocket NFT Token IDs work?",
        paragraph: `<p>Token IDs for Completed Rockets are issued sequentially in the order they are assembled and minted. Each Component NFT’s metadata is preserved within the Completed Rocket NFT. There is immense variety in the potential composition of each Rocket. The number of brand combinations alone exceeds the total inventory by a factor greater than 100:1. The choice is yours.</p>`,
    },
    {
        title: "What is the Test Flight Crew Patch?",
        paragraph: `<p>
        This patch proves that you have been a part of the Rocket Factory from the beginning. Future generations of the Rocket Factory will remember those who came first — the Test Flight Crew.
        <br/>
        <br/>
        Our test period for minting was open from 12pm ET on Friday, July 23rd till Thursday, July 29th at 12pm ET. Only 1 Test Flight Crew Patch was allowed to be minted per wallet.
        <br/>
        <br/>
        Patches are earned. Transfer, trade and sale of the Test Flight Crew Patch NFT is restricted at this time. Any additional utility of holding a patch will be revealed over time.
        </p>`,
    },
    {
        title: "How do Rocket Launches work?",
        paragraph: `<p>The Rocket Factory will announce launch window dates ahead of time. Each launch will have a set number of slots. Assembled Rocket holders who select the Launch Option will have the ability to sign up for a slot on the Rocket Factory Website.</p>`,
    },
    {
        title: "Do Rocket Launches cost money?",
        paragraph: `<p>A Rocket Launch will require a fixed 0.1 ETH charge. A Physical Rocket is a hand-made work of art that we build, launch, recover and ship to you. Even if your Physical Rocket is not recovered we will still send you something... the 0.1 ETH charge offsets only a fraction of our costs, but allows our Physical Rocket Factory to function.</p>`,
    },
];

export default ALL_QUESTIONS;
