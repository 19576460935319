import Tooltip from "@components/Starmap/Tooltip";
import usePlanetSelect from "@hooks/Starmap/usePlanetSelect";
import { ISuns } from "@interfaces/Starmap/Suns";
import STARS from "../../../../../starmap.stars.data";
import styles from "./Suns.module.scss";

interface ISunsProps {
    systemType: string;
    suns: ISuns[];
}

const Suns = ({ systemType, suns }: ISunsProps): JSX.Element => {
    const { handleChangeLinkColor, handleLeaveLinkColor } = usePlanetSelect({ suns });

    return (
        <div className={styles.suns__container}>
            <div className={styles.heading}>
                <h2>SUNS</h2>
                <hr />
                <p>(System Type: {systemType})</p>
            </div>
            <div className={styles.grid}>
                {suns.map(
                    ({ id, name }) =>
                        name && (
                            <>
                                <Tooltip id={`star-tooltip-${id}`} />
                                <a
                                    id={`star-item-${id}`}
                                    onMouseOver={() => handleChangeLinkColor(id, true)}
                                    onMouseLeave={() => handleLeaveLinkColor()}
                                    data-tooltip-id={`star-tooltip-${id}`}
                                    data-tooltip-html={`<div><h3>${name}</h3><p>Uninhabitable</p></div>`}
                                    key={id}
                                    className={styles.grid__item}
                                    href="#"
                                >
                                    <img src={STARS[name]} alt={name} className={styles.grid__item_uninhabitable} />
                                </a>
                            </>
                        )
                )}
            </div>
        </div>
    );
};

export default Suns;
