import onebedroomCorb from "@assets/images/starmap/stations/onebedroom_corb.png";
import onebedroomPly from "@assets/images/starmap/stations/onebedroom_ply.png";
import onebedroomSteel from "@assets/images/starmap/stations/onebedroom_steel.png";
import studioCorb from "@assets/images/starmap/stations/studio_corb.png";
import studioPly from "@assets/images/starmap/stations/studio_ply.png";
import studioSteel from "@assets/images/starmap/stations/studio_steel.png";
import { apartmentType } from "@customTypes/Starmap/ApartmentType";
import { IApartment } from "@interfaces/Starmap/Apartment";

const STARMAP_APARTMENTS: IApartment[] = [
    {
        id: 1,
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Corb",
        image: onebedroomCorb,
    },
    {
        id: 2,
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Plywood",
        image: onebedroomPly,
    },
    {
        id: 3,
        type: apartmentType.oneBedroom,
        name: "One Bedroom: Steel",
        image: onebedroomSteel,
    },
    {
        id: 4,
        type: apartmentType.studio,
        name: "Studio Apartment: Corb",
        image: studioCorb,
    },
    {
        id: 5,
        type: apartmentType.studio,
        name: "Studio Apartment: Plywood",
        image: studioPly,
    },
    {
        id: 6,
        type: apartmentType.studio,
        name: "Studio Apartment: Steel",
        image: studioSteel,
    },
];

export const GET_APARTMENTS = (type: apartmentType): IApartment[] => STARMAP_APARTMENTS.filter((c) => c.type === type);
