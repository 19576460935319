import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { IRocket } from "@interfaces/Rocket/Rocket";
import getRockets from "@services/Rocket/getRockets";
import { INITIAL_DEFAULT_OFFSET, INITIAL_DEFAULT_PAGE } from "@constants/pagination";

interface returnTypes {
    setRocketStats: Dispatch<SetStateAction<IRocket | null>>;
    totalRockets: number;
    totalPerType: number;
    totalLaunched: number;
    totalNoseBrand: number;
    totalBodyBrand: number;
    totalTailBrand: number;
}

const useGetRocketsStats = (): returnTypes => {
    const [totalRockets, setTotalRockets] = useState(0);
    const [totalPerType, setTotalPerType] = useState(0);
    const [totalLaunched, setTotalLaunched] = useState(0);
    const [totalNoseBrand, setTotalNoseBrand] = useState(0);
    const [totalBodyBrand, setTotalBodyBrand] = useState(0);
    const [totalTailBrand, setTotalTailBrand] = useState(0);
    const [rocket, setRocketStats] = useState<null | IRocket>(null);

    useEffect(() => {
        getStats();
    }, [rocket]);

    const getStats = async () => {
        if (rocket) {
            getRockets(INITIAL_DEFAULT_PAGE, INITIAL_DEFAULT_OFFSET, undefined, 1000).then(({ data: rockets }) => {
                setTotalRockets(rockets.length);

                let totalPerType = 0;
                let totalLaunched = 0;
                let totalNoseBrand = 0;
                let totalBodyBrand = 0;
                let totalTailBrand = 0;

                rockets.forEach(({ type, launched, noseBrand, bodyBrand, tailBrand }) => {
                    if (rocket.type === type) {
                        totalPerType++;
                    }

                    if (rocket.launched === launched) {
                        totalLaunched++;
                    }

                    if (rocket.noseBrand === noseBrand) {
                        totalNoseBrand++;
                    }

                    if (rocket.bodyBrand === bodyBrand) {
                        totalBodyBrand++;
                    }

                    if (rocket.tailBrand === tailBrand) {
                        totalTailBrand++;
                    }
                });

                setTotalPerType(totalPerType);
                setTotalLaunched(totalLaunched);
                setTotalNoseBrand(totalNoseBrand);
                setTotalBodyBrand(totalBodyBrand);
                setTotalTailBrand(totalTailBrand);
            });
        }
    };

    return {
        setRocketStats,
        totalRockets,
        totalPerType,
        totalLaunched,
        totalNoseBrand,
        totalBodyBrand,
        totalTailBrand,
    };
};

export default useGetRocketsStats;
