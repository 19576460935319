import styles from "./GalaxyBackground.module.scss";
import galaxyLight from "@assets/images/utils/galaxyBackground.jpg";
import galaxyDark from "@assets/images/utils/galaxyBackground_dark.png";

interface IGalaxyBackground {
    children: React.ReactNode;
    noPadding?: boolean;
    isGalaxyLight?: boolean;
}

const GalaxyBackground = ({ children, noPadding, isGalaxyLight = false }: IGalaxyBackground): JSX.Element => {
    const bgGalaxyUrl = !isGalaxyLight ? galaxyDark : galaxyLight;
    const backgroundImageStyle = {
        backgroundImage: `url(${bgGalaxyUrl})`,
    };

    return (
        <div
            className={`${styles.galaxy__container} ${noPadding ? styles.noPadding : ""}`}
            style={backgroundImageStyle}
        >
            <div className={styles.galaxy}>{children}</div>
        </div>
    );
};

export default GalaxyBackground;
