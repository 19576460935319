import { IZoneApartment } from "@interfaces/Starmap/Apartment";
import { IItemList } from "@interfaces/Starmap/ItemList";

const formatStarmapApartmentItemList = (data: IZoneApartment[]): IItemList[] =>
    data?.map((apartment) => ({
        id: apartment.id,
        title: apartment.name,
        imageUrl: apartment.image,
        linkUrl: "",
    }));

export default formatStarmapApartmentItemList;
