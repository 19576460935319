import { HANGAR } from "@constants/apiEndpoints";
import { IRocket } from "@interfaces/Rocket/Rocket";
import { RocketApiResponse } from "@interfaces/Rocket/RocketResponse";
import { apiResponseToRocket } from "@scripts/RocketHelper";
import { AxiosPromise } from "axios";
import { apiInstance } from "@services/Shared/instanceAxios";
import { IComponent } from "@interfaces/RocketComponent/Component";
import { apiResponseToComponent } from "@scripts/ComponentHelper";
import { ComponentApiResponse } from "@interfaces/RocketComponent/ComponentResponse";
import { idToCabinType } from "@scripts/TicketHelper";
import { ITokenResponse } from "@interfaces/Hangar/TokenResponse";
import { IRock } from "@interfaces/Rock/Rock";
import { ITicket } from "@interfaces/Ticket/Ticket";
import { ITokenItem } from "@interfaces/Hangar/TokenItem";

const getTokens = (checkSumAddress: string): AxiosPromise<ITokenResponse> =>
    apiInstance({
        url: `${HANGAR}${checkSumAddress}`,
        transformResponse: [
            (data) => {
                const result = JSON.parse(data);
                const components: IComponent[] = [];
                const rockets: IRocket[] = [];
                const tickets: ITicket[] = [];

                result?.components?.map((component: ComponentApiResponse) => {
                    components.push(apiResponseToComponent(component));
                });

                result?.rockets?.map((rocket: RocketApiResponse) => {
                    rockets.push(apiResponseToRocket(rocket));
                });

                result?.tickets?.map(({ id, amount }) => {
                    tickets.push({
                        id,
                        type: idToCabinType(id),
                        amount,
                    });
                });

                const planets: ITokenItem[] = result.planets ? [...result.planets] : [];
                const spaceStations: ITokenItem[] = result.spaceStations ? [...result.spaceStations] : [];
                const rocks: IRock[] = result.rocks ? [...result.rocks] : [];
                const patches: ITokenItem[] = [...result.patches, ...result.patches1155];
                const stuff: ITokenItem[] = result.stuffs ? [...result.stuffs] : [];

                return {
                    planets,
                    spaceStations,
                    components,
                    rockets,
                    tickets,
                    rocks,
                    patches,
                    stuff,
                };
            },
        ],
    });

export default getTokens;
