import { ReactElement, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "@components/Loader";
import CollectionItem from "../ItemCollection";
import styles from "./BoxCollection.module.scss";
import BoxFilter from "@components/BoxFilter";
import { searchBoxLine, searchBoxNotAllowlist } from "@pages/Collections/dataCollections/Category.data";
import useGetCollections from "@hooks/Collection/useGetCollections";
import NoItemCollection from "../NoItemCollection";
import { getColumnByCategory } from "@scripts/CollectionCategoryHelper";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";

interface IBoxCollection {
    categoryCollection: CollectionCategory;
}

const BoxCollection = ({ categoryCollection }: IBoxCollection): ReactElement => {
    const {
        itemsToShow,
        handleGetCollections,
        hasMore,
        handleSearchCollections,
        setCategory,
        resetFilters,
        isLoading,
    } = useGetCollections();
    const column = getColumnByCategory(categoryCollection);

    useEffect(() => {
        resetFilters();
        setCategory(categoryCollection);
    }, [categoryCollection]);

    return (
        <>
            {!searchBoxNotAllowlist.includes(categoryCollection) && <BoxFilter onSubmit={handleSearchCollections} />}
            {searchBoxLine.includes(categoryCollection) && <hr />}

            {itemsToShow.length === 0 && isLoading ? (
                <Loader />
            ) : itemsToShow.length > 0 ? (
                <InfiniteScroll
                    dataLength={itemsToShow.length}
                    next={handleGetCollections}
                    hasMore={hasMore}
                    loader={<Loader />}
                >
                    <div className={styles.collectionContainer__container}>
                        {itemsToShow.map((item) => (
                            <CollectionItem key={item.id} column={column} detail={item} />
                        ))}
                    </div>
                </InfiniteScroll>
            ) : (
                <NoItemCollection message="No items found" />
            )}
        </>
    );
};

export default BoxCollection;
