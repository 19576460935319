import { ITimelineCard } from "@interfaces/Timeline/TimelineCard";
import TimelineCard from "../TimelineCard";
import styles from "./IRLEventCard.module.scss";
import ButtonTimeline from "../ButtonTimeline";
import { ReactElement } from "react";
import { getLinkSocial } from "@scripts/SocialHelper";
import { timelineSocialType } from "@customTypes/Timeline/TimelineSocialType";

const IRLEventCard = ({ item }: ITimelineCard): ReactElement => {
    const link_discord = getLinkSocial(item.socials, timelineSocialType.discord);
    const link_twitter = getLinkSocial(item.socials, timelineSocialType.twitter);
    const link_instagram = getLinkSocial(item.socials, timelineSocialType.instagram);

    return (
        <TimelineCard key={item.id} title={item.title} category={item.category} date={item.date} dateEnd={item.dateEnd}>
            <div className={styles.irlMeetups__container}>
                <img src={item.image} alt="Rocket Launch" />
                {item.description && <p>{item.description}</p>}
            </div>
            <div className={styles.irlMeetups__buttonsbar}>
                {item.link ? (
                    <ButtonTimeline to={item.link} target="_blank">
                        LEARN MORE
                    </ButtonTimeline>
                ) : (
                    <>
                        <ButtonTimeline to={link_discord} target="_blank">
                            DISCORD
                        </ButtonTimeline>
                        <ButtonTimeline to={link_twitter} target="_blank">
                            TWITTER
                        </ButtonTimeline>
                        <ButtonTimeline to={link_instagram} target="_blank">
                            INSTAGRAM
                        </ButtonTimeline>
                    </>
                )}
            </div>
        </TimelineCard>
    );
};

export default IRLEventCard;
