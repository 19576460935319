import { useAxios } from "use-axios-client";
import { API_BASE_URL } from "@constants/env";
import { Config, Props } from "use-axios-client/bin/useBaseAxios";

const useApiCall = <T>(config: Config): Props<T> => {
    return useAxios<T>({
        baseURL: API_BASE_URL,
        ...config,
    });
};

export default useApiCall;
