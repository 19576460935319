import STARS from "../../../../../starmap.stars.data";
import styles from "./UninhabitablePlanetsList.module.scss";
import usePlanetSelect from "@hooks/Starmap/usePlanetSelect";
import { ISuns } from "@interfaces/Starmap/Suns";

interface IUninhabitablePlanetsListProps {
    suns: ISuns[];
}

const UninhabitablePlanetsList = ({ suns }: IUninhabitablePlanetsListProps): JSX.Element => {
    const { handleShowTooltip, handleHideTooltip } = usePlanetSelect({});

    return (
        <div className={styles.uninhabitablePlanetsList__container}>
            <h2>UNINHABITABLE </h2>
            <ul>
                {suns.map(
                    ({ name, id }) =>
                        name && (
                            <li
                                key={id}
                                id={`planet-item-${id}`}
                                onMouseOver={() => handleShowTooltip(id)}
                                onMouseLeave={() => handleHideTooltip()}
                            >
                                <img src={STARS[name]} alt="unihabitable planet preview" />
                                <p>{name}</p>
                            </li>
                        )
                )}
            </ul>
        </div>
    );
};

export default UninhabitablePlanetsList;
