import budweiser from "@assets/images/components/budweiser.png";
import chanel from "@assets/images/components/chanel.png";
import nasa from "@assets/images/components/nasa.png";
import skippy from "@assets/images/components/skippy.png";
import lisa from "@assets/images/components/lisa.png";
import campbells from "@assets/images/components/campbells.png";
import cupNoodles from "@assets/images/components/cup-noodles.png";
import usps from "@assets/images/components/usps.png";
import sweetNLow from "@assets/images/components/sweet-n-low.png";
import snickers from "@assets/images/components/snickers.png";
import nikecraft from "@assets/images/components/nikecraft.png";
import sevenEleven from "@assets/images/components/711.png";
import hermes from "@assets/images/components/hermes.png";
import trojan from "@assets/images/components/trojan.png";
import mcdonalds from "@assets/images/components/mcdonalds.png";
import adderall from "@assets/images/components/adderall.png";
import marlboro from "@assets/images/components/marlboro.png";
import wd40 from "@assets/images/components/wd40.png";
import ferrari from "@assets/images/components/ferrari.png";
import apple from "@assets/images/components/apple.png";
import brian from "@assets/images/components/brian.png";
import wonder from "@assets/images/components/wonder.png";
import scotch from "@assets/images/components/scotch.png";
import helloKitty from "@assets/images/components/hello-kitty.png";
import atari from "@assets/images/components/atari.png";
import tiffany from "@assets/images/components/tiffany.png";
import yoda from "@assets/images/components/yoda.png";
import cocaCola from "@assets/images/components/coke.png";
import miamiVice from "@assets/images/components/miami.png";
import purple from "@assets/images/components/purple.png";

export const HARDCODED_ROCKETS = [
    {
        image: purple,
        brand: "Purple",
    },
    {
        image: miamiVice,
        brand: "Miami Vice",
    },
    {
        image: cocaCola,
        brand: "Coca-Cola",
    },
    {
        image: yoda,
        brand: "Yoda",
    },
    {
        image: tiffany,
        brand: "Tiffany",
    },
    {
        image: apple,
        brand: "Apple",
    },
    {
        image: scotch,
        brand: "Scotch",
    },
    {
        image: helloKitty,
        brand: "Hello Kitty",
    },
    {
        image: wonder,
        brand: "Wonder Bread",
    },
    {
        image: marlboro,
        brand: "Marlboro",
    },
    {
        image: atari,
        brand: "Atari",
    },
    {
        image: ferrari,
        brand: "Ferrari",
    },
    {
        image: wd40,
        brand: "WD-40",
    },
    {
        image: hermes,
        brand: "Hermès",
    },
    {
        image: adderall,
        brand: "Adderall",
    },
    {
        image: mcdonalds,
        brand: "McDonald's",
    },
    {
        image: nasa,
        brand: "NASA",
    },
    {
        image: lisa,
        brand: "Lisa",
    },
    {
        image: sevenEleven,
        brand: "7-Eleven",
    },
    {
        image: nikecraft,
        brand: "NIKECRAFT",
    },
    {
        image: snickers,
        brand: "SNICKERS",
    },
    {
        image: sweetNLow,
        brand: "Sweet'N Low",
    },
    {
        image: usps,
        brand: "USPS",
    },
    {
        image: cupNoodles,
        brand: "Cup Noodles",
    },
    {
        image: campbells,
        brand: "Campbell's",
    },
    {
        image: brian,
        brand: "Brian",
    },
    {
        image: skippy,
        brand: "SKIPPY",
    },
    {
        image: trojan,
        brand: "Trojan",
    },
    {
        image: chanel,
        brand: "Chanel",
    },
    {
        image: budweiser,
        brand: "Budweiser",
    },
];
