import { ReactElement } from "react";
import styles from "./NoItemCollection.module.scss";

interface INoItemCollection {
    message: string;
}

const NoItemCollection = ({ message }: INoItemCollection): ReactElement => (
    <div className={styles.noItemCollection}>
        <span>{message}</span>
    </div>
);

export default NoItemCollection;
