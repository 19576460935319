import { WEB3_API_KEY, WEB3_API_URL } from "@constants/env";
import axios from "axios";

const defaultOptions = {
    baseURL: WEB3_API_URL,
};

// Create instance
export const apiInstance = axios.create(defaultOptions);
apiInstance.defaults.headers.common["api-key"] = WEB3_API_KEY;
