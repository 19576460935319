import { STARMAP_ZONES } from "@constants/apiEndpoints";
import useApiCall from "@hooks/shared/useApiCall";
import { IZoneFormatted } from "@interfaces/Starmap/Zone";
import formatZones from "@formatting/formatStarmapZones";

const useGetStarmapZones = (): IZoneFormatted[] | undefined =>
    useApiCall<IZoneFormatted[]>({
        url: STARMAP_ZONES,
        transformResponse: [(data) => formatZones(JSON.parse(data))],
    }).data;

export default useGetStarmapZones;
