import { IItemList } from "@interfaces/Starmap/ItemList";
import AssetItemList from "../AssetItemList";
import styles from "./UnorganizedList.module.scss";

interface IUnorganizedList {
    listItem: IItemList[];
}

const UnorganizedList = ({ listItem }: IUnorganizedList): JSX.Element => (
    <ul className={styles.metadata__unorganized}>
        {listItem?.map(({ title, imageUrl }) => (
            <AssetItemList key={title} img={imageUrl} name={title} />
        ))}
    </ul>
);
export default UnorganizedList;
