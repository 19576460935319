import { ReactElement } from "react";
import styles from "./BoxRarities.module.scss";
import Rarity from "@components/Rarity";
import { rarityData } from "@components/Rarity/Rarity.data";

const BoxRarities = (): ReactElement => (
    <div className={styles.boxRarities}>
        <h2>RARITY</h2>
        <div className={styles.boxRarities__grid}>
            {rarityData.map((rarity) => (
                <Rarity key={rarity.item} item={rarity.item} rarities={rarity.rarities} />
            ))}
        </div>
    </div>
);

export default BoxRarities;
