import { Link } from "react-router-dom";
import tomSachs from "@assets/images/header/header-logo.png";
import { HOME } from "@routes/routes";
import styles from "./Logo.module.scss";

interface ILogo {
    classname?: string;
}

const Logo = ({ classname }: ILogo): JSX.Element => (
    <Link to={HOME}>
        <img
            className={`${styles.logo} ${classname}`}
            src={tomSachs}
            alt="Tom Sachs: Rocket Factory"
            width="298"
            height="30"
        />
    </Link>
);

export default Logo;
