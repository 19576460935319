import PageContainer from "@components/PageContainer";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import { IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { useParams } from "react-router-dom";
import BoxCollection from "./components/BoxCollection";
import ComponentCollection from "./components/ComponentCollection";
import RocketCollection from "./components/RocketCollection";

const Collections = (): JSX.Element => {
    const { category } = useParams<{ category: string }>();
    const categorySearch = decodeURIComponent(category) as CollectionCategory;
    const categories = Object.values(CollectionCategory).map((value) => value);
    const categoriesWithoutStuff = categories.filter((category) => category !== CollectionCategory.stuff);
    const categoriesToShow = IS_STUFF_MINT_OPEN ? categories : categoriesWithoutStuff;

    return (
        <PageContainer title="Collections" categories={categoriesToShow}>
            {categorySearch !== CollectionCategory.components && categorySearch !== CollectionCategory.rockets && (
                <BoxCollection categoryCollection={categorySearch} />
            )}
            {categorySearch === CollectionCategory.rockets && <RocketCollection />}
            {categorySearch === CollectionCategory.components && <ComponentCollection />}
        </PageContainer>
    );
};

export default Collections;
