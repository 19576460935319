import { IRarity } from "@interfaces/Stuff/Rarity";

export const rarityTotalPercentage = (rarities: IRarity[]): number => {
    let total = 0;

    for (let i = 0; i < rarities.length; i++) {
        total += Number(rarities[i].value.slice(0, -1));
    }

    return total;
};
