import { createContext, ReactNode } from "react";
import { useWeb3Context, WalletProviders } from "@nftstudios/web3-provider";

interface ISessionContextProps {
    isInitialized: boolean;
    walletAddress: string;
    login: () => Promise<void>;
    logout: () => Promise<void>;
    signMessage: (message: string) => Promise<string>;
}

interface IChildrenProps {
    children: ReactNode;
}

export const SessionContext = createContext<ISessionContextProps>({} as ISessionContextProps);

const SessionContextProvider = ({ children }: IChildrenProps): JSX.Element => {
    const { isInitialized, walletAddress, connect, disconnect, signMessage } = useWeb3Context();

    const login = async () => {
        try {
            await connect(WalletProviders.MetaMask);
        } catch (_) {
            await connect(WalletProviders.WalletConnectV2);
        }
    };

    const logout = async () => {
        await disconnect();
    };

    return (
        <SessionContext.Provider
            value={{
                isInitialized,
                walletAddress,
                login,
                logout,
                signMessage,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export default SessionContextProvider;
