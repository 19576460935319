import { ReactElement } from "react";
import Subtitle from "../Subtitle";
import styles from "./TemplateBox.module.scss";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";

interface ITemplateBoxProps {
    children: React.ReactNode;
    title: string;
}

const TemplateBox = ({ children, title }: ITemplateBoxProps): ReactElement => {
    const isTicket = title === EHangarTitleType.TICKETS;

    return (
        <>
            <hr />
            <div className={styles.templateBox}>
                <Subtitle>
                    <h2>{title}</h2>
                </Subtitle>
                <div className={`${styles.templateBox__items} ${isTicket ? styles.isTicket : ""}`}>{children}</div>
            </div>
        </>
    );
};

export default TemplateBox;
