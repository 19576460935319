import PhaseIIHero from "./components/PhaseIIHero";
import RequiredReadingAndViewing from "./components/RequiredReadingAndViewing";

const PhaseII = (): JSX.Element => {
    return (
        <>
            <PhaseIIHero />
            <RequiredReadingAndViewing />
        </>
    );
};

export default PhaseII;
