import { STUFF_DIGITAL_MINTER_CONTRACT_ADDRESS } from "@constants/env";
import abi from "@contracts/DigitalScratchersMintAbi";
import { useWeb3Context } from "@nftstudios/web3-provider";

interface ReturnTypes {
    getMintedPerWallet(walletAddress: string): Promise<number>;
}

const useStuffDigitalMintRead = (): ReturnTypes => {
    const { web3 } = useWeb3Context();

    const getMintedPerWallet = async (walletAddress: string): Promise<number> => {
        if (!web3) {
            return 0;
        }

        const mintContract = new web3.eth.Contract(abi, STUFF_DIGITAL_MINTER_CONTRACT_ADDRESS);

        return parseInt(await mintContract.methods.mintsPerWallet(walletAddress).call());
    };

    return {
        getMintedPerWallet,
    };
};

export default useStuffDigitalMintRead;
