import IZone, { IZoneFormatted } from "@interfaces/Starmap/Zone";
import STARMAP_OVERVIEW from "@pages/Starmap/starmap.zones.data";

const formatZones = (data: IZone[] | undefined): IZoneFormatted[] | undefined =>
    data?.map((zone, index) => ({
        id: parseInt(zone.id),
        name: zone.name,
        image_default: STARMAP_OVERVIEW[index].default,
        image_hover: STARMAP_OVERVIEW[index].hover,
    }));

export default formatZones;
