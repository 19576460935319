import { IRock } from "@interfaces/Rock/Rock";

export const ROCK_RESIN_SLURRY = 0;
export const ROCK_PARTY_GIRL = 1;
export const ROCK_BAMBI = 2;
export const ROCK_PLYWOOD = 3;
export const ROCK_SHITCORN = 4;
export const ROCK_PETROLEUM = 5;
export const ROCK_BLOODY_FINGERS = 6;
export const ROCK_PEARLS_BEFORE_WINE = 7;
export const ROCK_GREASY = 8;
export const ROCK_AMERICAN_HISTORY_X = 9;
export const ROCK_URANIUM = 10;
export const ROCK_SNOW = 11;
export const ROCK_GOLDFINGER = 12;
export const ROCK_ANTIMATTER = 13;
export const ROCK_THE_SPICE = 14;

export const ROCK_RESIN_SLURRY_CONSECRATED = 15;
export const ROCK_PARTY_GIRL_CONSECRATED = 16;
export const ROCK_BAMBI_CONSECRATED = 17;
export const ROCK_PLYWOOD_CONSECRATED = 18;
export const ROCK_SHITCORN_CONSECRATED = 19;
export const ROCK_PETROLEUM_CONSECRATED = 20;
export const ROCK_BLOODY_FINGERS_CONSECRATED = 21;
export const ROCK_PEARLS_BEFORE_WINE_CONSECRATED = 22;
export const ROCK_GREASY_CONSECRATED = 23;
export const ROCK_AMERICAN_HISTORY_X_CONSECRATED = 24;
export const ROCK_URANIUM_CONSECRATED = 25;
export const ROCK_SNOW_CONSECRATED = 26;
export const ROCK_GOLDFINGER_CONSECRATED = 27;
export const ROCK_ANTIMATTER_CONSECRATED = 28;
export const ROCK_THE_SPICE_CONSECRATED = 29;

export const ROCK_TO_IMAGE = {
    [ROCK_RESIN_SLURRY]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/a.png",
    [ROCK_PARTY_GIRL]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/b.png",
    [ROCK_BAMBI]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/c.png",
    [ROCK_PLYWOOD]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/d.png",
    [ROCK_SHITCORN]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/e.png",
    [ROCK_PETROLEUM]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/f.png",
    [ROCK_BLOODY_FINGERS]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/g.png",
    [ROCK_PEARLS_BEFORE_WINE]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/h.png",
    [ROCK_GREASY]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/i.png",
    [ROCK_AMERICAN_HISTORY_X]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/j.png",
    [ROCK_URANIUM]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/k.png",
    [ROCK_SNOW]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/l.png",
    [ROCK_GOLDFINGER]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/m.png",
    [ROCK_ANTIMATTER]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/n.png",
    [ROCK_THE_SPICE]: "https://storage.googleapis.com/tsrf/ui/rocks-optimized/o.png",
    [ROCK_RESIN_SLURRY_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/a.png",
    [ROCK_PARTY_GIRL_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/b.png",
    [ROCK_BAMBI_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/c.png",
    [ROCK_PLYWOOD_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/d.png",
    [ROCK_SHITCORN_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/e.png",
    [ROCK_PETROLEUM_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/f.png",
    [ROCK_BLOODY_FINGERS_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/g.png",
    [ROCK_PEARLS_BEFORE_WINE_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/h.png",
    [ROCK_GREASY_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/i.png",
    [ROCK_AMERICAN_HISTORY_X_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/j.png",
    [ROCK_URANIUM_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/k.png",
    [ROCK_SNOW_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/l.png",
    [ROCK_GOLDFINGER_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/m.png",
    [ROCK_ANTIMATTER_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/n.png",
    [ROCK_THE_SPICE_CONSECRATED]: "https://storage.googleapis.com/tsrf/ui/rocks-consecrated/o.png",
};

export const ROCK_TO_DEED = {
    [ROCK_RESIN_SLURRY]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/a.png",
    [ROCK_PARTY_GIRL]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/b.png",
    [ROCK_BAMBI]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/c.png",
    [ROCK_PLYWOOD]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/d.png",
    [ROCK_SHITCORN]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/e.png",
    [ROCK_PETROLEUM]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/f.png",
    [ROCK_BLOODY_FINGERS]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/g.png",
    [ROCK_PEARLS_BEFORE_WINE]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/h.png",
    [ROCK_GREASY]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/i.png",
    [ROCK_AMERICAN_HISTORY_X]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/j.png",
    [ROCK_URANIUM]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/k.png",
    [ROCK_SNOW]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/l.png",
    [ROCK_GOLDFINGER]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/m.png",
    [ROCK_ANTIMATTER]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/n.png",
    [ROCK_THE_SPICE]: "https://storage.googleapis.com/tsrf/ui/rock-deed-optimized/o.png",
};

export const ROCK_NAMES = {
    [ROCK_RESIN_SLURRY]: "resin slurry",
    [ROCK_PARTY_GIRL]: "party girl",
    [ROCK_BAMBI]: "bambi",
    [ROCK_PLYWOOD]: "plywood",
    [ROCK_SHITCORN]: "shitcorn",
    [ROCK_PETROLEUM]: "petroleum",
    [ROCK_BLOODY_FINGERS]: "bloody fingers",
    [ROCK_PEARLS_BEFORE_WINE]: "pearls before wine",
    [ROCK_GREASY]: "greasy",
    [ROCK_AMERICAN_HISTORY_X]: "american history x",
    [ROCK_URANIUM]: "uranium",
    [ROCK_SNOW]: "snow",
    [ROCK_GOLDFINGER]: "goldfinger",
    [ROCK_ANTIMATTER]: "antimatter",
    [ROCK_THE_SPICE]: "the spice",
};

const ROCKS_FOR_PLANETS = [
    ROCK_RESIN_SLURRY,
    ROCK_PARTY_GIRL,
    ROCK_BAMBI,
    ROCK_AMERICAN_HISTORY_X,
    ROCK_URANIUM,
    ROCK_SNOW,
    ROCK_GOLDFINGER,
    ROCK_ANTIMATTER,
    ROCK_THE_SPICE,
];

export const getImageForRock = (rock: IRock): string => ROCK_TO_IMAGE[rock.id];

export const isExchangeableForPlanet = (id: number): boolean => {
    return ROCKS_FOR_PLANETS.includes(id);
};
