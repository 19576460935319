import { ITimeline } from "@interfaces/Timeline/Timeline";
import { timelineCategoryType } from "../../types/Timeline/TimelineCategoryType";

function toTimestamp(strDate: string) {
    const datum = Date.parse(strDate);
    return datum / 1000;
}

//These lines it are only an api mock.
const DATA: ITimeline[] = [
    {
        id: 1,
        date: toTimestamp("2022-12-03"),
        category: timelineCategoryType.irlEvents,
        title: "Miami: Planetarium Experience",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20221203_IRL%20Meetup_Planetarium%20Miami.jpg",
        description: "",
    },
    {
        id: 2,
        date: toTimestamp("2022-11-12"),
        category: timelineCategoryType.irlEvents,
        title: 'Marfa: "The Final Frontier" (First Look)',
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20221112_IRL%20Meetup_Marfa%20Activation.jpg",
        description: "",
    },
    {
        id: 3,
        date: toTimestamp("2022-06-20"),
        category: timelineCategoryType.milestones,
        title: "Monaverse Announcement",
        link: "https://twitter.com/tsrocketfactory/status/1538995058573295616",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20221112_Milestone_Monaverse%20Announcement.jpg",
        description: "",
    },
    {
        id: 4,
        date: toTimestamp("2022-11-12"),
        category: timelineCategoryType.movies,
        title: "The Final Frontier: Encyclopedia Galactica",
        link: "https://www.youtube.com/embed/fniGRZIMhvg",
        image: "",
        description: "",
    },
    {
        id: 5,
        date: toTimestamp("2022-10-07"),
        dateEnd: toTimestamp("2022-11-26"),
        category: timelineCategoryType.irlEvents,
        title: "Tom Sachs: Spaceships Exhibition",
        link: "https://www.tomsachs.com/exhibitions/spaceships",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20221007_Exhibition_Tom%20Sachs%20Spaceships.jpg",
        description: "",
    },
    {
        id: 6,
        date: toTimestamp("2022-10-01"),
        category: timelineCategoryType.movies,
        title: "Phase II: Mars Rock Mining",
        link: "https://www.youtube.com/embed/YlFGIOJuGAw",
        image: "",
        description: "",
    },
    {
        id: 7,
        date: toTimestamp("2022-09-01"),
        category: timelineCategoryType.irlEvents,
        title: "2nd Rocket Shredding Event",
        link: "https://www.instagram.com/reel/CiBbfjjjBYP/",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220901_IRL%20Meetup_2nd%20Rocket%20Shredding%20Event.jpg",
        description: "",
    },
    {
        id: 8,
        date: 1659369600,
        category: timelineCategoryType.launches,
        title: "Classified Location",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220801_Rocket%20Launch_Classified%20Location.jpg",
        description: "",
    },
    {
        id: 9,
        date: 1657314000,
        category: timelineCategoryType.launches,
        title: "Chicago, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220708_Rocket%20Launch_Chicago%20USA.jpg",
        description: "",
    },
    {
        id: 10,
        date: 1656151200,
        category: timelineCategoryType.launches,
        title: "Seoul, South Korea",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220625_Rocket%20Launch_Seoul%20South%20Korea.jpg",
        description: "",
    },
    {
        id: 11,
        date: toTimestamp("2022-06-23"),
        category: timelineCategoryType.irlEvents,
        title: "2nd Scavenger Hunt",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220623_IRL%20Meetup_2nd%20Scavenger%20Hunt.jpg",
        description: "",
    },
    {
        id: 12,
        date: toTimestamp("2022-06-22"),
        category: timelineCategoryType.irlEvents,
        title: "NFT NYC Pizza Party 2022",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220622_IRL%20Meetup_NFT%20NYC%20Pizza%20Party%202022.jpg",
        description: "",
    },
    {
        id: 13,
        date: toTimestamp("2022-06-01"),
        category: timelineCategoryType.milestones,
        title: "Mars Rocks Return",
        link: "https://opensea.io/collection/tom-sachs-mars-rocks",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220601_Milestone_Mars%20Rocks%20Return.jpg",
        description: "",
    },
    {
        id: 14,
        date: toTimestamp("2022-05-31"),
        category: timelineCategoryType.milestones,
        title: "Mars Rocks Go Live",
        link: "https://medium.com/@tsrocketfactory/how-rocks-work-773cd90effc5",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220531_Milestone_Mars%20Rocks%20Go%20Live.jpg",
        description: "",
    },
    {
        id: 15,
        date: toTimestamp("2022-05-21"),
        category: timelineCategoryType.milestones,
        title: "Mining Mission Begins",
        link: "https://medium.com/@tsrocketfactory/launch-commit-we-are-go-for-lift-off-609055f1b155",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220521_Milestone_Mining%20Mission%20Begins.jpg",
        description: "",
    },
    {
        id: 16,
        date: 1652976000,
        category: timelineCategoryType.launches,
        title: "Minneapolis, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220519_Rocket%20Launch_Minneapolis%20USA.jpg",
        description: "",
    },
    {
        id: 17,
        date: 1651939200,
        category: timelineCategoryType.launches,
        title: "Lucerne Valley, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220507_Rocket%20Launch_Lucerne%20Valley%20USA.png",
        description: "",
    },
    {
        id: 18,
        date: toTimestamp("2022-04-13"),
        category: timelineCategoryType.irlEvents,
        title: "Bright Moments Kraftwerk",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220413_IRL%20Meetup_Bright%20Moments%20Kraftwerk.jpg",
        description: "",
    },
    {
        id: 19,
        date: 1649865600,
        category: timelineCategoryType.launches,
        title: "Berlin, Germany",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220413_Rocket%20Launch_Berlin%20Germany.jpg",
        description: "",
    },
    {
        id: 20,
        date: 1649520000,
        category: timelineCategoryType.launches,
        title: "Space Program: Rare Earths Closing Demonstration",
        link: "https://www.tomsachs.com/exhibitions/space-program-rare-earths",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220409_Rocket%20Launch_Space%20Program%20Rare%20Earths%20Closing%20Demonstration.jpg",
        description: "",
    },
    {
        id: 21,
        date: toTimestamp("2022-04-01"),
        category: timelineCategoryType.milestones,
        title: "Tickets Go Live for Mothership Air",
        link: "https://medium.com/@tsrocketfactory/how-tickets-work-82a2a352cc35",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220401_Milestone_Tickets%20Go%20Live%20for%20Mothership%20Air.jpg",
        description: "",
    },
    {
        id: 22,
        date: 1645290000,
        category: timelineCategoryType.launches,
        title: "New York, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220219_Rocket%20Launch_New%20York%20USA.jpg",
        description: "",
    },
    {
        id: 23,
        date: toTimestamp("2022-01-21"),
        category: timelineCategoryType.irlEvents,
        title: "1st Rocket Shredding Event",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220121_IRL%20Meetup_1st%20Rocket%20Shredding%20Event.jpg",
        description: "",
    },
    {
        id: 24,
        date: toTimestamp("2022-01-12"),
        category: timelineCategoryType.movies,
        title: "Rocket Factory: The Shredder",
        link: "https://www.youtube.com/embed/BfqQZI-lfR0",
        image: "",
        description: "",
    },
    {
        id: 25,
        date: toTimestamp("2021-12-03"),
        category: timelineCategoryType.milestones,
        title: "Auction for 1st Rocket on Chain (Miami, USA)",
        link: "https://www.instagram.com/reel/CXCf3tWIK3L/",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211203_Milestone_Auction%20for%201st%20Rocket%20on%20Chain_Miami%20USA.jpg",
        description: "",
    },
    {
        id: 26,
        date: 1638550800,
        category: timelineCategoryType.launches,
        title: "Miami, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211203_Rocket%20Launch_Miami%20USA.jpg",
        description: "",
    },
    {
        id: 27,
        date: 1637449200,
        category: timelineCategoryType.launches,
        title: "Los Angeles, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211121_Rocket%20Launch_Los%20Angeles%20USA.jpg",
        description: "",
    },
    {
        id: 28,
        date: 1637168400,
        category: timelineCategoryType.launches,
        title: "Portland, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211117_Rocket%20Launch_Potland%20USA.jpg",
        description: "",
    },
    {
        id: 29,
        date: toTimestamp("2021-11-12"),
        category: timelineCategoryType.irlEvents,
        title: "1st Scavenger Hunt",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211112_IRL%20Meetup_1st%20Scavenger%20Hunt.jpg",
        description: "",
    },
    {
        id: 30,
        date: toTimestamp("2021-11-07"),
        category: timelineCategoryType.milestones,
        title: "Mothership Announced",
        link: "https://medium.com/@tsrocketfactory/introducing-phase-ii-the-mothership-e592e9ade8c5",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211107_Milestone_Mothership%20Announced.jpg",
        description: "",
    },
    {
        id: 31,
        date: toTimestamp("2021-11-04"),
        category: timelineCategoryType.irlEvents,
        title: "NFT NYC Pizza Party 2021",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211104_IRL%20Meetup_NFT%20NYC%20Pizza%20Party%202021.jpg",
        description: "",
    },
    {
        id: 32,
        date: 1633708800,
        category: timelineCategoryType.launches,
        title: "St. Louis, USA",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20211008_Rocket%20Launch_St%20Louis%20USA.jpg",
        description: "",
    },
    {
        id: 33,
        date: 1632960000,
        category: timelineCategoryType.launches,
        title: "Paris, France",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210929_Rocket%20Launch_Paris%20France.jpg",
        description: "",
    },
    {
        id: 34,
        date: toTimestamp("2021-09-19"),
        dateEnd: toTimestamp("2022-04-10"),
        category: timelineCategoryType.irlEvents,
        title: "Space Program: Rare Earths Exhibition",
        link: "https://www.tomsachs.com/exhibitions/space-program-rare-earths",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210919_Exhibition_Space%20Program%20Rare%20Earths.jpg",
        description: "",
    },
    {
        id: 35,
        date: 1631923200,
        category: timelineCategoryType.launches,
        title: "Space Program: Rare Earths Opening Demonstration",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210917_Rocket%20Launch_Space%20Program%20Rare%20Earths%20Opening%20Demonstration.jpg",
        description: "",
    },
    {
        id: 36,
        date: 1631232000,
        category: timelineCategoryType.launches,
        title: "Hamburg, Germany",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210909_Rocket%20Launch_Hamburg%20Germany.jpg",
        description: "",
    },
    {
        id: 37,
        date: 1630706400,
        category: timelineCategoryType.launches,
        title: "New York, USA (First 10 Launch)",
        link: "",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210904_Rocket%20Launch_New%20York%20USA_First%2010%20Launch.jpg",
        description: "",
    },
    {
        id: 38,
        date: toTimestamp("2021-08-17"),
        category: timelineCategoryType.milestones,
        title: "Launch Schedule Opens",
        link: "https://medium.com/@tsrocketfactory/road-to-launch-3001bd9e1e77",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210817_Milestone_Launch%20Schedule%20Opens.jpg",
        description: "",
    },
    {
        id: 39,
        date: toTimestamp("2021-08-08"),
        category: timelineCategoryType.milestones,
        title: "Assembly Area Opens",
        link: "https://medium.com/@tsrocketfactory/some-assembly-required-8d267bcca74",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210808_Milestone_Assembly%20Area%20Opens.jpg",
        description: "",
    },
    {
        id: 40,
        date: toTimestamp("2021-08-01"),
        category: timelineCategoryType.milestones,
        title: "Components Go Live",
        link: "https://medium.com/@tsrocketfactory/countdown-ad5a7db4f322",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210801_Milestone_Components%20Go%20Live.jpg",
        description: "",
    },
    {
        id: 41,
        date: toTimestamp("2021-08-01"),
        category: timelineCategoryType.movies,
        title: "Rocket Factory: How it Works",
        link: "https://www.youtube.com/embed/fNzlU9tPM4o",
        image: "",
        description: "",
    },
    {
        id: 42,
        date: toTimestamp("2021-07-23"),
        category: timelineCategoryType.milestones,
        title: "Test Flight Crew Patch Release",
        link: "https://medium.com/@tsrocketfactory/test-flight-crew-patch-1cb7236c441",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210723_Milestone_Test%20Flight%20Crew%20Patch%20Release.jpg",
        description: "",
    },
    {
        id: 43,
        date: toTimestamp("2021-07-19"),
        category: timelineCategoryType.milestones,
        title: "Rocket Factory Announced",
        link: "https://medium.com/@tsrocketfactory/tom-sachs-rocket-factory-5f6bdf22da2",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20210719_Milestone_Rocket%20Factory%20Announced.jpg",
        description: "",
    },
    {
        id: 44,
        date: toTimestamp("2022-07-22"),
        category: timelineCategoryType.press,
        subtitle: "Tom Sachs: Rocket Man to Renaissance Man",
        title: "New York Times Article",
        link: "https://www.nytimes.com/2022/07/22/arts/design/tom-sachs-seoul.html",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220722_Press_New%20York%20Times.jpg",
        description:
            "The artist is selling NFTs, designing Nikes, exploring outer space (sort of) and uncorking three concurrent shows in Seoul.",
    },
    {
        id: 45,
        date: toTimestamp("2022-10-01"),
        category: timelineCategoryType.press,
        subtitle: "Tom Sachs’ NFT Rocket Factory scoops Wallpaper* Design Award 2022",
        title: "Wallpaper*",
        link: "https://www.wallpaper.com/art/tom-sachs-rocket-factory",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20221001_Press_Wallpaper.jpg",
        description:
            "Tom Sachs’ brilliantly imaginative Rocket Factory marks the American artist's foray into the world of NFTs, and scoops ‘Best Rocket Launch’ at this year's Wallpaper* Design Awards 2022.",
    },
    {
        id: 46,
        date: toTimestamp("2022-09-01"),
        category: timelineCategoryType.milestones,
        title: "Phase II Experience Report Zine Release",
        link: "https://store.tomsachs.com/collections/frontpage/products/rocket-factory-phase-ii-experience-report-zine-nft",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20220901_Milestone_Phase%20II%20Experience%20Report%20Zine%20Release.jpg",
    },
    {
        id: 47,
        date: toTimestamp("2023-02-24"),
        category: timelineCategoryType.irlEvents,
        title: "NFT Paris Activation",
        link: "https://www.nftparis.xyz/blog/tom-sachs-is-coming-to-nft-paris",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20230224_IRL%20Meetup_NFT%20Paris%20Activation.jpg",
    },
    {
        id: 48,
        date: toTimestamp("2022-11-02"),
        category: timelineCategoryType.milestones,
        title: '"Too Darn Hot" LACMA Print Release',
        link: "https://avantarte.com/products/too-darn-hot",
        image: "https://storage.googleapis.com/nftstudios-cms/production/tomSachs/20221102_Milestone_Too%20Darn%20Hot%20LACMA%20Print%20Release.jpg",
    },
];

const DATA_TIMELINE = DATA.sort(function (x, y) {
    return y.date - x.date;
});

export default DATA_TIMELINE;
