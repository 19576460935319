import ButtonCentered from "@components/ButtonCentered";
import { TIMELINE } from "@routes/routes";
import styles from "./BottomSection.module.scss";

const BottomSection = (): React.ReactElement => {
    return (
        <div className={styles.bottomSection}>
            <ButtonCentered to={TIMELINE}>View Full Timeline</ButtonCentered>
        </div>
    );
};

export default BottomSection;
