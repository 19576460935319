import { useMediaQuery } from "react-responsive";

interface IuseResponsive {
  isLaptopL: boolean;
  isLaptopS: boolean;
  isLaptopM: boolean;
  isTabletOrMobile: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isTabletS: boolean;
}

const useResponsive = (): IuseResponsive => {
  const isLaptopL = useMediaQuery({ query: "(min-width: 1440px)" });
  const isLaptopM = useMediaQuery({ query: "(max-width: 1200px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const isLaptopS = useMediaQuery({ query: "(max-width: 992px)" });
  const isTablet = useMediaQuery({ minWidth: 480, maxWidth: 992 });
  const isTabletS = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return {
    isMobile,
    isTablet,
    isLaptopS,
    isLaptopM,
    isTabletOrMobile,
    isLaptopL,
    isTabletS,
  };
};

export default useResponsive;
