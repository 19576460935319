import Container from "@components/Container";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import { RECAPTCHA_KEY } from "@constants/env";
import { CollectionCategory } from "@customTypes/Collection/CollectionCategory";
import useStuffScratcherMint from "@hooks/contracts/Stuff/useStuffScratcherMint";
import { IScratcherSignature } from "@interfaces/Scratcher/ScratcherSignature";
import MintingInProgress from "@pages/MintingInProgress";
import { HOME, PHYSICAL_SCRATCHER_SUCCESS } from "@routes/routes";
import { IS_STUFF_MINT_OPEN } from "@scripts/DateHelper";
import { ReactElement, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Redirect, generatePath } from "react-router-dom";
import styles from "./PhysicalScratcher.module.scss";
import InvalidSerialNumber from "./components/InvalidSerialNumber";
import SerialNumberInput from "./components/SerialNumberInput";
import ValidSerialNumber from "./components/ValidSerialNumber";

const PhysicalScratcher = (): ReactElement => {
    const { mint, isMintingInProgress, isMintingSuccessful, txId } = useStuffScratcherMint();
    const [signature, setSignature] = useState<IScratcherSignature | null>(null);
    const [showFail, setShowFail] = useState(false);

    const handleSetShowSuccess = (signature: IScratcherSignature) => {
        setShowFail(false);
        setSignature(signature);
    };

    const handleSetShowFail = () => {
        setShowFail(true);
        setSignature(null);
    };

    const handleReset = () => {
        setShowFail(false);
        setSignature(null);
    };

    const handleMint = () => {
        if (!signature) {
            return;
        }

        mint(signature);
    };

    if (isMintingSuccessful && txId) {
        return <Redirect to={generatePath(PHYSICAL_SCRATCHER_SUCCESS, { txId })} />;
    }

    if (!IS_STUFF_MINT_OPEN) {
        return <Redirect to={HOME} />;
    }

    if (isMintingInProgress) {
        return <MintingInProgress txId={txId} mintingType={CollectionCategory.stuff} />;
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
            <GalaxyBackground>
                <Container className={styles.physicalScratcher}>
                    <h1>Physical Scratcher</h1>
                    <p>
                        Congratulations on winning a TSRF "Stuff" NFT! Please provide the Serial Number (S/N) on the
                        bottom of your scratcher to claim your NFT.
                    </p>
                    <SerialNumberInput
                        onSuccess={handleSetShowSuccess}
                        onFailure={handleSetShowFail}
                        onReset={handleReset}
                    />
                    {showFail && <InvalidSerialNumber />}
                    {signature && <ValidSerialNumber onMint={handleMint} />}
                </Container>
            </GalaxyBackground>
        </GoogleReCaptchaProvider>
    );
};

export default PhysicalScratcher;
