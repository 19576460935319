import DATA_TIMELINE from "./Timeline.data";
import PageContainer from "@components/PageContainer";
import { timelineCategoryType } from "../../types/Timeline/TimelineCategoryType";
import { ITimeline } from "@interfaces/Timeline/Timeline";
import LaunchCard from "./components/LaunchCard";
import PressCard from "./components/PressCard";
import IRLMeetupCard from "./components/IRLMeetupCard";
import MilestoneCard from "./components/MilestoneCard";
import MovieCard from "./components/MovieCard";
import { useParams } from "react-router-dom";

const Timeline = (): JSX.Element => {
    const { category } = useParams<{ category: string }>();
    const categorySearch = decodeURIComponent(category);
    const categories = Object.values(timelineCategoryType).map((value) => value);

    const data =
        categorySearch !== timelineCategoryType.all.toString()
            ? DATA_TIMELINE.filter((item: ITimeline) => item.category === categorySearch)
            : DATA_TIMELINE;

    return (
        <PageContainer title="Timeline" categories={categories}>
            {data.map((item) => {
                switch (item.category) {
                    case timelineCategoryType.launches:
                        return <LaunchCard item={item} />;
                    case timelineCategoryType.press:
                        return <PressCard item={item} />;
                    case timelineCategoryType.irlEvents:
                        return <IRLMeetupCard item={item} />;
                    case timelineCategoryType.milestones:
                        return <MilestoneCard item={item} />;
                    case timelineCategoryType.movies:
                        return <MovieCard item={item} />;
                    default:
                        break;
                }
            })}
        </PageContainer>
    );
};

export default Timeline;
