const PLANET_DESERT = 0;
const PLANET_FOREST = 1;
const PLANET_WATER = 2;
const PLANET_VOLCANIC = 3;
const PLANET_POST_CIV = 4;
const PLANET_ARTIFICIAL = 5;
const PLANET_COMET = 6;
const PLANET_ROCKYMOON = 7;
const PLANET_JOVIAN = 8;

const BEDROOM_UNITE = 9;
const BEDROOM_PLYWOOD = 10;
const BEDROOM_STEEL = 11;
const STUDIO_UNITE = 12;
const STUDIO_PLYWOOD = 13;
const STUDIO_STEEL = 14;

export const PLANET_NAMES = {
    [PLANET_WATER]: "Water",
    [PLANET_FOREST]: "Forest",
    [PLANET_DESERT]: "Desert",
    [PLANET_ARTIFICIAL]: "Artificial",
    [PLANET_POST_CIV]: "Post Civ",
    [PLANET_VOLCANIC]: "Volcanic",
    [PLANET_JOVIAN]: "Jovian Moon",
    [PLANET_ROCKYMOON]: "Rocky Moon",
    [PLANET_COMET]: "Comet",
};

export const SPACE_STATION_NAMES = {
    [BEDROOM_UNITE]: "One Bedroom Apartment (Unite)",
    [BEDROOM_PLYWOOD]: "One Bedroom Apartment (Plywood)",
    [BEDROOM_STEEL]: "One Bedroom Apartment (Steel)",
    [STUDIO_UNITE]: "Studio Apartment (Unite)",
    [STUDIO_PLYWOOD]: "Studio Apartment (Plywood)",
    [STUDIO_STEEL]: "Studio Apartment (Steel)",
};
