import { ReactNode } from "react";
import formatDate from "@formatting/formatDate";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import styles from "./TimelineCard.module.scss";
import { getSingularCategoryType, timelineCategoryType } from "@customTypes/Timeline/TimelineCategoryType";

interface ITimelineCard {
    date: number;
    dateEnd?: number;
    title: string;
    category: timelineCategoryType;
    children?: ReactNode;
}

const TimelineCard = ({ date, dateEnd, title, category, children }: ITimelineCard): JSX.Element => {
    const dateFrom = formatDate(date, "MM.dd.yyyy");
    const dateTo = formatDate(dateEnd, "MM.dd.yyyy");

    return (
        <div className={styles.timelineCard__container}>
            <Title heading={HeadingType.h4} headingColor="gray">
                {dateFrom} {dateEnd && ` - ${dateTo}`}
            </Title>
            <Title heading={HeadingType.h3}>
                {getSingularCategoryType(category)} - {title}
            </Title>
            <div>{children}</div>
        </div>
    );
};

export default TimelineCard;
