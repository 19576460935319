import videoThumbnailPlanets from "@assets/images/home/videoThumbnailPlanets.jpg";
import stuffThumbnail from "@assets/images/howItWorks/stuffThumbnail.jpg";
import ButtonCentered from "@components/ButtonCentered";
import Player from "@components/Player";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import { HOME_VIDEO } from "@constants/externalUrls";
import { PLANET_MINT_FINAL_FRONTIER } from "@routes/routes";
import styles from "./PhaseIIIHero.module.scss";

const PhaseIIIHero = (): JSX.Element => (
    <div className={styles.phaseIIIHero}>
        <div className={styles.textContainer}>
            <Title heading={HeadingType.h2}>PHASE III - THE FINAL FRONTIER</Title>
            <Player
                width="100%"
                volume={1}
                controls={false}
                muted={false}
                loop={false}
                playing={true}
                media={HOME_VIDEO}
                height="700px"
                light={videoThumbnailPlanets}
            />
            <p>
                Prepare for Landing. Mars Rocks have fueled our Journey to the Final Frontier. Each Rock will be
                Transubstantiated into a unique, hand-made generative World. From a Planet of your own to a Stateroom
                aboard a Space Station.
            </p>
        </div>
        <ButtonCentered to={PLANET_MINT_FINAL_FRONTIER}>MINT NOW</ButtonCentered>
        <div className={styles.bottomVideo}>
            <Player
                width="100%"
                volume={1}
                controls={false}
                muted={false}
                loop={false}
                playing={true}
                media={"https://www.youtube.com/watch?v=bJljMPcfI-Q"}
                height="700px"
                light={stuffThumbnail}
            />
        </div>
    </div>
);
export default PhaseIIIHero;
