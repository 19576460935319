import Container from "@components/Container";
import Link, { LinkVariant } from "@components/Link";
import { HOME, PLANET_MINT_FINAL_FRONTIER } from "@routes/routes";
import { IS_PLANETS_PHASE_OPEN } from "@scripts/DateHelper";
import { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import styles from "./PlanetMintHome.module.scss";

const PlanetMintHome = (): ReactElement => {
    if (!IS_PLANETS_PHASE_OPEN) {
        return <Redirect to={HOME} />;
    }

    return (
        <div className={styles.planetMintHome}>
            <Container>
                <h1>The Final Frontier</h1>
                <Link to={PLANET_MINT_FINAL_FRONTIER} variant={LinkVariant.Button}>
                    Mint now
                </Link>
            </Container>
        </div>
    );
};

export default PlanetMintHome;
