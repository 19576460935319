import Container from "@components/Container";
import GalaxyBackground from "@components/Starmap/GalaxyBackground";
import { EScratcherType } from "@customTypes/Scratcher/ScratcherType";
import useGetTokensMintedOnTx from "@hooks/contracts/Stuff/useGetTokensMintedOnTx";
import { IStuff } from "@interfaces/Stuff/Stuff";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./ScratcherSuccessPage.module.scss";
import ButtonsSection from "./components/ButtonsSection";
import StuffContainer from "./components/StuffContainer";

interface IScratcherSuccesPageProps {
    scratcherType?: EScratcherType;
}

const ScratcherSuccessPage = ({ scratcherType = EScratcherType.PHYSICAL }: IScratcherSuccesPageProps): ReactElement => {
    const { txId } = useParams<{ txId: string }>();
    const { isInitialized, getTokensMintedOnTx } = useGetTokensMintedOnTx();
    const [notFound, setNotFound] = useState(false);
    const [mintedTokens, setMintedTokens] = useState<IStuff[]>([]);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        getTokensMintedOnTx(txId).then((tokens) => {
            if (tokens.length === 0) {
                setNotFound(true);
                return;
            }

            setMintedTokens(tokens);
        });
    }, [isInitialized]);

    return (
        <GalaxyBackground isGalaxyLight>
            <Container className={styles.scratcherSuccessPage}>
                <h1>Success Page</h1>
                <p>
                    Congratulations! You have successfully claimed your "Stuff" NFTs.
                    <br />
                    Your "Stuff" NFTs come with an associated #D model that can be viewed in your hangar.
                    <br />
                    These objects will soon be available to place within TSRF Final Frontier metaverse spaces.
                </p>
                <StuffContainer stuffs={mintedTokens} />
                <hr />
                <ButtonsSection scratcherType={scratcherType} />
            </Container>
        </GalaxyBackground>
    );
};

export default ScratcherSuccessPage;
