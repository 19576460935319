import { ReactElement } from "react";
import Attribute from "@components/Attribute";
import styles from "./BoxAttributes.module.scss";
import { IMetadataAttribute } from "@interfaces/Metadata/MetadataAttribute";

interface IBoxAttributes {
    attributes: IMetadataAttribute[];
}

const BoxAttributes = ({ attributes }: IBoxAttributes): ReactElement => (
    <div className={styles.boxAttributes}>
        <h2>ATTRIBUTES</h2>
        <div className={styles.boxAttributes__grid}>
            {attributes.map((attr) => (
                <Attribute key={attr.trait_type} attribute={attr.trait_type} subtitle={attr.value} data={attr.data} />
            ))}
        </div>
    </div>
);

export default BoxAttributes;
