import styles from "./RequiredReadingAndViewing.module.scss";
import rockTypes from "@assets/images/howItWorks/rockTypes.jpg";
import zine from "@assets/images/howItWorks/zine.jpg";
import Title from "@components/Title";
import { HeadingType } from "@components/Title/Title";
import ModalImage from "react-modal-image";
import Link, { LinkVariant } from "@components/Link";
import { DOWNLOAD_PDF_ZINE_URL, PURCHASE_REPORT_ZINE_URL } from "@constants/externalUrls";

const RequiredReadingAndViewing = (): JSX.Element => {
    return (
        <div className={styles.requiredReadingAndViewing__container}>
            <Title heading={HeadingType.h3}>Phase II Required Reading and Viewing:</Title>
            <div className={styles.imageContainer}>
                <div className={styles.marsRocks}>
                    <ModalImage small={rockTypes} large={rockTypes} alt="Rock Types" hideDownload={true} />
                    <h5>Mars Rock Types</h5>
                </div>
                <div className={styles.zine}>
                    <ModalImage small={zine} large={zine} alt="Zine" hideDownload={true} />
                    <h5>Phase II Experience Report Zine</h5>
                    <div className={styles.buttonContainer}>
                        <Link variant={LinkVariant.Button} to={PURCHASE_REPORT_ZINE_URL}>
                            Buy Here
                        </Link>
                        <Link variant={LinkVariant.Button} to={DOWNLOAD_PDF_ZINE_URL}>
                            Download PDF
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequiredReadingAndViewing;
