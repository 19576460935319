import { addressToName } from "@scripts/PatchHelper";
import { ITokenItem } from "@interfaces/Hangar/TokenItem";
import TemplateBox from "../TemplateBox";
import { EHangarTitleType } from "@customTypes/MyHangar/HangarTitleType";
import Link from "@components/Link";
import { getOpenSeaLink } from "@scripts/OpenSeaHelper";

interface IPatchesParams {
    items: ITokenItem[];
}

const Patches = ({ items }: IPatchesParams): JSX.Element => {
    const renderedPatches = {};

    if (items.length === 0) {
        return <></>;
    }

    return (
        <>
            <TemplateBox title={EHangarTitleType.PATCHES}>
                {items.map(({ contractAddress, image, id }, i) => {
                    if (renderedPatches[contractAddress]) {
                        return;
                    }

                    renderedPatches[contractAddress] = true;
                    return (
                        <Link to={getOpenSeaLink(contractAddress, id.toString())} key={`${id}-${i}`}>
                            <img src={image} alt={addressToName(contractAddress)} />
                            <span>{addressToName(contractAddress)}</span>
                        </Link>
                    );
                })}
            </TemplateBox>
        </>
    );
};

export default Patches;
