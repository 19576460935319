import {
    COMMEMORATIVE_TOKEN_CONTRACT_ADDRESS,
    EIGHT_EIGHT_CONTRACT_ADDRESS,
    FIRST_10_CONTRACT_ADDRESS,
    STL_CONTRACT_ADDRESS,
} from "@constants/env";

export const addressToName = (address: string): string => {
    if (address.toLowerCase() === COMMEMORATIVE_TOKEN_CONTRACT_ADDRESS.toLowerCase()) {
        return "TEST FLIGHT CREW";
    }

    if (address.toLowerCase() === FIRST_10_CONTRACT_ADDRESS.toLowerCase()) {
        return "First 10";
    }

    if (address.toLowerCase() === EIGHT_EIGHT_CONTRACT_ADDRESS.toLowerCase()) {
        return "8/8";
    }

    if (address.toLowerCase() === STL_CONTRACT_ADDRESS.toLowerCase()) {
        return "STL";
    }

    return "";
};
