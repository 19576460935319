import axios from "axios";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { PUBLIC_ADDRESS_HEADER, SIGNATURE_HEADER } from "@constants/apiEndpoints";
import { API_BASE_URL } from "@constants/env";
import { SessionContext } from "@context/SessionContext";
import { HOME } from "@routes/routes";
import Loader from "@components/Loader";
import styles from "./Zine.module.scss";

const Zine = (): ReactElement => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const { walletAddress, isInitialized } = useContext(SessionContext);

    useEffect(() => {
        if (walletAddress) {
            handleReadReport();
        }
    }, [walletAddress]);

    const handleReadReport = async () => {
        setIsLoading(true);
        const signature = localStorage.getItem("zineSignature");
        axios
            .get("/hangar/mineReport", {
                baseURL: API_BASE_URL,
                headers: {
                    [PUBLIC_ADDRESS_HEADER]: walletAddress,
                    [SIGNATURE_HEADER]: signature,
                },
            })
            .then(({ data }) => {
                setImages(data);
                setIsLoading(false);
            })
            .catch(() => {
                history.push(HOME);
            });
    };

    if (isInitialized && !walletAddress) {
        return <Redirect to={HOME} />;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className={styles.zine}>
            {images.map((image) => (
                <img src={image} alt="" key={image} />
            ))}
        </div>
    );
};

export default Zine;
