import { IScratcherSignature } from "@interfaces/Scratcher/ScratcherSignature";
import checkSerialNumber from "@services/Scratcher/checkSerialNumber";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface returnTypes {
    getSignature: (walletAddress: string, serialNumbers: string[]) => Promise<IScratcherSignature>;
}

const RECAPTCHA_VERSION = "v3";

const useGetScratcherSignature = (): returnTypes => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            throw "Execute recaptcha not yet available";
        }

        return await executeRecaptcha("yourAction");
    };

    const getSignature = async (walletAddress: string, serialNumbers: string[]): Promise<IScratcherSignature> => {
        const recaptchaToken = await handleReCaptchaVerify();

        const { data } = await checkSerialNumber(walletAddress, RECAPTCHA_VERSION, serialNumbers, recaptchaToken);

        return data;
    };

    return {
        getSignature,
    };
};

export default useGetScratcherSignature;
